import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import routeState from 'decorators/routeState';
import HeaderContainer from 'domains/Header/HeaderContainer';
// we're only requiring the footer here so webpack knows to compile
// its css into the legacy bundle
// eslint-disable-next-line no-unused-vars
import FooterLayout from 'domains/Footer/FooterLayout';

// FIXME: We had issues in the past when we used tpt-connect and this was a stateless function,
// but might be able to clean this up now:
/* eslint-disable react/prefer-stateless-function */
@connect(({ config: { assetManifest } }) => ({ assetManifest }))
@routeState
class LegacyEntry extends Component {
  static propTypes = {
    assetManifest: PropTypes.object.isRequired,
    routeState: PropTypes.shape({
      shouldHideHeaderFooter: PropTypes.bool
    })
  };

  static defaultProps = {
    routeState: {}
  };

  render() {
    const { legacy, legacyGrid } = this.props.assetManifest;
    return (
      <div className="LegacyEntry">
        <Helmet
          link={[
            { rel: 'stylesheet', href: legacy.css },
            { rel: 'stylesheet', href: legacyGrid.css }
          ]}
        />
        <HeaderContainer
          {...this.props}
          isHidden={!!this.props.routeState.shouldHideHeaderFooter}
        />
        {this.props.children}
      </div>
    );
  }
}

LegacyEntry.propTypes = {
  children: PropTypes.node
};

export default LegacyEntry;
