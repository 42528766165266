const CHAT_PAGES_OPTIONS = [
  {
    regex: /^\/help\/?$/i,
    deptName: 'Customer Experience',
    tags: 'CustomerExperience',
    hideOnOffline: true
  },
  {
    regex: /^\/(My-School|School-Welcome)\/?$/i,
    deptName: 'Account Management',
    tags: 'AccountManagement',
    hideOnOffline: true
  }
];

/**
 * Fetch options for a given path. Returns first match in CHAT_PAGES_OPTIONS
 * @param { string } path
 * @return { object } options object, undefined if not found
 */
function getPathOptions(path) {
  return CHAT_PAGES_OPTIONS.find((opt) => opt.regex.test(path));
}
/**
 * Utility function to determine if given path is where the Zendesk chat widget
 * should be rendered. Add new path regex into chatPathPathPatterns array above.
 * @param { string } path
 * @return { boolean } true if is a chat page, false otherwise
 */
export function isChatPage(path) {
  return !!getPathOptions(path);
}

/**
 * Utility function to get Zendesk initialization function depending on pathname.
 * Makes it easy to define custom behavior depending on what page chat is rendered on
 * @param { string } path
 * @return { function } initialization function if options could be found for path, else null
 */
export function chatPageInitFunc(path) {
  if (!isChatPage(path)) {
    return null;
  }

  const { deptName, tags, hideOnOffline } = getPathOptions(path);

  return function zopimInit() {
    const dept = window.$zopim.livechat.departments.getDepartment(deptName);
    window.$zopim.livechat.addTags(tags);

    if (hideOnOffline) {
      if (dept && dept.status === 'online') {
        window.$zopim.livechat.departments.filter(deptName);
        window.$zopim.livechat.departments.setVisitorDepartment(deptName);
      } else {
        window.$zopim.livechat.setStatus('offline');
        window.$zopim.livechat.hideAll();
      }
    } else {
      window.$zopim.livechat.departments.filter(deptName);
      window.$zopim.livechat.departments.setVisitorDepartment(deptName);
    }
  };
}
