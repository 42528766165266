import match from 'react-router/lib/match';

import { parseQueryString } from './queryString';

/**
 * resolves with react-router props if there was a route match, otherwise,
 * rejects
 * @param { !object } opts
 * @return { !Promise }
 */
export default function computeReactRouterProps(opts) {
  return new Promise((resolve, reject) => {
    match({ parseQueryString, ...opts }, (err, redirectLocation, renderProps) => {
      if (err) {
        reject(err);
        return;
      }

      if (redirectLocation) {
        reject(redirectLocation);
        return;
      }

      if (renderProps) {
        resolve(renderProps);
        return;
      }

      reject(new Error('No matching route'));
    });
  });
}
