import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './ResponsiveGrid.scss';

const Grid = ({ children, hasPadding, className }) => (
  <div className="responsive">
    <div
      className={classNames(
        'container',
        {
          'container-lg-padded': hasPadding
        },
        className
      )}
    >
      {children}
    </div>
  </div>
);

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  hasPadding: PropTypes.bool,
  className: PropTypes.string
};

Grid.defaultProps = {
  hasPadding: false,
  className: undefined
};

export default Grid;
