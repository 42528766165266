import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Anchor from 'components/Anchor';
import './LinkButton.scss';

// A button component that contains a "to" property for specifying
// what to link to when the button is clicked.
const LinkButton = ({ className, children, label, labelClass, disabled, ...props }) => (
  <Anchor
    forceReload
    {...props}
    color="inherit"
    className={classnames('LinkButton', className, { disabled })}
  >
    {label && (
      <span className={classnames('A__label', 'LinkButton__label', labelClass)}>{label}</span>
    )}
    {children && children}
  </Anchor>
);

LinkButton.propTypes = {
  /**
   * Inner contents (can pass this or `label` prop)
   */
  children: PropTypes.node,
  /**
   * Text to appear in label
   */
  label: PropTypes.string,
  /**
   * For styling the text inside of the button.
   */
  labelClass: PropTypes.string,
  /**
   * For adding css styling.
   */
  className: PropTypes.string,
  /**
   * if true the link is disabled
   */
  disabled: PropTypes.bool
};

LinkButton.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  labelClass: '',
  label: null
};

export default LinkButton;
