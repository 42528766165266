import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

const CopyrightToolPage = ({ config }) => (
  <Helmet
    script={[
      {
        src: config.assetManifest.copyrightTool.js,
        defer: true
      }
    ]}
    link={[
      {
        rel: 'stylesheet',
        href: config.assetManifest.copyrightTool.css
      }
    ]}
  />
);

CopyrightToolPage.propTypes = {
  config: PropTypes.shape({
    assetManifest: PropTypes.shape({
      copyrightTool: PropTypes.shape({
        js: PropTypes.string,
        css: PropTypes.string
      })
    })
  })
};

export default connect(({ config }) => ({ config }))(CopyrightToolPage);
