/* eslint-disable @miovision/disallow-date/no-new-date */
import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import withLocation from 'decorators/withLocation';
import { isChatPage, chatPageInitFunc } from 'lib/chatPage';

const userDataQuery = gql`
  query Member {
    member {
      id
      username
      email
    }
  }
`;

@withLocation
class ZendeskChat extends React.Component {
  static propTypes = {
    userInfoData: PropTypes.shape({
      member: PropTypes.object
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string
    }).isRequired
  };

  componentWillMount() {
    if (IS_BROWSER && !IS_TEST) {
      window.$zopim =
        window.$zopim ||
        ((d, s) => {
          const z = function (c) {
            z._.push(c);
          };
          const $ = d.createElement(s);
          z.s = $;
          const e = d.getElementsByTagName(s)[0];
          window.$zopim = z;
          z.set = function (o) {
            z.set._.push(o);
          };
          z._ = [];
          z.set._ = [];
          $.async = !0;
          $.onload = () => {
            window.$zopim(() => {
              window.$zopim.livechat.setOnConnected(this.customize.bind(this));
            });
          };
          $.setAttribute('charset', 'utf-8');
          $.src = 'https://v2.zopim.com/?4RGWwRw6X5Ueis88vra4A0pkgXDHIuU2';
          z.t = +new Date();
          $.type = 'text/javascript';
          e.parentNode.insertBefore($, e);
        })(document, 'script');
    }
  }

  shouldComponentUpdate() {
    return false; // NEVER RE-RENDER
  }

  customize() {
    const user = this.props.userInfoData.member;

    if (user && user.username) {
      // for now, let the user type in their display name
      // TODO: look into autofilling this from the API
      // window.$zopim.livechat.setName(user.username);
    }

    if (user && user.email) {
      window.$zopim.livechat.setEmail(user.email);
    }

    if (isChatPage(this.props.location.pathname)) {
      const init = chatPageInitFunc(this.props.location.pathname);
      if (typeof init === 'function') {
        init();
      }
    }
  }

  render() {
    return <div />;
  }
}

export default compose(graphql(userDataQuery, { name: 'userInfoData' }))(ZendeskChat);
