import PropTypes from 'prop-types';
import React from 'react';

export const ReactTrackingContext = React.createContext({});

/**
 * @file Allows passing TrackedComponent all tracking properties from an arbitrary ancestor.
 * You must provide a `contextTag` and then use it on a matching `TrackedComponent`
 * which will pick up all the properties provided to TrackingContext.
 * Note: you may layer multiple TrackingContexts with different contextTags.
 * In the following example, clicking the deeply nested button will trigger the event "hi/yo".
 * @example
 * <TrackingContext contextTag="test" category="hi" action="yo">
 *  <div
 *   <div>
 *    <div>
 *      <TrackedComponent contextTag="test">
 *        <button />
 *      </TrackedComponent>
 *    </div>
 *   </div>
 *  </div>
 * </TrackingContext>
 */

function TrackingContext({ contextTag, children, ...trackingContextProps }) {
  return (
    <ReactTrackingContext.Consumer>
      {(previousTrackingContextProps) => (
        <ReactTrackingContext.Provider
          value={{
            ...previousTrackingContextProps,
            [contextTag]: trackingContextProps
          }}
        >
          {children}
        </ReactTrackingContext.Provider>
      )}
    </ReactTrackingContext.Consumer>
  );
}

TrackingContext.propTypes = {
  contextTag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default TrackingContext;
