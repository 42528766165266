export const TPT_DESELECT_ALL_RESOURCES = '@@tpt/DESELECT_ALL_RESOURCES';
export const TPT_SELECT_ALL_RESOURCES = '@@tpt/SELECT_ALL_RESOURCES';
export const TPT_TOGGLE_RESOURCE = '@@tpt/TOGGLE_RESOURCE';

export function deselectAllResources(resources) {
  return {
    type: TPT_DESELECT_ALL_RESOURCES,
    payload: resources
  };
}

export function selectAllResources(resources) {
  return {
    type: TPT_SELECT_ALL_RESOURCES,
    payload: resources
  };
}

export function toggleResource(resource) {
  return {
    type: TPT_TOGGLE_RESOURCE,
    payload: resource
  };
}
