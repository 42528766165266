import React from 'react';
import Badge from 'domains/UploadPage/Badge';
import PropTypes from 'prop-types';

const getMenuItemText = (inSellMenuSection) =>
  inSellMenuSection ? 'My Easel Listings ' : 'My Easel Library ';

const DigitalActivitiesMenuItemPartial = ({ inSellMenuSection }) => (
  <span>
    {getMenuItemText(inSellMenuSection)}
    <Badge label="NEW" />
  </span>
);

DigitalActivitiesMenuItemPartial.propTypes = {
  inSellMenuSection: PropTypes.bool
};

export default DigitalActivitiesMenuItemPartial;
