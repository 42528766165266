export const getTextSize = (size) => {
  switch (size) {
    case 'micro':
      return 'microcopy';
    case 'small':
      return 'bodySmall';
    case 'large':
      return 'bodyLarge';
    default:
      return 'body';
  }
};
