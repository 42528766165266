import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import Grid from 'components/Grid';
import Row from 'components/Grid/Row';
import Col from 'components/Grid/Col';
import Logo from 'components/Logo';
import CategoryMenu from 'components/CategoryMenu';
import ErrorBoundary from 'components/ErrorBoundary';
import HeaderNavList from 'domains/Header/HeaderNavList';
import { isChatPage } from 'lib/chatPage';
import OptimizelyBannerContainer from 'components/OptimizelyBannerContainer';
import EaselBannerEyebrow from 'domains/TpTEasel/TpTEaselEyebrow/EaselBannerEyebrow';
import PreSaleNotifications from './PreSaleNotifications';
import HeaderSearchContainer from './HeaderSearch/HeaderSearchContainer';
import SellerHeaderContainer from './SellerHeader/SellerHeaderContainer';
import BuyerHeaderContainer from './BuyerHeader/BuyerHeaderContainer';
import LoggedOutHeaderLayout from './LoggedOutHeader/LoggedOutHeaderLayout';
import SaleBannerContainer from './SaleBanner/SaleBannerContainer';
import ClassFundInterruptSocialModalContainer from './ClassFundInterruptSocialModal/ClassFundInterruptSocialModalContainer';
import ZendeskChat from './ZendeskChat';
import HeaderAlertContainer from './HeaderAlertContainer';
import SchoolsAccessSellerEnrollmentModalContainer from '../../domains/SchoolsAccessSellerEnrollmentModal/SchoolsAccessSellerEnrollmentModalContainer';
import './HeaderLayout.scss';

const HeaderLayout = ({
  isHidden,
  isSeller,
  isPremiumSeller,
  isBasicOrPremiumSeller,
  isLoggedIn,
  pathname,
  shouldShowPreSaleNotification,
  schoolMatchingModalComponent,
  adminToolboxComponent,
  featureVariants,
  referer,
  hasOptedOutOfSchools2020Catalog
}) => {
  const isEaselEducationExperiment = ['header_adoption', 'all_changes'].includes(
    featureVariants.easel_education
  );
  const shouldShowEasel =
    featureVariants.digital_activities_nav_bar === 'on' && !isEaselEducationExperiment;
  const hasLargerSearchBar = !isLoggedIn && !isEaselEducationExperiment;

  return (
    <div className={classnames('HeaderLayout', { 'HeaderLayout--hidden': isHidden })}>
      {isChatPage(pathname) && <ZendeskChat />}
      {schoolMatchingModalComponent}
      {featureVariants.optimizely_banner === 'on' && <OptimizelyBannerContainer />}
      <SaleBannerContainer />
      <EaselBannerEyebrow featureVariants={featureVariants} isLoggedIn={isLoggedIn} />
      <ClassFundInterruptSocialModalContainer
        variant={featureVariants.classfund_interrupt_modal_v2}
        pathname={pathname}
        isLoggedIn={isLoggedIn}
      />

      {isLoggedIn &&
        isBasicOrPremiumSeller &&
        pathname.startsWith('/Dashboard') &&
        featureVariants &&
        featureVariants.schools_catalog_seller_modal === 'on' && (
          <SchoolsAccessSellerEnrollmentModalContainer
            hasOptedOutOfSchools2020Catalog={hasOptedOutOfSchools2020Catalog}
          />
        )}

      <Grid
        className={classnames('HeaderLayout__container', 'HeaderLayout__container--bottomBorder')}
      >
        <Row className="HeaderLayout__containerContent">
          <Col xs={12}>
            <Row>
              <Col xs={4}>
                <div className="HeaderLayout__logo">
                  <Logo />
                </div>
              </Col>
              <Col xs={8}>
                <HeaderNavList
                  shouldShowSchools={!isLoggedIn}
                  shouldShowDigitalActivities={shouldShowEasel}
                  isLoggedIn={isLoggedIn}
                  isSeller={isSeller}
                  isPremiumSeller={isPremiumSeller}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={2}>
                <CategoryMenu />
              </Col>
              <Col xs={hasLargerSearchBar ? 6 : 5}>
                <ErrorBoundary>
                  <HeaderSearchContainer featureVariants={featureVariants} referer={referer} />
                </ErrorBoundary>
              </Col>
              <Col xs={hasLargerSearchBar ? 4 : 5} className="HeaderLayout__pushDown">
                {isLoggedIn && isSeller && (
                  <SellerHeaderContainer featureVariants={featureVariants} />
                )}
                {isLoggedIn && !isSeller && (
                  <BuyerHeaderContainer featureVariants={featureVariants} />
                )}
                {!isLoggedIn && <LoggedOutHeaderLayout featureVariants={featureVariants} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
      {adminToolboxComponent}
      <div className="HeaderLayout__alerts">
        <PreSaleNotifications shouldRender={shouldShowPreSaleNotification} />
        <HeaderAlertContainer
          isLoggedIn={isLoggedIn}
          featureVariants={featureVariants}
          pathname={pathname}
          isHidden={isHidden}
        />
      </div>
    </div>
  );
};

HeaderLayout.defaultProps = {
  adminToolboxComponent: null
};

HeaderLayout.propTypes = {
  isHidden: PropTypes.bool,
  isSeller: PropTypes.bool.isRequired,
  isPremiumSeller: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  shouldShowPreSaleNotification: PropTypes.bool.isRequired,
  adminToolboxComponent: PropTypes.node,
  featureVariants: featureVariantsPropTypes,
  referer: PropTypes.string,
  schoolMatchingModalComponent: PropTypes.node,
  isBasicOrPremiumSeller: PropTypes.bool,
  hasOptedOutOfSchools2020Catalog: PropTypes.bool
};

export default HeaderLayout;
