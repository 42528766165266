import { applyMiddleware, createStore as _createStore, combineReducers, compose } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import tptReducers from 'lib/reducers';

export default function createStore(initialState = {}, reducers = {}, middlewares = []) {
  const _window = global || window || {};
  const composeEnhancers = _window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? _window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
  return _createStore(
    combineReducers({
      ...tptReducers,
      routing: routerReducer,
      config: (state = {}) => state,
      form: formReducer,
      ...reducers
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}
