export const BRAINTREE_PAYMENT = 'BRAINTREE';
export const PURCHASE_ORDER_PAYMENT = 'PURCHASE_ORDER';
export const METHOD_NOT_REQUIRED = 'METHOD_NOT_REQUIRED';

export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';

export function setPaymentType(value) {
  return { type: SET_PAYMENT_TYPE, value: value || METHOD_NOT_REQUIRED };
}

export default function reducer(state = {}, payload = {}) {
  if (payload.type === SET_PAYMENT_TYPE) {
    return { ...state, paymentType: payload.value };
  }

  return state;
}
