import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';

import './MenuItem.scss';

// One list item in a Menu.
const MenuItem = (props) => {
  const {
    className,
    value,
    selected = false,
    label = props.children,
    hideIcon,
    size,
    ...other
  } = props;
  return (
    <li
      {...other}
      data-value={value}
      className={classnames(
        'MenuItem',
        { selected },
        className,
        typeof size === 'string' ? `MenuItem--${size}` : ''
      )}
      selected={selected}
    >
      <span className="MenuItem__label">{label}</span>
      {selected && !hideIcon && <Icon className="MenuItem__check" name="check" />}
    </li>
  );
};

MenuItem.propTypes = {
  /**
   * For adding css styling.
   */
  className: PropTypes.string,
  /**
   * If true, the item is selected.
   */
  selected: PropTypes.bool,
  /**
   * The text displayed for that item.
   */
  label: PropTypes.any,
  children: PropTypes.node,
  /**
   * Used elsewhere to reference that items.
   */
  value: PropTypes.any,
  hideIcon: PropTypes.bool,
  /**
   * Amount of padding to be applied
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md'])
};

MenuItem.defaultProps = {
  hideIcon: false
};

export default MenuItem;
