import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'react-apollo';
import MenuSection from 'components/MenuSection';
import InboxMenuItemPartial from 'domains/Header/components/InboxMenuItem';
import Badge from 'domains/UploadPage/Badge';
import { EASEL_ACTIVITIES_URL } from 'config/constants';
import { applicationFeatures } from 'config/features';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import featureVariantsInfo from 'decorators/featureVariants';
import { CONTRIBUTION_BASE_URL, pageStatuses } from 'domains/Contribution/helpers';
import withClassfundSummary from 'decorators/withClassfundSummary';
import DigitalActivitiesMenuItemPartial from 'domains/Header/components/DigitalActivitiesMenuItem';

/**
  Menu items for a Buyer. To be used in headers.
  @return { Component }
  */
const BuyerDropdownMenu = ({
  featureVariants,
  countUnreadMessages,
  classfundSummary: { status }
}) => (
  <React.Fragment>
    <MenuSection
      title="Account"
      items={[
        { title: 'My Account', url: '/My-Account/Basics' },
        {
          title: 'Account Balance',
          url: `/Account-Balance`
        },
        {
          title: (
            <span>TpT ClassFund {status === pageStatuses.DRAFT && <Badge label="DRAFT" />}</span>
          ),
          url: `/${CONTRIBUTION_BASE_URL}`
        },
        featureVariants &&
          featureVariants.schools_access_header_links === 'on' && {
            title: <span>TpT School Access</span>,
            url: `/TpTSchoolAccess/Teachers`,
            trackedComponentProps: {
              category: 'Header',
              action: 'Clicked TpT School Access',
              label: 'TpT School Access'
            }
          },
        {
          title: <InboxMenuItemPartial countUnreadMessages={countUnreadMessages} />,
          url: '/Mailbox'
        },
        { title: 'Become a Seller', url: '/My-Account/Upgrade/Basic' }
      ]}
    />
    <MenuSection
      title="Buy"
      items={[
        { title: 'My Wish List', url: '/Wish-List' },
        { title: 'My Purchases', url: '/My-Purchases' },
        {
          title: <DigitalActivitiesMenuItemPartial />,
          url: EASEL_ACTIVITIES_URL
        },
        { title: 'My Favorite Sellers', url: '/My-Account/Favorite_Sellers' }
      ]}
    />
  </React.Fragment>
);

BuyerDropdownMenu.defaultProps = {
  featureVariants: {},
  countUnreadMessages: 0,
  classfundSummary: {
    status: pageStatuses.ACTIVE
  }
};

BuyerDropdownMenu.propTypes = {
  featureVariants: featureVariantsPropTypes,
  countUnreadMessages: PropTypes.number,
  classfundSummary: PropTypes.shape({
    status: PropTypes.string
  })
};

export default compose(
  featureVariantsInfo(applicationFeatures),
  withClassfundSummary
)(BuyerDropdownMenu);
