import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import {
  SCHOOLS_FLASH_MESSAGE_KEY,
  SchoolsFlashMessages,
  TEACHER_LEAVES_SCHOOL
} from 'shared/domains/MySchoolPage/SchoolFlashMessages';
import { getFeatureVariant } from 'lib/features';
import Alert from 'components/Alert';

@withApollo
class SchoolsFlashMessageContainer extends Component {
  static propTypes = {
    storage: PropTypes.object,
    isLoggedIn: PropTypes.bool.isRequired,
    client: PropTypes.shape({
      readFragment: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    storage: {}
  };

  state = {
    level: 'success',
    messageLookupKey: null
  };

  componentDidMount() {
    const { storage } = this.props;
    this.consumeMessage(storage);
  }

  consumeMessage(storage) {
    const messageItem = storage.getItem(SCHOOLS_FLASH_MESSAGE_KEY);

    if (messageItem === undefined || messageItem === null) {
      return null;
    }

    const message = JSON.parse(messageItem);
    storage.removeItem(SCHOOLS_FLASH_MESSAGE_KEY);

    this.setState({ level: message.level, messageLookupKey: message.message });

    return message;
  }

  lookupMessage(messageLookupKey) {
    return SchoolsFlashMessages[messageLookupKey];
  }

  render() {
    const { level, messageLookupKey } = this.state;
    const { isLoggedIn, client } = this.props;

    if (messageLookupKey === null) {
      return null;
    }

    // Only show Teacher leaves school message if user is logged in and behind feature flag
    const tcsFeatureVariant = getFeatureVariant(client, 'schools_leave_tcs');
    const shouldShowLeaveTcsMessage = isLoggedIn && tcsFeatureVariant === 'on';
    const shouldShowFlashMessage =
      messageLookupKey !== TEACHER_LEAVES_SCHOOL || shouldShowLeaveTcsMessage;

    return (
      shouldShowFlashMessage && (
        // We collapsed the layout into the container to prevent a future where we accidently pass
        // something unsafe to the layout (which no longer exists) now that we are not sanitizing
        // on the client side anymore
        <Alert notificationType={level} allowClose>
          {this.lookupMessage(messageLookupKey)}
        </Alert>
      )
    );
  }
}

export default SchoolsFlashMessageContainer;
