import PropTypes from 'prop-types';
import React from 'react';

import HeaderAlertLayout from './HeaderAlertLayout';

function HeaderAlertContainer(props) {
  const { isLoggedIn, isHidden, pathname } = props;

  return isHidden ? null : <HeaderAlertLayout pathname={pathname} isLoggedIn={isLoggedIn} />;
}

HeaderAlertContainer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  isHidden: PropTypes.bool
};

HeaderAlertContainer.defaultProps = {
  isHidden: false
};

export default HeaderAlertContainer;
