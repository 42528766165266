import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import './Accordion.scss';

class Accordion extends Component {
  constructor(...args) {
    super(...args);
    this.state = { isOpen: this.props.isOpen };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
  }

  handleClick = () => {
    const newState = !this.state.isOpen;
    this.setState({ isOpen: newState });
    this.props.onClickCb(newState);
  };

  render() {
    const { children, label, className } = this.props;
    const { isOpen } = this.state;
    const accordionClasses = classnames('Accordion', `${className}`, {
      Accordion__isOpen: isOpen
    });
    const chevronClasses = classnames('Accordion__chevron', {
      Accordion__chevron__isOpen: isOpen
    });
    const labelWithChevron = (
      <span>
        {label}
        <Icon name="chevron-right" className={chevronClasses} />
      </span>
    );
    return (
      <div className={accordionClasses}>
        <button className="Accordion__trigger" onClick={this.handleClick}>
          {labelWithChevron}
        </button>
        {isOpen && (
          <div className="Accordion__container" data-testid="AccordionContainer">
            {children}
          </div>
        )}
      </div>
    );
  }
}

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClickCb: PropTypes.func,
  className: PropTypes.string
};

Accordion.defaultProps = {
  isOpen: false,
  onClickCb: () => {},
  className: ''
};

export default Accordion;
