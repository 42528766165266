import React from 'react';
import PropTypes from 'prop-types';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import Grid from 'components/ResponsiveGrid';
import GlobalErrorsContainer from 'domains/Header/GlobalErrorsContainer/GlobalErrorsContainer';
import SaleBannerContainer from 'domains/Header/SaleBanner/SaleBannerContainer';
import SitewideBannerContainer from 'components/SitewideBanner/SitewideBannerContainer';
import ClassFundInterruptSocialModalContainer from 'domains/Header/ClassFundInterruptSocialModal/ClassFundInterruptSocialModalContainer';
import ClassFundFeed from 'domains/Contribution/components/ClassFundFeed';
import BTSMarketingBanner from 'domains/Header/BTSMarketingBanner';
import NarrowClassFundFeedLayout from 'domains/Contribution/components/ClassFundFeed/NarrowClassFundFeedLayout';
import PreSaleNotifications from 'domains/Header/PreSaleNotifications';
import NavigationHeader from 'domains/Header/components/NavigationHeader';
import EaselBannerEyebrow from 'domains/TpTEasel/TpTEaselEyebrow/EaselBannerEyebrow';
import OptimizelyBannerContainer from 'components/OptimizelyBannerContainer';
import PrimaryHeaderLayout from './PrimaryHeaderLayout';
import SecondaryHeaderLayout from './SecondaryHeaderLayout';

import './ResponsiveHeaderLayout.scss';

const ResponsiveHeaderLayout = ({
  pathname,
  isHidden,
  isLoggedIn,
  isSeller,
  isPremiumSeller,
  isMobile,
  featureVariants,
  adminToolboxComponent,
  shouldRenderClassFundFeed,
  shouldShowPreSaleNotification,
  shouldHideGlobalError
}) =>
  !isHidden && (
    <>
      {featureVariants.optimizely_banner === 'on' && <OptimizelyBannerContainer />}
      <SaleBannerContainer />
      {featureVariants.bts_marketing_banner === 'on' && <BTSMarketingBanner />}
      <EaselBannerEyebrow
        pathname={pathname}
        featureVariants={featureVariants}
        isLoggedIn={isLoggedIn}
      />
      <ClassFundInterruptSocialModalContainer
        variant={featureVariants.classfund_interrupt_modal_v2}
        pathname={pathname}
        isLoggedIn={isLoggedIn}
      />
      <Grid hasPadding>
        <div className="HeaderLayout--responsive">
          <PrimaryHeaderLayout
            isLoggedIn={isLoggedIn}
            isSeller={isSeller}
            isPremiumSeller={isPremiumSeller}
            isMobile={isMobile}
            featureVariants={featureVariants}
          />
          <SecondaryHeaderLayout
            isLoggedIn={isLoggedIn}
            isSeller={isSeller}
            featureVariants={featureVariants}
          />
          {featureVariants.show_global_category_nav_bar_v2 === 'show_navbar' && (
            <NavigationHeader isMobile={isMobile} />
          )}
          {shouldRenderClassFundFeed && (
            <ClassFundFeed
              className="ContributionFeedWrapper"
              component={NarrowClassFundFeedLayout}
              variant={featureVariants.classfund_social_feed_method}
            />
          )}
        </div>
        {!shouldHideGlobalError && <GlobalErrorsContainer />}
        {adminToolboxComponent}
        <PreSaleNotifications shouldRender={shouldShowPreSaleNotification} />
        <SitewideBannerContainer pathname={pathname} />
      </Grid>
    </>
  );

ResponsiveHeaderLayout.defaultProps = {
  pathname: '',
  isHidden: false,
  adminToolboxComponent: null,
  featureVariants: {},
  isMobile: false,
  shouldRenderClassFundFeed: false,
  shouldHideGlobalError: false
};

ResponsiveHeaderLayout.propTypes = {
  pathname: PropTypes.string,
  isHidden: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  isSeller: PropTypes.bool.isRequired,
  isPremiumSeller: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  adminToolboxComponent: PropTypes.node,
  featureVariants: featureVariantsPropTypes,
  shouldRenderClassFundFeed: PropTypes.bool,
  shouldShowPreSaleNotification: PropTypes.bool.isRequired,
  shouldHideGlobalError: PropTypes.bool
};

export default ResponsiveHeaderLayout;
