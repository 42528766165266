/**
 * Helpers for imperative access to cached feature flag state
 *
 * These helpers can be used in contexts where feature flags have
 * already been retrieved. To actually retrieve the flags, ensure
 * that they have been added to the appropriate array in
 * shared/config/features.js.
 *
 * See e.g. shared/pages/ProductPage.jsx for an example of where feature
 * flags for a specific page are actually loaded and added to cache.
 */

import constructApolloKey from 'tpt_module-utils/constructApolloKey';
import FEATURE_TYPE_NAMES from 'tpt_modules/features/typeNames';
import FeatureVariantFragment from 'tpt_modules/features/fragments/FeatureVariantFields.graphql';
import querystring from 'querystring';

function readFeatureFragment(apolloClient, featureName) {
  return apolloClient.readFragment({
    id: constructApolloKey(FEATURE_TYPE_NAMES.FEATURE_VARIANT, featureName),
    fragment: FeatureVariantFragment
  });
}

export function isFeatureFlagEnabled(apolloClient, featureName) {
  const fragment = readFeatureFragment(apolloClient, featureName);
  return fragment ? fragment.variant === 'on' : false;
}

export function getFeatureVariant(apolloClient, featureName) {
  const fragment = readFeatureFragment(apolloClient, featureName);
  return fragment ? fragment.variant : null;
}

export function applyOverridableFeatureVariantsTo(params) {
  if (IS_BROWSER) {
    const featureVariantsQueryName = 'ab_tests';
    const queryString = window.location.search.replace(new RegExp('^[?]+'), '');
    const parsed = querystring.parse(queryString);
    if (parsed[featureVariantsQueryName]) {
      return {
        ...params,
        [featureVariantsQueryName]: parsed[featureVariantsQueryName]
      };
    }
  }
  return params;
}
