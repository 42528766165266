import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-modal';
import analytics from 'lib/analytics';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';

import Button from 'components/LegacyButton';
import Divider from 'components/Divider';

import './DialogModal.scss';
import './DialogModal.unscoped.scss';

/**
 * @callback closeModal
 * @param event - the synthetic event that triggered the close
 * @param {Object} [info] - additional info about the closing of the modal
 * @param {string} [info.button] - Name of button that triggered close. Either 'accept' or 'decline'.
 */

class DialogModal extends Component {
  static propTypes = {
    acceptButtonLabel: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    /**
     * @param {closeModal} [props.closeModal] - triggered on accept, decline, and X/overlay.
     */
    closeModal: PropTypes.func,
    declineButtonLabel: PropTypes.string,
    ctaHelperText: PropTypes.node,
    header: PropTypes.node,
    footer: PropTypes.node,
    footerLine: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    onDecline: PropTypes.func,
    onAccept: PropTypes.func,
    redirectLink: PropTypes.string,
    shouldCloseOnOverlayClick: PropTypes.bool,
    shouldRenderButtonPair: PropTypes.bool,
    shouldRenderCloseButton: PropTypes.bool,
    shouldNotCloseOnAccept: PropTypes.bool,
    disableAcceptButton: PropTypes.bool,
    validate: PropTypes.func,
    xtraLarge: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    wide: PropTypes.bool,
    lg: PropTypes.bool,
    isMobile: PropTypes.bool,
    acceptButtonSkin: PropTypes.oneOf(['red', 'primary']),
    fullScreen: PropTypes.bool,
    breadCrumbs: PropTypes.node,
    fixedBottom: PropTypes.bool,
    closeOnPageUnload: PropTypes.bool,
    /* splits fullScreenWithPadding into two options */
    stretchVerticalWithPadding: PropTypes.bool,
    stretchHorizontalWithPadding: PropTypes.bool,
    /* for modals that are big, as the screen shrinks, take over the padding */
    consumePaddingWhenLarge: PropTypes.bool
  };

  static defaultProps = {
    acceptButtonLabel: 'Confirm',
    declineButtonLabel: 'Cancel',
    className: null,
    header: '',
    footer: null,
    footerLine: <Divider />,
    // TODO: This is default true, but we should remove the buttons from the modal
    // (This is the responsibility of the contents, not the modal proper)
    shouldRenderButtonPair: true,
    shouldRenderCloseButton: false,
    shouldNotCloseOnAccept: false,
    disableAcceptButton: false,
    xtraLarge: false,
    large: false,
    small: false,
    wide: false,
    lg: false,
    isMobile: false,
    acceptButtonSkin: 'primary',
    ctaHelperText: '',
    validate: () => true,
    fullScreen: false,
    closeOnPageUnload: false,
    stretchVerticalWithPadding: false,
    stretchHorizontalWithPadding: false,
    consumePaddingWhenLarge: false,
    fixedBottom: false
  };

  constructor(...args) {
    super(...args);

    this.state = { isModalOpen: this.props.isOpen };
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  componentWillMount() {
    // Use our top-level container as the "app element"
    // The modal will be added as a sibling to the app element in
    // the DOM, and react-modal will hide it from the
    // accessibility tree when the modal is open.
    Modal.setAppElement(CONFIG.appContainerSelector);
  }

  componentDidMount() {
    const { closeOnPageUnload, closeModal } = this.props;
    if (closeOnPageUnload && window) {
      window.addEventListener(
        'popstate',
        () => {
          const { isModalOpen } = this.state;
          if (isModalOpen) {
            closeModal();
            /* eslint-disable no-restricted-globals */
            history.go(0);
            /* eslint-enable no-restricted-globals */
          }
        },
        false
      );
    }
  }

  componentWillReceiveProps({ isOpen }) {
    this.setState({ isModalOpen: isOpen });
    const { closeOnPageUnload } = this.props;
    /* eslint-disable no-restricted-globals */
    closeOnPageUnload && isOpen && history.pushState(null, null, location.href);
    /* eslint-enable no-restricted-globals */
  }

  onModalAfterOpen() {
    // Enable Google Optimize experiments to run after modals are opened and rendered.
    // Since modals dynamically render content, this will let
    // Optimize to know when it's safe to apply its changes.
    analytics.activateOptimize();
  }

  onCloseModal() {
    const { closeOnPageUnload, closeModal } = this.props;
    closeModal();
    /* eslint-disable no-restricted-globals */
    closeOnPageUnload && history.back();
    /* eslint-enable no-restricted-globals */
  }

  onClickDecline = (event) => {
    const { onDecline, closeModal, redirectLink } = this.props;

    if (isFunction(onDecline)) {
      onDecline();
    }

    if (!redirectLink && isFunction(closeModal)) {
      closeModal(event, { button: 'decline' });
    }
  };

  onClickAccept = (event) => {
    const { onAccept, closeModal, shouldNotCloseOnAccept, validate } = this.props;

    if (validate && !validate()) {
      return;
    }

    if (isFunction(onAccept)) {
      onAccept();
    }

    if (isFunction(closeModal) && !shouldNotCloseOnAccept) {
      closeModal(event, { button: 'accept' });
    }
  };

  renderFullScreenModal() {
    const { children, className, breadCrumbs } = this.props;
    return (
      <div className="DialogModal">
        <Modal
          className={classnames(
            {
              DialogModal__content: true,
              'DialogModal__content--fullscreen': true
            },
            className
          )}
          isOpen={this.state.isModalOpen}
          onRequestClose={this.onCloseModal}
          overlayClassName="DialogModal__overlay"
          portalClassName={classnames('DialogModalPortal', 'tpt-frontend')}
          data-testid="DialogModal"
          role="dialog"
        >
          <div className="DialogModal__headSection">
            <div className="DialogModal__headSection--title">{breadCrumbs}</div>
            <div className="DialogModal__headSection--close">
              <button
                className="DialogModal__headSection--closeButton"
                onClick={this.onCloseModal}
                type="button"
              >
                Close
              </button>
            </div>
          </div>
          <div className="DialogModal__body--fullscreen">{children}</div>
        </Modal>
      </div>
    );
  }

  renderModal() {
    const {
      acceptButtonLabel,
      children,
      className,
      declineButtonLabel,
      ctaHelperText,
      header,
      footer,
      footerLine,
      redirectLink,
      shouldCloseOnOverlayClick,
      shouldRenderButtonPair,
      shouldRenderCloseButton,
      disableAcceptButton,
      xtraLarge,
      large,
      small,
      wide,
      lg,
      isMobile,
      acceptButtonSkin,
      stretchVerticalWithPadding,
      stretchHorizontalWithPadding,
      consumePaddingWhenLarge,
      fixedBottom
    } = this.props;
    return (
      <div className="DialogModal">
        <Modal
          className={classnames(
            {
              DialogModal__content: true,
              'DialogModal__content--xtra--large': xtraLarge,
              'DialogModal__content--large': large,
              'DialogModal__content--small': small,
              'DialogModal__content--wide': wide,
              'DialogModal__content--lg': lg,
              'DialogModal__content--mobile': isMobile,
              'DialogModal__content--stretchVt': stretchVerticalWithPadding,
              'DialogModal__content--stretchHz': stretchHorizontalWithPadding,
              'DialogModal__content--consumePd': consumePaddingWhenLarge,
              'DialogModal__content--fixed-bottom': fixedBottom
            },
            className
          )}
          isOpen={this.state.isModalOpen}
          onRequestClose={this.onCloseModal}
          onAfterOpen={this.onModalAfterOpen}
          overlayClassName="DialogModal__overlay"
          portalClassName={classnames('DialogModalPortal', 'tpt-frontend')}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          data-testid="DialogModal"
          role="dialog"
        >
          {shouldRenderCloseButton && (
            <button
              className={classnames({
                DialogModal__closeButton: true
              })}
              onClick={this.onCloseModal}
              type="button"
              aria-label="Close"
            />
          )}
          <div
            className={classnames({
              DialogModal__body: true,
              'DialogModal__body--large': large || xtraLarge,
              'DialogModal__body--wide': wide,
              'DialogModal__body--lg': lg,
              'DialogModal__body--stretchVt': stretchVerticalWithPadding
            })}
          >
            {header && <h3 className="DialogModal__header">{header}</h3>}
            {children}
            {shouldRenderButtonPair && (
              <div className="DialogModal__buttonPair" data-testid="DialogModal__buttonPair">
                {ctaHelperText && (
                  <div
                    className="DialogModal__buttonPairHelperText"
                    data-testid="DialogModal__buttonPairHelperText"
                  >
                    {ctaHelperText}
                  </div>
                )}
                <div>
                  <span data-testid="DialogModal__acceptButton">
                    <Button
                      label={acceptButtonLabel}
                      onClick={this.onClickAccept}
                      disabled={disableAcceptButton}
                      skin={acceptButtonSkin}
                    />
                  </span>
                  <span
                    data-testid="DialogModal__declineButton"
                    className="DialogModal__declineButton"
                  >
                    <Button
                      forceReload={!!redirectLink}
                      label={declineButtonLabel}
                      linkTo={redirectLink}
                      onClick={this.onClickDecline}
                      skin="white link"
                    />
                  </span>
                </div>
              </div>
            )}
            {footer && (
              <div className="DialogModal__footer">
                {footerLine}
                {footer}
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }

  render() {
    const { fullScreen } = this.props;
    return fullScreen ? this.renderFullScreenModal() : this.renderModal();
  }
}

export default DialogModal;
