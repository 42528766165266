/** TODO: upgrade to @teachers/design-system/Spinner */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Spinner.scss';

const STROKE_WIDTH = 2;
const SIZES_MAP = {
  xsmall: 14,
  small: 18,
  medium: 24
};

/**
  Given a radius of a circle and an angle in degrees,
  calculate the length of the segment on a circumference

  @param {number} radius radius of circle
  @param {number} angleInDegrees angle of sector
  @return {number} circumference of sector
* */
const calculateSectorCircumference = (radius, angleInDegrees) => {
  const rad = angleInDegrees * (Math.PI / 180);
  return 2 * radius + radius * rad;
};

/**
  Given a radius of a circle, calculate the strokeDasharray
  that will leave a gap of a given angle in degrees

  @param {number} radius radius of circle
  @param {number} angleInDegrees angle of sector
  @return {string} stroke dash array
* */
const renderStrokeDash = (radius, angleInDegrees = 10) => {
  const circumfrence = 2 * Math.PI * radius;
  const sectorPerimeter = calculateSectorCircumference(radius, angleInDegrees);
  return `${Math.round(circumfrence - sectorPerimeter)}px ${Math.round(sectorPerimeter)}px`;
};

const Spinner = ({ accessibilityText, color, size: sizeKey }) => {
  const rootClassName = Spinner.displayName;

  const size = SIZES_MAP[sizeKey];
  const radius = (size - STROKE_WIDTH) / 2;
  const classes = classnames(rootClassName, {
    [`${rootClassName}--${color}`]: color !== 'light'
  });

  return (
    <span role="progressbar" aria-valuetext={accessibilityText} className={classes}>
      <svg
        className={`${rootClassName}__svg`}
        viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
        width={`${size}px`}
        height={`${size}px`}
        strokeLinecap="round"
        strokeDasharray={renderStrokeDash(radius)}
      >
        <circle
          className={`${rootClassName}__circle`}
          cx={size}
          cy={size}
          r={radius}
          fill="none"
          strokeWidth={STROKE_WIDTH}
        />
      </svg>
    </span>
  );
};

Spinner.defaultProps = {
  color: 'light',
  size: 'small',
  accessibilityText: ''
};

Spinner.propTypes = {
  /** Visually hidden text for screen readers. */
  accessibilityText: PropTypes.string,

  /** Color of spinner icon. */
  color: PropTypes.oneOf(['primary', 'light', 'white']),

  /** Size of spinner. */
  size: PropTypes.oneOf(['xsmall', 'small', 'medium'])
};

Spinner.displayName = 'Spinner';

export default Spinner;
