import React from 'react';
import PropTypes from 'prop-types';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import CategoryMenu from 'components/CategoryMenu';
import HeaderSearchContainer from 'domains/Header/HeaderSearch/HeaderSearchContainer';
import UserDetailsContainer from 'domains/Header/UserDetailsContainer';

/* eslint-disable react/no-multi-comp */
function SecondaryHeaderLayout({ isLoggedIn, isSeller, featureVariants }) {
  return (
    <Row className="SecondaryHeaderLayout">
      {featureVariants.show_global_category_nav_bar_v2 !== 'show_navbar' && (
        <Col auto className="d-none d-lg-flex">
          {/* Hide browse col in devices < lg viewport */}
          <CategoryMenu label="Browse" isResponsive />
        </Col>
      )}

      <Col col lg={5}>
        <HeaderSearchContainer
          featureVariants={{
            headercart_graphapi: 'on',
            ...featureVariants
          }}
        />
      </Col>

      <Col className="d-lg-flex d-none justify-content-end align-content-center">
        <UserDetailsContainer
          isLoggedIn={isLoggedIn}
          isSeller={isSeller}
          featureVariants={featureVariants}
        />
      </Col>
    </Row>
  );
}

SecondaryHeaderLayout.propTypes = {
  isLoggedIn: PropTypes.bool,
  isSeller: PropTypes.bool,
  featureVariants: featureVariantsPropTypes
};

SecondaryHeaderLayout.defaultProps = {
  featureVariants: {}
};

export default SecondaryHeaderLayout;
