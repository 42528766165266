import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import classnames from 'classnames';
import Icon from 'components/Icon';
import './SearchAutosuggest.scss';

class SearchAutosuggestBespoke extends Component {
  static propTypes = {
    inputProps: PropTypes.shape({
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      onKeyUp: PropTypes.func.isRequired,
      placeholder: PropTypes.string,
      type: PropTypes.string
    }).isRequired,
    suggestions: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string, // title of a suggestion category
        children: PropTypes.arrayOf(
          PropTypes.shape({
            // suggestions within this category
            text: PropTypes.string.isRequired, // text to use as the input's programmatic value
            displayNode: PropTypes.node, // a node that displays instead of `text` if sent in
            url: PropTypes.string // URL that the user is sent to upon selecting a suggestion
          })
        )
      })
    ),
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    onSuggestionsClearRequested: PropTypes.func.isRequired,
    onSuggestionSelected: PropTypes.func.isRequired,
    handleButtonClick: PropTypes.func.isRequired,
    inputRefCallback: PropTypes.func.isRequired,
    className: PropTypes.string
  };

  getSuggestionValue(suggestion) {
    // NOTE: This function is required by react-autosuggest to fill in the input field:
    // https://github.com/moroshko/react-autosuggest/tree/v7.0.2#getsuggestionvalue-required
    // Search parses the URL query params to populate the input box and this value is not used.
    // When the suggestions array is empty, calling `.text` on the first element
    // throws an error so we're checking the type first.
    return typeof suggestion !== 'undefined' ? suggestion.text : '';
  }

  getSectionSuggestions(section) {
    return section.children;
  }

  renderSuggestion(suggestion) {
    return (
      <span>
        {suggestion.thumbUrl && (
          <img
            className="SearchAutosuggestBespoke__thumbnail"
            alt={`${suggestion.text} thumbnail`}
            src={suggestion.thumbUrl}
          />
        )}
        {suggestion.displayNode || suggestion.text}
      </span>
    );
  }

  // TODO: clean up the IE11 hack when React16 comes out. Cleanup extends to HeaderSearch
  // HACK: this function is only necessary for the IE11 input hack
  renderInputComponent = (inputProps) => {
    // strip out value from the input's props so the input doesn't re-render on state change
    // instead, the `onChange` handler will fire, resulting in ONLY the suggestions re-rendering

    const newInputProps = inputProps;
    if (IS_BROWSER && !IS_TEST) {
      // if we're in a browser, expose a ref at the container level
      const oldRef = inputProps.ref;
      newInputProps.ref = (element) => {
        this.props.inputRefCallback(element);
        oldRef(element);
      };
    }

    return <input {...omit(newInputProps, 'value')} />;
  };

  renderSectionTitle(section) {
    return <span>{section.category}</span>;
  }

  render() {
    const {
      inputProps: { placeholder = 'Search', type = 'search', ...othersInputProps },
      suggestions,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      onSuggestionSelected,
      handleButtonClick,
      className
    } = this.props;

    return (
      <div className={classnames('SearchAutosuggestBespoke', className)}>
        <form
          method="GET"
          // below action helps to prevent default form.submit action in slow-network cases
          // eslint-disable-next-line no-script-url
          action="javascript:window.location.href = '/Browse/Search:' + encodeURIComponent(document.querySelector('.react-autosuggest__input').value);"
          onSubmit={(e) => {
            e.preventDefault();
            handleButtonClick();
          }}
        >
          <Autosuggest
            // externally defined attributes
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionSelected={onSuggestionSelected}
            suggestions={suggestions}
            inputProps={{
              'aria-label': placeholder,
              inputMode: 'text',
              placeholder,
              type,
              ...othersInputProps
            }}
            // internally defined attributes
            getSectionSuggestions={this.getSectionSuggestions}
            getSuggestionValue={this.getSuggestionValue}
            renderSectionTitle={this.renderSectionTitle}
            renderSuggestion={this.renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            shouldRenderSuggestions={() => true} // forces an emptystring render suggestions
            multiSection
          />
        </form>
        <button
          type="button"
          aria-label="Search"
          className="SearchAutosuggestBespoke__button"
          onClick={handleButtonClick}
        >
          <Icon className="SearchAutosuggestBespoke__buttonIcon" name="search" />
        </button>
      </div>
    );
  }
}

export default SearchAutosuggestBespoke;
