/**
 * A list of names of features
 * needed on the application entry level
 *
 * @type { !array.<!string> }
 */
export const applicationFeatures = [
  'csp_header',
  'csp_header_minimal',
  'fullstory_sampling',
  'fullstory_in_head',
  'responsive_header_footer',
  'show_discount_cart_page',
  'schools_access_header_links',
  'student_privacy_policy',
  'datadog_rum',
  'no_optimizely_on_new_mobile_pages',
  'my_purchases_migration_to_react',
  'schools_catalog_seller_modal',
  'easel_rebrand',
  'fb_seller_pixel_my_account',
  'contact_us_via_ada',
  'optimizely_banner',
  'hide_contact_us_in_footer',
  'my_products_migration_to_react'
];

export const homepageFeatures = [
  'online_resources_on_homepage',
  'online_resource_banner',
  'ratings_reviews_new',
  'contribution_feed_slider',
  'classfund_social_feed_method',
  'digital_filter_facet',
  'digital_filter_location',
  'home_fb_likebox',
  'tfj_homepage_banner',
  'tfj_general',
  'tfj_suggest',
  'homepage_recs_order',
  'easel_rebrand',
  'easel_interactive_banner_carousel',
  'fb_seller_pixel_my_account',
  're_rs_suggested_filters_v1_1',
  'easel_education',
  'schools_logged_in_homepage_banner',
  'rc_data_home_page'
];

export const headerFeatures = [
  'schools_awareness_banner',
  'schools_teacher_join_notification',
  'schools_growth_free_download_page_ad_taketwo',
  'schools_growth_my_purchases_house_ad',
  'schools_growth_wishlist_house_ad',
  'schools_growth_po_checkout_house_ad',
  'schools_growth_post_purchase_house_ad',
  'schools_leave_tcs',
  'classfund_interrupt_modal_v2',
  'classfund_social_feed_method',
  'digital_search_suggestions_tip',
  'digital_filter_facet',
  'digital_filter_location',
  'digital_activities_nav_bar',
  'bts_marketing_banner',
  'digital_seller_activity_badge',
  'digital_seller_activity_badge_product_page',
  'tfj_suggest',
  'tfj_homepage_banner',
  'tfj_general',
  'show_global_category_nav_bar_v2',
  're_rs_suggested_filters_v1_1',
  'easel_rebrand',
  'easel_education'
];

/**
 * A list of names of features
 * that can be enabled in the ProductPage domain
 *
 * @type { !array.<!string> }
 */
export const productPageFeatures = [
  'product_page_related_items',
  'schools_demo_school',
  'display_learning_objective_product_page',
  'ratings_reviews_new',
  'classfund_product_wishlist',
  'no_optimizely_on_new_mobile_pages',
  'responsive_product_page_mobile',
  'digital_seller_activity_badge_product_page',
  'responsive_product_preview_slider',
  'tfj_product_page_blurb',
  'digital_education_v2',
  'make_tpt_da_button_primary',
  'fb_seller_pixels',
  'easel_rebrand',
  'consolidate_metadata_v3_v4',
  'attach_assessments_buyer',
  're_rs_easel_assigned_badge'
];

/**
 * A list of names of features
 * that can be enabled in the CartPage domain
 *
 * @type { !array.<!string> }
 */
export const cartPageFeatures = [
  'ratings_reviews_new',
  'digital_education_v2',
  'easel_rebrand',
  'fb_seller_pixels'
];

/**
 * A list of names of features
 * that can be enabled in the OrderReceiptPage domain
 *
 * @type { !array.<!string> }
 */
export const orderReceiptPageFeatures = [
  'make_tpt_da_button_primary',
  'easel_rebrand',
  'schools_post_purchase_banner',
  'attach_assessments_buyer_modal',
  'attach_assessments_buyer',
  're_rs_easel_assigned_badge'
];

/**
 * A list of names of features
 * that can be enabled in the CheckoutPage domain
 */
export const checkoutPageFeatures = [
  'remove_po_fee',
  'my_account_seller_nexus',
  'use_beta_braintree_dropin',
  'easel_rebrand',
  'easel_education'
];

/**
 * A list of names of features
 * that can be enabled in the Schools domains
 *
 * @type { !array.<!string> }
 */
export const schoolsFeatures = [
  'schools_funded_accounts_full',
  'schools_library_filters',
  'schools_request_to_join',
  'schools_leave_tcs',
  'org_admins_reclaim_licenses',
  'schools_purchase_history'
];

/**
 * A list of names of features
 * that can be enabled related to online resources.
 * Owned and maintained by the digital team.
 *
 * @type {!array.<!string>}
 */
export const onlineResourceFeatures = [
  'online_resources_in_bundles',
  'online_resource_primary_free',
  'attach_assessments_seller'
];

/*
 * A list of names of features
 * that can be enabled in the SearchPage
 *
 * @type { !array.<!string> }
 */
export const searchPageFeatures = [
  'schools_growth_browse_page_ad_taketwo',
  'ratings_reviews_new',
  'classfund_product_wishlist',
  'digital_badge_search_page',
  'digital_filter_facet',
  'digital_filter_location',
  'digital_seller_activity_badge',
  'search_image_lazyload',
  'tfj_blurb_on_search_results',
  'digital_education_v2',
  'show_targeted_search_page_header_footer',
  'consolidate_metadata_v3_v4',
  'fb_seller_pixels',
  'rs_ai_reranker',
  'easel_rebrand',
  're_rs_*',
  'rc_data_search_results',
  'attach_assessments_buyer'
];

/**
 * A list of names of features
 * that can be enabled in the Seller Dashboard
 *
 * @type { !array.<!string> }
 */
export const sellerDashboardFeatures = [
  'seller_dashboard_year_range',
  'seller_dashboard_take_rate_message',
  'hide_custom_requests',
  'schools_catalog_statuses',
  'easel_stats_on_seller_dashboard'
];

/**
 * A list of names of features
 * that can be enabled on the Sales Reports page
 *
 * @type { !array.<!string> }
 */
export const salesReportsFeatures = [
  'sales_tax_columns_in_nexus_determination_report',
  'third_party_tax_exports'
];

export const wishlistFeatures = [
  'ratings_reviews_new',
  'classfund_social_feed_method',
  'fb_seller_pixels',
  'easel_rebrand',
  'attach_assessments_buyer'
];

export const uploadPageFeatures = [
  'product_file_size_4gb',
  'qi_beta',
  'upload_page_long_standards_notations',
  'education_standards_service_integration',
  'online_resource_to_bundle_conversion',
  'let_sellers_attach_easel'
];

export const myAccountFeatures = [
  'my_account_seller_nexus',
  'my_account_address_tab',
  'school_in_profile',
  'add_state_to_buyer_profile',
  'fb_seller_pixel_my_account'
];

export const evaluationPageFeatures = ['ratings_reviews_new', 'ratings_evaluations_fullstory'];

export const aboutPageFeatures = ['show_targeted_category_landing_pages'];

export const tfjFeatures = ['tfj_professional_development_filter'];

export const myPurchasesPageFeatures = [
  'my_purchases_migration_to_react',
  're_rs_easel_assigned_badge',
  'emphasize_seller_created_activities',
  'attach_assessments_buyer'
];

/**
 * A list of names of features
 * that can be enabled on the My Products page
 *
 * @type { !array.<!string> }
 */
export const myProductsPageFeatures = [
  'my_products_migration_to_react',
  're_rs_easel_assigned_badge'
];

/**
 * A list of names of features
 * that enables the tpt-frontend<=>gql-gateway intergation for specific routes
 * by appling next middleware to needed routes:
 * featureFlagPreloadMiddleware(['feature_flag'])
 */
export const gqlGatewayIntegrationFeatures = [
  'gql_gateway_integration',
  'easel_stats_on_seller_dashboard'
];

// Exporting all features by default
// so we can construct proper prop type definitions
export default [
  ...aboutPageFeatures,
  ...applicationFeatures,
  ...headerFeatures,
  ...productPageFeatures,
  ...schoolsFeatures,
  ...onlineResourceFeatures,
  ...searchPageFeatures,
  ...sellerDashboardFeatures,
  ...salesReportsFeatures,
  ...checkoutPageFeatures,
  ...cartPageFeatures,
  ...wishlistFeatures,
  ...uploadPageFeatures,
  ...myAccountFeatures,
  ...evaluationPageFeatures,
  ...tfjFeatures,
  ...myPurchasesPageFeatures
];
