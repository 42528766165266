import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { capitalize } from 'util/utils';
import textProps from './textProps';
import './Text.scss';

/*
 * Primitive component used to render text based on our typographic system variants.
 * This component is intended to be heavily used to display all text on screen.
 * It should be a basic building block used to create compound components -
 * i.e. FormFeedbackText, Headings, PageHeadings, CardHeading, etc.
 */
const Text = (props) => {
  const {
    align,
    children,
    className: classNameProp,
    color,
    component: componentProp,
    inline,
    truncate,
    variant,
    strong,
    underline,
    ...other
  } = props;
  const rootClassName = Text.displayName;
  const classes = classnames(
    rootClassName,
    {
      [`${rootClassName}--${variant}`]: variant,
      [`${rootClassName}--align${capitalize(align)}`]: align !== 'inherit',
      [`${rootClassName}--color${capitalize(color)}`]: color !== 'default',
      [`${rootClassName}--inline`]: inline,
      [`${rootClassName}--truncate`]: truncate,
      [`${rootClassName}--strong`]: strong,
      [`${rootClassName}--underline`]: underline
    },
    classNameProp
  );

  const variantTagMap = {
    displayLarge: 'h1',
    display: 'h1',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    body: 'div',
    bodyLarge: 'div',
    bodySmall: 'div',
    microcopy: 'span',
    button: 'span'
  };

  const Component = componentProp || variantTagMap[variant] || 'div';

  return (
    <Component className={classes} {...other}>
      {children}
    </Component>
  );
};

Text.defaultProps = {
  align: 'inherit',
  className: '',
  color: 'textPrimary',
  inline: false,
  strong: false,
  truncate: false,
  variant: 'body',
  underline: false
};

Text.propTypes = {
  /** Set the text alignment */
  align: PropTypes.oneOf(textProps.alignments),

  /** The content of the component. */
  children: PropTypes.node,

  className: PropTypes.string,

  /** Set the color based on our color design tokens. Includes variants that are appriopriate for this component. */
  color: PropTypes.oneOf(textProps.colors),

  /** If true, will set the display to inline */
  inline: PropTypes.bool,

  /** The component used for the root node. Either a string to use a DOM element or a component. */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),

  /** If true, will add emphasis to text */
  strong: PropTypes.bool,

  /** If true, will underline text */
  underline: PropTypes.bool,

  /** If true, will truncate text on one line with an ellipsis. If you need to truncate multiple lines, use the `TruncatedTextBox` component. */
  truncate: PropTypes.bool,

  /** Sets the typographic variant */
  variant: PropTypes.oneOf(textProps.variants)
};

Text.displayName = 'Text';

export default Text;
