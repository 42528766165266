import logger from 'lib/logger';

/**
 * Reports error to bugsnag, if it is available.
 * @param {!Error} error Error instance
 * @param {string} [message] error message to report
 * @param {object} [opts] additional options, documented in https://docs.bugsnag.com/platforms/browsers/js/reporting-handled-errors/
 * @return {void}
 */
const reportError = (error, message, opts = {}) => {
  const { Bugsnag } = window;
  if (Bugsnag) {
    /* eslint-disable no-param-reassign */
    opts.metaData = opts.metaData || {};
    opts.metaData.tpt = opts.metaData.tpt || {};
    opts.metaData.tpt.message = message;
    /* eslint-enable no-param-reassign */
    Bugsnag.notify(error, opts);
  }
};

/**
 * Reports error to bugsnag, if it is available. If not, log error.
 *
 * This is useful for when we're in between server-side rendering and client-side rendering.
 *
 * @param {!Error} error Error instance
 * @param {string} [message] error message to report
 * @param {object} [opts] additional options, documented in https://docs.bugsnag.com/platforms/browsers/js/reporting-handled-errors/
 * @return {void}
 */
export const reportOrLogError = (error, message, opts = {}) => {
  if (typeof window !== 'undefined' && window.Bugsnag) {
    /* eslint-disable no-param-reassign */
    opts.metaData = opts.metaData || {};
    opts.metaData.tpt = opts.metaData.tpt || {};
    opts.metaData.tpt.message = message;
    /* eslint-enable no-param-reassign */
    window.Bugsnag.notify(error, opts);
  } else {
    logger.error({ message, error, opts });
  }
};

export const reportInfo = (error, message = '', opts = {}) => {
  reportError(error, message, { ...opts, severity: 'info' });
};

/**
 * Just like reportInfo, but may log in to server in case we're server-side rendering
 *
 * @param {*} error Error object (if any)
 * @param {*} message Message
 * @param {*} opts Extra data
 * @return {undefined} Nothing
 */
export const reportOrLogInfo = (error, message = '', opts = {}) => {
  if (typeof window !== 'undefined' && window.Bugsnag) {
    reportInfo(error, message, opts);
  } else {
    logger.info({ message, error, opts });
  }
};

export default reportError;
