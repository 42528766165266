import { get } from 'lodash';
// TODO: put this somewhere else
const ANALYTICS_CATEGORY = 'Online Product';
// Action type
export const AUTH_LOAD_SUCCESS = 'UploadPage/Partners/AUTH_LOAD_SUCCESS';
export const AUTH_LOAD_ERROR = 'UploadPage/Partners/AUTH_LOAD_ERROR';
export const HELP_MODAL_OPEN = 'UploadPage/Partners/HELP_MODAL_OPEN';
export const HELP_MODAL_CLOSE = 'UploadPage/Partners/HELP_MODAL_CLOSE';
export const PARTNER_INIT = 'UploadPage/Partners/INIT';
export const PICKER_LOAD = 'UploadPage/Partners/PICKER_LOAD';
export const PICKER_LOAD_SUCCESS = 'UploadPage/Partners/PICKER_LOAD_SUCCESS';
export const PICKER_OPEN = 'UploadPage/Partners/PICKER_OPEN';
export const PICKER_OPEN_SUCCESS = 'UploadPage/Partners/PICKER_OPEN_SUCCESS';
export const PICKER_OPEN_ERROR = 'UploadPage/Partners/PICKER_OPEN_ERROR';
export const PICKER_CLOSE = 'UploadPage/Partners/PICKER_CLOSE';
export const PICKER_SELECT = 'UploadPage/Partners/PICKER_SELECT';
export const PICKER_SELECT_SUCCESS = 'UploadPage/Partners/PICKER_SELECT_SUCCESS';
export const PICKER_SELECT_ERROR = 'UploadPage/Partners/PICKER_SELECT_ERROR';
export const PICKER_SELECT_RESET = 'UploadPage/Partners/PICKER_SELECT_RESET';
export const RESOURCE_ERROR_MODAL_CLOSE = 'UploadPage/Partners/RESOURCE_ERROR_MODAL_CLOSE';

// Inital state
export const initialState = {
  auth: null,
  onlineResourceData: null,
  picker: null,
  pickerLoading: {
    loadComplete: false,
    error: null
  },
  pickerOpen: false,
  helpModalOpen: false,
  helpModalDisplayed: false,
  resourceErrorModalOpen: false
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH_LOAD_SUCCESS: {
      const auth = get(action, 'payload.auth', null);
      const picker = get(action, 'payload.picker', null);
      return { ...state, pickerLoading: { loadComplete: true, error: null }, auth, picker };
    }
    case AUTH_LOAD_ERROR: {
      return {
        ...state,
        pickerLoading: {
          loadComplete: true,
          error: get(action, 'payload.errorMessage', 'Unknown Error')
        }
      };
    }
    case HELP_MODAL_OPEN:
      return { ...state, helpModalOpen: true, helpModalDisplayed: true };
    case HELP_MODAL_CLOSE:
      return { ...state, helpModalOpen: false };
    case PICKER_OPEN: {
      return { ...state, resourceErrorModalOpen: false };
    }
    case PICKER_OPEN_SUCCESS:
      return { ...state, pickerOpen: true };
    case PICKER_CLOSE:
      return { ...state, pickerOpen: false };
    case PICKER_SELECT_SUCCESS: {
      const onlineResourceData = get(action, 'payload.onlineResourceData');
      return { ...state, onlineResourceData };
    }
    case PICKER_SELECT_ERROR: {
      const onlineResourceData = get(action, 'payload.onlineResourceData');
      return { ...state, onlineResourceData, resourceErrorModalOpen: true };
    }
    case PICKER_SELECT_RESET:
      return { ...state, onlineResourceData: null };
    case RESOURCE_ERROR_MODAL_CLOSE:
      return { ...state, resourceErrorModalOpen: false, onlineResourceData: null };

    default:
      return state;
  }
}

export function initPartner() {
  return { type: PARTNER_INIT };
}

// Action creators
export function loadAuth({ auth, picker }) {
  return { type: AUTH_LOAD_SUCCESS, payload: { auth, picker } };
}

export function loadAuthError(errorMessage) {
  return { type: AUTH_LOAD_ERROR, payload: { errorMessage } };
}

export function openHelpModal() {
  return { type: HELP_MODAL_OPEN };
}

export function closeHelpModal() {
  return { type: HELP_MODAL_CLOSE };
}

export function closeResourceErrorModal() {
  return { type: RESOURCE_ERROR_MODAL_CLOSE };
}

export function openPicker() {
  return {
    type: PICKER_OPEN,
    meta: {
      analytics: {
        eventCat: ANALYTICS_CATEGORY,
        eventAct: 'Open Google Docs Picker',
        eventLbl: 'Show Picker'
      }
    }
  };
}

export function openPickerError() {
  return {
    type: PICKER_OPEN_ERROR,
    meta: {
      analytics: {
        eventCat: ANALYTICS_CATEGORY,
        eventAct: 'Account Selection Aborted',
        eventLbl: 'Choose an account'
      }
    }
  };
}

export function openPickerSuccess(user) {
  return {
    type: PICKER_OPEN_SUCCESS,
    payload: { user },
    meta: {
      analytics: {
        eventCat: ANALYTICS_CATEGORY,
        eventAct: 'Account Selected',
        eventLbl: 'Choose an account'
      }
    }
  };
}

export function closePicker() {
  return {
    type: PICKER_CLOSE,
    meta: {
      analytics: {
        eventCat: ANALYTICS_CATEGORY,
        eventAct: 'Picker Closed',
        eventLbl: 'Picker Action'
      }
    }
  };
}

export function resetSelection() {
  return {
    type: PICKER_SELECT_RESET,
    meta: {
      analytics: {
        eventCat: ANALYTICS_CATEGORY,
        eventAct: 'File reset',
        eventLbl: 'Picker Action'
      }
    }
  };
}

export function selectPicker(docs) {
  return {
    type: PICKER_SELECT,
    payload: { docs },
    meta: {
      analytics: {
        eventCat: ANALYTICS_CATEGORY,
        eventAct: 'File Selected',
        eventLbl: 'Picker Action'
      }
    }
  };
}

export function selectPickerSuccess(onlineResourceData) {
  return {
    type: PICKER_SELECT_SUCCESS,
    payload: { onlineResourceData }
  };
}

export function selectPickerError(onlineResourceData) {
  return {
    type: PICKER_SELECT_ERROR,
    payload: { onlineResourceData }
  };
}
