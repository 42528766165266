/* eslint-disable @miovision/disallow-date/no-new-date */
/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import PropTypes from 'prop-types';
import { isWithinInterval } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Anchor from 'components/Anchor';
import Grid from 'components/ResponsiveGrid';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import TrackedComponent from 'components/TrackedComponent/TrackedComponent';
import './BTSMarketingBanner.scss';

const defaultSchedule = [
  {
    startDate: '2020-10-09',
    endDate: '2020-10-12',
    copy: (
      <strong>
        Happy Latinx Heritage Month! Get ideas for community building from Latinx, Hispanic, &
        Latino-identifying teachers. 📝{' '}
      </strong>
    ),
    link:
      'https://blog.teacherspayteachers.com/latinx-teacher-authors-on-teaching-latinx-culture-and-building-inclusive-classrooms/',
    linkLabel: 'Read blog',
    textColor: '#0C3D56',
    linkColor: '#FFFFF'
  },
  {
    startDate: '2020-10-13',
    endDate: '2020-10-15',
    copy: (
      <strong>
        Teach for Justice Webinar with Dr. Eddie Moore Jr. on Diversity, Privilege and Leadership.
        📝{' '}
      </strong>
    ),
    link: 'https://www.teacherspayteachers.com/teachforjustice#professionallearning',
    linkLabel: 'Register',
    textColor: '#FFFFFF',
    bgColor: '#000000',
    linkColor: '#FFFFFF'
  }
];

// NOTE: we should banners by schedule only in America/New_York timezone
const BTSMarketingBanner = ({ schedule }) => {
  const nowUtc = new Date();

  const bannerInfo = schedule.find(({ startDate, endDate }) =>
    isWithinInterval(nowUtc, {
      start: zonedTimeToUtc(`${startDate} 00:00:00`, 'America/New_York'),
      end: zonedTimeToUtc(`${endDate} 23:59:59`, 'America/New_York')
    })
  );

  if (!bannerInfo) {
    return null;
  }

  const { copy, link, linkLabel, textColor, bgColor, linkColor } = bannerInfo;

  return (
    <div className="BTSMarketingBanner" role="alert" style={{ backgroundColor: bgColor }}>
      <Grid hasPadding>
        <Row>
          <Col>
            <div className="BTSMarketingBanner__text" style={{ color: textColor }}>
              {copy}{' '}
              <TrackedComponent
                category="BTS Marketing Top Header Banner"
                action="Clicked link"
                label="Learn more"
              >
                <Anchor
                  className="BTSMarketingBanner__link"
                  to={link}
                  forceReload
                  openNewTab
                  style={{ color: linkColor || '' }}
                >
                  <span className="BTSMarketingBanner__linkLabel">{linkLabel}</span>
                  <span className="BTSMarketingBanner__linkCarrot"> &gt;</span>
                </Anchor>
              </TrackedComponent>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

BTSMarketingBanner.propTypes = {
  schedule: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      copy: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      linkLabel: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired
    })
  )
};

BTSMarketingBanner.defaultProps = {
  schedule: defaultSchedule
};

export default BTSMarketingBanner;
