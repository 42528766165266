export const TPT_DESELECT_EDUCATION_STANDARD = '@@tpt/DESELECT_EDUCATION_STANDARD';
export const TPT_SELECT_EDUCATION_STANDARD = '@@tpt/SELECT_EDUCATION_STANDARD';
export const TPT_SET_EDUCATION_STANDARDS = '@@tpt/SET_EDUCATION_STANDARD';

export function deselectEducationStandard(standardId) {
  return {
    type: TPT_DESELECT_EDUCATION_STANDARD,
    payload: standardId
  };
}

export function selectEducationStandard(standard) {
  return {
    type: TPT_SELECT_EDUCATION_STANDARD,
    payload: standard
  };
}

export function setEducationStandards(selectedStandards) {
  return {
    type: TPT_SET_EDUCATION_STANDARDS,
    payload: selectedStandards
  };
}
