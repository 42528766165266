import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './QuoteLayout.scss';

const quoteTypes = {
  success: {
    quote: (
      <Fragment>
        “I truly didn’t know what was going
        <br />
        to happen when I made my
        <br />
        ClassFund page. But{' '}
        <span className="QuoteLayout__quoteSelected">
          I ended up
          <br />
          getting a lot of contributions,
        </span>{' '}
        more
        <br />
        than I ever anticipated.”
      </Fragment>
    ),
    author: 'Sarah T, Wisconsin'
  },
  salaries: {
    quote: (
      <Fragment>
        “TpT ClassFund <i>SAVED</i> me this year!{' '}
        <span className="QuoteLayout__quoteSelected">
          With the money I got, I was able to make a dent in my Wish List,
        </span>{' '}
        and the kids are loving what they get to do.”
      </Fragment>
    ),
    author: 'Hope M, 4th Grade Teacher'
  },
  givingSeason: {
    quote: (
      <Fragment>
        <span className="QuoteLayout__title">We’re Celebrating You</span>
        <br />
        <span className="QuoteLayout__title">This Giving Season!</span>
        <br />
        <div className="QuoteLayout__text">
          In celebration of Giving Season, we’re contributing $5 to 50 teachers every day between
          11/15 and 11/21. Create your TpT ClassFund and be entered for a chance to win.
        </div>
      </Fragment>
    )
  }
};

const QuoteLayout = ({ quoteType }) => {
  const { quote, author } = quoteTypes[quoteType];

  return (
    <div className="QuoteLayout text-center">
      <div className="QuoteLayout__header">
        <img
          alt="TpT ClassFund"
          height="25px"
          src="https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/1a5252de544a66e0d3471adf44afeee1.svg"
        />
        <br />
      </div>
      <div className="QuoteLayout__quotation">“</div>
      <div className="QuoteLayout__quote">{quote}</div>
      {author && <div className="QuoteLayout__author">{author}</div>}
    </div>
  );
};

QuoteLayout.propTypes = {
  quoteType: PropTypes.oneOf(['success', 'salaries'])
};

QuoteLayout.defaultProps = {
  quoteType: 'success'
};

export default QuoteLayout;
