import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Grid from 'components/ResponsiveGrid';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import Anchor from 'components/Anchor';
import Logo from 'components/Logo';
import GlobalErrorsContainer from 'domains/Header/GlobalErrorsContainer/GlobalErrorsContainer';
import SitewideBannerContainer from 'components/SitewideBanner/SitewideBannerContainer';
import NortonVerify from 'components/NortonVerify';
import { CONTRIBUTION_BASE_URL, isClassfundCheckoutPage } from 'domains/Contribution/helpers';
import classFundLogo from './class_fund_logo.svg';
import './ContributionHeaderLayout.scss';

const ContributionHeaderLayout = ({
  adminToolboxComponent,
  pathname,
  isHidden,
  shouldShowSitewideBanner,
  hasPadding,
  showGlobalError,
  preview
}) => (
  <div
    className={classnames('ContributionHeaderLayout', {
      [`ContributionHeaderLayout--slim`]: isHidden,
      ContributionHeaderLayout__preview: preview
    })}
  >
    <Grid hasPadding={hasPadding}>
      <Row>
        <Col xs={6}>
          <Anchor forceReload to={`/${CONTRIBUTION_BASE_URL}`}>
            <img
              src={classFundLogo}
              alt="Class Fund"
              className="ContributionHeaderLayout__classFundLogo"
            />
          </Anchor>
        </Col>
        <Col className="text-right" xs={6}>
          {isClassfundCheckoutPage(pathname) ? (
            <NortonVerify />
          ) : (
            <Logo isSymbol className="ContributionHeaderLayout__logo" />
          )}
        </Col>
      </Row>
      {showGlobalError && <GlobalErrorsContainer responsive />}
      {shouldShowSitewideBanner && <SitewideBannerContainer pathname={pathname} />}
    </Grid>
    {adminToolboxComponent}
  </div>
);

ContributionHeaderLayout.defaultProps = {
  adminToolboxComponent: null,
  isHidden: false,
  shouldShowSitewideBanner: true,
  hasPadding: true,
  showGlobalError: true,
  preview: false
};

ContributionHeaderLayout.propTypes = {
  adminToolboxComponent: PropTypes.node,
  pathname: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  shouldShowSitewideBanner: PropTypes.bool,
  hasPadding: PropTypes.bool,
  showGlobalError: PropTypes.bool,
  preview: PropTypes.bool
};

export default ContributionHeaderLayout;
