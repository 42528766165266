/**
 * Utilities for Heap, our analytics and tracking vendor.
 * Before using these utilities consider whether your tracking needs require a
 * a code change or if they can be done using Heap's autocaptured events.
 */

import pickBy from 'lodash/pickBy';

// GA360 events that we're migrating to use custom Heap events.
// The object is a map from the GA360 event name to the updated Heap name.
// See the "Track (Custom Events)" tabs https://docs.google.com/spreadsheets/d/1EhQV16oxOzQv7tN2b1NjMohzwMGT3oS0rHpCsu2-dh4/edit#gid=307975692
const GA360_TO_HEAP_CUSTOM_EVENTS = {
  'Open Custom School Modal': 'Click - Add Your School',
  'Error adding resource': 'Display Error - Add Resource',
  'Text Error Message': 'Display Error - Delete Text Entry',
  'Prefilled Error - Existing Campaign Page': 'Display Error - Existing Campaign Page',
  'FB share failure': ['Share - Classfund Page to Facebook', { value: 'false' }],
  'FB share success': ['Share - Classfund Page to Facebook', { value: 'true' }],
  'Displayed Feed': 'Display Feed - New Products',
  // Manually added
  // 'Add to Cart': 'Add To Cart',
  'Displayed Product Modal': 'Display Modal - Product',
  'Displayed Resources In Your Grade': 'Display - Resources in Your Grade',
  'Displayed Resources You May Like': 'Display - Resources You May Like',
  'Hovered Over Standards Pill': 'Hover - Standards',
  'Thumbnails Hovered': 'Hover - Thumbnail',
  'Thumbnails Rendered': 'Render - Thumbnail',
  'Digital Resources for Students Tooltip': 'Tooltip Displayed',
  'Top Page Error Message': 'Display Error - Top Page',
  'Error from Filestack': 'Display Error - Upload Image ',
  'Account Selected': 'Account Selected',
  'Account Selection Aborted': 'Account Selection Aborted',
  'Add to Drive': 'Add To Drive',
  'Add To Drive Failure': 'Add To Drive Failure',
  'Add to Drive Modal Close': 'Add To Drive Modal Close',
  'Add To Drive Retries': 'Add To Drive Retries',
  'Add To Drive Success': 'Add To Drive Success',
  'Add To Drive Timing': 'Add To Drive Timing',
  'Drive Permissions Error': 'Drive Permissions Error',
  'Drive Permissions Success': 'Drive Permissions Success',
  'Submit duration': 'Submit duration',
  'Submit timed out': 'Submit timed out',
  'Add Custom School': 'Submit - Custom School Form',
  'User read ratings/reviews': 'Click - See More Product Reviews',
  'File reset': 'Reset - Online Product File',
  'File Selected': 'Select - Online Product File',
  'Open Google Docs Picker': 'Open - Google Docs Picker',
  'Picker Closed': 'Close - Online Product Picker',
  'Preview Button Rendered': 'Render - Product Page Preview Button',
  // In the spreadsheet but already being sent to Heap in CommentsLayout
  // 'Resource loaded with 5 or more V1 reviews': 'Load - Resource - Five Or More Old Reviews',
  'TpT + Drive Modal Accepted': 'Accept - TpT Plus Drive Modal',
  'Landed on closed Page': 'View - Closed ClassFund Page',
  'CTA Appeared: Add To Cart': 'CTA Appeared: Add To Cart',
  'CTA Appeared: Watch Full Video': 'CTA Appeared: Watch Full Video',
  'CTA Appeared: Was This Helpful': 'CTA Appeared: Was This Helpful',
  'Input in Headline': 'Change - Classfund Create Input',
  'Apply Account Balance': 'Click - Apply Account Balance',
  'Apply Gift Card': 'Click - Apply Gift Card',
  'Apply Promo Code': 'Click - Apply Promo Code',
  'Clicked Update my page': 'Click - Update Classfund Page',
  'Interacted with Carousel': 'Interact - Classfund Carousel',
  'Download Bundle Bonus': ['Click - Download Button', { type: 'Bundle Bonus' }],
  Download: 'Click - Download Button',
  'Paid Download': ['Click - Download Button', { type: 'Product', paid: 'true' }],
  'TpTSA Seller Opted In': 'Seller - View - 2021 TpTSA Enrollment Modal (Opted In)',
  'TpTSA Seller Opted Out': 'Seller - View - 2021 TpTSA Enrollment Modal (Opted Out)',
  'TpTSA Seller Closed Modal': 'Seller - Closed - 2021 TpTSA Enrollment Modal',
  'TpTSA Seller Clicked Learn More': 'Seller - Click - Learn More from 2021 Enrollment Modal'
};

export function sendLegacyGAEventAsHeapCustomEvent({
  eventCat: category,
  eventAct,
  eventLbl: label,
  eventVal: value
}) {
  if (!GA360_TO_HEAP_CUSTOM_EVENTS[eventAct]) {
    return;
  }

  if (global.heap) {
    let name = GA360_TO_HEAP_CUSTOM_EVENTS[eventAct];
    let eventProperties = {};
    if (Array.isArray(name)) {
      [name, eventProperties] = name;
    }
    global.heap.track(
      name,
      pickBy(
        {
          category,
          label,
          value,
          ...eventProperties
        },
        // Heap event property vals are strings or numbers
        (val) => typeof val === 'string' || typeof val === 'number'
      )
    );
  }
}
