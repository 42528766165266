import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

const BundlesUploadPage = ({ config }) => (
  <Helmet
    script={[
      {
        src: config.assetManifest.fileRevisionHistory.js,
        defer: true
      },
      {
        src: config.assetManifest.bundlesUploadForm.js,
        defer: true
      },
      {
        src: config.assetManifest.productCcssSlot.js,
        defer: true
      },
      {
        src: config.assetManifest.productTitleSlot.js,
        defer: true
      }
    ]}
    link={[
      {
        rel: 'stylesheet',
        href: config.assetManifest.bundlesUploadForm.css
      },
      {
        rel: 'stylesheet',
        href: config.assetManifest.fileRevisionHistory.css
      },
      {
        rel: 'stylesheet',
        href: config.assetManifest.productCcssSlot.css
      },
      {
        rel: 'stylesheet',
        href: config.assetManifest.productTitleSlot.css
      }
    ]}
  />
);

BundlesUploadPage.propTypes = {
  config: PropTypes.shape({
    assetManifest: PropTypes.objectOf(
      PropTypes.shape({
        js: PropTypes.string,
        css: PropTypes.string
      })
    )
  }).isRequired
};

export default connect(({ config }) => ({ config }))(BundlesUploadPage);
