import { connect } from 'react-redux';
import { push, replace, goBack } from 'react-router-redux';

function withLocation(WrappedComponent) {
  return connect(
    ({ routing }) => ({
      location: routing.locationBeforeTransitions
    }),
    (dispatch) => ({
      pushLocation: (...args) => dispatch(push(...args)),
      replaceLocation: (...args) => dispatch(replace(...args)),
      goBack: (...args) => dispatch(goBack(...args))
    })
  )(WrappedComponent);
}

export default withLocation;
