import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { displayProps, spacingProps, overflowProps } from './propTypes';
import './Box.scss';

const ROOT_CLASSNAME = 'Box';

const Box = (props) => {
  const {
    children,
    className,
    component: Component,
    display,
    paddingX,
    paddingY,
    padding,
    paddingRight,
    paddingLeft,
    paddingTop,
    paddingBottom,
    margin,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    marginX,
    marginY,
    overflow,
    ...other
  } = props;
  const classes = classnames(
    ROOT_CLASSNAME,
    {
      [`${ROOT_CLASSNAME}--display-${display}`]: display !== 'block',
      [`${ROOT_CLASSNAME}--padding-${padding}`]: padding,
      [`${ROOT_CLASSNAME}--padding-right-${paddingRight}`]: paddingRight,
      [`${ROOT_CLASSNAME}--padding-left-${paddingLeft}`]: paddingLeft,
      [`${ROOT_CLASSNAME}--padding-top-${paddingTop}`]: paddingTop,
      [`${ROOT_CLASSNAME}--padding-bottom-${paddingBottom}`]: paddingBottom,
      [`${ROOT_CLASSNAME}--padding-x-${paddingX}`]: paddingX,
      [`${ROOT_CLASSNAME}--padding-y-${paddingY}`]: paddingY,
      [`${ROOT_CLASSNAME}--margin-${margin}`]: margin,
      [`${ROOT_CLASSNAME}--margin-x-${marginX}`]: marginX,
      [`${ROOT_CLASSNAME}--margin-y-${marginY}`]: marginY,
      [`${ROOT_CLASSNAME}--margin-right-${marginRight}`]: marginRight,
      [`${ROOT_CLASSNAME}--margin-left-${marginLeft}`]: marginLeft,
      [`${ROOT_CLASSNAME}--margin-top-${marginTop}`]: marginTop,
      [`${ROOT_CLASSNAME}--margin-bottom-${marginBottom}`]: marginBottom,
      [`${ROOT_CLASSNAME}--overflow-${overflow}`]: overflow
    },
    className
  );

  return (
    <Component className={classes} {...other}>
      {children}
    </Component>
  );
};

Box.defaultProps = {
  display: 'block',
  component: 'div'
};

Box.propTypes = {
  children: PropTypes.node,

  /** Extend class name for use in composing other design system components. Avoid for most usage as there might be unexpected style overrides. */
  className: PropTypes.string,

  /** The component used for the root node. Either a string to use a DOM element or a component. */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),

  /** Sets whether component is treated as a block or inline element and the layout used for its children, such as flex. */
  display: PropTypes.oneOf(displayProps),

  /** Sets the padding area on all four sides of the component. */
  padding: PropTypes.oneOf(spacingProps),

  /** Sets the padding area on left and right sides of the component. */
  paddingX: PropTypes.oneOf(spacingProps),

  /** Sets the padding area on top and bottom sides of the component. */
  paddingY: PropTypes.oneOf(spacingProps),

  /** Sets the padding area on the right side of the component. */
  paddingRight: PropTypes.oneOf(spacingProps),

  /** Sets the padding area on the left side of the component. */
  paddingLeft: PropTypes.oneOf(spacingProps),

  /** Sets the padding area on the top side of the component. */
  paddingTop: PropTypes.oneOf(spacingProps),

  /** Sets the padding area on the bottom side of the component. */
  paddingBottom: PropTypes.oneOf(spacingProps),

  /** Sets the margin area on all four sides of the component. */
  margin: PropTypes.oneOf(spacingProps),

  /** Sets the margin area on left and right sides of the component. */
  marginX: PropTypes.oneOf(spacingProps),

  /** Sets the margin area on top and bottom sides of the component. */
  marginY: PropTypes.oneOf(spacingProps),

  /** Sets the margin area on the right side of the component. */
  marginRight: PropTypes.oneOf(spacingProps),

  /** Sets the margin area on the left side of the component. */
  marginLeft: PropTypes.oneOf(spacingProps),

  /** Sets the margin area on the top side of the component. */
  marginTop: PropTypes.oneOf(spacingProps),

  /** Sets the margin area on the bottom side of the component. */
  marginBottom: PropTypes.oneOf(spacingProps),

  /** Sets what to do when components content is too big to fit in its block formatting context. */
  overflow: PropTypes.oneOf(overflowProps)
};

export default Box;
