import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Grid from 'components/ResponsiveGrid';
import Anchor from 'components/Anchor';
import Button from 'components/LegacyButton';
import { TPT_EASEL_BUYER_LANDING_URL } from 'shared/config/constants';
import EaselLogoBlack from 'shared/domains/TpTEasel/components/TpTEaselLogo/icons/easel-by-tpt-black.svg';
import { getImportToDigitalToolUrl } from '../../ProductPage/helpers/digitalHelper';
import { TPT_DIGITAL_ACTIVITY_SAMPLE_PRODUCT_ID } from '../../DigitalActivityBadging/constants';
import TpTEaselAsset from './images/TpTEaselAsset.svg';

import './EaselEyebrowBannerLayout.scss';

const EaselEyebrowBannerLayout = ({ isLoggedIn }) => {
  const easelSampleActivityImportUrl = getImportToDigitalToolUrl({
    id: TPT_DIGITAL_ACTIVITY_SAMPLE_PRODUCT_ID,
    isLoggedIn
  });

  return (
    <Grid hasPadding>
      <div className="EaselEyebrowBannerLayout">
        <div className="EaselEyebrowBannerLayout__contentContainer">
          <img className="EaselEyebrowBannerLayout__image" src={TpTEaselAsset} alt="Easel by TpT" />
          <div className="EaselEyebrowBannerLayout__infoContainer">
            <Text variant="bodySmall" strong>
              DID YOU KNOW:
            </Text>
            <Text variant="bodyLarge" strong>
              Seamlessly assign resources as digital activities
            </Text>
            <div data-testid="try-now-container" className="EaselEyebrowBannerLayout__try">
              <p>
                Learn how in 5 minutes with a tutorial resource.&nbsp;
                <Anchor to={easelSampleActivityImportUrl} opensNewTab>
                  Try it Now&nbsp;&nbsp;
                  <Icon name="long-arrow-right" />
                </Anchor>
              </p>
            </div>
          </div>
        </div>
        <div className="EaselEyebrowBannerLayout__easelContainer">
          <Button
            data-testid="learn-more-button"
            size="sm"
            label="Learn More"
            linkTo={TPT_EASEL_BUYER_LANDING_URL}
            opensNewTab
          />
          <img
            className="EaselEyebrowBannerLayout__easelLogo"
            src={EaselLogoBlack}
            alt="Easel by TpT"
          />
        </div>
      </div>
    </Grid>
  );
};

EaselEyebrowBannerLayout.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default EaselEyebrowBannerLayout;
