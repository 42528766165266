import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Divider from '@teachers/design-system/components/Divider';
import Button from 'components/LegacyButton';
import Anchor from 'components/Anchor';
import Icon from 'components/Icon';
import Text from 'components/Text';
import {
  TPT_EASEL_BUYER_LANDING_URL,
  TPT_EASEL_HOME_URL,
  EASEL_ASSESSMENTS_URL
} from 'shared/config/constants';
import easelActivities from 'shared/images/Easel/easel_activities_boxes.svg';
import easelAssessments from 'shared/images/Easel/easel_assessments_boxes.svg';
import EaselByTpTLogo from '../../../TpTEasel/components/EaselByTpTLogo';
import { getImportToDigitalToolUrl } from '../../../ProductPage/helpers/digitalHelper';
import { TPT_DIGITAL_ACTIVITY_SAMPLE_PRODUCT_ID } from '../../../DigitalActivityBadging/constants';

import './EaselHeaderMenu.scss';

const browseEaselResources = '/Browse/Usage/Easel-Activity-Seller-Created';

const EaselHeaderMenu = ({ isLoggedIn }) => (
  <div className="EaselHeaderMenu">
    {/* Overview */}
    <div className={classnames('EaselHeaderMenu__col', 'EaselHeaderMenu__col--overview')}>
      <div className="EaselHeaderMenu__col--header">
        <Text variant="bodySmall">OVERVIEW</Text>
        <Divider />
      </div>
      <div className="EaselHeaderMenu__col--body">
        <EaselByTpTLogo className="EaselHeaderMenu__easelLogo" />
        <Text variant="body">
          Interactive resources you can assign in your digital classroom from TpT.
        </Text>
        <Button
          className="EaselHeaderMenu__col--button"
          label={isLoggedIn ? 'Go to Easel' : 'Learn about Easel'}
          linkTo={isLoggedIn ? TPT_EASEL_HOME_URL : TPT_EASEL_BUYER_LANDING_URL}
          opensNewTab
        />
        {isLoggedIn && (
          <div className="EaselHeaderMenu__link">
            <Anchor
              to={getImportToDigitalToolUrl({
                id: TPT_DIGITAL_ACTIVITY_SAMPLE_PRODUCT_ID,
                isLoggedIn: true
              })}
              opensNewTab
            >
              Try the interactive tutorial&nbsp;
              <Icon name="long-arrow-right" />
            </Anchor>
          </div>
        )}
      </div>
    </div>
    {/* Tools */}
    <div className={classnames('EaselHeaderMenu__col', 'EaselHeaderMenu__col--tools')}>
      <div className="EaselHeaderMenu__col--header">
        <Text variant="bodySmall">TOOLS</Text>
        <Divider />
      </div>
      <div className="EaselHeaderMenu__col--bodyTools">
        {/* Activities */}
        <div className="EaselHeaderMenu__subcol">
          <div className="EaselHeaderMenu__subcol--activities">
            <img src={easelActivities} alt="" className="EaselHeaderMenu__icon" />
            <Text variant="bodyLarge" strong className="EaselHeaderMenu__subcol--title">
              Easel Activities
            </Text>
            <Text variant="body">
              Pre-made digital activities. Add highlights, virtual manipulatives, and more.
            </Text>
          </div>
          <div className="EaselHeaderMenu__link">
            <Anchor to={browseEaselResources} forceReload>
              Browse Easel Activities&nbsp;
              <Icon name="long-arrow-right" />
            </Anchor>
          </div>
        </div>
        {/* Assessments */}
        <div className="EaselHeaderMenu__subcol">
          <div className="EaselHeaderMenu__subcol--assessments">
            <img src={easelAssessments} alt="" className="EaselHeaderMenu__icon" />
            <Text variant="bodyLarge" strong className="EaselHeaderMenu__subcol--title">
              Easel Assessments
            </Text>
            <Text variant="body">
              Quizzes with auto-grading that will be available for purchase on TpT soon.
            </Text>
          </div>
          <div className="EaselHeaderMenu__link">
            <Anchor to={EASEL_ASSESSMENTS_URL} opensNewTab>
              Make an assessment now&nbsp;
              <Icon name="long-arrow-right" />
            </Anchor>
          </div>
        </div>
      </div>
    </div>
  </div>
);

EaselHeaderMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default EaselHeaderMenu;
