import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from '@teachers/design-system/components/Spinner';
import '@teachers/design-system/components/Spinner/Spinner.scss';
import './OptimizelyBannerContainer.scss';

/*
 * This optimizely banner is used by Crometrics/Optimizely
 * to run banner experiments. They rely on element `id`s
 * to update and append banner elements.
 */
function OptimizelyBannerContainer({ hideTimeoutMS }) {
  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!window.optimizely || typeof window.optimizely.get === 'undefined') {
        setShouldRender(false);
      }
    }, hideTimeoutMS);

    return () => clearTimeout(timeout);
  }, []);

  return shouldRender ? (
    <div id="optimizelyBanner" className="OptimizelyBannerContainer">
      <div
        id="optimizelyBannerPlaceholder"
        className="OptimizelyBannerContainer__placeholderContainer"
      />
      <div className="OptimizelyBannerContainer__spinnerContainer" id="optimizelyBannerSpinner">
        <Spinner data-testid="optimizely-banner-spinner" animation="border" variant="secondary" />
      </div>
    </div>
  ) : null;
}

OptimizelyBannerContainer.propTypes = {
  /* The number of milliseconds to wait for window.optimizely
   * until the banner automatically hides. */
  hideTimeoutMS: PropTypes.number
};

OptimizelyBannerContainer.defaultProps = {
  hideTimeoutMS: 3000
};

export default OptimizelyBannerContainer;
