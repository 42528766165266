import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import Anchor from 'components/Anchor';
import ButtonBase from 'shared/components/ButtonBase';
import './Button.scss';

// TODO: use invariant to check for forceReload && opensNewTab
const Button = ({
  className,
  label,
  skin = 'primary',
  disabled,
  size = 'md',
  fullWidth,
  onClick,
  linkTo,
  forceReload,
  opensNewTab,
  iconLeft,
  iconRight,
  loginAndRedirectTo,
  type = 'button',
  'data-testid': dataTestId
}) => {
  const shouldRenderLink = (linkTo || loginAndRedirectTo) && !disabled;
  // NOTE: disabled means a button MUST be rendered, since <a> tags have no disabled pseudo-state

  const buttonClassNames = classnames(className, {
    Button: true,
    'Button--medium': size === 'md',
    'Button--small': size === 'sm',
    'Button--fullWidth': fullWidth,
    'Button--link': shouldRenderLink,
    'Button--primary': skin === 'primary',
    'Button--primaryOutline': skin === 'primary outline',
    'Button--primaryLink': skin === 'primary link',
    'Button--secondary': skin === 'secondary',
    'Button--secondaryOutline': skin === 'secondary outline',
    'Button--tertiary': skin === 'tertiary',
    // TODO: Add semantic names with design
    'Button--white': skin === 'white',
    'Button--whiteLink': skin === 'white link',
    'Button--whiteOutline': skin === 'white outline',
    'Button--red': skin === 'red',
    'Button--redLink': skin === 'red link',
    'Button--gray': skin === 'gray',
    'Button--grayOutline': skin === 'gray outline',
    'Button--grayLink': skin === 'gray link',
    'Button--highlight': skin === 'highlight'
  });

  const ComponentTag = shouldRenderLink ? Anchor : ButtonBase;

  return (
    <ComponentTag
      className={buttonClassNames}
      onClick={onClick}
      disabled={disabled}
      type={type}
      // ⬇ anchor-specific properties ⬇
      loginAndRedirectTo={loginAndRedirectTo}
      to={linkTo}
      forceReload={forceReload}
      opensNewTab={opensNewTab}
      data-testid={dataTestId}
    >
      {iconLeft && <Icon name={iconLeft} className="Button__iconLeft" />}
      {label}
      {iconRight && <Icon name={iconRight} className="Button__iconRight" />}
    </ComponentTag>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  skin: PropTypes.oneOf([
    'primary',
    'primary outline',
    'primary link',
    'secondary',
    'secondary outline',
    'tertiary',
    'white',
    'white outline',
    'white link',
    'red',
    'red link',
    'gray',
    'gray link',
    'gray outline',
    'highlight'
  ]),
  size: PropTypes.oneOf(['md', 'sm']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  forceReload: PropTypes.bool,
  opensNewTab: PropTypes.bool,
  label: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  loginAndRedirectTo: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'rest']),
  'data-testid': PropTypes.string
};

export default Button;
