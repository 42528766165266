import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Grid from 'components/ResponsiveGrid';
import Logo from 'components/Logo';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import GlobalErrorsContainer from 'domains/Header/GlobalErrorsContainer/GlobalErrorsContainer';
import LoggedOutHeaderLayout from 'domains/Header/LoggedOutHeader/LoggedOutHeaderLayout';
import NortonVerify from 'components/NortonVerify';
import ClassFundInterruptSocialModalContainer from 'domains/Header/ClassFundInterruptSocialModal/ClassFundInterruptSocialModalContainer';
import { isEvaluationPage } from 'domains/Header/util';
import EaselBannerEyebrow from 'domains/TpTEasel/TpTEaselEyebrow/EaselBannerEyebrow';

import 'domains/Header/SkinnyHeaderLayout.scss';

function shouldShowNortonLogo(location) {
  return (
    /^\/(next-)?cart/i.test(location.pathname) && !location.pathname.toLowerCase().includes('/done')
  );
}

const ResponsiveSkinnyHeaderLayout = ({
  featureVariants,
  isLoggedIn,
  isHidden,
  location,
  pathname
}) => {
  const showNorton = shouldShowNortonLogo(location);

  return (
    !isHidden && (
      <>
        <EaselBannerEyebrow featureVariants={featureVariants} isLoggedIn={isLoggedIn} />
        <div
          className={classnames('SkinnyHeaderLayout--responsive', {
            'SkinnyHeaderLayout--overrideMargin': isEvaluationPage(pathname)
          })}
        >
          <ClassFundInterruptSocialModalContainer
            variant={featureVariants.classfund_interrupt_modal_v2}
            pathname={pathname}
            isLoggedIn={isLoggedIn}
          />
          <Grid hasPadding>
            <Row className="SkinnyHeaderLayout--responsive__row">
              <Col alignSelf="center">
                <Logo className="SkinnyHeaderLayout__Logo" />
              </Col>
              <Col className="d-none d-lg-block">
                {!isLoggedIn && (
                  <LoggedOutHeaderLayout showCart={false} featureVariants={featureVariants} />
                )}
              </Col>
              {showNorton && (
                <Col auto>
                  <div className="SkinnyHeaderLayout__norton">
                    <NortonVerify />
                  </div>
                </Col>
              )}
            </Row>
            <GlobalErrorsContainer />
          </Grid>
        </div>
      </>
    )
  );
};

ResponsiveSkinnyHeaderLayout.defaultProps = {
  featureVariants: {},
  isHidden: false
};

ResponsiveSkinnyHeaderLayout.propTypes = {
  featureVariants: featureVariantsPropTypes,
  isHidden: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  pathname: PropTypes.string.isRequired
};

export default ResponsiveSkinnyHeaderLayout;
