import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import Cookies from 'js-cookie';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { pageStatuses } from 'domains/Contribution/helpers';
import ClassfundSummaryQuery from 'domains/Contribution/queries/ClassfundSummary.graphql';

export default (WrappedComponent) => {
  @graphql(ClassfundSummaryQuery, {
    skip: () => !IS_BROWSER,
    ssr: false,
    props: ({ data }) => {
      const classfundSummary = get(data, 'member.contribution.classfundSummary', {});
      return { classfundSummary: { ...classfundSummary }, isLoading: data.loading };
    }
  })
  class WithClassfundSummaryComponent extends Component {
    static propTypes = {
      classfundSummary: PropTypes.shape({
        status: PropTypes.string,
        totalContributions: PropTypes.string
      }),
      isLoading: PropTypes.bool
    };

    static defaultProps = {
      classfundSummary: {
        status: pageStatuses.NONE,
        totalContributions: '$0.00'
      },
      isLoading: true
    };

    componentDidUpdate = () => {
      const {
        classfundSummary: { status, totalContributions },
        isLoading
      } = this.props;
      const active = status !== pageStatuses.DELETED;
      const isDraft = status === pageStatuses.DRAFT;

      if (!isLoading) {
        // set cookie for optimizely
        // Do not change active, isDraft as they will affect
        // the optimizely tests until tests are updated
        Cookies.set(
          'classfundSummary',
          { active, isDraft, status, totalContributions },
          { expires: 1 / 12 }
        );
      }
    };

    render() {
      const {
        classfundSummary: { status, totalContributions },
        ...props
      } = this.props;
      return <WrappedComponent classfundSummary={{ status, totalContributions }} {...props} />;
    }
  }

  WithClassfundSummaryComponent.displayName = `WithClassfundSummaryComponent(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return hoistNonReactStatics(WithClassfundSummaryComponent, WrappedComponent);
};
