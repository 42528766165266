import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import Alert from 'components/Alert';
import Anchor from 'components/Anchor';
import GetSellerPaidProducts from './GetSellerPaidProducts.graphql';

// Pre Sale notification block for sellers with at least one active product
// Please don't remove this file! This is a seller notification we show to sellers
// during sitewide sales

const PreSaleNotifications = ({ shouldRender, hasPaidProducts = false }) => {
  if (!shouldRender || !hasPaidProducts) {
    return null;
  }
  return (
    <Alert notificationType="info" allowClose={false}>
      Hello Teacher-Author!&nbsp; Our Teacher Appreciation Sale is coming up May 4th &amp;
      5th.&nbsp;&nbsp;
      <Anchor to="/Throw-a-Sale" openNewTab>
        Ready Your Store
      </Anchor>
    </Alert>
  );
};

PreSaleNotifications.propTypes = {
  shouldRender: PropTypes.bool.isRequired,
  hasPaidProducts: PropTypes.bool
};

export default graphql(GetSellerPaidProducts, {
  skip: ({ shouldRender }) => !shouldRender,
  props: ({ data: { seller } }) =>
    seller && seller.paidProducts && { hasPaidProducts: seller.paidProducts.length > 0 }
})(PreSaleNotifications);

export { PreSaleNotifications as PurePreSaleNotificationBespoke };
