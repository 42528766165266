/**
 * This component exists solely to hide the header/footer on admin dashboard pages,
 * which are served through PHP but need to use React components while not showing the
 * header or footer.
 */
import PropTypes from 'prop-types';
import { Component } from 'react';
import routeState from 'decorators/routeState';

@routeState
class LegacyAdminPage extends Component {
  static propTypes = {
    children: PropTypes.node,
    setRouteState: PropTypes.func.isRequired
  };

  componentWillMount() {
    const { setRouteState } = this.props;
    setRouteState({ shouldHideHeaderFooter: true });
  }

  render() {
    return this.props.children || null;
  }
}

export default LegacyAdminPage;
