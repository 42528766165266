import React from 'react';
import PropTypes from 'prop-types';
import BuyerDropdownMenu from 'domains/Header/BuyerHeader/BuyerDropdown';
import UserDetailsRow from 'domains/Responsive/UserDetailsRow';

const ResponsiveBuyerHeaderLayout = ({ firstName, name, countUnreadMessages }) => (
  <div>
    <UserDetailsRow loggedIn name={firstName || name} url="/My-Account/Basics" />
    <BuyerDropdownMenu countUnreadMessages={countUnreadMessages} label={firstName || name} />
  </div>
);

ResponsiveBuyerHeaderLayout.propTypes = {
  name: PropTypes.string,
  firstName: PropTypes.string,
  countUnreadMessages: PropTypes.number
};

export default ResponsiveBuyerHeaderLayout;
