import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Anchor from 'components/Anchor';
import logo from './logo.svg';
import logoSymbol from './logo-symbol.svg';
import './Logo.scss';

export const DEFAULT_LOGO_HEIGHT_PX = 38;
export const DEFAULT_LOGO_WIDTH_PX = 250;
export const DFEAULT_SYMBOL_WIDTH_PX = 38;

const Logo = ({ height: heightOverride, className, isSymbol, ...other }) => {
  const width = isSymbol ? DFEAULT_SYMBOL_WIDTH_PX : DEFAULT_LOGO_WIDTH_PX;
  let styles = {};

  if (!heightOverride) {
    styles = { width, height: DEFAULT_LOGO_HEIGHT_PX };
  } else {
    styles = { height: heightOverride };
  }

  return (
    <div className={classnames('Logo', { 'Logo--isSymbol': isSymbol }, className)} {...other}>
      <Anchor forceReload to="/">
        <img
          className="Logo__img"
          style={styles}
          src={isSymbol ? logoSymbol : logo}
          alt="Teachers Pay Teachers"
        />
      </Anchor>
    </div>
  );
};

Logo.defaultProps = {
  className: null,
  isSymbol: false,
  height: undefined
};

Logo.propTypes = {
  // Additional classes on component container
  className: PropTypes.string,

  // If true, will render the TpT symbol (no text)
  isSymbol: PropTypes.bool,

  // If provided, will override the height of the <img>
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Logo;
