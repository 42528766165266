import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'components/Dropdown';
import LinkButton from 'components/LinkButton';
import BuyerDropdownMenu from './BuyerDropdownMenu';

import './BuyerDropdown.scss';

// TODO: fix this component so it renders out the same way, regardless of the width of its parent
const BuyerDropdownBespoke = ({ countUnreadMessages, label }) => (
  <Dropdown
    openOnHover
    className="BuyerDropdownBespoke"
    label={
      <div className="BuyerDropdownBespoke__label">
        <span className="BuyerDropdownBespoke__labelName">{label}</span>
      </div>
    }
  >
    <BuyerDropdownMenu countUnreadMessages={countUnreadMessages} />

    <LinkButton
      forceReload
      to="/Logout"
      label="Log Out"
      className="BuyerDropdownBespoke__logoutLink green outline cta"
    />
  </Dropdown>
);

BuyerDropdownBespoke.defaultProps = {
  countUnreadMessages: 0,
  label: 'My TpT'
};

BuyerDropdownBespoke.propTypes = {
  countUnreadMessages: PropTypes.number,
  label: PropTypes.string
};

export default BuyerDropdownBespoke;
