/**
 * @param { !string } typeName
 * @return { !string | null }
 */
export default function constructApolloKey(...args) {
  const [typeName, ...idKeys] = args;

  if (idKeys.length) {
    return `${typeName}:${idKeys.join('.')}`;
  }

  return null;
}
