/* eslint-disable @miovision/disallow-date/no-new-date */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { parse, differenceInDays } from 'date-fns';
import withUserData from 'decorators/withUserData';
import withClassfundSummary from 'decorators/withClassfundSummary';
import { pageStatuses } from 'domains/Contribution/helpers';
import DialogModal from 'components/DialogModal';
import QuoteLayout from 'domains/Header/ClassFundInterruptSocialModal/layouts/QuoteLayout';
import ModalButtons from 'domains/Header/ClassFundInterruptSocialModal/layouts/ModalButtons';
import './ClassFundInterruptSocialModal.scss';

export const MODAL_CLOSED_BY_USER_LS_KEY = 'classFundInterruptSocialModalV2ClosedByUser';

const pagesMap = {
  Home: (p) => p === '/',
  'Site Search': (p) => p.startsWith('/Browse/'),
  'Product Page': (p) => p.startsWith('/Product/'),
  Cart: (p) => p.startsWith('/Cart') && !p.startsWith('/Cart/Checkout'),
  Wishlist: (p) => p.startsWith('/Wish-List'),
  'Store Page': (p) => p.startsWith('/Store/'),
  'My Purchases': (p) => p.startsWith('/My-Purchases')
};
export const getPageName = (pathname) => {
  const result = Object.entries(pagesMap).find(([, check]) => check(pathname));
  return result && result[0];
};

export const isMoreThanDaysAfterSignup = (signUpDateStr, days = 30) => {
  if (!signUpDateStr || !signUpDateStr.length) {
    return false;
  }

  const now = new Date();
  const signUpDate = parse(signUpDateStr, 'yyyy-LL-dd HH:mm', new Date());
  return differenceInDays(now, signUpDate) > days;
};

class ClassFundInterruptSocialModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  componentDidUpdate = () => {
    if (!this.shouldTryToShowModal()) {
      return;
    }

    const wasShowed = this.wasModalShowedBefore();

    const canSaveToLS = this.saveShowedModalToLS();

    if (!wasShowed && canSaveToLS) {
      // Render relies on clientside-only localStorage
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isOpen: true
      });
    }
  };

  wasModalShowedBefore = () => Boolean(localStorage.getItem(MODAL_CLOSED_BY_USER_LS_KEY));

  saveShowedModalToLS = () => {
    try {
      localStorage.setItem(MODAL_CLOSED_BY_USER_LS_KEY, true);
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to set localStorage for Classfund interrupt modal', e);
    }
    return false;
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  shouldTryToShowModal = () => {
    const {
      variant,
      pathname,
      isMobile,
      excludeMobile,
      isLoggedIn,
      signUpDate,
      classfundSummary: { status },
      country
    } = this.props;

    const hasNoActiveClassFundPage = status === pageStatuses.NONE;

    const pagename = getPageName(pathname);

    const shouldShowModalForUsers =
      country === 'United States'
        ? hasNoActiveClassFundPage && isMoreThanDaysAfterSignup(signUpDate)
        : false;

    if (
      !variant ||
      variant === 'off' ||
      variant === 'off_control' ||
      !pagename ||
      !isLoggedIn ||
      (isMobile && excludeMobile) ||
      !shouldShowModalForUsers
    ) {
      return false;
    }
    return true;
  };

  render() {
    const { variant, pathname } = this.props;
    const { isOpen } = this.state;

    const pagename = getPageName(pathname);

    if (!isOpen) {
      return null;
    }

    let component = null;

    if (variant === 'quote_success') {
      component = (
        <Fragment>
          <QuoteLayout />
          <ModalButtons pagename={pagename} />
        </Fragment>
      );
    }

    if (variant === 'quote_success_explore') {
      component = (
        <Fragment>
          <QuoteLayout />
          <ModalButtons pagename={pagename} showLearnMore={false} buttonLabel="Explore more" />
        </Fragment>
      );
    }

    if (variant === 'quote_salaries') {
      component = (
        <Fragment>
          <QuoteLayout quoteType="salaries" />
          <ModalButtons pagename={pagename} />
        </Fragment>
      );
    }

    if (variant === 'giving_season') {
      component = (
        <Fragment>
          <QuoteLayout quoteType="givingSeason" />
          <ModalButtons pagename={pagename} showLearnMore={false} showFinePrint />
        </Fragment>
      );
    }

    return (
      <DialogModal
        className="ClassFundInterruptSocialModal"
        header=""
        isOpen={isOpen}
        shouldRenderCloseButton
        shouldRenderButtonPair={false}
        closeModal={this.closeModal}
      >
        {component}
      </DialogModal>
    );
  }
}

ClassFundInterruptSocialModalContainer.defaultProps = {
  variant: 'off',
  pathname: '',
  isMobile: false,
  excludeMobile: true,
  isLoggedIn: false,
  signUpDate: '',
  classfundSummary: {
    status: pageStatuses.ACTIVE
  },
  country: ''
};

ClassFundInterruptSocialModalContainer.propTypes = {
  variant: PropTypes.oneOf([
    'off',
    'off_control',
    'quote_success',
    'quote_success_explore',
    'quote_salaries',
    'giving_season'
  ]),
  pathname: PropTypes.string,
  isMobile: PropTypes.bool,
  excludeMobile: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  signUpDate: PropTypes.string,
  classfundSummary: PropTypes.shape({
    status: PropTypes.string
  }),
  country: PropTypes.string
};

export default compose(
  connect(({ config }) => ({
    isMobile: config.isMobile
  })),
  withClassfundSummary,
  withUserData({
    props: (data) => ({
      signUpDate: get(data, 'userData.member.created', ''),
      country: get(data, 'userData.member.userProfile.country', '')
    })
  })
)(ClassFundInterruptSocialModalContainer);
