import React from 'react';
import Route from 'react-router/lib/Route';
import ApplicationEntry from '../entries/ApplicationEntry';
import LegacyEntry from '../entries/LegacyEntry';
import LegacyAdminPage from '../pages/LegacyAdminPage';
import ItemsQuickEditPage from '../pages/ItemsQuickEditPage';
import ItemsDiditalEditPage from '../pages/ItemsDiditalEditPage';
import ItemsDiditalNewPage from '../pages/ItemsDiditalNewPage';
import ItemsVideoEditPage from '../pages/ItemsVideoEditPage';
import ItemsVideoNewPage from '../pages/ItemsVideoNewPage';
import ItemsGoodsEditPage from '../pages/ItemsGoodsEditPage';
import ItemsGoodsNewPage from '../pages/ItemsGoodsNewPage';
import BundlesUploadPage from '../pages/BundlesUploadPage';
import BundleRevisionsPage from '../pages/BundleRevisionsPage';
import CopyrightToolPage from '../pages/CopyrightToolPage';
import StorePage from '../pages/StorePage';
import injectPage from './injectPage';

export default (
  <Route component={ApplicationEntry}>
    <Route component={LegacyEntry}>
      {/* This route is used only for loading our Header/Footer so the server could
           easily concatenate legacy, php content and then a react footer */}
      <Route
        path="/itemsDigital/editNext/:itemId"
        component={injectPage('itemsDigitalEditNextPage')}
      />
      <Route
        path="/My-Products/New/Digital-Next"
        component={injectPage('itemsDigitalNewNextPage')}
      />
      <Route
        path="/itemsVideo/migrateNext/:itemId"
        component={injectPage('itemsVideoEditNextPage')}
      />
      <Route path="/itemsVideo/editNext/:itemId" component={injectPage('itemsVideoEditNextPage')} />
      <Route path="/My-Products/New/Video-Next" component={injectPage('itemsVideoNewNextPage')} />
      <Route path="/My-Products/New/Bundle-Next" component={injectPage('itemsBundleNewNextPage')} />
      <Route
        path="/itemsBundle/editNext/:itemId"
        component={injectPage('itemsBundleEditNextPage')}
      />
      <Route
        path="/itemsBundle/migrateNext/:itemId"
        component={injectPage('itemsBundleEditNextPage')}
      />
      <Route path="/itemsGoods/edit/:itemId" component={ItemsGoodsEditPage} />
      <Route path="/My-Products/New/Bundle" component={BundlesUploadPage} />
      <Route path="/itemsBundle/add" component={BundlesUploadPage} />
      <Route path="/itemsBundle/edit/:itemId" component={BundlesUploadPage} />
      <Route path="/itemsBundle/migrate/:itemId" component={BundlesUploadPage} />
      <Route path="/itemsDigital/edit/:itemId" component={ItemsDiditalEditPage} />
      <Route path="/My-Products/New/Digital" component={ItemsDiditalNewPage} />
      <Route path="/itemsGoods/edit/:itemId" component={ItemsGoodsEditPage} />
      <Route path="/My-Products/New/Hard-Good" component={ItemsGoodsNewPage} />
      <Route path="/My-Products/New/Used-Good" component={ItemsGoodsNewPage} />
      <Route path="/itemsVideo/edit/:itemId" component={ItemsVideoEditPage} />
      <Route path="/My-Products/New/Video" component={ItemsVideoNewPage} />
      <Route path="/My-Products*" component={ItemsQuickEditPage} />
      <Route path="/Store/*" component={StorePage} />
      <Route path="/adminnew3" component={LegacyAdminPage}>
        <Route path="bundle_revisions/history/:itemId" component={BundleRevisionsPage} />
        <Route path="items/copyright_tool*" component={CopyrightToolPage} />
        <Route path="users/details/:userId" component={injectPage('exemptionsTool')} />
        <Route path="*" />
      </Route>
      <Route path="/My-Purchases*" component={injectPage('myPurchasesPage')} />
      <Route path="/orders/free_downloads*" component={injectPage('addToGoogleDriveButtons')} />
      <Route path="/My-Free-Downloads*" component={injectPage('addToGoogleDriveButtons')} />
      <Route path="/FreeDownload/*" component={injectPage('freeDownloadDigitalActivitySection')} />
      <Route path="*" />
    </Route>
  </Route>
);
