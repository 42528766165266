import PropTypes from 'prop-types';
import React from 'react';
import ReactRouterLink from 'react-router/lib/Link';
import classnames from 'classnames';
import { isFunction } from 'lodash';
import './Anchor.scss';

// TODO: use invariant to check for uniqueness between conflicting props
// (to && loginAndRedirectTo)
// (openNewTab && forceReload)
const Anchor = ({
  children,
  to = '#',
  onClick,
  color = 'green',
  className,
  openNewTab: _openNewTab = false,
  opensNewTab: _opensNewTab = false,
  forceReload = false,
  decorateOnHover,
  loginAndRedirectTo,

  // Catch-all to add other props suitable for <a> / ReactRouterLink
  ...extraProps
}) => {
  // Handle our two "openNewTab"/"opensNewTab" props, which have identical behavior
  const openNewTab = _openNewTab || _opensNewTab;

  const defaultClassNames = classnames({
    Anchor: true,
    'Anchor--green': color === 'green',
    'Anchor--gray': color === 'gray',
    'Anchor--black': color === 'black',
    'Anchor--inherit': color === 'inherit',
    'Anchor--undecorated': !decorateOnHover
  });

  const renderPlainAnchor = openNewTab || forceReload || loginAndRedirectTo;

  // work around for react router bug, where it doesn't scroll to hash location
  // if hash is already set  (location change event never fired)
  const onClickWithClear = (e) => {
    if (window.history && window.history.replaceState) {
      window.history.replaceState(null, null, window.location.pathname + window.location.search);
    } else {
      window.location.hash = '';
    }
    if (isFunction(onClick)) {
      onClick(e);
    }
  };

  return renderPlainAnchor ? (
    <a
      {...extraProps}
      className={className || defaultClassNames}
      onClick={loginAndRedirectTo ? null : onClickWithClear}
      target={openNewTab ? '_blank' : null}
      href={loginAndRedirectTo ? `/Login/?f=${loginAndRedirectTo}` : to}
      // Security: https://web.dev/external-anchors-use-rel-noopener/
      rel={openNewTab ? 'noopener' : null}
    >
      {children}
    </a>
  ) : (
    <ReactRouterLink
      {...extraProps}
      className={className || defaultClassNames}
      onClick={onClickWithClear}
      to={to}
    >
      {children}
    </ReactRouterLink>
  );
};

Anchor.propTypes = {
  children: PropTypes.node,

  /** Whether to underline on hover. (Defaults to true) */
  decorateOnHover: PropTypes.bool,

  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object,
      hash: PropTypes.string
    })
  ]),
  onClick: PropTypes.func,

  /**
   * Link color. Mutually exclusive to `className` prop; will not be used if `className`
   * prop is set.
   */
  color: PropTypes.oneOf(['green', 'black', 'gray', 'inherit']),

  /**
   * Custom class name. This is mutually exclusive to `color` prop, as specifying `className`
   * completely replaces the default classname on Anchor and also any color classes.
   */
  className: PropTypes.string,
  openNewTab: PropTypes.bool,
  opensNewTab: PropTypes.bool,
  forceReload: PropTypes.bool,
  loginAndRedirectTo: PropTypes.string
};

Anchor.defaultProps = {
  decorateOnHover: true
};

export default Anchor;
