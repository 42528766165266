import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'react-apollo';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import MenuSection from 'components/MenuSection';
import InboxMenuItemPartial from 'domains/Header/components/InboxMenuItem';
import DigitalActivitiesMenuItemPartial from 'domains/Header/components/DigitalActivitiesMenuItem';
import Badge from 'domains/UploadPage/Badge';
import { EASEL_ACTIVITIES_URL, EASEL_LISTINGS_ASSESSMENTS_URL } from 'config/constants';
import { applicationFeatures } from 'config/features';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import featureVariantsInfo from 'decorators/featureVariants';
import { CONTRIBUTION_BASE_URL, pageStatuses } from 'domains/Contribution/helpers';
import withClassfundSummary from 'decorators/withClassfundSummary';

/**
  Menu items for a Seller. To be used in headers.
  @return { Component }
  */
const SellerDropdownMenu = ({
  featureVariants,
  colProps,
  countUnreadMessages,
  storeUrl,
  logoutButton,
  classfundSummary: { status },
  isPremiumSellerOrAdmin
}) => (
  <Row>
    <Col {...colProps}>
      <MenuSection
        title="Promote"
        items={[
          { title: 'Throw a Sale', url: '/Throw-a-Sale' },
          { title: 'Promote on TpT', url: '/My-Sponsored' },
          { title: 'Newsletter', url: '/Newsletter-Promotion' },
          featureVariants.fb_seller_pixel_my_account === 'on' &&
            isPremiumSellerOrAdmin && {
              title: (
                <span>
                  Facebook Pixel
                  <Badge label="NEW" />
                </span>
              ),
              url: '/My-Account/Facebook-Pixel'
            }
        ]}
      />
      <MenuSection
        title="Communicate"
        items={[
          { title: 'Note to Followers', url: '/Note-To-Followers' },
          { title: 'Manage Q&As', url: `${storeUrl}?query1#tab_ask_a_question` },
          { title: 'Manage Comments', url: `${storeUrl}?query2#tab_ratings_feedback` },
          // HACK: `?query` and `?query2` are hacks to force a full page reload
          { title: "Seller's Forum", url: '/forum/' }
        ]}
      />
    </Col>
    <Col {...colProps}>
      <MenuSection
        title="Buy"
        items={[
          { title: 'My Wish List', url: '/Wish-List' },
          { title: 'My Purchases', url: '/My-Purchases' },
          {
            title: <DigitalActivitiesMenuItemPartial featureVariants={featureVariants} />,
            url: EASEL_ACTIVITIES_URL
          },
          { title: 'My Favorite Sellers', url: '/My-Account/Favorite_Sellers' }
        ]}
      />
      <MenuSection
        title="Sell"
        items={[
          { title: 'Dashboard', url: '/Dashboard' },
          { title: 'Sales Reports', url: '/My-Sales' },
          { title: 'My Product Listings', url: '/My-Products' },
          {
            title: (
              <DigitalActivitiesMenuItemPartial
                featureVariants={featureVariants}
                inSellMenuSection
              />
            ),
            url: EASEL_LISTINGS_ASSESSMENTS_URL
          },
          { title: 'My Product Statistics', url: '/My-Statistics' },
          { title: 'My Store', url: storeUrl }
        ]}
      />
    </Col>
    <Col {...colProps}>
      <MenuSection
        title="Account"
        items={[
          { title: 'My Account', url: '/My-Account/Basics' },
          {
            title: 'Account Balance',
            url: `/Account-Balance`
          },
          {
            title: (
              <span>TpT ClassFund {status === pageStatuses.DRAFT && <Badge label="DRAFT" />}</span>
            ),
            url: `/${CONTRIBUTION_BASE_URL}`
          },
          featureVariants &&
            featureVariants.schools_access_header_links === 'on' && {
              title: <span>TpT School Access</span>,
              url: `/TpTSchoolAccess/Teachers`,
              trackedComponentProps: {
                category: 'Header',
                action: 'Clicked TpT School Access',
                label: 'TpT School Access'
              }
            },
          {
            title: 'TpT Credit Balance',
            url: '/Tptcredits'
          },
          {
            title: <InboxMenuItemPartial countUnreadMessages={countUnreadMessages} />,
            url: '/Mailbox'
          }
        ]}
      />
      {logoutButton}
    </Col>
  </Row>
);

SellerDropdownMenu.propTypes = {
  featureVariants: featureVariantsPropTypes,
  storeUrl: PropTypes.string,
  countUnreadMessages: PropTypes.number,
  /* Props for each col in menu */
  colProps: PropTypes.object,
  /* Component to log user out */
  logoutButton: PropTypes.node,
  classfundSummary: PropTypes.shape({
    status: PropTypes.string
  }),
  isPremiumSellerOrAdmin: PropTypes.bool
};

SellerDropdownMenu.defaultProps = {
  classfundSummary: {
    status: pageStatuses.ACTIVE
  },
  isPremiumSellerOrAdmin: false
};

export default compose(
  featureVariantsInfo(applicationFeatures),
  withClassfundSummary
)(SellerDropdownMenu);
