// Action type
export const SET_MODAL_OPEN = '@@tpt/SchoolMatching/SET_MODAL_OPEN';

// Reducer
export default function reducer(state = { isOpen: false }, action = {}) {
  if (action.type === SET_MODAL_OPEN) {
    return { ...state, isOpen: action.isOpen };
  }
  return state;
}

// Action creator
export function setModalOpen(isOpen) {
  return { type: SET_MODAL_OPEN, isOpen };
}
