import React from 'react';
import PropTypes from 'prop-types';
import Anchor from 'components/Anchor';
import Badge from 'domains/UploadPage/Badge';
import './HeaderDigitalActivity.scss';

export const CLICK_EVENT = 'Nav Header - Clicked Digital Activities';

export const REDIRECT_TO = {
  DIGITAL_SELLER: '/digital'
};

export const getEaselRedirectLink = (isPremiumSeller) => {
  if (isPremiumSeller) {
    return REDIRECT_TO.DIGITAL_SELLER;
  }
  return CONFIG.endpoints.apis.easelByTpt;
};

const HeaderDigitalActivity = ({ isLoggedIn, itemClassName, isSeller, isPremiumSeller }) => {
  const getRedirectTo = () => {
    return getEaselRedirectLink(isPremiumSeller);
  };
  const triggerHeapEvent = () => {
    global.heap.track(CLICK_EVENT, {
      isLoggedIn,
      isSeller
    });
  };

  return (
    <li className={itemClassName}>
      <Anchor
        color="gray"
        forceReload
        to={`${getRedirectTo()}?utm_source=tpt-header-nav`}
        onClick={triggerHeapEvent}
      >
        Easel by TpT
      </Anchor>
      <span className="NewEaselHeaderBadge">
        <Badge label="NEW" />
      </span>
    </li>
  );
};

HeaderDigitalActivity.defaultProps = {
  itemClassName: 'HeaderLayout__link'
};

HeaderDigitalActivity.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isSeller: PropTypes.bool.isRequired,
  isPremiumSeller: PropTypes.bool.isRequired,
  itemClassName: PropTypes.string
};

export default HeaderDigitalActivity;
