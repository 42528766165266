import PropTypes from 'prop-types';

export const OrganizationInvitationType = PropTypes.shape({
  invitationHash: PropTypes.string,
  sender: PropTypes.shape({
    name: PropTypes.string
  }),
  organization: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string
  })
});
