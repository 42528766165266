import spacingTokens from 'styles/design-tokens/output/spacing.json';

function removeREADMEBanner(arr) {
  return arr.filter((key) => key !== '_README');
}

const spacing = removeREADMEBanner(Object.keys(spacingTokens));
export const spacingProps = ['auto', 'none', ...spacing.map((s) => s.replace(/^spacing-/i, ''))];
export const displayProps = ['block', 'inline-block', 'inline', 'flex', 'visuallyHidden'];
export const overflowProps = ['visible', 'hidden'];
