import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Divider.scss';

const Divider = ({ appearance, label, rootNode: RootNode }) => {
  const dividerClassnames = classnames('Divider', {
    'Divider--gray': appearance === 'gray'
  });

  return (
    <RootNode className={dividerClassnames}>
      <div className="Divider__innerLine" />
      {label && <span className="Divider__label">{label}</span>}
      <div className="Divider__innerLine" />
    </RootNode>
  );
};

Divider.defaultProps = {
  appearance: 'gray',
  label: null,
  rootNode: 'div'
};

Divider.propTypes = {
  appearance: PropTypes.oneOf(['gray']),
  label: PropTypes.string,
  /** The html tag used for the root node */
  rootNode: PropTypes.string
};

export default Divider;
