import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import { TPT_EASEL_BUYER_LANDING_URL } from 'config/constants';
import Anchor from 'components/Anchor';
import EaselBannerFormerlyTPTDA from './images/EaselBannerFormerlyTPTDA.png';
import MobileTpTEaselEyebrow from './images/MobileEaselBannerEyebrow.png';
import EaselEyebrowBannerLayout from './EaselEyebrowBannerLayout';
import './EaselBannerEyebrow.scss';

const saleInfoQuery = gql`
  query SaleInfoQuery {
    saleBannerInfo {
      secondsLeft
    }
  }
`;

const EaselBannerEyebrow = ({ pathname, isSitewideSale, featureVariants, isLoggedIn }) => {
  const isTfjBannerVisible =
    pathname === '/' &&
    featureVariants?.tfj_homepage_banner === 'on' &&
    featureVariants?.tfj_general === 'on';

  if (isSitewideSale || isTfjBannerVisible || featureVariants.optimizely_banner === 'on') {
    return null;
  }

  if (['banner_and_homepage_adoption', 'all_changes'].includes(featureVariants.easel_education)) {
    return <EaselEyebrowBannerLayout isLoggedIn={isLoggedIn} />;
  }

  return (
    <div className="EaselEyebrowBanner" data-testid="easel-eyebrow-banner">
      <Anchor
        className="EaselEyebrowBanner__link"
        to={`${TPT_EASEL_BUYER_LANDING_URL}?utm_source=tpt-eyebrow-banner`}
        forceReload
        openNewTab
      >
        <div className="EaselEyebrowBanner__imageContainer" data-testid="easel-banner-container">
          <picture>
            <source
              media="(max-width: 579px)"
              srcSet={MobileTpTEaselEyebrow}
              data-testid="easel-banner-mobile"
            />
            <source
              media="(min-width: 580px)"
              srcSet={EaselBannerFormerlyTPTDA}
              data-testid="easel-banner-desktop"
            />

            {/* Default case - for IE11 which doesn't support <picture> */}
            <img
              className="EaselEyebrowBanner__image"
              src={EaselBannerFormerlyTPTDA}
              alt="Easel by TpT"
            />
          </picture>
        </div>
      </Anchor>
    </div>
  );
};

EaselBannerEyebrow.propTypes = {
  pathname: PropTypes.string,
  isSitewideSale: PropTypes.bool,
  featureVariants: featureVariantsPropTypes,
  isLoggedIn: PropTypes.bool.isRequired
};

export default graphql(saleInfoQuery, {
  props: ({ data: { loading, saleBannerInfo } }) => ({
    isSitewideSale: !loading && saleBannerInfo ? saleBannerInfo.secondsLeft > 0 : false
  })
})(EaselBannerEyebrow);

export { EaselBannerEyebrow as PureEaselBannerEyebrow };
