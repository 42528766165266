const alignments = ['inherit', 'left', 'center', 'right'];
const colors = ['error', 'success', 'textPrimary', 'textSecondary', 'textWhite', 'inherit'];
const headings = ['displayLarge', 'display', 'h1', 'h2', 'h3', 'h4'];
const variants = [...headings, 'bodyLarge', 'body', 'bodySmall', 'microcopy', 'button'];

export default {
  alignments,
  colors,
  headings,
  variants
};
