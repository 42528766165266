import React from 'react';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Anchor from 'components/Anchor';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import Divider from 'components/Divider';
import classnames from 'classnames';
import TrackedComponent from 'components/TrackedComponent/TrackedComponent';
import Badge from 'domains/UploadPage/Badge';

import './NestedMenu.scss';

function hasChildren(item) {
  return typeof item.subcategories !== 'undefined';
}

class NestedMenu extends React.Component {
  static propTypes = {
    /*
      `items` is an array of menu items to be displayed.
      An item is structured as such:
        {
          label, // label for a menu item
          url, // where label links to
          color, // color of link
          isDivider, // true if you want to add a divider
          isTitle, // true if item is a non-clickable title
          subcategories, // array of items that are subcategories of current item
          isNew, // true if item should have NEW badge next to it
        }
    */
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string
  };

  constructor(...args) {
    super(...args);

    const { items } = this.props;

    this.state = {
      items, // current items shown in menu
      itemsHistory: [], // history of items shown
      parentHistory: [] // history of parent items that were clicked on
    };
  }

  showChild(item, e) {
    // ignore if no child subcategories
    if (typeof item.subcategories === 'undefined') {
      return;
    }

    // prevent any navigation
    e.preventDefault();

    // add current state to history
    const nextItemsHistory = Object.assign([], this.state.itemsHistory);
    nextItemsHistory.push(this.state.items);

    const nextParentHistory = Object.assign([], this.state.parentHistory);
    nextParentHistory.push(item);

    this.setState({
      items: item.subcategories,
      itemsHistory: nextItemsHistory,
      parentHistory: nextParentHistory
    });
  }

  showParent = () => {
    const nextItemsHistory = Object.assign([], this.state.itemsHistory);
    const nextParentHistory = Object.assign([], this.state.parentHistory);

    // return if no parent state present
    if (nextItemsHistory.length < 1) {
      return;
    }

    const currItems = nextItemsHistory.pop();
    nextParentHistory.pop();

    this.setState({
      items: currItems,
      itemsHistory: nextItemsHistory,
      parentHistory: nextParentHistory
    });
  };

  render() {
    const { className } = this.props;
    const { items, parentHistory } = this.state;
    const parent = parentHistory[parentHistory.length - 1];

    return (
      <div className={classnames('NestedMenu', className)}>
        <Menu>
          {parent && (
            <MenuItem size="md" onClick={this.showParent} key={parent.label}>
              <strong>
                {' '}
                <Icon name="angle-left" className="NestedMenu__backToParentIcon" /> {parent.label}{' '}
              </strong>
            </MenuItem>
          )}

          {items.map((item) => {
            if (item.isTitle) {
              return (
                <li key="title" className="NestedMenu__title">
                  {item.label}
                </li>
              );
            }

            if (item.isDivider) {
              return <Divider rootNode="li" key={item.label} />;
            }

            const link = (
              <Anchor
                className={`Anchor--${item.color || 'black'} d-block NestedMenu__anchor`}
                decorateOnHover={false}
                color={item.color || 'black'}
                to={item.url || ''}
                forceReload={!hasChildren(item)}
              >
                {item.label}{' '}
                {item.isNew && (
                  <>
                    <Badge label="NEW" />{' '}
                  </>
                )}
                {hasChildren(item) && (
                  <Icon name="angle-right" className="NestedMenu__hasSubmenuIcon" />
                )}
              </Anchor>
            );

            return (
              <MenuItem size="xs" key={item.label} onClick={this.showChild.bind(this, item)}>
                {item.trackedComponentProps ? (
                  <TrackedComponent {...item.trackedComponentProps}>{link}</TrackedComponent>
                ) : (
                  link
                )}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

export default NestedMenu;
