import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EaselHeaderDropdown from './EaselDropdown/EaselHeaderDropdown';

import './EaselHeaderLayout.scss';

const EaselHeaderLayout = ({ showNew, isLoggedIn }) => (
  <div
    className={classnames('EaselHeaderLayout', {
      EaselHeaderLayout__new: showNew
    })}
  >
    <EaselHeaderDropdown isLoggedIn={isLoggedIn} />
  </div>
);

EaselHeaderLayout.propTypes = {
  showNew: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired
};

EaselHeaderLayout.defaultProps = {
  showNew: false
};

export default EaselHeaderLayout;
