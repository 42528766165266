import React from 'react';
import PropTypes from 'prop-types';
import { BREAKPOINTS } from 'config/constants';
import Dropdown from 'components/Dropdown';
import DigitalActivityBadgeWithTooltip from 'components/DigitalActivityBadgeWithTooltip';
import NavigationHeaderPill from './NavigationHeaderPill';
import NavigationHeaderDropdownMenu from './NavigationHeaderDropdownMenu';

import './NavigationHeader.scss';

export const getNavigationMenuData = (menuOverflow, isMobile) => {
  const navMenu = [
    { label: 'All Digital Resources', url: '/Browse/Usage/Digital' },
    {
      label: (
        <DigitalActivityBadgeWithTooltip
          className="TpTDigitalActivities__Tooltip"
          pageName="Navigation Header"
          isMobile={isMobile}
          badgeProps={{
            size: 'small'
          }}
          tooltipProps={{
            digitalActivityVariant: 'general',
            arrowPosition: 'top',
            withImage: true
          }}
        />
      ),
      url: '/Browse/Usage/TpT-Digital-Activity',
      className: 'NavigationHeader__TpTDigitalActivities'
    },
    { label: 'Activities', url: '/Browse/Type-of-Resource/Activities' },
    { label: 'Printables', url: '/Browse/Type-of-Resource/Printables' },
    { label: 'Worksheets', url: '/Browse/Type-of-Resource/Worksheets' },
    { label: 'Assessments', url: '/Browse/Type-of-Resource/Assessment' },
    { label: 'Literacy Centers', url: '/Browse/Type-of-Resource/Literacy-Center-Ideas' }
  ];
  if (menuOverflow) return navMenu.slice(0, 6);
  return navMenu;
};

const NavigationHeader = ({ isMobile }) => {
  const [menuOverflow, setMenuOverflow] = React.useState(true);
  // eslint-disable-next-line camelcase
  const navMenu = getNavigationMenuData(menuOverflow, isMobile);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const matchesListener = () => {
        const { matches } = window.matchMedia(`(max-width: ${BREAKPOINTS.xl}px)`);
        setMenuOverflow((prevState) => {
          if (prevState !== matches) return matches;
          return prevState;
        });
      };
      window.addEventListener('resize', matchesListener);
      matchesListener();
      return () => window.removeEventListener('resize', matchesListener);
    }
    return undefined;
  }, [setMenuOverflow]);

  return (
    <div className="NavigationHeader">
      {navMenu.map(({ label, url, className }) => (
        <NavigationHeaderPill
          key={`${label}+${url}`}
          label={label}
          url={url}
          className={className}
        />
      ))}

      <Dropdown
        className="NavigationHeader-Dropdown"
        labelNode={<div className="NavHeaderPill">More</div>}
        icon={<></>}
        openOnHover
        renderOnOpen
      >
        <NavigationHeaderDropdownMenu menuOverflow={menuOverflow} />
      </Dropdown>
    </div>
  );
};

NavigationHeader.propTypes = {
  isMobile: PropTypes.bool
};

NavigationHeader.defaultProps = {
  isMobile: false
};

export default NavigationHeader;
