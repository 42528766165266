import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import gql from 'graphql-tag';
import featureVariantsInfo from 'decorators/featureVariants';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import { headerFeatures } from 'config/features';
import AsyncSitewideBanner from './AsyncSitewideBanner';

const currentSitewideBannersAdvancedQuery = gql`
  query CurrentSitewideBannersAdvancedQuery($url: String) {
    currentSitewideBannersAdvanced(url: $url) {
      message
      type
      allowClose
    }
  }
`;

const SitewideBannerContainer = ({ currentBanners }) =>
  currentBanners.map((el) => (
    <AsyncSitewideBanner
      key={el.message}
      notificationType={el.type.toLowerCase()}
      message={el.message}
      allowClose={el.allowClose}
    />
  ));

SitewideBannerContainer.propTypes = {
  currentBanners: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      allowClose: PropTypes.bool,
      message: PropTypes.string.isRequired
    })
  ).isRequired,
  featureVariants: featureVariantsPropTypes,
  pathname: PropTypes.string.isRequired
};

export default compose(
  featureVariantsInfo(headerFeatures),
  graphql(currentSitewideBannersAdvancedQuery, {
    options: ({ pathname }) => ({ ssr: false, variables: { url: pathname } }),
    props: ({ data: { loading, currentSitewideBannersAdvanced: currentSitewideBanners } }) => ({
      currentBanners: !loading && currentSitewideBanners ? currentSitewideBanners : []
    })
  })
)(SitewideBannerContainer);

export { SitewideBannerContainer as PureSitewideBannerContainer };
