import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import Anchor from 'components/Anchor';

import './Tooltip.scss';

/*
             top left         top         top right
left top    |-------------------------------------| right top
left        |                                     | right
left bottom |-------------------------------------| right bottom
             bottom left     bottom    bottom right
*/
export const arrowPositionOptions = [
  'left',
  'left top',
  'left bottom',
  'right',
  'right top',
  'right bottom',
  'top',
  'top left',
  'top right',
  'bottom',
  'bottom left',
  'bottom right'
];

class Tooltip extends Component {
  static propTypes = {
    image: PropTypes.string,
    header: PropTypes.node,
    bodyCopy: PropTypes.node.isRequired,
    ctaCopy: PropTypes.string,
    ctaLinkTo: PropTypes.string,
    secondaryCtaCopy: PropTypes.string,
    secondaryCtaLinkTo: PropTypes.string,
    arrowPosition: PropTypes.oneOf(arrowPositionOptions),
    hideTooltip: PropTypes.bool,
    className: PropTypes.string,
    onActionTaken: PropTypes.func,
    onSecondaryActionTaken: PropTypes.func,
    onClose: PropTypes.func,
    shouldCloseOnAction: PropTypes.bool,
    shouldCloseOnSecondaryAction: PropTypes.bool,
    shouldShowCloseIcon: PropTypes.bool,
    ctaLinkOpenNewTab: PropTypes.bool,
    secondaryCtaLinkOpenNewTab: PropTypes.bool,
    altText: PropTypes.string
  };

  static defaultProps = {
    ctaLinkTo: null,
    secondaryCtaCopy: null,
    secondaryCtaLinkTo: null,
    arrowPosition: 'left',
    hideTooltip: false,
    onActionTaken: null,
    onSecondaryActionTaken: null,
    onClose: null,
    className: null,
    shouldCloseOnAction: true,
    shouldCloseOnSecondaryAction: true,
    shouldShowCloseIcon: true,
    ctaLinkOpenNewTab: false,
    secondaryCtaLinkOpenNewTab: false,
    altText: ''
  };

  state = { isClosed: false };

  getClassNames = () => {
    const { className, arrowPosition } = this.props;

    const defaultClassNames = {
      Tooltip: true,
      'Tooltip--left': arrowPosition.startsWith('left'),
      'Tooltip--right': arrowPosition.startsWith('right'),
      'Tooltip--top': arrowPosition.startsWith('top'),
      'Tooltip--bottom': arrowPosition.startsWith('bottom'),
      'Tooltip--verticalBottom':
        arrowPosition === 'left bottom' || arrowPosition === 'right bottom',
      'Tooltip--verticalTop': arrowPosition === 'left top' || arrowPosition === 'right top',
      'Tooltip--horizontalLeft': arrowPosition === 'top left' || arrowPosition === 'bottom left',
      'Tooltip--horizontalRight': arrowPosition === 'top right' || arrowPosition === 'bottom right'
    };

    if (className) {
      defaultClassNames[className] = className;
    }

    return classnames(defaultClassNames);
  };

  takeAction = (e) => {
    e.preventDefault();
    const { onActionTaken, shouldCloseOnAction } = this.props;
    if (onActionTaken) {
      onActionTaken();
    }
    if (shouldCloseOnAction) {
      this.closeTooltip(e);
    }
  };

  takeSecondaryAction = (e) => {
    e.preventDefault();
    const { onSecondaryActionTaken, shouldCloseOnSecondaryAction } = this.props;
    if (onSecondaryActionTaken) {
      onSecondaryActionTaken();
    }
    if (shouldCloseOnSecondaryAction) {
      this.closeTooltip(e);
    }
  };

  closeTooltip = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
    this.setState({ isClosed: true });
  };

  render() {
    const {
      image,
      header,
      bodyCopy,
      ctaCopy,
      ctaLinkTo,
      ctaLinkOpenNewTab,
      secondaryCtaCopy,
      secondaryCtaLinkTo,
      secondaryCtaLinkOpenNewTab,
      hideTooltip,
      shouldShowCloseIcon,
      altText
    } = this.props;

    const { isClosed } = this.state;

    if (isClosed || hideTooltip) {
      return null;
    }

    return (
      <div className={this.getClassNames()}>
        <div className="Tooltip__container">
          {shouldShowCloseIcon && (
            <Anchor data-testid="Tooltip_closeIconAnchor" color="gray" onClick={this.closeTooltip}>
              <div className="Tooltip__closeIcon">
                <Icon name="times" />
              </div>
            </Anchor>
          )}
          {image && <img className="Tooltip__image" src={image} alt={altText || ''} />}
          <div className="Tooltip__content">
            {header && <h3 className="Tooltip__header">{header}</h3>}
            <div className="Tooltip__bodyCopy">{bodyCopy}</div>
            {ctaCopy && (
              <div className="Tooltip__ctaContainer">
                <Anchor
                  data-testid="Tooltip_ctaAnchor"
                  color="green"
                  to={ctaLinkTo}
                  onClick={ctaLinkTo ? undefined : this.takeAction}
                  openNewTab={ctaLinkOpenNewTab}
                >
                  <span className="Tooltip__cta">{ctaCopy}</span>
                </Anchor>
                {secondaryCtaCopy && (
                  <Anchor
                    data-testid="Tooltip_secondaryCtaAnchor"
                    color="green"
                    to={secondaryCtaLinkTo}
                    onClick={secondaryCtaLinkTo ? undefined : this.takeSecondaryAction}
                    openNewTab={secondaryCtaLinkOpenNewTab}
                  >
                    <span className="Tooltip__secondaryCta">{secondaryCtaCopy}</span>
                  </Anchor>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Tooltip;
