import Cookies from 'js-cookie';
import { merge } from 'lodash';

import {
  TPT_UPDATE_SELECTED_RESOURCES_PRICE,
  TPT_UPDATE_ROUTE_STATE,
  TPT_UPDATE_SERVER_STATE,
  TPT_ADD_GLOBAL_ERROR,
  TPT_ENABLE_GLOBAL_ERROR_REQUEST_TRACE_IDS,
  TPT_ADD_GLOBAL_ERROR_REQUEST_TRACE_ID,
  TPT_CLEAR_GLOBAL_ERRORS,
  TPT_UPDATE_ADVERTISED_PRICE,
  TPT_LOADING_STATE,
  TPT_FORWARDING_STATE,
  TPT_SET_BRAINTREE_INSTANCE,
  TPT_ADD_CHECKOUT_ERROR,
  TPT_CLEAR_CHECKOUT_ERRORS,
  TPT_SET_COOKIE,
  TPT_SET_ISOMORPHIC_DATE
} from 'lib/actions';
import schoolMatchingReducer from 'domains/SchoolMatching/redux';
import cartReducer from 'domains/Cart/responsive/redux';
import partnerReducer from 'domains/UploadPage/Partners/redux';
import myProductsReducer, { MY_PRODUCTS_STATE_KEY } from 'domains/MyProductsPage/redux';
import jurisdictionReducer from 'domains/UploadPage/EducationStandardsSlot/jurisdictionRedux';
import preferredJurisdictionReducer from 'domains/UploadPage/EducationStandardsSlot/preferredJurisdictionStateRedux';
import educationStandardsReducer from './educationStandardsReducer';
import resourcesStateReducer from './CopyrightTool/reducers';

/**
 * Errors are stored in a default namespace, but allow the ability to
 * pass a custom namespace so that components can control the adding
 * and clearing of errors when state is shared across components
 * @param { object } state
 * @param { object } action
 *   @config { type } TPT_ADD_GLOBAL_ERROR || TPT_CLEAR_GLOBAL_ERRORS
 *   @config { object } payload
 *   @config { string= } namespace
 * @return { object }
 */
export function globalErrorsReducer(state = { default: [] }, action) {
  const { type, payload, namespace: ns } = action;
  const namespace = typeof ns === 'string' ? ns : 'default';

  if (type === TPT_ADD_GLOBAL_ERROR) {
    if ([namespace] in state) {
      return { ...state, [namespace]: [...state[namespace], payload] };
    }
    return { ...state, [namespace]: [payload] };
  }

  if (type === TPT_CLEAR_GLOBAL_ERRORS) {
    return { ...state, [namespace]: [] };
  }

  return state;
}

export function globalErrorRequestTraceIdsReducer(
  state = { enabled: false, traceIds: [] },
  action
) {
  const { type, payload } = action;

  if (type === TPT_ENABLE_GLOBAL_ERROR_REQUEST_TRACE_IDS) {
    return { enabled: true, traceIds: state.traceIds };
  }

  if (type === TPT_ADD_GLOBAL_ERROR_REQUEST_TRACE_ID) {
    return { enabled: state.enabled, traceIds: [...state.traceIds, payload] };
  }

  if (type === TPT_CLEAR_GLOBAL_ERRORS) {
    return { enabled: false, traceIds: [] };
  }
  return state;
}

export function resourcePickerStateReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case TPT_UPDATE_SELECTED_RESOURCES_PRICE:
      return {
        ...state,
        bundlePrice: payload
      };
    default:
      return state;
  }
}

export function advertisedPriceStateReducer(state = 0, action) {
  const { type, payload } = action;
  if (type !== TPT_UPDATE_ADVERTISED_PRICE) {
    return state;
  }
  return payload;
}

export function loadingStateReducer(state = [], action) {
  const { type, payload } = action;
  if (type === TPT_LOADING_STATE) {
    if (payload.loadingState) {
      return [...state, payload.loadingItem];
    }
    return state.filter((item) => item !== payload.loadingItem);
  }
  return state;
}

export function routeStateReducer(state = {}, action) {
  const { type, payload } = action;
  if (type !== TPT_UPDATE_ROUTE_STATE) {
    return state;
  }
  return merge({}, state, {
    [payload.pathname]: payload.partialState
  });
}

/**
 * Used to share state btwn react components and the server
 * @param { !object } state
 * @param { !object } actionOpts
 * @return { !object }
 */
export function serverStateReducer(state = { headers: {} }, { type, payload }) {
  if (type !== TPT_UPDATE_SERVER_STATE) {
    return state;
  }

  return {
    ...state,
    ...payload
  };
}

/**
 * Used to forward form parameters (ex: the body of a POST request) from
 * the server to the client
 * @param { !object } state
 * @param { !object } action
 * @return { !object }
 */
export function forwardingStateReducer(state = {}, { type, payload }) {
  if (type !== TPT_FORWARDING_STATE) {
    return state;
  }

  return {
    ...state,
    ...payload
  };
}

export function checkoutErrorsReducer(state = [], action) {
  const { type, payload } = action;

  if (type === TPT_ADD_CHECKOUT_ERROR) {
    return [...state, payload];
  }

  if (type === TPT_CLEAR_CHECKOUT_ERRORS) {
    return [];
  }

  return state;
}

/**
 * Checkout:
 * The Braintree client instance provides a method to request payment method
 * nonces; this is a centralized store of that instance in order to allow
 * components that are not the payment UI (CardInformationBespoke) to request
 * the nonce (i.e., for submitting the order when you press the button).
 *
 * @param { object= } state
 * @param { object= } action
 *   @config { !string } type
 *   @config { object= } payload
 * @return { !{ braintreeInstance: !object, braintreeDropinStatus: string } }
 */
export function braintreeInstanceReducer(state = {}, { type, payload }) {
  if (type !== TPT_SET_BRAINTREE_INSTANCE) {
    return state;
  }
  return {
    braintreeInstance: payload.braintreeInstance,
    braintreeDropinStatus: payload.braintreeDropinStatus
  };
}

/**
 * Stores all cookies from the client in redux.
 *
 * NOTE: cookies are not available on pages rendered server-side. Take care to not rely on
 * cookies in the store if your component might be rendered on the server.
 *
 * It is possible that relying on cookies in redux state can cause an extra render of your
 * component when redux populates your props. If you don't need to detect changes in cookies
 * in your component, then you should use js-cookie directly to fetch what you need.
 *
 * Only cookies changed using the TPT_SET_COOKIE action will trigger a refresh of the cookie
 * state, so be sure that any cookies you're relying on from redux are updated using that action.
 *
 * @param { object= } state
 * @param { object= } action
 *   @config { !string } type
 *   @config { object= } payload
 * @return { !object }
 */
export function cookiesReducer(state = {}, { type, payload }) {
  if (!IS_BROWSER) {
    return state;
  }

  if (type === TPT_SET_COOKIE) {
    const { name, value, attributes } = payload;
    Cookies.set(name, value, attributes);
  }

  return Cookies.get() || {};
}

export function dateReducer(state = {}, { type, payload }) {
  if (type !== TPT_SET_ISOMORPHIC_DATE) {
    return state;
  }
  return {
    ...state,
    currentDate: payload.currentDate
  };
}

export default {
  forwardingState: forwardingStateReducer,
  serverState: serverStateReducer,
  routeState: routeStateReducer,
  globalErrors: globalErrorsReducer,
  globalErrorRequestTraceIds: globalErrorRequestTraceIdsReducer,
  resourcePickerState: resourcePickerStateReducer,
  advertisedPriceState: advertisedPriceStateReducer,
  loadingState: loadingStateReducer,
  braintreeInstance: braintreeInstanceReducer,
  resourcesState: resourcesStateReducer,
  educationStandardsState: educationStandardsReducer,
  jurisdictionState: jurisdictionReducer,
  preferredJurisdictionState: preferredJurisdictionReducer,
  schoolMatchingState: schoolMatchingReducer,
  cartState: cartReducer,
  partnerState: partnerReducer,
  checkoutErrors: checkoutErrorsReducer,
  cookiesState: cookiesReducer,
  dateState: dateReducer,
  [MY_PRODUCTS_STATE_KEY]: myProductsReducer
};
