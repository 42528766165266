import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import AsyncResponsiveSaleBanner from './AsyncResponsiveSaleBanner';

const saleBannerInfoQuery = gql`
  query SaleBannerInfoQuery {
    saleBannerInfo {
      title
      promoCode
      savingsPercent
      secondsLeft
    }
  }
`;

const SaleBannerContainer = ({ title, promoCode, savingsPercent, secondsLeft }) => {
  const shouldShowBanner = secondsLeft > 0;
  if (!shouldShowBanner) {
    return null;
  }
  return (
    <AsyncResponsiveSaleBanner
      title={title}
      promoCode={promoCode}
      secondsLeft={secondsLeft}
      savingsPercent={savingsPercent}
    />
  );
};

SaleBannerContainer.propTypes = {
  title: PropTypes.string,
  promoCode: PropTypes.string,
  savingsPercent: PropTypes.number,
  secondsLeft: PropTypes.number
};

export default graphql(saleBannerInfoQuery, {
  props: ({ data: { loading, saleBannerInfo } }) => ({
    title: !loading && saleBannerInfo ? saleBannerInfo.title : '',
    promoCode: !loading && saleBannerInfo ? saleBannerInfo.promoCode : '',
    savingsPercent: !loading && saleBannerInfo ? saleBannerInfo.savingsPercent : 0,
    secondsLeft: !loading && saleBannerInfo ? saleBannerInfo.secondsLeft : 0
  })
})(SaleBannerContainer);

export { SaleBannerContainer as PureSaleBannerContainer };
