import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/Icon';
import Anchor from 'components/Anchor';
import TrackedComponent from 'components/TrackedComponent/TrackedComponent';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';

import './MenuSection.scss';

const MenuSection = ({ items, icon, title }) => (
  <div className="MenuSection">
    {(title || icon) && (
      <div className="MenuSection__header">
        <div className="MenuSection__title">
          {icon && <Icon name={icon} className="MenuSection__icon" />}
          {title}
        </div>
      </div>
    )}
    <Menu>
      {
        // filter out invalid items prior to render to allow simpler feature flagging
        items
          .filter((item) => item)
          .map((item, idx) => {
            const link = (
              // eslint-disable-next-line react/no-array-index-key
              <Anchor forceReload color="inherit" decorateOnHover={false} to={item.url} key={idx}>
                <MenuItem>{item.title}</MenuItem>
              </Anchor>
            );
            if (item.trackedComponentProps) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <TrackedComponent {...item.trackedComponentProps} key={idx}>
                  {link}
                </TrackedComponent>
              );
            }
            return link;
          })
      }
    </Menu>
  </div>
);

MenuSection.propTypes = {
  items: PropTypes.array.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string
};

export default MenuSection;
