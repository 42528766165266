export const TOGGLE_CROSSWALK_STATE_SELECTED = '@@tpt/TOGGLE_CROSSWALK_STATE_SELECTED';
export const SET_STANDARDS_DISPLAY_PREFERENCE = '@@tpt/SET_STANDARDS_DISPLAY_PREFERENCE';

const initialState = { isCrosswalkStateSelected: false };
export default function reducer(state = initialState, action = {}) {
  if (action.type === SET_STANDARDS_DISPLAY_PREFERENCE) {
    return { ...state, isCrosswalkStateSelected: action.payload };
  }
  if (action.type === TOGGLE_CROSSWALK_STATE_SELECTED) {
    return { ...state, isCrosswalkStateSelected: !state.isCrosswalkStateSelected };
  }
  return state;
}

export function toggleCrosswalkStateSelected() {
  return { type: TOGGLE_CROSSWALK_STATE_SELECTED };
}

export function setPreference(preferStateStandards) {
  return { type: SET_STANDARDS_DISPLAY_PREFERENCE, payload: preferStateStandards };
}
