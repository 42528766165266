import PropTypes from 'prop-types';
import React from 'react';
import { withApollo } from 'react-apollo';
import Anchor from 'components/Anchor';
import { featureVariantsPropTypes } from 'shared/config/prop-definitions';
import HeaderCartContainer from '../HeaderCart/HeaderCartContainer';
import BuyerDropdown from './BuyerDropdown';
import EaselHeaderLayout from '../EaselHeader/EaselHeaderLayout';

import './BuyerHeaderLayout.scss';

const BuyerHeaderLayout = ({ firstName, name, countUnreadMessages, featureVariants }) => {
  const isEaselEducationExperiment = ['header_adoption', 'all_changes'].includes(
    featureVariants.easel_education
  );

  const label = isEaselEducationExperiment ? firstName || name : 'My TpT';

  return (
    <div className="BuyerHeaderLayout">
      {isEaselEducationExperiment && (
        <div className="BuyerHeaderLayout__easelContainer">
          <EaselHeaderLayout isLoggedIn showNew />
        </div>
      )}
      {!isEaselEducationExperiment && (
        <div className="BuyerHeaderLayout__greetingContainer">
          <div className="BuyerHeaderLayout__greetingLabel">
            Hi,&nbsp;
            <Anchor to="/My-Account/Basics" forceReload color="black">
              {firstName || name}
            </Anchor>
          </div>
        </div>
      )}
      <div className="BuyerHeaderLayout__dropdownContainer">
        <BuyerDropdown countUnreadMessages={countUnreadMessages} label={label} />
      </div>
      <div className="BuyerHeaderLayout__cartContainer">
        <HeaderCartContainer isShortened={isEaselEducationExperiment} />
      </div>
    </div>
  );
};

BuyerHeaderLayout.propTypes = {
  name: PropTypes.string,
  firstName: PropTypes.string,
  countUnreadMessages: PropTypes.number,
  featureVariants: featureVariantsPropTypes
};

BuyerHeaderLayout.defaultProps = {
  featureVariants: {}
};

export default withApollo(BuyerHeaderLayout);
