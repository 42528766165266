import { stringify, parse } from 'querystring';
import createBrowserHistory from 'history/lib/createBrowserHistory';
import useRouterHistory from 'react-router/lib/useRouterHistory';

export default function createHistory() {
  /* eslint-disable max-len */
  // github.com/reactjs/react-router/blob/master/upgrade-guides/v2.0.0.md#custom-query-string-parsing
  const createAppHistory = useRouterHistory(createBrowserHistory);
  return createAppHistory({
    // JSON stringify all nested query parts
    // github.com/sindresorhus/query-string#nesting
    stringifyQuery: (query) =>
      stringify(
        Object.keys(query).reduce(
          (_query, key) => ({
            ..._query,
            [key]: typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key]
          }),
          {}
        )
      ),

    parseQueryString: (str) => {
      const query = parse(str);
      Object.keys(query).forEach((key) => {
        try {
          query[key] = JSON.parse(query[key]);
        } catch (e) {
          /* carry-on */
        }
      });
      return query;
    }
  });
}
