import PropTypes from 'prop-types';
import React from 'react';
import Social from './Social';
import css from './SocialRow.module.scss';

const SocialRow = ({ social, isResponsive }) => (
  <div className={css.container}>
    {Object.keys(social).map((key) => (
      <div key={key} className={css.iconContainer}>
        <Social type={key} profilePath={social[key]} isResponsive={isResponsive} />
      </div>
    ))}
  </div>
);

SocialRow.propTypes = {
  social: PropTypes.object.isRequired,
  isResponsive: PropTypes.bool
};

export default SocialRow;
