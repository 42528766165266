import React from 'react';
import PropTypes from 'prop-types';
import Anchor from 'components/Anchor';
import Dropdown from 'components/Dropdown';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import SubMenuPrefab from 'components/SubMenu/SubMenuPrefab';

import './CategoryMenu.scss';

export const getMainCategories = () => {
  const resourceTypes = [
    {
      label: 'Independent Work Packet',
      url: '/Browse/Type-of-Resource/Independent-Work-Packet'
    },
    { label: 'Worksheets', url: '/Browse/Type-of-Resource/Worksheets' },
    { label: 'Lesson Plans', url: '/Browse/Type-of-Resource/Lesson-Plans-Individual' },
    { label: 'Printables', url: '/Browse/Type-of-Resource/Printables' },
    { label: 'Activities', url: '/Browse/Type-of-Resource/Activities' },
    { label: 'Assessment', url: '/Browse/Type-of-Resource/Assessment' },
    { label: 'Math Centers', url: '/Browse/Type-of-Resource/Math-Centers' },
    { label: 'Literacy Center Ideas', url: '/Browse/Type-of-Resource/Literacy-Center-Ideas' },
    { label: 'Projects', url: '/Browse/Type-of-Resource/Projects' }
  ];

  const formatSection = {
    label: 'Format',
    subcategories: [
      { label: 'Easel Activities', url: '/Browse/Format/Easel-Activity' },
      { label: 'Google Apps', url: '/Browse/Format/All-Google' },
      { label: 'Microsoft', url: '/Browse/Format/All-Microsoft' },
      { label: 'PDF', url: '/Browse/Format/PDF' },
      { label: 'Image', url: '/Browse/Format/Images' },
      { label: 'Video', url: '/Browse/Format/Videos' }
    ]
  };

  const mainCategories = [
    {
      label: 'Grade Level',
      subcategories: [
        { label: 'Pre-K - K', url: '/Browse/Grade-Level/PreK-K' },
        { label: '1 - 2', url: '/Browse/Grade-Level/1-2' },
        { label: '3 - 5', url: '/Browse/Grade-Level/3-5' },
        { label: '6 - 8', url: '/Browse/Grade-Level/6-8' },
        { label: '9 - 12', url: '/Browse/Grade-Level/9-12' },
        { label: 'Other', url: '/Browse/Grade-Level/Other' }
      ]
    },
    {
      label: 'Subject',
      subcategories: [
        { label: 'Arts & Music', url: '/Browse/PreK-12-Subject-Area/Arts' },
        {
          label: 'English Language Arts',
          url: '/Browse/PreK-12-Subject-Area/English-Language-Arts'
        },
        { label: 'World Language', url: '/Browse/PreK-12-Subject-Area/World-Language' },
        { label: 'Math', url: '/Browse/PreK-12-Subject-Area/Math' },
        { label: 'Science', url: '/Browse/PreK-12-Subject-Area/Science' },
        {
          label: 'Social Studies - History',
          url: '/Browse/PreK-12-Subject-Area/Social-Studies-History'
        },
        { label: 'Specialty', url: '/Browse/PreK-12-Subject-Area/Specialty' },
        { label: 'Holidays / Seasonal', url: '/Browse/PreK-12-Subject-Area/HolidaysSeasonal' }
      ]
    },
    {
      label: 'Price',
      subcategories: [
        { label: 'Free', url: '/Browse/Price-Range/Free' },
        { label: 'Under $5', url: '/Browse/Price-Range/Under-5' },
        { label: '$5 to $10', url: '/Browse/Price-Range/5-to-10' },
        { label: '$10 and up', url: '/Browse/Price-Range/Above-10' },
        { label: 'On Sale', url: '/Browse/Price-Range/On-Sale' }
      ]
    },
    {
      label: 'Resource Type',
      subcategories: resourceTypes
    }
  ];

  mainCategories.push(formatSection);

  return mainCategories;
};

const CategoryMenu = ({ label: categoryLabel, isResponsive }) => {
  const mainCategory = ({ label, subcategories }) => (
    <SubMenuPrefab key={label} label={label}>
      <Menu>
        {subcategories.map((subcategory) => (
          <MenuItem key={subcategory.url}>
            <Anchor color="inherit" forceReload decorateOnHover={false} to={subcategory.url}>
              {subcategory.label}
            </Anchor>
          </MenuItem>
        ))}
      </Menu>
    </SubMenuPrefab>
  );

  const mainCategories = getMainCategories();
  return (
    <Dropdown
      openOnHover
      label={categoryLabel}
      labelBy="Category Menu"
      className="CategoryMenu"
      wrapperClassName={isResponsive ? 'CategoryMenu--responsive' : null}
      bubbleLabel
    >
      <Menu>{mainCategories.map(mainCategory)}</Menu>
    </Dropdown>
  );
};

CategoryMenu.propTypes = {
  label: PropTypes.node,
  isResponsive: PropTypes.bool
};

CategoryMenu.defaultProps = {
  label: 'All Categories',
  isResponsive: false
};

export default CategoryMenu;
