// This holds helper functions for managing organization queries, and validating
// the results.
import { get } from 'lodash';

export function isOrgMember(orgQueryResponse) {
  return get(orgQueryResponse, 'member.organizations.organizations', []).length > 0;
}

export function isOrgAdmin(orgQueryResponse) {
  return get(orgQueryResponse, 'member.organizations.organizations[0].orgAdmin.isAdmin', false);
}

export function isOrgTeacher(orgQueryResponse) {
  return isOrgMember(orgQueryResponse) && !isOrgAdmin(orgQueryResponse);
}

export function orgInfo(orgQueryResponse) {
  return get(orgQueryResponse, 'member.organizations.organizations[0]', {});
}

// This function takes in the result of calling orgInfo
export function adminInfo(orgInfoResult) {
  return get(orgInfoResult, 'admins[0]', false);
}

// This function takes in the result of calling orgInfo
export function userHasMadeRequest(orgInfoResult) {
  // eslint-disable-next-line no-prototype-builtins
  return get(orgInfoResult, 'requests.[0]', {}).hasOwnProperty('requestId');
}

export function requestsForOrg(orgQueryResponse) {
  const info = orgInfo(orgQueryResponse);
  const isAdmin = isOrgAdmin(orgQueryResponse);
  return isAdmin && get(info, 'requests', false);
}

export function firstRequestForOrg(orgQueryResponse) {
  const requests = requestsForOrg(orgQueryResponse);
  return get(requests, ['0'], false);
}

export function requesterInformationForOrg(orgQueryResponse) {
  const requests = requestsForOrg(orgQueryResponse);
  return get(requests, ['0', 'requester'], false);
}

export function isTeacherCreatedSchool(orgQueryResponse) {
  return get(orgQueryResponse, 'member.organizations.organizations[0].hasAdmin') === false;
}
