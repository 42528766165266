import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import GlobalErrorsContainer from 'domains/Header/GlobalErrorsContainer/GlobalErrorsContainer';
import SessionStorage from 'util/utils/sessionStorage';
import SitewideBannerContainer from 'components/SitewideBanner/SitewideBannerContainer';
import SchoolsFlashMessageContainer from './SchoolsFlashMessageContainer';

const HeaderAlertLayout = ({ pathname, isLoggedIn }) => (
  <Fragment>
    <GlobalErrorsContainer />

    <SitewideBannerContainer pathname={pathname} />

    <SchoolsFlashMessageContainer storage={new SessionStorage()} isLoggedIn={isLoggedIn} />
  </Fragment>
);

HeaderAlertLayout.propTypes = {
  pathname: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

export default HeaderAlertLayout;
