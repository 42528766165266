/*
 * This is forked from react-fa and is a simple React wrapper
 * for consuming Fontawesome icon font-based icons.
 * https://github.com/andreypopp/react-fa/blob/v5.0.0/src/Icon.js
 *
 * TODO: we also have a @teachers/design-system/components/SvgIcon, which
 * renders svg-based icons - whereas `Icon` renders our custom tpt-icon web font
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'shared/iconfont/build/tpticon.scss';

const iconClassName = 'tpticon';

const Icon = ({ component: Component, name, size, fixedWidth, className, ...rest }) => {
  const classNames = classnames(
    iconClassName,
    {
      [`${iconClassName}-${name}`]: name,
      [`${iconClassName}-${size}`]: size,
      [`${iconClassName}-fw`]: fixedWidth
    },
    className
  );

  return <Component {...rest} className={classNames} />;
};

Icon.defaultProps = {
  component: 'span'
};

Icon.propTypes = {
  // List of available icon names are in 'src/shared/iconfont/svgs' directory
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['lg', '2x', '3x', '4x']),
  fixedWidth: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

export default Icon;
