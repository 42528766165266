/**
 * Decorates a component to inject our most widely-used user data fields into it via
 * Apollo Client. By default, the prop it injects to will be named `userDataResponse`.
 *
 * This decorator will only trigger a single request regardless of how many places it is present
 * in the component tree. (Apollo deduplicates requests and returns data from its cache).
 *
 * Assumes that the decorated component already has an isLoggedIn or config.isLoggedIn prop.
 * Example usage:
 *
 * > @connect(({ config: { isLoggedIn } }) => ({
 * >   isLoggedIn
 * > }))
 * > @withUserData(optionalConfigObject)
 * > ComponentToDecorate
 *
 * Or in the case where we are decorating a component that is only shown when the user is logged in:
 *
 * > @withUserData({ skip: false })
 * > ComponentToDecorate
 */

import { graphql } from 'react-apollo';
import merge from 'lodash/merge';
import PrimaryUserDataQuery from 'tpt_modules/queries/PrimaryUserData.graphql';

/*
 * Default configuration to be passed to graphql() Apollo query function.
 */
const defaultQueryConfig = {
  // Make the data available as this.props.userData by default
  name: 'userData',

  // We should only query for user data in the case where the user appears to be logged in.
  // Please set an `isLoggedIn` prop on the decorated component separately (see usage example
  // in the comment atop this file.
  // (TODO: decide whether to add Redux connect, and therefore an extra wrapper component, here)
  skip: (props) => !(props.isLoggedIn || (props.config && props.config.isLoggedIn)),

  options: {
    context: {
      // This flags this request as a special user data request where a response is required. If user data
      // fails to return, we can check for this context in Apollo afterware and redirect to the login
      // page.
      redirectIfUserResponseEmpty: true
    }
  }
};

/**
 * @param {object} customApolloQueryConfig - custom config fields to pass in to `graphql()` function
 *      (See `defaultQueryConfig` above for defaults)
 * @param {object} userDataQuery - custom query to use instead of default PrimaryUserDataQuery
 * @return {function} decorator function that can be passed a component to wrap
 *      `(ComponentToWrap) => WrappedComponent`
 */
export default function withUserData(
  customApolloQueryConfig = {},
  userDataQuery = PrimaryUserDataQuery
) {
  return function decorateWithUserData(ComponentToWrap) {
    return graphql(
      userDataQuery,
      merge({}, defaultQueryConfig, customApolloQueryConfig)
    )(ComponentToWrap);
  };
}

/**
 * Imperative method for reading user data from the cache.
 * Favor the withUserData() decorator over this, but there are some cases
 * where it can be helpful to obtain user data from within an
 * Express handler (e.g. when writing our third-party tracking tags that need
 * to be positioned at a very specific place in the DOM).
 * @param {ApolloClient} apolloClient
 * @return {object|undefined} user data, or undefined if user is logged out
 *    or data is otherwise unavailable
 */
export function readUserData(apolloClient) {
  try {
    const userData = apolloClient.readQuery({
      query: PrimaryUserDataQuery
    });
    if (userData && typeof userData.member === 'object') {
      return {
        // This is an intentionally limited subset of our user data.
        // If you need more complete user data, you should be using the
        // withUserData decorator.
        id: userData.member.id,
        hashedUserId: userData.member.hashedUserId,
        country: userData.member.userProfile.country
      };
    }
    return undefined;
  } catch (e) {
    // It's not a given that the query has run, and Apollo Client
    // always throws if it hasn't. Return undefined when we can't
    // get user data.
    return undefined;
  }
}
