import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from 'components/Text';
import textProps from 'components/Text/textProps';
import tptEaselIcon from './icons/tpt-easel-icon.svg';

const TpTEaselLogo = ({ className, showIcon, textVariant }) =>
  showIcon ? (
    <img
      className={classnames('TpTEaselLogo', 'TpTEaselLogo__icon', className)}
      src={tptEaselIcon}
      alt="Easel by TpT"
    />
  ) : (
    <Text
      className={classnames('TpTEaselLogo', 'TpTEaselLogo__text', className)}
      component="span"
      variant={textVariant}
      strong
      inline
    >
      Easel
    </Text>
  );

TpTEaselLogo.defaultProps = {
  showIcon: true,
  textVariant: 'bodyLarge'
};

TpTEaselLogo.propTypes = {
  className: PropTypes.string,
  showIcon: PropTypes.bool,
  textVariant: PropTypes.oneOf(textProps.variants)
};
export default TpTEaselLogo;
