/* eslint-env browser, node */
import slugify from 'slugify';
import URL from 'url';
import analytics from 'lib/analytics';
import { get } from 'lodash';

/** Pluralize words
 * @param { !string } singular
 * @param { !string } plural
 * @param { !number } number
 * @return { string }
 */
export function pluralize(singular, plural, number) {
  if (number === 1) {
    return singular;
  }

  return plural;
}

/** Format numbers with commas
 * @param { !number } num
 * @return { string }
 */
export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

/** formatAndRoundThousands numbers to round and shorten to 1K if over 1000.
 * @param { !number } num
 * @return { string }
 */
export function formatAndRoundThousands(num) {
  if (num < 1000) {
    return num.toString();
  }

  const roundedThousands = Math.round((num / 1000) * 10) / 10;
  return `${roundedThousands}k`;
}

/**
 * Convert a map into a URL parameter string
 *
 * @param {object} paramObj // Map of parameters
 * @return {string}
 */
export function objectToParams(paramObj) {
  return Object.entries(paramObj)
    .map(([k, v]) => (v ? `${encodeURIComponent(k)}=${encodeURIComponent(v)}` : ''))
    .filter((e) => e)
    .join('&');
}

/**
 * Determine whether or not the current device is touch-enabled
 *
 * @return {boolean}
 */
export function isTouch() {
  const {
    navigator = {
      maxTouchPoints: 0,
      msMaxTouchPoints: 0
    }
  } = global;

  return (
    ('ontouchstart' in global && global.ontouchstart !== null) ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0 ||
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !global.MSStream)
  );
}

/*
 * Parses money from graphQL api
 *
 * @param {string|number} money // Stringified money ie: "$1,337.42" => 1337.42, or a number.
 * @return {number}
 */
export function parseMoney(money) {
  if (typeof money === 'number') {
    return money;
  }
  return parseFloat(money.replace(/[^\d.-]/g, ''));
}

/**
 * Gets correct current price from product
 *
 * TODO: remove support for lowercase `discountprice` / `saleprice`. This naming
 * predates the new Price object format we are switching over to.
 *
 * @param { { discountPrice: string, salePrice: string, price: string } } product
 * @return { number }
 */
export function getAdvertisedPrice({
  saleprice = 0,
  salePrice = 0,
  discountprice = 0,
  discountPrice = 0,
  price = 0
}) {
  return (
    parseMoney(salePrice) ||
    parseMoney(saleprice) ||
    parseMoney(discountPrice) ||
    parseMoney(discountprice) ||
    parseMoney(price)
  );
}

/**
 * Gets correct current price from numeric prices
 * @param { { discountprice: number, saleprice: number, price: number } } product
 * @return { number }
 */
export function getAdvertisedPriceNumeric({ saleprice, discountprice, price }) {
  return saleprice || discountprice || price;
}

/**
 * Download the JSON store dump
 *
 * @param {string} content // Stringified store content
 * @return { undefined }
 */
export function downloadJson(content) {
  const blob = new Blob([content], { type: 'application/json' });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = `tpt_debug_${Date.now()}.json`;
  a.click();
}

/**
 * A function that decorates a given number as a currency amount
 *
 * @param { number | string } value
 * @return { string }
 */
export function decorateAsCurrency(value) {
  // TODO: i18n currency?
  return `$${value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
}

/**
 * Email validation
 *
 * @param {string} email
 * @return {boolean}
 */
export function isInvalidEmail(email) {
  // Email Regex
  // eslint-disable-next-line no-useless-escape
  const validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len

  return !validEmailRegex.test(email);
}

/**
 * Add Total to Ratings
 *
 * @param {[{quetion: string, rating: number}]} ratings
 * @return {!array}
 */
export function addTotalToRatings(ratings) {
  const nonZeroRatings = ratings.map(({ rating }) => rating).filter((rating) => rating);

  if (nonZeroRatings.length === 0) {
    return ratings.concat([{ question: 'Total', rating: 0 }]);
  }

  const totalRating =
    nonZeroRatings.reduce((agg, rating) => agg + rating, 0) / nonZeroRatings.length;

  return ratings.concat([{ question: 'Total', rating: totalRating }]);
}

/**
 * Converts <br> to \n
 * Context: it is used for ignoring the <br> tags that get
 *          added by the Core Web endpoint for comments
 *
 * @param {string} text
 * @return { string }
 */
export function trimBreakTags(text) {
  return text.replace(/<br\s?\/?>/g, '');
}

/**
 * Converts \n to <br>
 *
 * @param {string} htmlString
 * @return { string }
 */
export function lineBreaksToBr(htmlString) {
  return htmlString.replace(/\n/g, '<br/>\n');
}

/**
 * Remove html/xml tags, remove space duplicates, trim
 * (Html to plain text)
 *
 * @param {string} htmlString
 * @return { string }
 */
export function htmlToPlain(htmlString) {
  return htmlString
    .replace(/(<\/?[^>]+>)/gi, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();
}

/**
 * Converts string into ordinal String pieces
 *
 * @param {string} name
 * @return {{number, suffix, grade}}
 */
export function toOrdinalParts(name) {
  if (!name) {
    return {};
  }
  const [, number, suffix, end] = name.match(/^(\d+)(st|nd|rd|th)(.*?)$/) || [];

  return { number, suffix, end };
}

/**
 * Formats array of facets
 *
 * @param {Array({url: string, name: string}) | null} facets
 * @return {Array({string, string})}
 */

export const formatFacets = (facets) =>
  (facets || []).filter((f) => f).map((facet) => ({ ...facet, url: `/Browse/${facet.url}` }));

/**
 * Gets whether product has been tagged as not grade-specific.
 *
 * @param {Array({name: string}) | null} grades
 * @return {boolean}
 */

export const getTaggedAsNotGradeSpecific = (usGrades) =>
  (usGrades || []).some((g) => g.name === 'Not Grade Specific');

/**
 * Used to parse a list of pasted-in emails
 * @param {string} data
 * @return {Array(string)}
 */

export function parseEmailData(data) {
  // Split email addresses
  const separators = [',', ';', '/', ':', '\n', '\r'];
  const emails = data
    .split(new RegExp(separators.join('|')))
    .map((d) => d.trim())
    .filter((d) => d.length > 0);

  // Extract email from brackets if necessary
  const regex = new RegExp('<(.*?)>');
  return emails.map((email) => (email.match(regex) ? email.match(regex)[1] : email));
}

/**
 * Lowercases and slugifys string for url readiness
 * @param {string} string
 * @return {string}
 */
export function lowerSlugify(string) {
  return slugify(string).toLowerCase();
}

/**
 * Returns if tpt connect meta is in loading state
 * @param {{ isSuccess: boolean, isError: boolean }} object
 * @return {boolean}
 */
export function connectMetaIsLoading({ isSuccess, isError }) {
  return !(isError || isSuccess);
}

/**
 * Returns a string which represents the discounted
 * price rounded to 2 decimals
 * @param {number} totalPrice
 * @param {number} percentDiscount
 * @return {string}
 */
export function getDiscountedPrice(totalPrice, percentDiscount) {
  return (totalPrice * (1 - percentDiscount / 100)).toFixed(2);
}

/**
 * Returns a string with all instances of the keys
 * in `mapObj` replaced with their corresponding values
 * @param {string} str
 * @param {object} mapObj
 * @return {string}
 */
export function replaceAll(str, mapObj) {
  if (!str) {
    return '';
  }
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  return str.replace(re, (matched) => mapObj[matched]);
}

/**
 * If no discount price is set, graph will respond $0.00
 *
 * @param { string } discountPriceFromGraph
 * @return { boolean }
 */
export function discountPriceSetInGraph(discountPriceFromGraph) {
  if (discountPriceFromGraph === '$0.00') {
    return false;
  }
  return true;
}

/**
 * Merge two arrays of objects by object key
 *
 * @param { !Array.<!Object> } a
 * @param { !Array.<!Object> } b
 * @param { !string } key
 * @return { !Array.<!Object> }
 */
export function mergeArraysByKey(a, b, key) {
  if (!Array.isArray(a) || !Array.isArray(b)) return [];

  const map = new Map();

  a.concat(b).forEach((obj) => map.set(obj[key], Object.assign(map.get(obj[key]) || {}, obj)));

  return Array.from(map.values());
}

/**
 * Convert HTML entities to its corresponding characters
 *
 * @param { !string } input
 * @return { string }
 */
export function decodeHtml(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

/**
 * waits for a given miliseconds
 *
 * @param { number } ms
 *
 *   async function demo() {
 *     console.log('Taking a break...');
 *     await sleep(2000);
 *     console.log('Two seconds later');
 *   }
 * @return {Promise}
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/** Capitalize the first letter of a string
 * @param {string} string
 * @return {string}
 */
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/** Truncate string to maxChar characters
 * @param { string } string
 * @param { !number } maxChar
 * @return { string }
 */

export function truncate(string, maxChar) {
  return string !== undefined && string.length > maxChar
    ? `${string.substr(0, maxChar)}...`
    : string;
}

/** Track clicks through Google Analytics
 * @param { string } label
 * @param { string } category
 * @param { string } action
 * @return { boolean }
 */

export const trackEventClick = (label, category, action) => () =>
  analytics.trackEvent({
    eventCat: category,
    eventAct: action,
    eventLbl: label
  });

/** Check if product is available on TpTSA
 * @param { object } product
 * @return { boolean }
 */

export const productIsAvailableOnSchoolAccess = (product) => {
  return get(product, 'resourceProperties.isAvailableOnSchoolAccess', 'false');
};
