import Cookies from 'js-cookie';
import { isTpTAdmin } from 'config/constants';

const SIFTSCIENCE_JS_API_KEY = CONFIG.siftScience && CONFIG.siftScience.jsApiKey;

const getSessionId = () => Cookies.get('ajs_anonymous_id');

const initSiftScience = (options) => {
  const {
    userId = '',
    userGroupId = '',
    sessionId = getSessionId(),
    adminImpersonation = false, // is this user being impersonated by an admin?
    testUser = false // is this a test user?
  } = options;

  // ignore if neither session id nor user id is present
  // or if is a TpT admin
  // or is a TpT admin impersonating as a user
  // or is a test user
  // or no api key is present
  if (
    (!sessionId && !userId) ||
    isTpTAdmin(userGroupId) ||
    adminImpersonation ||
    testUser ||
    !SIFTSCIENCE_JS_API_KEY
  ) {
    return;
  }

  // eslint-disable-next-line no-multi-assign
  const _sift = (window._sift = window._sift || []);
  _sift.push(['_setAccount', SIFTSCIENCE_JS_API_KEY]);
  _sift.push(['_setUserId', userId]);
  _sift.push(['_setSessionId', sessionId]);
  _sift.push(['_trackPageview']);

  (function ss() {
    function ls() {
      const e = document.createElement('script');
      e.src = 'https://cdn.siftscience.com/s.js';
      document.body.appendChild(e);
    }
    if (window.attachEvent) {
      window.attachEvent('onload', ls);
    } else {
      window.addEventListener('load', ls, false);
    }
  })();
};

export { initSiftScience };
