import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from 'components/Dropdown';
import LinkButton from 'components/LinkButton';
import Avatar from 'components/Avatar';
import SellerDropdownMenu from './SellerDropdownMenu';
import './SellerDropdown.scss';

// TODO: fix this component so it renders out reliably regardless of parent's width
const SellerDropdownBespoke = ({
  imageUrl,
  firstName,
  name,
  countUnreadMessages = 0,
  storeUrl,
  isPremiumSellerOrAdmin,
  isShortened
}) => {
  const label = isShortened ? (
    <div className="SellerDropdownBespoke__label">
      <span className="SellerDropdownBespoke__labelName">{firstName || name}</span>
    </div>
  ) : (
    <div className="SellerDropdownBespoke__label">
      <Avatar size="small" alt={name} src={imageUrl} />
      <span className="SellerDropdownBespoke__labelName">{name}</span>
    </div>
  );

  return (
    <Dropdown openOnHover className="SellerDropdownBespoke" label={label} labelBy="Seller Dropdown">
      <SellerDropdownMenu
        isPremiumSellerOrAdmin={isPremiumSellerOrAdmin}
        countUnreadMessages={countUnreadMessages}
        storeUrl={storeUrl}
        colProps={{ xs: 4 }}
        logoutButton={
          <LinkButton
            forceReload
            to="/Logout"
            label="Log Out"
            className="SellerDropdownBespoke__logoutLink green outline cta"
          />
        }
      />
    </Dropdown>
  );
};

SellerDropdownBespoke.propTypes = {
  name: PropTypes.string.isRequired,
  storeUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  countUnreadMessages: PropTypes.number,
  isPremiumSellerOrAdmin: PropTypes.bool,
  isShortened: PropTypes.bool,
  firstName: PropTypes.string
};

SellerDropdownBespoke.defaultProps = {
  isShortened: false
};

export default SellerDropdownBespoke;
