import { CCSS_JURISDICTION_ID } from 'tpt_modules/education-standards/const';

export const SELECT_JURISDICTION = '@@tpt/SELECT_JURISDICTION';

const initialState = { jurisdictionId: CCSS_JURISDICTION_ID };
export default function reducer(state = initialState, action = {}) {
  if (action.type === SELECT_JURISDICTION) {
    return { ...state, jurisdictionId: action.jurisdictionId };
  }
  return state;
}

export function selectJurisdiction(jurisdictionId) {
  return { type: SELECT_JURISDICTION, jurisdictionId };
}
