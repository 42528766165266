import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonBase from 'components/ButtonBase';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';
import buttonProps from './buttonProps';
import './Button.scss';

export const ROOT_CLASSNAME = 'DSButton';

export function isVariantContained(variant) {
  return !variant.includes('tertiary');
}

function isString(arg) {
  return typeof arg === 'string';
}

const Button = (props) => {
  const {
    accessibilityLabel,
    className: classNameProp,
    component: componentProp,
    disabled,
    fullWidth,
    iconLeft: iconLeftProp,
    iconRight: iconRightProp,
    loading,
    label,
    size: buttonSize,
    variant,
    ...other
  } = props;
  const isDisabled = disabled || loading;
  const isContained = isVariantContained(variant);
  const isTertiary = !isContained;
  const iconLeft = isString(iconLeftProp) ? <Icon name={iconLeftProp} /> : iconLeftProp;
  const iconRight = isString(iconRightProp) ? <Icon name={iconRightProp} /> : iconRightProp;
  const Component = componentProp || (other.href ? 'a' : ButtonBase);

  const classes = classnames(
    ROOT_CLASSNAME,
    {
      [`${ROOT_CLASSNAME}--${variant}`]: isContained,
      [`${ROOT_CLASSNAME}--${buttonSize}`]: buttonSize,
      [`${ROOT_CLASSNAME}--contained`]: isContained,
      [`${ROOT_CLASSNAME}--uncontained`]: !isContained,
      [`${ROOT_CLASSNAME}--tertiary`]: isTertiary,
      [`${ROOT_CLASSNAME}--tertiary-destructive`]: variant === 'tertiary-destructive',
      [`${ROOT_CLASSNAME}--loading`]: loading,
      [`${ROOT_CLASSNAME}--disabled`]: isDisabled,
      [`${ROOT_CLASSNAME}--fullWidth`]: fullWidth
    },
    classNameProp
  );

  const iconClasses = classnames(`${ROOT_CLASSNAME}__icon`, {
    [`${ROOT_CLASSNAME}__icon--left`]: iconLeft,
    [`${ROOT_CLASSNAME}__icon--right`]: iconRight
  });

  return (
    <Component
      aria-label={!label && accessibilityLabel ? accessibilityLabel : null}
      className={classes}
      disabled={isDisabled}
      {...other}
    >
      <span className={`${ROOT_CLASSNAME}__content`}>
        {loading && (
          <span className={`${ROOT_CLASSNAME}__loader`}>
            <Spinner size={buttonSize === 'large' ? 'small' : 'xsmall'} />
          </span>
        )}
        {iconLeft && (
          <span aria-hidden="true" className={iconClasses}>
            {iconLeft}
          </span>
        )}
        {label && <span className={`${ROOT_CLASSNAME}__text`}>{label}</span>}
        {iconRight && (
          <span aria-hidden="true" className={iconClasses}>
            {iconRight}
          </span>
        )}
      </span>
    </Component>
  );
};

Button.propTypes = {
  /** Visually hidden text for screen readers. */
  accessibilityLabel: PropTypes.string,

  /** The class name used to add override styles to the button. */
  className: PropTypes.string,

  /** The component used for the root node. Either a string to use a DOM element or a component. */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

  /** If true, the button will be disabled. */
  disabled: PropTypes.bool,

  /** The URL to link to when the button is clicked. If defined, will render the button as an “a” element */
  href: PropTypes.string,

  /** If defined, will render an icon to the left of the label. Supports both a React node and a string based on set of icons defined here. */
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** If defined, will render an icon to the right of the label. Supports both a React node and a string based on set of icons defined here. */
  iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** If true, the button will expand to fill its container. */
  fullWidth: PropTypes.bool,

  /** The button label. */
  label: PropTypes.node.isRequired,

  /** If true, the button will display a loading progress element instead of its contents. This also disables the button. */
  loading: PropTypes.bool,

  /** The click event handler. */
  onClick: PropTypes.func,

  /** The size of the button. */
  size: PropTypes.oneOf(buttonProps.sizeProps),

  /** The HTML type attribute of the button. */
  type: PropTypes.oneOf(buttonProps.typeProps),

  /** The appearance of the button. */
  variant: PropTypes.oneOf(buttonProps.variantProps)
};

Button.displayName = 'Button';

Button.defaultProps = {
  disabled: false,
  fullWidth: false,
  loading: false,
  size: 'medium',
  type: 'button',
  variant: 'secondary'
};

export default Button;
