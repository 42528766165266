import React from 'react';
import PropTypes from 'prop-types';
import NavigationSubcategory from './NavigationSubcategory';

import './NavigationHeaderDropdownMenu.scss';

export const getNavCategories = (menuOverflow) => {
  const mainCategories = {
    gradeLevel: {
      label: 'Grade',
      subcategories: [
        { label: 'Pre-K - K', url: '/Browse/Grade-Level/PreK-K' },
        { label: '1 - 2', url: '/Browse/Grade-Level/1-2' },
        { label: '3 - 5', url: '/Browse/Grade-Level/3-5' },
        { label: '6 - 8', url: '/Browse/Grade-Level/6-8' },
        { label: '9 - 12', url: '/Browse/Grade-Level/9-12' },
        { label: 'Other', url: '/Browse/Grade-Level/Other' }
      ]
    },
    subject: {
      label: 'Subject',
      subcategories: [
        { label: 'Arts & Music', url: '/Browse/PreK-12-Subject-Area/Arts' },
        {
          label: 'English Language Arts',
          url: '/Browse/PreK-12-Subject-Area/English-Language-Arts'
        },
        { label: 'World Language', url: '/Browse/PreK-12-Subject-Area/World-Language' },
        { label: 'Math', url: '/Browse/PreK-12-Subject-Area/Math' },
        { label: 'Science', url: '/Browse/PreK-12-Subject-Area/Science' },
        {
          label: 'Social Studies - History',
          url: '/Browse/PreK-12-Subject-Area/Social-Studies-History'
        },
        { label: 'Special Education', url: '/Browse/PreK-12-Subject-Area/Special-Education' },
        { label: 'Specialty', url: '/Browse/PreK-12-Subject-Area/Specialty' },
        { label: 'Holidays / Seasonal', url: '/Browse/PreK-12-Subject-Area/HolidaysSeasonal' }
      ]
    },
    price: {
      label: 'Price',
      subcategories: [
        { label: 'Free', url: '/Browse/Price-Range/Free' },
        { label: 'Under $5', url: '/Browse/Price-Range/Under-5' },
        { label: '$5 to $10', url: '/Browse/Price-Range/5-to-10' },
        { label: '$10 and up', url: '/Browse/Price-Range/Above-10' },
        { label: 'On Sale', url: '/Browse/Price-Range/On-Sale' }
      ]
    },
    resourceType: {
      label: 'Resource Type',
      subcategories: [
        { label: 'Math Centers', url: '/Browse/Type-of-Resource/Math-Centers' },
        {
          label: 'Independent Work Packet',
          url: '/Browse/Type-of-Resource/Independent-Work-Packet'
        },
        { label: 'Lesson Plans', url: '/Browse/Type-of-Resource/Lesson-Plans-Individual' },
        { label: 'Projects', url: '/Browse/Type-of-Resource/Projects' },
        { label: 'Google Apps', url: '/Browse/Type-of-Resource/Google-Apps' }
      ]
    }
  };

  if (menuOverflow) {
    mainCategories.resourceType.subcategories = [
      { label: 'Literacy Centers', url: '/Browse/Type-of-Resource/Literacy-Center-Ideas' },
      ...mainCategories.resourceType.subcategories
    ];
  }
  return mainCategories;
};

const NavigationHeaderDropdownMenu = ({ menuOverflow }) => {
  const { gradeLevel, subject, price, resourceType } = getNavCategories(menuOverflow);
  return (
    <div className="NavigationHeaderDropdownMenuContainer">
      <div className="NavigationHeaderDropdownMenu">
        <NavigationSubcategory category={resourceType} />
        <NavigationSubcategory category={gradeLevel} />
        <NavigationSubcategory category={subject} />
        <NavigationSubcategory category={price} />
      </div>
    </div>
  );
};

NavigationHeaderDropdownMenu.propTypes = {
  menuOverflow: PropTypes.bool
};

export default NavigationHeaderDropdownMenu;
