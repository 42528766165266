import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'core-js/fn/number/is-integer';

const ModificatorType = PropTypes.oneOfType([PropTypes.number, PropTypes.bool]);

/**
 * A column of the Grid structure.
 */
export default class Col extends Component {
  constructor(props) {
    super(props);

    this._classMap = {
      xs: 'col-xs',
      sm: 'col-sm',
      md: 'col-md',
      lg: 'col-lg',
      xsOffset: 'col-xs-offset',
      smOffset: 'col-sm-offset',
      mdOffset: 'col-md-offset',
      lgOffset: 'col-lg-offset'
    };
  }

  render() {
    const { className, reverse, ...otherProps } = this.props;
    const classes = [];

    if (className) {
      classes.push(className);
    }

    if (reverse) {
      classes.push('reverse');
    }

    Object.keys(otherProps).forEach((key) => {
      if (this._classMap[key]) {
        const colBaseClass = Number.isInteger(otherProps[key])
          ? `${this._classMap[key]}-${otherProps[key]}`
          : `${this._classMap[key]}-12`; // BS grid requires the count

        delete otherProps[key]; // remove so it doesnt react html element

        classes.push(colBaseClass);
      }
    });

    return React.createElement(
      this.props.tagName || 'div',
      {
        ...otherProps,
        className: classes.join(' ')
      },
      this.props.children
    );
  }
}

Col.propTypes = {
  /**
   * For specifying the width of the column on extra-small devices (out of 12).
   */
  xs: ModificatorType,
  /**
   * For specifying the width of the column on small devices (out of 12).
   */
  sm: ModificatorType,
  /**
   * For specifying the width of the column on medium-sized devices (out of 12).
   */
  md: ModificatorType,
  /**
   * For specifying the width of the column on large devices (out of 12).
   */
  lg: ModificatorType,
  /**
   * Offsets the column by specified amount on extra-small devices (out of 12)
   */
  xsOffset: PropTypes.number,
  /**
   * Offsets the column by specified amount on small devices (out of 12)
   */
  smOffset: PropTypes.number,
  /**
   * Offsets the column by specified amount on medium-sized devices (out of 12)
   */
  mdOffset: PropTypes.number,
  /**
   * Offsets the column by specified amount on large devices (out of 12)
   */
  lgOffset: PropTypes.number,
  /**
   * When true, the columns are reversed (flipped over the y-axis).
   */
  reverse: PropTypes.bool,
  /**
   * Used for adding css styling.
   */
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node
};
