import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Anchor from 'components/Anchor';
import './Avatar.scss';

export const AVATAR_SIZES = ['small', 'mobile', 'base', 'medium', 'large'];
const defaultSrc = 'https://ecdn1.teacherspayteachers.com/images/avatars/default.jpg';

const Avatar = ({
  alt,
  src,
  url,
  size,
  className,
  isMobile,
  component: componentProp,
  ...other
}) => {
  const Component = componentProp || (url ? Anchor : 'span');

  const props = Component === Anchor ? { ...other, forceReload: true } : { ...other };

  return (
    <Component className={classnames('Avatar', className)} to={url || null} {...props}>
      <img
        alt={alt}
        className={`Avatar__img Avatar__img--${size}`}
        src={src || defaultSrc}
        data-pin-nopin="true"
        loading={isMobile ? 'lazy' : 'auto'}
      />
    </Component>
  );
};

Avatar.propTypes = {
  /** The `alt` tag for the `img` element. */
  alt: PropTypes.string,

  /** @ignore If present, extends the styles applied to the component. */
  className: PropTypes.string,

  /**
   * The component used for the root node.
   * Either a string to use a DOM element or a component.
   */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),

  /** The size of the avatar. */
  size: PropTypes.oneOf(AVATAR_SIZES),

  /** The `src` attribute for the `img` element. */
  src: PropTypes.string,

  /** If present, will wrap the avatar in an anchor. */
  url: PropTypes.string,

  /** If present, will lazy load images. */
  isMobile: PropTypes.bool
};

Avatar.defaultProps = {
  alt: '',
  size: 'base',
  isMobile: false
};

export default Avatar;
