import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/Text';
import FlexBox from 'components/FlexBox';
import TpTEaselBadge from 'domains/TpTEasel/components/TpTEaselBadge';
import {
  TPT_EASEL_DETAILS,
  TPT_EASEL_TOOLTIP_COPY
} from 'domains/DigitalActivityBadging/constants';
import TpTEaselIncludedAsset from 'shared/images/Easel/easel_activities_boxes.svg';
import TpTEaselCompatibleAsset from 'shared/images/Easel/easel_activities_compatible.svg';
import TpTEaselAsset from './TpTEaselAsset.svg';
import EaselTooltip from '../EaselTooltip';
import './DigitalActivityTooltip.scss';

const getTooltipConfig = (digitalActivityVariant, isBundle) => {
  const sellerCreatedBundleCopy = TPT_EASEL_DETAILS.bundleSellerCreated;

  const sellerCreatedCopy = TPT_EASEL_DETAILS.sellerCreated;

  const sellerEnabledBundleCopy = TPT_EASEL_DETAILS.bundleSellerEnabled;

  const sellerEnabledCopy = TPT_EASEL_DETAILS.sellerEnabled;

  const configMap = {
    general: TPT_EASEL_DETAILS.general,
    sellerCreated: isBundle ? sellerCreatedBundleCopy : sellerCreatedCopy,
    sellerCreatedActivities: isBundle
      ? sellerCreatedBundleCopy
      : TPT_EASEL_DETAILS.sellerCreatedActivities,
    sellerEnabled: isBundle ? sellerEnabledBundleCopy : sellerEnabledCopy
  };

  return configMap[digitalActivityVariant];
};

const prefixVariant = {
  general: 'Compatible with',
  sellerEnabled: 'Compatible with',
  sellerCreated: 'Includes'
};

const DigitalActivityTooltip = ({
  digitalActivityVariant,
  arrowPosition,
  withImage,
  useGenericImage,
  showCloseIcon,
  onClose,
  isBundle
}) => {
  const bodyCopy = getTooltipConfig(digitalActivityVariant, isBundle);
  const isSellerEnabled = digitalActivityVariant === 'sellerEnabled';
  const isSellerCreated = digitalActivityVariant === 'sellerCreated';

  const header = (
    <FlexBox wrap>
      <Text className="DigitalActivityTooltip__prefix" strong>
        {prefixVariant[digitalActivityVariant] || ''}
      </Text>
      <TpTEaselBadge
        text={TPT_EASEL_TOOLTIP_COPY[digitalActivityVariant]}
        showIcon={false}
        textVariant="body"
      />
    </FlexBox>
  );

  const getImage = () => {
    if (!withImage) return { image: null, altText: 'Easel by TpT' };
    if (useGenericImage) return { image: TpTEaselAsset, altText: 'Easel by TpT' };
    if (isSellerEnabled)
      return { image: TpTEaselCompatibleAsset, altText: 'Compatible with Easel by TpT' };
    if (isSellerCreated)
      return { image: TpTEaselIncludedAsset, altText: 'Easel by TpT Activity Included' };
    return { image: null, altText: 'Easel by TpT' };
  };

  return (
    <EaselTooltip
      className="DigitalActivityTooltip"
      header={header}
      body={bodyCopy}
      arrowPosition={arrowPosition}
      withCreamBackground={useGenericImage}
      showCloseIcon={showCloseIcon}
      onClose={onClose}
      {...getImage()}
    />
  );
};

DigitalActivityTooltip.propTypes = {
  digitalActivityVariant: PropTypes.string.isRequired,
  arrowPosition: PropTypes.string,
  withImage: PropTypes.bool,
  useGenericImage: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onClose: PropTypes.func,
  isBundle: PropTypes.bool
};

DigitalActivityTooltip.defaultProps = {
  arrowPosition: 'left',
  withImage: false,
  useGenericImage: false,
  showCloseIcon: false,
  onClose: undefined,
  isBundle: false
};
export default DigitalActivityTooltip;
