import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from 'components/Text';
import textProps from 'components/Text/textProps';

import './CompatibleWithText.scss';

const CompatibleWithText = ({ size = 'body', className }) => (
  <div className={classnames('CompatibleWithText', className)}>
    <Text className="CompatibleWithText__text" variant={size} color="textPrimary">
      Compatible with&nbsp;
    </Text>
  </div>
);

CompatibleWithText.propTypes = {
  size: PropTypes.oneOf(textProps.variants),
  className: PropTypes.string
};

export default CompatibleWithText;
