import ApolloClient from 'apollo-client';
import { parser, DocumentType } from 'react-apollo/parser';
import { ApolloLink, from } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import computeFetchOptions from 'lib/computeFetchOptions';
import logger from 'lib/logger';
import { mergeArraysByKey } from 'util/utils';
import Cookies from 'js-cookie';
import ExtensionsVariantsQuery from 'tpt_modules/features/queries/ExtensionsVariants.graphql';
import fragmentTypes from 'shared/generated/fragmentTypes.json';

export function is5xxError(response) {
  return parseInt(response.status / 100, 10) === 5;
}
export function fetchWithOpname(uri, options, operationName) {
  if (operationName) {
    // eslint-disable-next-line no-param-reassign
    uri += `?opname=${operationName}`;
  }

  let fetchPromise;

  if (!IS_BROWSER) {
    // eslint-disable-next-line global-require
    fetchPromise = require('decorators/timing').time(fetch, 'apollo.fetch.ttfb', [
      `opname:${operationName || 'NoOperationNameProvided'}`
    ]);
  } else {
    fetchPromise = fetch;
  }

  return fetchPromise.call(this, uri, options);
}

/**
 * Factory that returns an appropriate fetch method to use for the specified
 * Apollo client name
 * @param {string} clientName - name of this Apollo client (e.g. `api`, `gql-gateway`)
 * @return {function} fetch function suitable for the specified Apollo client
 */

function getFetchFunctionForClient(clientName) {
  return function fetchWithOpnameAndRetry(uri, options) {
    const { operationName, query } = JSON.parse(options.body);

    return fetchWithOpname(uri, options, operationName)
      .then((response) => {
        if (is5xxError(response)) {
          // eslint-disable-next-line max-len
          // Throw an error with the same format as this: https://github.com/apollographql/apollo-client/blob/v1.9.0/src/transport/networkInterface.ts#L219-L224
          const httpError = new Error(
            `Network request failed with status ${response.status} - "${response.statusText}"`
          );
          httpError.response = response;
          throw httpError;
        }
        return response;
      })
      .catch((e) => {
        // Retry if we encounter a network error or 5xx and the query is not a mutation
        if (
          !IS_BROWSER &&
          process.env.DISABLE_APOLLO_RETRIES !== 'true' &&
          parser(gql(query)).type === DocumentType.Query
        ) {
          // eslint-disable-next-line global-require
          const sdc = require('server/lib/statsd').default;
          const opName = operationName || 'NoOperationNameProvided';

          sdc.increment('apollo.fetch.retry-attempt', 1, [`opname:${opName}`]);
          logger.debug(`Retrying Apollo query: ${opName}`);

          return fetchWithOpname(uri, options, operationName).then((payload) => {
            sdc.increment('apollo.fetch.retry-success', 1, [`opname:${opName}`]);
            sdc.increment('apollo.fetch.retry-attempt', 1, [
              `opname:${opName}`,
              `backend:${clientName}`
            ]);
            return payload;
          });
        }
        throw e;
      });
  };
}

/** creates an apollo client
 *
 * @param { string } url of graphapi
 * @param { string } gatewayUrl gql-gateway url
 * @param { !object } req request
 * @param { boolean } ssrMode server side rendering
 * @param { function } afterware
 * @param { object }  initialState
 * @return { !object }
 **/
export default function createApolloClient({
  url = '',
  gatewayUrl = '',
  req,
  ssrMode = false,
  afterware,
  initialState = {}
}) {
  // eslint-disable-next-line prefer-const
  let apolloClient;

  /**
   * Middleware to log queries and mutations in client and browser
   */
  const loggingMiddleware = new ApolloLink((operation, forward) => {
    const type = operation.query.definitions[0].operation === 'mutation' ? 'Mutation' : 'Query';
    logger.debug(
      `Graph API ${type}: ${operation.operationName}(${JSON.stringify(operation.variables)})`
    );
    return forward(operation);
  });

  /**
   * Callback function to save payload.extensions
   *
   * @param { !object } payload  GraphQL query response
   * @return { !object }  ^ Same as above
   */
  function onPayloadReceived(payload) {
    const { extensions } = payload;

    if (extensions && extensions.variants) {
      apolloClient._extensionsVariants = apolloClient._extensionsVariants || [];

      const variants = mergeArraysByKey(
        apolloClient._extensionsVariants,
        extensions.variants,
        'test_name'
      );

      apolloClient.writeQuery({
        query: ExtensionsVariantsQuery,
        data: {
          extensionsVariants: variants.map((variant) => ({
            ...variant,
            __typename: 'ExtensionsVariant'
          }))
        }
      });

      apolloClient._extensionsVariants = variants;
    }

    return payload;
  }

  const onPayloadReceivedLink = new ApolloLink((operation, forward) =>
    forward(operation).map(onPayloadReceived)
  );

  const baseUrl = url || gatewayUrl || '';

  const fetchOptions = computeFetchOptions({ req, baseUrl });
  const httpLink = (apiUrl, clientName) =>
    createHttpLink({
      uri: apiUrl,
      fetch: getFetchFunctionForClient(clientName),
      includeExtensions: true,
      fetchOptions,
      headers: fetchOptions.headers
    });

  const onErrorLink = onError(({ response, operation, networkError, graphQLErrors }) => {
    const opName = operation.operationName || 'NoOperationNameProvided';
    const data = { req, variables: operation.variables };
    let logType = '';

    if (networkError) {
      // don't log networkError.response, it's too noisy
      const { response: _res, ...err } = networkError;
      data.networkError = err;
    }
    // ignore errors if request was marked as non-blocking
    if (operation.getContext().nonBlockingRequest && response) {
      logType = '(Non-Blocking Request)';
      response.errors = null;
    }

    if (graphQLErrors) {
      logger.error(`Apollo GraphQL Error${logType}: ${opName}`, { ...data, graphQLErrors });
    } else {
      logger.error(`Apollo Network Error${logType}: ${opName}`, data);
    }
  });

  const gatewayLink = from([
    new ApolloLink((operation, forward) => {
      if (req?.cookies?.sessionKey) {
        operation.setContext((context) => ({
          headers: {
            ...(context.headers || {}),
            Authorization: `Bearer ${Buffer.from(req.cookies.sessionKey, 'utf8').toString(
              'base64'
            )}`
          }
        }));
      } else if (IS_BROWSER && Cookies.get('sessionKey')) {
        operation.setContext((context) => ({
          headers: {
            ...(context.headers || {}),
            Authorization: `Bearer ${btoa(Cookies.get('sessionKey'))}`
          }
        }));
      } else {
        logger.debug(`No sessionKey cookie was used for hitting ${gatewayUrl}/gateway/graphql`);
      }
      return forward(operation);
    }),
    httpLink(`${gatewayUrl}/gateway/graphql`, 'gateway')
  ]);

  const apiLink = httpLink(`${url}/graph/graphql`, 'graphql');
  let link = from([
    onPayloadReceivedLink,
    onErrorLink,
    ApolloLink.split(
      (operation) => {
        return operation.getContext().clientName === 'gateway';
      },
      gatewayLink,
      apiLink
    )
  ]);

  if (afterware) {
    const afterwareLink = new ApolloLink((operation, forward) =>
      forward(operation).map((responseData) => {
        afterware(operation.getContext(), responseData);
        return responseData;
      })
    );

    link = afterwareLink.concat(link);
  }

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes
  });

  apolloClient = new ApolloClient({
    ssrMode,
    link: IS_DEVELOPMENT ? from([loggingMiddleware, link]) : link,
    connectToDevTools: true,
    cache: new InMemoryCache({ fragmentMatcher }).restore(initialState)
  });

  return apolloClient;
}
