import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from 'components/Icon';
import Cookies from 'js-cookie';

import Anchor from 'components/Anchor';
import Button from 'components/LegacyButton';
import Accordion from 'components/Accordion';

import withLocation from 'decorators/withLocation';

import './AdminToolbox.scss';

class AdminToolbox extends Component {
  static propTypes = {
    displayName: PropTypes.string.isRequired,
    shouldShowAdminLoggedInAsAlert: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isNewAuthOn: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isNewAuthOn: this.isNewAuthOn() });
  }

  turnOnNewAuth = () => {
    Cookies.set('TPT_NEW_AUTH', 'on', { domain: CONFIG.cookieDomain, expires: 12 });
    this.setState({ isNewAuthOn: true });
  };

  turnOffNewAuth = () => {
    Cookies.remove('TPT_NEW_AUTH', { domain: CONFIG.cookieDomain });
    Cookies.remove('sessionKey', { domain: CONFIG.cookieDomain });
    this.setState({ isNewAuthOn: false });
  };

  isNewAuthOn() {
    return !!Cookies.get('TPT_NEW_AUTH');
  }

  render() {
    const {
      displayName,
      shouldShowAdminLoggedInAsAlert,
      location: { pathname }
    } = this.props;
    const { turnOnNewAuth, turnOffNewAuth } = this;
    const { isNewAuthOn } = this.state;

    return (
      <span>
        {shouldShowAdminLoggedInAsAlert && (
          <div className="AdminToolbox--userWarning" key="AdminToolbox-userWarning">
            You&rsquo;re now logged in as {displayName}.
            <p>
              <Anchor to="/users/back_to_admin" forceReload>
                Back to admin panel
              </Anchor>
            </p>
          </div>
        )}
        <div className="AdminToolbox--buttons" key="AdminToolboxbuttons">
          <Accordion label="TpT Admin Tools">
            <ul>
              <li>
                Log in w/ New Auth&nbsp;&nbsp;
                <Button
                  label="On"
                  size="sm"
                  linkTo="/Logout"
                  forceReload
                  onClick={turnOnNewAuth}
                  disabled={isNewAuthOn}
                />
                <Button
                  label="Off"
                  size="sm"
                  linkTo="/Logout"
                  forceReload
                  onClick={turnOffNewAuth}
                  disabled={!isNewAuthOn}
                />
              </li>
              {!shouldShowAdminLoggedInAsAlert && pathname.startsWith('/Product/') && (
                <li>
                  <Anchor
                    to={pathname.replace('/Product/', '/Download/')}
                    forceReload
                    color="black"
                  >
                    <Icon name="download" />
                    Download File
                  </Anchor>
                </li>
              )}
              <li>
                <Anchor to="https://bit.ly/TpTHomeroom" forceReload openNewTab color="black">
                  <Icon name="home" />
                  TpT Homeroom
                </Anchor>
              </li>
              <li>
                <Anchor to="/adminnew3/dashboard" forceReload openNewTab color="black">
                  <Icon name="cog" />
                  Admin Panel
                </Anchor>
              </li>
            </ul>
          </Accordion>
        </div>
      </span>
    );
  }
}

export default withLocation(AdminToolbox);
