import {
  TPT_DESELECT_EDUCATION_STANDARD,
  TPT_SELECT_EDUCATION_STANDARD,
  TPT_SET_EDUCATION_STANDARDS
} from 'lib/educationStandardsActions';

function educationStandardsReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case TPT_DESELECT_EDUCATION_STANDARD:
      return state.filter((standard) => standard.id !== payload);
    case TPT_SELECT_EDUCATION_STANDARD:
      if (
        payload.id !== undefined &&
        state.filter((standard) => standard.id === payload.id).length === 0
      ) {
        return [...state, payload];
      }
      return state;
    case TPT_SET_EDUCATION_STANDARDS:
      return payload || state;
    default:
      return state;
  }
}

export default educationStandardsReducer;
