import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { clearGlobalErrors } from 'lib/actions';
import Alert from 'components/Alert';
import Anchor from 'components/Anchor';

function GlobalErrorsContainer({ errors = [], onClose, requestTraceIds = [], responsive }) {
  /* eslint-disable react/no-array-index-key */

  // Grab errors from all namespaces and collapse into an array.
  const allErrors = Object.values(errors).reduce((acc, item) => acc.concat(item));
  if (!allErrors.length) {
    return null;
  }

  return (
    <div className="GlobalErrorsContainer">
      <Alert onClose={onClose} notificationType="error" isOpen responsive={responsive}>
        {allErrors.map((err, idx) => (
          <p key={idx}>{err}</p>
        ))}
        {requestTraceIds.length > 0 && (
          <Fragment>
            Sorry about the error! Please{' '}
            <Anchor to="/Contact" openNewTab>
              reach out to us
            </Anchor>{' '}
            along with the error code: {requestTraceIds.join(',')}
          </Fragment>
        )}
      </Alert>
    </div>
  );
  /* eslint-enable react/no-array-index-key */
}

GlobalErrorsContainer.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node]))
  ).isRequired,
  requestTraceIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  responsive: PropTypes.bool
};

export default connect(
  ({ globalErrors, globalErrorRequestTraceIds }) => ({
    errors: globalErrors,
    requestTraceIds: globalErrorRequestTraceIds.enabled ? globalErrorRequestTraceIds.traceIds : []
  }),
  {
    onClose: clearGlobalErrors
  }
)(GlobalErrorsContainer);
