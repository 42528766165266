import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DigitalActivityBadge from 'domains/DigitalActivityBadging/DigitalActivityBadge';
import DigitalActivityTooltip from '../DigitalActivityTooltip';
import './DigitalActivityBadgeWithTooltip.scss';

export const DigitalActivityBadgeWithTooltip = ({
  className,
  pageName,
  badgeProps,
  tooltipProps,
  withTooltip,
  openTooltip,
  isMobile,
  isSearchFilter,
  easelBadgeTextVariant,
  useEaselIcon
}) => {
  const { isSellerPublishedDigital } = badgeProps;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isWaitingToClose, setIsWaitingToClose] = React.useState(false);

  const tooltipEnabled = withTooltip && !isMobile;
  const addCompatibleWithText = !isSearchFilter && !isSellerPublishedDigital;

  const renderTooltip = tooltipEnabled && (openTooltip || isOpen || isWaitingToClose);

  React.useEffect(() => {
    if (renderTooltip && !openTooltip) {
      global.heap.track('Opened - TpT Digital Activity Tooltip', { category: pageName });
    }
  }, [renderTooltip, openTooltip]);

  const closeTooltip = () => {
    setIsOpen(false);
    setIsWaitingToClose(true);
    setTimeout(() => {
      setIsWaitingToClose(false);
    }, 250);
  };

  const eventProps =
    tooltipEnabled && !openTooltip
      ? {
          onMouseOver: () => {
            setIsOpen(true);
          },
          onFocus: () => setIsOpen(true),
          onMouseLeave: () => closeTooltip(),
          onBlur: () => closeTooltip()
        }
      : {};

  return (
    <div
      className={classnames('DigitalActivityBadgeWithTooltip', { [className]: !!className })}
      {...eventProps}
    >
      <DigitalActivityBadge
        {...badgeProps}
        addCompatibleWithText={addCompatibleWithText}
        easelBadgeTextVariant={easelBadgeTextVariant}
        useEaselIcon={useEaselIcon}
        className={className}
      />

      {renderTooltip && <DigitalActivityTooltip {...tooltipProps} />}
    </div>
  );
};

DigitalActivityBadgeWithTooltip.propTypes = {
  pageName: PropTypes.string.isRequired,
  badgeProps: PropTypes.shape(DigitalActivityBadge.propTypes).isRequired,
  tooltipProps: PropTypes.shape(DigitalActivityTooltip.propTypes).isRequired,
  withTooltip: PropTypes.bool,
  className: PropTypes.string,
  openTooltip: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSearchFilter: PropTypes.bool,
  easelBadgeTextVariant: PropTypes.string,
  useEaselIcon: PropTypes.bool
};

DigitalActivityBadgeWithTooltip.defaultProps = {
  withTooltip: true,
  openTooltip: false,
  isMobile: false,
  isSearchFilter: false,
  useEaselIcon: false
};

export default DigitalActivityBadgeWithTooltip;
