import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'react-slick';
import Box from 'components/Box';
import logoSymbol from 'components/Logo/logo-symbol.svg';
import './NarrowClassFundFeedLayout.scss';

const NarrowClassFundFeedLayout = ({ className, contributions, variant }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    swipe: false,
    touchMove: false,
    vertical: true,
    pauseOnHover: true,
    accessibility: false
  };

  const shouldShowCreatedDate = variant === 'timestamps';
  const classes = classnames('NarrowClassFundFeedLayout', className);

  return (
    <Box className={classes}>
      <Box className="NarrowClassFundFeedLayout__contributions">
        <Box className="NarrowClassFundFeedLayout__contributionsPre">
          Recent TpT ClassFund supporters:
        </Box>
        {contributions.length ? (
          <Slider {...settings} className="NarrowClassFundFeedLayout__slider">
            {contributions.map(({ message, contributorName, created }) => (
              <div key={contributorName}>
                <Box className="NarrowClassFundFeedLayout__contribution">
                  <img
                    className="NarrowClassFundFeedLayout__contributionLogo"
                    src={logoSymbol}
                    alt="Teachers Pay Teachers"
                  />
                  <Box className="NarrowClassFundFeedLayout__contributionContainer">
                    <Box>
                      {shouldShowCreatedDate && (
                        <span data-testid="relativeDate">
                          {formatDistanceToNowStrict(zonedTimeToUtc(created, 'America/New_York'), {
                            addSuffix: true
                          })}{' '}
                        </span>
                      )}
                      {contributorName}
                      <span> gave and said:</span>
                    </Box>
                    <Box className="NarrowClassFundFeedLayout__contributionMessage">
                      &quot;{message}&quot;
                    </Box>
                  </Box>
                </Box>
              </div>
            ))}
          </Slider>
        ) : (
          <div>
            <Box className="NarrowClassFundFeedLayout__contribution NarrowClassFundFeedLayout__contributionPlaceholder">
              <img
                className="NarrowClassFundFeedLayout__contributionLogo"
                src={logoSymbol}
                alt="Teachers Pay Teachers"
              />
              <Box className="NarrowClassFundFeedLayout__contributionContainer">
                <Box className="NarrowClassFundFeedLayout__contributionContributor">
                  Lorem ipsum dolor sit amet, consectetur
                </Box>
                <Box className="NarrowClassFundFeedLayout__contributionMessage">
                  <Box className="NarrowClassFundFeedLayout__contributionMessageLine">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        )}
      </Box>
    </Box>
  );
};

NarrowClassFundFeedLayout.defaultProps = {
  className: '',
  variant: 'timestamps'
};

NarrowClassFundFeedLayout.propTypes = {
  className: PropTypes.string,
  contributions: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      created: PropTypes.string,
      contributorName: PropTypes.string
    })
  ).isRequired,
  variant: PropTypes.oneOf(['timestamps', 'no_timestamps'])
};

export default NarrowClassFundFeedLayout;
