/*
  This object is passed to IntlProvider and contains the messages
  that react-intl will find by id when formatting and translating a message.
  Each key in this object should match a locale for the translated messages.

  https://github.com/yahoo/react-intl/wiki/Components#message-descriptor
*/

const localeMessages = {
  'en-US': {
    ratingCount: `{count, plural,
      =0 {not yet rated}
      one {# Rating}
      other {# Ratings}
    }`,
    ratingCountTruncated: `{count, plural,
      =0 {not yet rated}
      other {#}
    }`,
    questionCount: `{count, plural,
      =0 {N/A questions}
      one {# question}
      other {# questions}
    }`,
    downloadCount: `{count, plural,
      one {# Download}
      other {# Downloads}
    }`,
    followerCount: `{count, plural,
      one {# Follower}
      other {# Followers}
    }`,
    pageCount: `{count, plural,
      one {# page}
      other {# pages}
    }`,
    hoursCount: `{count, plural,
      one {HR}
      other {HRS}
    }`,
    licenseCount: `{count, plural,
      one {# license}
      other {# licenses}
    }`,
    itemCount: `{count, plural,
      one {Item}
      other {Items}
    }`,
    giftCardCount: `{count, plural,
      one {# Gift Card}
      other {# Gift Cards}
    }`,
    productCount: `{count, plural,
      =0 {no products}
      one {# product}
      other {# products}
    }`,
    pageTitle:
      '{title} {itemCount, select, 0 {} other {({itemCount} {itemLabel})}} | Teachers Pay Teachers'
  }
};

export default localeMessages;
