import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import Anchor from 'components/Anchor';
import css from './Social.module.scss';

const socialTypes = {
  twitter: {
    icon: 'twitter',
    href: 'https://twitter.com/',
    label: 'Twitter'
  },
  instagram: {
    icon: 'instagram',
    href: 'https://www.instagram.com/',
    label: 'Instagram'
  },
  pinterest: {
    icon: 'pinterest-p',
    href: 'https://www.pinterest.com/',
    label: 'Pinterest'
  },
  facebook: {
    icon: 'facebook',
    href: 'https://www.facebook.com/',
    label: 'Facebook'
  },
  github: {
    icon: 'github',
    href: 'https://github.com/',
    label: 'Github'
  },
  linkedIn: {
    icon: 'linkedin',
    href: 'https://linkedin.com/in/',
    label: 'LinkedIn'
  }
};

const Social = ({ type, profilePath, isResponsive }) => (
  <Anchor
    to={`${socialTypes[type].href}${profilePath}`}
    className={classnames(css.item, {
      [css.itemResponsive]: isResponsive
    })}
    opensNewTab
  >
    <Icon name={socialTypes[type].icon} />
    <span className="visually-hidden">{socialTypes[type].label}</span>
  </Anchor>
);

Social.propTypes = {
  type: PropTypes.oneOf(Object.keys(socialTypes)).isRequired,
  profilePath: PropTypes.string.isRequired,
  isResponsive: PropTypes.bool
};

Social.defaultProps = {
  isResponsive: undefined
};

export default Social;
