import PropTypes from 'prop-types';
import React from 'react';
import LinkButton from 'components/LinkButton';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import Anchor from 'components/Anchor';

const UserDetailsRow = ({ loggedIn, name, url, imageUrl }) => {
  let cta = { url: '/Login', label: 'Log In' };
  if (loggedIn) {
    cta = { url: '/Logout', label: 'Log Out' };
  }
  return (
    <Row className="UserDetailsRow">
      <Col className="UserDetailsRow__userDetails">
        <Anchor to={url} forceReload color="black">
          {imageUrl && <img className="UserDetailsRow__labelAvatar" alt="avatar" src={imageUrl} />}
          <span> {name} </span>
        </Anchor>
      </Col>

      <Col auto>
        <LinkButton
          forceReload
          to={cta.url}
          label={cta.label}
          className="UserDetailsRow__logoutLink green outline cta small"
        />
      </Col>
    </Row>
  );
};

UserDetailsRow.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  loggedIn: PropTypes.bool
};
export default UserDetailsRow;
