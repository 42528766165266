/**
 * Usage:
 *
 *     logger.info('Hello, world!');
 *
 *
 * You can log additional data using the second argument:
 *
 *     logger.trace('We did something', { duration: Date.now() - startTime });
 *
 *     logger.debug('Here is some data', { foods: ['bread', 'ice cream'] });
 *
 *
 * The 'err' property is a special case:
 *
 *     logger.error('Uh oh:', { err: new Error('we broke it') });
 *
 *
 * The severity levels, in ascending order: trace, debug, info, warn, error, fatal
 */

/* eslint-disable no-console */

import safeJsonStringify from 'safe-json-stringify';
import { isPlainObject } from 'lodash';
import { ANONYMOUS_COOKIE_ID } from 'config/constants';

/**
 * Severity levels of loggers, as defined by bunyan
 *
 * @enum { !number }
 */
const LOG_LEVELS = {
  trace: 10,
  debug: 20,
  info: 30,
  warn: 40,
  error: 50,
  fatal: 60
};

/**
 * This is exported for testing only.
 *
 * @param { !string } message
 * @param { object } data
 * @param { array } tags
 * @return { !object }
 */
export function formatLogData(message, data = {}, tags = []) {
  const { req, err, ...otherData } = isPlainObject(data) ? data : { value: data };

  const retVal = {
    // ab_flags: {}, // todo
    // item_id: '', // todo
    msg: typeof message === 'string' ? message : safeJsonStringify(message),
    tags: safeJsonStringify(tags),
    timestamp: new Date().toISOString(),
    // user_id: '', // todo
    data: otherData
  };

  if (err instanceof Error) {
    if (!message) {
      retVal.msg = err.message;
    } else {
      retVal.msg = `${message} ${err.message}`;
    }

    Object.assign(retVal, {
      class: err.name,
      stack_trace: err.stack
    });
  } else if (err !== undefined) {
    retVal.data.err = err;
  }

  if (req && req.headers && req.method) {
    Object.assign(retVal, {
      anon_id: (req.cookies && req.cookies[ANONYMOUS_COOKIE_ID]) || '',
      // Because we set "trust proxy", this contains the left-most entry in X-Forwarded-For.
      // Unfortunately that means it can be spoofed easily.
      // TODO: use a custom trust function as in https://expressjs.com/en/guide/behind-proxies.html
      client_ip: req.ip || (req.connection && req.connection.remoteAddress),
      http_referer: req.get('referer'),
      req_id: req.id,
      req_method: req.method,
      req_params: JSON.stringify(req.query),
      req_uri: `${req.protocol}://${req.get('host')}${req.originalUrl}`,
      user_agent: req.get('user-agent')
    });
  } else if (req !== undefined) {
    retVal.data.req = req;
  }

  // don't try to add a 'level' property to the log object, bunyan doesn't like it
  delete retVal.level;

  return retVal;
}

/**
 * Create a bunyan logger (or fake bunyan logger)
 *
 * @return { Logger }
 */
function createLogger() {
  if (!IS_BROWSER) {
    // eslint-disable-next-line global-require
    const bunyan = require('bunyan');
    return bunyan.createLogger({
      name: 'tpt-frontend',
      // makeLogFn() handles log ignoring, so just set the bunyan logger to the minimum level
      level: 'trace'
    });
  }

  // fake bunyan logger for the browser
  const fakeLogger = (level) => {
    const consoleFn = (console[level] || console.log).bind(console);
    return function consoleWrapper({ msg, data }) {
      consoleFn(msg, data);
    };
  };

  return {
    trace: fakeLogger('trace'),
    debug: fakeLogger('debug'),
    info: fakeLogger('info'),
    warn: fakeLogger('warn'),
    error: fakeLogger('error'),
    fatal: fakeLogger('fatal')
  };
}

/**
 * Create a logging function given a severity level
 *
 * @param { !Logger } logger
 * @param { !string } level
 * @param { string= } minLevel
 * @return { function }
 */
function makeLogFn(logger, level, minLevel) {
  // If the severity level is lower than the minimum we want to log, use a noop
  if (LOG_LEVELS[level] < LOG_LEVELS[minLevel]) {
    return function noop() {};
  }

  return function log(message, data = {}, tags = []) {
    const logArgs = formatLogData(message, data, tags);

    logger[level].call(logger, logArgs, logArgs.msg);
  };
}

/**
 * Minimum severity of logs to be emitted
 *
 * @const {string}
 */
const LOG_LEVEL = (!IS_BROWSER && process.env.LOG_LEVEL) || CONFIG.logLevel || 'info';

if (!LOG_LEVELS[LOG_LEVEL]) {
  throw new Error(`Invalid LOG_LEVEL. Must be one of ${Object.keys(LOG_LEVELS)}`);
}

const logger = createLogger();

export default {
  trace: makeLogFn(logger, 'trace', LOG_LEVEL),
  debug: makeLogFn(logger, 'debug', LOG_LEVEL),
  info: makeLogFn(logger, 'info', LOG_LEVEL),
  warn: makeLogFn(logger, 'warn', LOG_LEVEL),
  error: makeLogFn(logger, 'error', LOG_LEVEL),
  fatal: makeLogFn(logger, 'fatal', LOG_LEVEL)
};
