import PropTypes from 'prop-types';
import React, { Children } from 'react';
import classnames from 'classnames';
import { KEY_TYPES } from 'config/constants';
import MenuItem from 'components/MenuItem';
import './Menu.scss';

// A vertically listed group of MenuItems.
const Menu = (props) => {
  const { onItemClick, className, children, selectedValues = [], selectedValue } = props;

  if (selectedValue) {
    selectedValues.push(selectedValue);
  }

  return (
    <ul className={classnames('Menu', className)}>
      {Children.toArray(children).map((item) => {
        const value =
          typeof item.props.value === 'object'
            ? JSON.stringify(item.props.value)
            : item.props.value;

        const label =
          typeof item.props.label === 'object'
            ? JSON.stringify(item.props.label)
            : item.props.label;

        const onClick = item.props.onClick || onItemClick;

        const ariaProps =
          typeof onClick === 'function'
            ? {
                role: 'button',
                tabIndex: 0,
                onKeyDown: (e) => {
                  if (e.which === KEY_TYPES.ENTER || e.which === KEY_TYPES.SPACE) {
                    onClick(e);
                  }
                },
                'aria-pressed': selectedValues.includes(value),
                'aria-label': label
              }
            : {};

        return React.cloneElement(item, {
          ...item.props,
          value,
          onClick,
          selected: selectedValues.includes(value),
          ...ariaProps
        });
      })}
    </ul>
  );
};

Menu.propTypes = {
  /**
   * For adding css styling.
   */
  className: PropTypes.string,
  children: PropTypes.node,
  /**
   * The MenuItems that are selected by default.
   */
  selectedValues: PropTypes.array,
  /**
   * The MenuItem that is selected by default.
   */
  selectedValue: PropTypes.any,
  /**
   * The function to call when a MenuItem is selected.
   */
  onItemClick: PropTypes.func
};

Menu.MenuItem = MenuItem;

export default Menu;
