import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

export default (pageFileName) => {
  const InjectPage = ({ config }) => (
    <Helmet
      script={[
        {
          src: config.assetManifest[pageFileName].js,
          defer: true
        }
      ]}
      link={[
        {
          rel: 'stylesheet',
          href: config.assetManifest[pageFileName].css
        }
      ]}
    />
  );
  InjectPage.propTypes = {
    config: PropTypes.object
  };

  return connect(({ config }) => ({ config }))(InjectPage);
};
