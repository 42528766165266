import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const ModificatorType = PropTypes.oneOf(['xs', 'sm', 'md', 'lg']);
const modificatorKeys = [
  'start',
  'center',
  'end',
  'top',
  'middle',
  'bottom',
  'around',
  'between',
  'first',
  'last'
];

// A row of the Grid structure.
const Row = (props) => {
  const modificators = ['row'];
  for (let i = 0; i < modificatorKeys.length; ++i) {
    const key = modificatorKeys[i];
    const value = props[key];
    if (value) {
      modificators.push(`${key}-${value}`);
    }
  }

  if (props.reverse) {
    modificators.push('reverse');
  }

  const className = classNames(props.className, modificators);

  return React.createElement(props.tagName || 'div', { ...props, className }, props.children);
};

Row.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  /**
   * When true, the rows are reversed (flipped over the x-axis).
   */
  reverse: PropTypes.bool,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  start: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  center: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  end: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  top: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  middle: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  bottom: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  around: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  between: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  first: ModificatorType,
  /**
   * Possible values: 'xs', 'sm', 'md', or 'lg'
   */
  last: ModificatorType,
  /**
   * For adding css styling.
   */
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node
  /* eslint-enable react/no-unused-prop-types */
};

export default Row;
