import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from 'components/Tooltip';
import { TPT_EASEL_BUYER_LANDING_URL } from 'shared/config/constants';
import './EaselTooltip.scss';

const EaselTooltip = ({
  className,
  header,
  body,
  image,
  altText,
  arrowPosition,
  onClose,
  showCloseIcon,
  showFreeToUseCopy,
  withCreamBackground
}) => {
  const bodyCopy = (
    <>
      {body}
      {showFreeToUseCopy && <strong>&nbsp;Easel by TpT is free to use!</strong>}
    </>
  );
  return (
    <Tooltip
      className={classnames('EaselTooltip', `EaselTooltip__${arrowPosition}`, className, {
        EaselTooltip__withImage: !!image,
        EaselTooltip__withCreamBackground: withCreamBackground
      })}
      image={image}
      altText={altText}
      header={header}
      bodyCopy={bodyCopy}
      arrowPosition={arrowPosition}
      ctaCopy="Learn More"
      ctaLinkTo={`${TPT_EASEL_BUYER_LANDING_URL}?utm_source=tpt-tooltip`}
      ctaLinkOpenNewTab
      secondaryCtaCopy={showCloseIcon ? 'Okay. Got it!' : null}
      shouldShowCloseIcon={showCloseIcon}
      onClose={onClose}
    />
  );
};

EaselTooltip.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  image: PropTypes.node,
  altText: PropTypes.string,
  arrowPosition: PropTypes.string,
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  showFreeToUseCopy: PropTypes.bool,
  withCreamBackground: PropTypes.bool
};

EaselTooltip.defaultProps = {
  arrowPosition: 'left',
  onClose: undefined,
  showCloseIcon: false,
  showFreeToUseCopy: true,
  withCreamBackground: false
};

export default EaselTooltip;
