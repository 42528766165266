import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from 'components/Text';
import textProps from 'components/Text/textProps';
import TpTEaselLogo from '../TpTEaselLogo';

import './TpTEaselBadge.scss';

const TpTEaselBadge = ({ showIcon, showNew, text, textVariant }) => (
  <Text
    component="span"
    variant={textVariant}
    strong
    inline
    className={classnames('TpTEaselBadge', { 'TpTEaselBadge--new': showNew })}
    data-testid="tpt-easel-badge"
  >
    <TpTEaselLogo
      showIcon={showIcon}
      textVariant={textVariant}
      className={text ? 'TpTEaselBadge__logo--withText' : null}
    />{' '}
    {text}
  </Text>
);

TpTEaselBadge.propTypes = {
  showIcon: PropTypes.bool,
  showNew: PropTypes.bool,
  text: PropTypes.string,
  textVariant: PropTypes.oneOf(textProps.variants)
};

TpTEaselBadge.defaultProps = {
  showIcon: false,
  showNew: false,
  text: null,
  textVariant: 'body'
};

export default TpTEaselBadge;
