import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import './Alert.scss';

class Alert extends Component {
  static propTypes = {
    children: PropTypes.node,
    notificationType: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    allowClose: PropTypes.bool,
    responsive: PropTypes.bool,
    isOpen: PropTypes.bool,
    iconLeft: PropTypes.string,
    onClose: PropTypes.func,
    role: PropTypes.string
  };

  static defaultProps = {
    allowClose: true,
    isOpen: true
  };

  componentWillMount() {
    this.setState({
      isOpen: this.props.isOpen
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isOpen: nextProps.isOpen
    });
  }

  defaultClassNames = () =>
    classnames({
      Alert: true,
      'Alert--error': this.props.notificationType === 'error',
      'Alert--warning': this.props.notificationType === 'warning',
      'Alert--info': this.props.notificationType === 'info',
      'Alert--success': this.props.notificationType === 'success',
      'Alert--responsive': this.props.responsive
    });

  closeNotification = () => {
    const { onClose } = this.props;
    typeof onClose === 'function' && onClose();
    this.setState({ isOpen: false });
  };

  render() {
    const { allowClose, children, iconLeft, role } = this.props;

    if (!this.state.isOpen) {
      return null;
    }

    return (
      <div className={this.defaultClassNames()} role={role || undefined}>
        {allowClose && (
          <div
            className="Alert__close"
            onClick={this.closeNotification}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                this.closeNotification();
              }
            }}
          >
            ×
          </div>
        )}
        <div className="Alert__content">
          {iconLeft && <img className="Alert__contentIcon" src={iconLeft} alt="" />}
          {children}
        </div>
      </div>
    );
  }
}

export default Alert;
