export const MY_PRODUCTS_STATE_KEY = 'myProductsState';

// Actions
export const ADD_TRANSACTIONAL_MESSAGE = '@@tpt/MyProducts/ADD_TRANSACTIONAL_MESSAGE';
export const REMOVE_TRANSACTIONAL_MESSAGE = '@@tpt/MyProducts/REMOVE_TRANSACTIONAL_MESSAGE';

// Reducer
export default function reducer(state = { transactionalMessage: '' }, action = {}) {
  if (action.type === ADD_TRANSACTIONAL_MESSAGE) {
    return { ...state, transactionalMessage: action.content };
  }
  if (action.type === REMOVE_TRANSACTIONAL_MESSAGE) {
    return { ...state, transactionalMessage: '' };
  }
  return state;
}

// Action creators
export function addTransactionalMessage({ content }) {
  return { type: ADD_TRANSACTIONAL_MESSAGE, content };
}
export function removeTransactionalMessage() {
  return { type: REMOVE_TRANSACTIONAL_MESSAGE };
}
