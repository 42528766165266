/**
 * Navigate to the given url
 * (pushes onto the history stack)
 *
 * @param { !string } url
 * @return { undefined }
 */
export function navigate(url) {
  if (!IS_KARMA) {
    window.location.href = url;
  }
}

/**
 * Replace the current url with the given url
 * (overrides the current history stack item)
 *
 * @param { !string } url
 * @return { undefined }
 */
export function replace(url) {
  if (!IS_KARMA) {
    window.location.replace(url);
  }
}

/**
 * Get url string that user will be redirected to
 * after authentication
 *
 * @param { string } backUrl
 * @param { boolean } cutOffHost
 * @return { string }
 */
function getBackUrlParam(backUrl, cutOffHost) {
  let backUrlParam = backUrl;
  if (backUrlParam === '/') {
    return '';
  }

  if (backUrlParam === '') {
    backUrlParam = window.location.href;
  }

  if (cutOffHost) {
    // eslint-disable-next-line no-useless-escape
    backUrlParam = backUrlParam.replace(/^.*\/\/[^\/]+/, '');
  }

  return `?f=${encodeURI(backUrlParam)}`;
}

/**
 * Get /Login url with backUrlParam
 * after authentication
 *
 * @param { string } backUrl
 * @param { boolean } cutOffHost
 * @return { string }
 */
export function loginUrlWithBackPath(backUrl = '', cutOffHost = true) {
  return `/Login${getBackUrlParam(backUrl, cutOffHost)}`;
}
