import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import Anchor from 'components/Anchor';
import { CLASSFUND_URL } from 'domains/Contribution/helpers';
import './ModalButtons.scss';

const URL = '/ClassFund/Create';

const ModalButtons = ({ handleClick, buttonLabel, showLearnMore, showFinePrint }) => (
  <div className="ModalButtons text-center">
    <div className="ModalButtons__create">
      <Button
        className="ModalButtons__createButton"
        onClick={() => {
          handleClick();
        }}
        variant="primary"
        href={URL}
        label={buttonLabel}
        forcereload
      />
    </div>
    {showLearnMore && (
      <div className="ModalButtons__learnMoreLink">
        <Anchor to={CLASSFUND_URL} forceReload onClick={handleClick}>
          Learn more
        </Anchor>
      </div>
    )}
    {showFinePrint && (
      <div className="ModalButtons__learnMoreLink">
        No purchase necessary. Open to U.S. residents age 18 and over. Enter before 11/21/2019 at
        11:59pm ET. Additional terms and conditions apply. See full Official Rules{' '}
        <span className="ModalButtons__link">
          <a
            href="https://docs.google.com/document/d/1L8FAqW2VPFQ_DJKOqkoZR8__7_-xH5PuUTD7McCEYts"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </span>
        .
      </div>
    )}
  </div>
);

ModalButtons.defaultProps = {
  handleClick: () => {},
  showLearnMore: true,
  showFinePrint: false,
  buttonLabel: 'Get started'
};

ModalButtons.propTypes = {
  handleClick: PropTypes.func,
  showLearnMore: PropTypes.bool,
  showFinePrint: PropTypes.bool,
  buttonLabel: PropTypes.string
};

export default ModalButtons;
