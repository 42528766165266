import React from 'react';
import PropTypes from 'prop-types';

import BuyerHeaderContainer from 'domains/Header/BuyerHeader/BuyerHeaderContainer';
import SellerHeaderContainer from 'domains/Header/SellerHeader/SellerHeaderContainer';
import LoggedOutHeaderLayout from 'domains/Header/LoggedOutHeader/LoggedOutHeaderLayout';
import { featureVariantsPropTypes } from 'config/prop-definitions';

const UserDetailsContainer = ({ isLoggedIn, isSeller, isResponsive, featureVariants }) => {
  if (isLoggedIn && !isSeller) {
    return <BuyerHeaderContainer isResponsive={isResponsive} featureVariants={featureVariants} />;
  }
  if (isLoggedIn && isSeller) {
    return <SellerHeaderContainer isResponsive={isResponsive} featureVariants={featureVariants} />;
  }
  return <LoggedOutHeaderLayout isResponsive={isResponsive} featureVariants={featureVariants} />;
};

UserDetailsContainer.propTypes = {
  isLoggedIn: PropTypes.bool,
  isSeller: PropTypes.bool,
  isResponsive: PropTypes.bool,
  featureVariants: featureVariantsPropTypes
};

UserDetailsContainer.defaulProps = {
  featureVariants: {}
};

export default UserDetailsContainer;
