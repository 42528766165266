import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import listensToClickOutside from 'react-onclickoutside';
import { isTouch } from 'util/utils';
import { KEY_TYPES } from 'config/constants';

import './Dropdown.scss';

@listensToClickOutside
class Dropdown extends Component {
  static propTypes = {
    /**
     * When true, the dropdown label will have a bubble style around it
     */
    bubbleLabel: PropTypes.bool,

    /**
     * Components to be nested inside the dropdown content
     */
    children: PropTypes.node,

    /**
     * For adding css styling.
     */
    className: PropTypes.string,

    /**
     * Class to be applied on dropdown wrapper
     */
    wrapperClassName: PropTypes.string,

    /**
     * When true, the dropdown will close once a user chooses a value from the selection.
     */
    closeOnSelect: PropTypes.bool,

    /**
     * Press ESC key to close the dropdown
     * Default: true
     */
    escEnabled: PropTypes.bool,

    /**
     * Boolean, defaults to true. If false, `.ignore-react-onclickoutside` class is not applied to
     * the dropdown's content. When nesting dropdowns inside of a dropdown, set this to false on the
     * parent dropdown.
     */
    ignoreOwnBody: PropTypes.bool,

    /**
     * When true, the dropdown is open.
     */
    isOpen: PropTypes.bool,

    /**
     * When true, make spacing adjustments to behave when used in new responsive grid
     */
    isResponsive: PropTypes.bool,

    /**
     * Dropdown label for ARIA labelBy
     */
    labelBy: PropTypes.string,

    /**
     * The default value displayed in the dropdown.
     */
    label: PropTypes.node,

    /**
     * The icon shown on the label. Defaults to angle-down.
     */
    icon: PropTypes.node,

    /**
     * A function called when the value selected changes.
     */
    onToggle: PropTypes.func,

    /**
     * When true, the dropdown will open/close onmouseenter/leave
     */
    openOnHover: PropTypes.bool,

    /**
     * [Optional] function called when clicking on the dropdown target (the ButtonBase)
     * Does NOT apply to touch devices, as they need to reserve the click for toggling
     */
    handleButtonClick: PropTypes.func,
    /**
     * When true, the dropdown will be disabled
     */
    disabled: PropTypes.bool,
    /**
     * [Optional] Render this node as the dropdown trigger instead of standard label
     */
    labelNode: PropTypes.node,
    /**
     * When true, only render contents if open
     */
    renderOnOpen: PropTypes.bool
  };

  constructor(props) {
    super(props);

    const { isOpen = false } = props;
    this.state = { isOpen };
    this.isTouch = isTouch();
  }

  componentDidMount() {
    if (this.props.escEnabled) {
      document.addEventListener('keydown', (e) => {
        e.keyCode === 27 && // ESC
          this.state.isOpen &&
          this.handleClickOutside();
      });
    }
  }

  componentWillReceiveProps({ isOpen }) {
    if (isOpen !== undefined && this.state.isOpen !== isOpen) {
      this.setState({ isOpen });
    }
  }

  handleClick = (e) => {
    e.stopPropagation();

    const { handleButtonClick } = this.props;

    if (handleButtonClick && !this.isTouch) {
      // touch devices should never override toggle fxnality
      handleButtonClick();
    } else {
      this.toggle();
    }
  };

  handleMouseEnter = () => {
    this.toggle(true);
  };

  handleMouseLeave = () => {
    this.toggle(false);
  };

  toggle(shouldOpen) {
    const { onToggle } = this.props;
    const { isOpen } = this.state;

    if (shouldOpen === undefined) {
      // eslint-disable-next-line no-param-reassign
      shouldOpen = !isOpen;
    } else if (shouldOpen === isOpen) {
      return;
    }

    this.setState({ isOpen: shouldOpen }, onToggle);
  }

  closeOnSelect = () => {
    this.toggle(false);
  };

  // Required for 'listensToClickOutside' decorator
  handleClickOutside = () => {
    this.toggle(false);
  };

  render() {
    const {
      bubbleLabel,
      className = 'Dropdown',
      wrapperClassName,
      children,
      closeOnSelect,
      openOnHover,
      ignoreOwnBody = true,
      isResponsive,
      disabled = false,
      renderOnOpen = false,
      icon,
      labelNode,
      labelBy
    } = this.props;
    const { isOpen } = this.state;

    const shouldOpenOnHover = openOnHover && !this.isTouch; // hover doesn't apply to touch screens

    const target = (
      <span
        className={classnames('Dropdown__button', 'Dropdown__target', `${className}__button`)}
        onClick={this.handleClick}
        onMouseEnter={shouldOpenOnHover ? this.handleMouseEnter : undefined}
        onKeyDown={(e) => {
          if (e.which === KEY_TYPES.ENTER || e.which === KEY_TYPES.SPACE) {
            this.handleClick(e);
          }
          if (e.which === KEY_TYPES.ESC) {
            this.closeOnSelect(e);
          }
        }}
        aria-label={labelBy || labelNode}
        aria-pressed={isOpen}
        tabIndex={0}
        role="button"
      >
        {!this.props.labelNode && (
          <div
            className={classnames(`${className}__buttonContent`, {
              [`${className}__buttonContent--responsive`]: isResponsive
            })}
          >
            <span className="label">{this.props.label}</span>
            <span className="Dropdown__icon">{icon || <Icon name="angle-down" />}</span>
          </div>
        )}

        {this.props.labelNode}
      </span>
    );

    return (
      <div
        onMouseLeave={shouldOpenOnHover ? this.handleMouseLeave : undefined}
        className={classnames(wrapperClassName, className, { isOpen, bubbleLabel, disabled })}
      >
        {target}
        {children && (
          <div
            className={classnames(`${className}__content`, { [`${className}--hidden`]: !isOpen })}
          >
            <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              onClick={closeOnSelect && this.closeOnSelect}
              className={classnames({ 'ignore-react-onclickoutside': ignoreOwnBody })}
            >
              {renderOnOpen ? isOpen && children : children}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Dropdown;
