import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { isEqual, omit } from 'lodash';
import analytics from 'lib/analytics';
import FeatureVariantsQuery from 'tpt_modules/features/queries/FeatureVariants.graphql';
import { featureVariantsPropTypes, featureVariantsGraphqlPropTypes } from 'config/prop-definitions';

export default function featureVariantsInfo(...names) {
  return (WrappedComponent) => {
    @graphql(FeatureVariantsQuery, {
      name: 'featureVariantData',
      options: {
        variables: {
          names: Array.isArray(names[0]) ? names[0] : names
        }
      }
    })
    class FeatureVariantComponent extends Component {
      static propTypes = {
        featureVariantData: featureVariantsGraphqlPropTypes,
        featureVariants: featureVariantsPropTypes
      };

      static defaultProps = {
        featureVariants: {}
      };

      componentWillMount() {
        this.updateFeatureVariantsIfNeeded(this.props);
        this.updateOwnPropsIfNeeded(this.props);
      }

      componentWillReceiveProps(nextProps) {
        this.didFeatureVariantsChange = this.updateFeatureVariantsIfNeeded(nextProps);
        this.didOwnPropsChange = this.updateOwnPropsIfNeeded(nextProps);
      }

      componentDidMount() {
        analytics.registerAbTests({
          featureVariants: { ...this.ownProps.featureVariants, ...this.featureVariants }
        });
      }

      shouldComponentUpdate() {
        return Boolean(this.didFeatureVariantsChange || this.didOwnPropsChange);
      }

      static WrappedComponent = WrappedComponent;

      updateFeatureVariantsIfNeeded({ featureVariantData: { featureVariants = [] } }) {
        const newFeatureVariants = featureVariants.reduce(
          (acc, { name, variant }) => ({
            ...acc,
            [name]: variant
          }),
          {}
        );

        if (isEqual(this.featureVariants, newFeatureVariants)) {
          return false;
        }

        this.featureVariants = newFeatureVariants;
        return true;
      }

      updateOwnPropsIfNeeded(props) {
        const newOwnProps = omit(props, 'featureVariantData');
        // TODO: should this use shallowEqual since the structure of the wrapped
        // component's props can be deeply nested?
        // FWIW, redux uses shallow comparison
        if (isEqual(this.ownProps, newOwnProps)) {
          return false;
        }

        this.ownProps = newOwnProps;
        return true;
      }

      render() {
        return (
          <WrappedComponent
            {...this.ownProps}
            // Pass down any featureVariants that were passed in from parent, in addition
            // to the component-specific ones retrieved by Apollo
            featureVariants={{ ...this.ownProps.featureVariants, ...this.featureVariants }}
          />
        );
      }
    }

    FeatureVariantComponent.displayName = `FeatureVariantComponent(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    return hoistNonReactStatics(FeatureVariantComponent, WrappedComponent);
  };
}
