import React from 'react';
import Badge from 'domains/UploadPage/Badge';
import Icon from 'components/Icon';
import Row from 'components/Grid/Row';

import './TeachForJusticeSuggestionLayout.scss';

const TeachForJusticeSuggestionLayout = () => (
  <Row className="TeachForJusticeSuggestionLayout">
    <div className="TeachForJusticeSuggestionLayout__text">
      <Badge label="NEW" />
      Explore <b>Teach for Justice resources</b>
    </div>
    <div className="TeachForJusticeSuggestionLayout__icon">
      <Icon name="external-link" />
    </div>
  </Row>
);

export default TeachForJusticeSuggestionLayout;
