import PropTypes from 'prop-types';
import React from 'react';
import './InboxMenuItemPartial.scss';

// used in both BuyerDropdownBespoke and SellerDropdownBespoke
const InboxMenuItemPartial = ({ countUnreadMessages }) => (
  <span className="InboxMenuItemPartial">
    Inbox
    {countUnreadMessages > 0 && (
      <span className="InboxMenuItemPartial__itemCount">{countUnreadMessages}</span>
    )}
  </span>
);

InboxMenuItemPartial.propTypes = {
  countUnreadMessages: PropTypes.number.isRequired
};

export default InboxMenuItemPartial;
