import { stringify } from 'query-string';
import { get } from 'lodash';

const SEARCH_SUGGESTIONS_SERVICE_URL =
  CONFIG.endpoints.apis.suggest || 'https://suggest-production.teacherspayteachers.com/suggestions';

/**
 * Fetches search suggestions from new suggestions service API.
 * @param {object} params - mapping of (string) => string query parameters
 * @param {string} [suggestUrl] - URL of API endpoint (optional)
 * @return {Promise<SearchSuggestResults>} search autocomplete results
 */
export function callSuggestionsService(params, suggestUrl = SEARCH_SUGGESTIONS_SERVICE_URL) {
  const urlParams = stringify(params);
  const resourceTypesLimit = 2;
  const sellersLimit = 2;

  // This is the global window.fetch instance (polyfilled in older browsers)
  return fetch(`${suggestUrl}?${urlParams}`, {
    credentials: 'same-origin'
  })
    .then((result) => result.json())
    .then((suggestions) => ({
      list: get(suggestions, 'searches', []).map((searchTerm) => searchTerm.searchTerms),
      resourcetypes: get(suggestions, 'resourceTypes', [])
        .slice(0, resourceTypesLimit)
        .reduce((acc, resourceType) => {
          acc[resourceType.id] = {
            name: resourceType.name,
            url: `/Browse/Type-of-Resource/${resourceType.url}`
          };
          return acc;
        }, {}),
      sellers: get(suggestions, 'sellers', [])
        .slice(0, sellersLimit)
        .reduce((acc, seller) => {
          acc[seller.sellerId] = {
            text: seller.sellerStoreName,
            thumb_url: seller.storeThumbUrl,
            // Attribute storeUrl is actually short store slug name, so we add Store/
            url: `/Store/${seller.storeUrl}`
          };
          return acc;
        }, {})
    }));
}
