import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './Grid.scss';

import Row from './Row';
import Col from './Col';

// NOTE: Usage of this component has significantly altered since we have no responsive strategy.
// Grid will always spit out a min-width: 1024px container, regardless of viewport width.
// Please be careful to min-width the parent container properly when setting a background color!

// A responsive, fluid grid system that appropriately scales up to 12 columns as
// the device size changes.
const Grid = (props) => {
  const { fluid, children, className, tagName = 'div' } = props;
  const containerClass = fluid ? 'container-fluid' : 'container';
  return React.createElement(
    tagName,
    {
      ...props,
      className: classNames(className, containerClass)
    },
    children
  );
};

Grid.propTypes = {
  /**
   * When true, the grid will resize along the window instead of hard responsive
   * breakpoints.
   */
  fluid: PropTypes.bool,
  /**
   * For adding css styling.
   */
  className: PropTypes.string,
  tagName: PropTypes.string,
  children: PropTypes.node
};

Grid.Row = Row;
Grid.Col = Col;

export default Grid;
