/* eslint-disable @miovision/disallow-date/no-new-date */
/* eslint-disable @miovision/disallow-date/no-static-date */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql, withApollo } from 'react-apollo';
import { get } from 'lodash';
import analytics from 'lib/analytics';
import {
  memberDataPropTypes,
  featureVariantsPropTypes,
  userProfilePropTypes,
  storePropTypes
} from 'config/prop-definitions';
import {
  isSeller as isSellerFn,
  isTpTAdmin,
  isPremiumSeller as isPremiumSellerFn,
  isBasicOrPremiumSeller as isBasicOrPremiumSellerFn
} from 'config/constants';
import { headerFeatures } from 'config/features';
import AdminToolbox from 'domains/Header/AdminToolbox/AdminToolbox';
import { shouldShowSkinnyHeaderFooter } from 'domains/Header/util';
import featureVariantsInfo from 'decorators/featureVariants';
import mySchoolInfoWithPendingInvitationQuery from 'tpt_modules/members/queries/MySchoolInfoWithPendingInvitation.graphql';
import withLocation from 'decorators/withLocation';
import SkinnyHeaderLayout from 'domains/Header/SkinnyHeaderLayout';
import HeaderLayout from 'domains/Header/HeaderLayout';
import { initSiftScience } from 'lib/initSiftScience';
import ResponsiveHeaderLayout from 'domains/Responsive/ResponsiveHeaderLayout';
import ResponsiveSkinnyHeaderLayout from 'domains/Responsive/ResponsiveSkinnyHeaderLayout';
import ContributionHeaderLayout from 'domains/Contribution/layouts/ContributionHeaderLayout';
import {
  shouldShowContributionHeader,
  shouldShowContributionHeaderCampaignEditor,
  shouldShowClassFundFeedInHeader
} from 'domains/Contribution/helpers';
import { isTeacherCreatedSchool } from 'lib/orgHelper';
import withUserData from 'decorators/withUserData';

/**
 * TODO: Move this to a global AuthenticatedUser query at ApplicationEntry.
 * Maps results from mySchoolInfoWithPendingInvitationQuery query to two useful props.
 * @param {object} object Data, loading from Apollo.
 * @return {object} Return props:
 *  - @property {boolean} isOrgMember True if the user is in an org.
 */
export function generateMySchoolPropsFromQuery({ data, loading }) {
  if (loading) {
    return {
      isOrgMember: false,
      isOrgAdmin: false,
      organizationJoinRequest: null,
      isOrgMemberInTCS: false
    };
  }
  const { member } = data;
  const organizationJoinRequest = get(member, 'organizationJoinRequests[0]', null);
  const noMemberData = member === undefined || member === null;
  if (noMemberData || member.organizations.organizations.length === 0) {
    return {
      isOrgMember: false,
      isOrgAdmin: false,
      organizationJoinRequest,
      isOrgMemberInTCS: false
    };
  }
  return {
    isOrgMember: true,
    isOrgAdmin: get(member, 'organizations.organizations[0].orgAdmin.isAdmin', false),
    organizationJoinRequest: null,
    isOrgMemberInTCS: isTeacherCreatedSchool(data)
  };
}

/**
 * Determines whether to show a time-limited seller notification
 * @param {boolean} isSeller - whether user is seller
 * @param {number} totalItems - total count of items listed by user
 * @param {object} dateRange - object with start and end timestamps representing time range
 *  during which notification should show.
 * @param {number}  dateRange.start - start time (UNIX timestamp w/ correct timezone for sale) ex. "2018-11-20T00:00:00-05:00"
 * @param {number}  dateRange.end - end time (UNIX timestamp w/ correct timezone for sale) ex. "2018-11-25T23:59:59-05:00"
 * @return {boolean} - whether seller notification should be rendered
 */
export function shouldRenderSellerOnlyNotification(isSeller, totalItems, dateRange) {
  const { start, end } = dateRange;
  const currentTime = Date.now();
  const startDate = new Date(start);
  const endDate = new Date(end);

  const isBtw = currentTime > startDate.getTime() && currentTime < endDate.getTime();
  return isSeller && totalItems > 0 && isBtw;
}

@withApollo
@featureVariantsInfo(headerFeatures)
@withLocation
@connect((state) => ({ config: state.config, cookies: get(state, 'serverState.cookies') }))
@withUserData({
  name: 'userDataResponse',
  props: ({ userDataResponse }) => {
    const member = userDataResponse.member || {};
    return {
      userData: member,
      userProfileData: member.userProfile || {},
      userStoreData: member.store || {}
    };
  }
})
@graphql(mySchoolInfoWithPendingInvitationQuery, {
  skip: (ownProps) => !ownProps.config || !ownProps.config.isLoggedIn,
  props: generateMySchoolPropsFromQuery
})
class HeaderContainer extends Component {
  static propTypes = {
    isHidden: PropTypes.bool,
    isOrgMember: PropTypes.bool,
    isOrgAdmin: PropTypes.bool,
    isOrgMemberInTCS: PropTypes.bool,
    organizationJoinRequest: PropTypes.shape({
      school: PropTypes.shape({
        name: PropTypes.string
      })
    }),
    config: PropTypes.shape({
      isLoggedIn: PropTypes.bool.isRequired,
      isMobile: PropTypes.bool,
      referer: PropTypes.string
    }).isRequired,
    userData: memberDataPropTypes,
    userProfileData: userProfilePropTypes,
    userStoreData: storePropTypes,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    featureVariants: featureVariantsPropTypes.isRequired,
    isResponsive: PropTypes.bool
  };

  static defaultProps = {
    isHidden: false,
    isResponsive: false,
    isOrgMember: false,
    isOrgAdmin: false,
    isOrgMemberInTCS: false,
    organizationJoinRequest: null,
    userData: {},
    userProfileData: {},
    userStoreData: {}
  };

  componentDidMount() {
    const {
      userData,
      config: { isLoggedIn },
      isOrgMember,
      isOrgAdmin,
      isOrgMemberInTCS
    } = this.props;
    analytics.registerAbTests(this.props);

    const { id: userId, groupId: userGroupId, impersonatingAdminId, isTestUser } = userData;

    initSiftScience({
      adminImpersonation: Boolean(impersonatingAdminId),
      testUser: isTestUser,
      userId,
      userGroupId
    });

    if (window.heap) {
      const anonId = analytics.getAnonId();
      if (userId) {
        window.heap.identify(userId);
      }
      if (anonId) {
        window.heap.addEventProperties({
          tAnonId: anonId,
          isLoggedIn: !!isLoggedIn
        });
      }
    }

    // Send user data to Optimizely.
    let orgMembership = 'none';
    if (isOrgMember) {
      orgMembership = 'teacher';
    }
    if (isOrgMemberInTCS) {
      orgMembership = 'teacher_tcs';
    }
    if (isOrgAdmin) {
      orgMembership = 'admin';
    }
    window.optimizely = window.optimizely || [];
    window.optimizely.push({
      type: 'user',
      attributes: {
        isLoggedIn: String(isLoggedIn),
        groupId: String(userGroupId),
        orgMembership
      }
    });
  }

  render() {
    const {
      isHidden,
      config: { isLoggedIn, isMobile, referer },
      userData,
      userProfileData,
      userStoreData,
      location,
      location: { pathname },
      featureVariants,
      isResponsive
    } = this.props;

    const shouldRenderSkinnyHeader = shouldShowSkinnyHeaderFooter(location);

    const shouldRenderContributionHeader = shouldShowContributionHeader(location);
    const shouldRenderClassFundFeed =
      shouldShowClassFundFeedInHeader(location) &&
      (featureVariants.classfund_social_feed_method === 'timestamps' ||
        featureVariants.classfund_social_feed_method === 'no_timestamps');

    // Add a check so global error is displayed above the preview pane
    const shouldHideGlobalError = shouldShowContributionHeaderCampaignEditor(location);

    const isSeller = isSellerFn(userData.groupId);
    const isPremiumSeller = isPremiumSellerFn(userData.groupId);
    const isBasicOrPremiumSeller = isBasicOrPremiumSellerFn(userData.groupId);
    const { schoolsCatalogOptOuts = [] } = userStoreData;
    const hasOptedOutOfSchools2020Catalog = schoolsCatalogOptOuts.some(
      (catalogInfo) => catalogInfo.catalogId === 'schools2020'
    );

    const isDuringPreSale = shouldRenderSellerOnlyNotification(
      isSeller,
      userStoreData.totalItems,
      CONFIG.sitewideSale.preSaleNotification
    );

    const shouldShowAdminLoggedInAsAlert = Boolean(userData.impersonatingAdminId);
    const shouldShowAdminToolbox = isTpTAdmin(userData.groupId) || shouldShowAdminLoggedInAsAlert;
    const adminToolboxComponent = shouldShowAdminToolbox && (
      <AdminToolbox
        shouldShowAdminLoggedInAsAlert={shouldShowAdminLoggedInAsAlert}
        displayName={userProfileData.displayName}
      />
    );

    if (shouldRenderContributionHeader) {
      return (
        <ContributionHeaderLayout
          pathname={pathname}
          adminToolboxComponent={adminToolboxComponent}
          isHidden={isHidden}
        />
      );
    }

    if (shouldRenderSkinnyHeader && isResponsive) {
      return (
        <ResponsiveSkinnyHeaderLayout
          isLoggedIn={isLoggedIn}
          isHidden={isHidden}
          location={location}
          pathname={pathname}
          featureVariants={featureVariants}
        />
      );
    }

    if (shouldRenderSkinnyHeader) {
      return (
        <SkinnyHeaderLayout
          showLogIn={!isLoggedIn}
          adminToolboxComponent={adminToolboxComponent}
          isHidden={isHidden}
          pathname={pathname}
          featureVariants={featureVariants}
        />
      );
    }

    if (isResponsive) {
      return (
        <ResponsiveHeaderLayout
          isHidden={isHidden}
          isSeller={isSeller}
          isPremiumSeller={isPremiumSeller}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          adminToolboxComponent={adminToolboxComponent}
          pathname={pathname}
          featureVariants={featureVariants}
          shouldRenderClassFundFeed={shouldRenderClassFundFeed}
          shouldShowPreSaleNotification={isDuringPreSale}
          shouldHideGlobalError={shouldHideGlobalError}
        />
      );
    }

    return (
      <HeaderLayout
        adminToolboxComponent={adminToolboxComponent}
        featureVariants={featureVariants}
        isHidden={isHidden}
        isSeller={isSeller}
        isPremiumSeller={isPremiumSeller}
        isBasicOrPremiumSeller={isBasicOrPremiumSeller}
        isLoggedIn={isLoggedIn}
        pathname={pathname}
        referer={referer}
        shouldShowPreSaleNotification={isDuringPreSale}
        hasOptedOutOfSchools2020Catalog={hasOptedOutOfSchools2020Catalog}
      />
    );
  }
}

export default HeaderContainer;
