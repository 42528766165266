import { capitalize, get } from 'lodash';

/**
 * A file of string constants
 */

export const ITEM_TYPE_DIGITAL_PRODUCT = 'DIGITAL_PRODUCT';
export const ITEM_TYPE_GIFT_CERTIFICATE = 'GIFT_CERTIFICATE';
export const ITEM_TYPE_HARD_GOOD = 'HARD_GOOD';
export const ITEM_TYPE_UNKNOWN = 'UNKNOWN';
export const ITEM_TYPE_USED_GOOD = 'USED_GOOD';
export const ITEM_TYPE_VIDEO = 'VIDEO';
export const ITEM_TYPE_BUNDLE = 'BUNDLE';
export const ITEM_TYPE_SCHOOL_FUNDS = 'SCHOOL_FUNDS';
export const ITEM_TYPE_ONLINE_RESOURCE = 'ONLINE_RESOURCE';

export const PRODUCT_STATUS_ACTIVE = 'ACTIVE';
export const PRODUCT_STATUS_NOT_ACTIVE = 'NOT_ACTIVE';

export const PRODUCT_STATUS_ADMIN_TYPE_ID_INAPPROPRIATE_CONTENT = '1';

export const TPTDA_TUTORIAL_RESOURCE_ID = '5903492';

export const itemTypes = [
  ITEM_TYPE_DIGITAL_PRODUCT,
  ITEM_TYPE_GIFT_CERTIFICATE,
  ITEM_TYPE_HARD_GOOD,
  ITEM_TYPE_UNKNOWN,
  ITEM_TYPE_USED_GOOD,
  ITEM_TYPE_VIDEO,
  ITEM_TYPE_BUNDLE,
  ITEM_TYPE_ONLINE_RESOURCE
];

export const itemTypeToIntMap = {
  [ITEM_TYPE_DIGITAL_PRODUCT]: 0,
  [ITEM_TYPE_HARD_GOOD]: 1,
  [ITEM_TYPE_USED_GOOD]: 2,
  [ITEM_TYPE_GIFT_CERTIFICATE]: 3,
  [ITEM_TYPE_VIDEO]: 4,
  [ITEM_TYPE_BUNDLE]: 5,
  [ITEM_TYPE_ONLINE_RESOURCE]: 6
};

export const itemTypeFn = (iType) =>
  itemTypes.reduce(
    (agg, type) => ({
      ...agg,
      [`is${type.split('_').map(capitalize).join('')}`]: iType === type
    }),
    { type: iType }
  );

export const getBundled = (bundle) => ({
  bundledProductTypes: get(bundle, 'includedItemTypes', []),
  bundledOnlineResourceTypes: get(bundle, 'includedOnlineResourceTypes', []),
  containsDigitalActivity: get(bundle, 'digitalActivities.totalAvailableForDigital', 0) > 0,
  containsSellerCreatedDigital: get(bundle, 'digitalActivities.totalSellerCreatedDigital', 0) > 0
});

export const RESOURCE_TYPE_GOOGLE_APPS = 'Google Apps';
export const RESOURCE_TYPE_INTERNET_ACTIVITIES = 'Internet Activities';
export const RESOURCE_TYPE_MICROSOFT_ONEDRIVE = 'Microsoft OneDrive';
export const RESOURCE_TYPE_WEBQUESTS = 'Webquests';

export const DIGITAL_RESOURCE_TYPES = [
  RESOURCE_TYPE_WEBQUESTS,
  RESOURCE_TYPE_GOOGLE_APPS,
  RESOURCE_TYPE_INTERNET_ACTIVITIES,
  RESOURCE_TYPE_MICROSOFT_ONEDRIVE
];

export const formTypeFn = (itemId, itemType) => ({
  isNewProduct: !itemId,
  isEdit: !!itemId && itemType === ITEM_TYPE_BUNDLE,
  isMigrate: !!itemId && !(itemType === ITEM_TYPE_BUNDLE)
});

export const getUploadFormType = (
  itemId,
  itemType,
  itemIdForDuplication,
  digitalToBundleMigrate,
  digitalToVideoMigrate
) => ({
  isNewProduct: !itemId && !itemIdForDuplication,
  isSimilarProduct: !itemId && itemIdForDuplication,
  isEdit: !!itemId && !digitalToBundleMigrate && !digitalToVideoMigrate,
  isMigrateToVideo: !!itemId && !!digitalToVideoMigrate,
  isMigrateToBundle: !!itemId && !!digitalToBundleMigrate,
  itemId: itemId || itemIdForDuplication
});

export const KEY_TYPES = {
  COMMA: 188,
  ENTER: 13,
  SHIFT: 16,
  SPACE: 32,
  TAB: 9,
  ESC: 27,
  LEFTARROW: 37,
  UPARROW: 38,
  RIGHTARROW: 39,
  DOWNARROW: 40
};

export const BUYER_GROUP = 2;
export const BASIC_SELLER_GROUP = 3;
export const PREMIUM_SELLER_GROUP = 4;

export const USER_TYPES = {
  BUYER: 'buyer',
  BASIC_SELLER: 'seller basic',
  PREMIUM_SELLER: 'seller premium',
  PUBLISHER: 'seller publisher',
  TECHNOQUEST: 'seller technoquest',
  ADMIN: 'admin'
};

/**
 * List of user types that should have access to "premium seller" features
 */
export const CAN_ACCESS_PREMIUM_SELLER_FEATURES = [USER_TYPES.PREMIUM_SELLER, USER_TYPES.ADMIN];

/* Separate isSeller function bascause publishers
are also sellers which is somewhat non-intuitive
group type 5 is other publishers and can login group
6 is technoquest which can sell things but currently
can not actually login */
export const isSeller = (groupId) => {
  const sellerTypes = [3, 4, 5, 6];
  return sellerTypes.indexOf(groupId) > -1;
};

export const isBasicOrPremiumSeller = (groupId) => {
  const sellerTypes = [BASIC_SELLER_GROUP, PREMIUM_SELLER_GROUP];
  return sellerTypes.includes(groupId);
};

export const isPremiumSeller = (groupId) => groupId === PREMIUM_SELLER_GROUP;

/* A function to map from the 28 group_ids
in our database to the corresponding user type */
export const getUserType = (groupId) => {
  const groupIdsByUserType = {
    1: USER_TYPES.ADMIN,
    2: USER_TYPES.BUYER,
    3: USER_TYPES.BASIC_SELLER,
    4: USER_TYPES.PREMIUM_SELLER,
    5: USER_TYPES.PUBLISHER,
    6: USER_TYPES.TECHNOQUEST,
    7: USER_TYPES.ADMIN,
    8: USER_TYPES.ADMIN,
    9: USER_TYPES.ADMIN,
    10: USER_TYPES.ADMIN,
    11: USER_TYPES.ADMIN,
    12: USER_TYPES.ADMIN,
    13: USER_TYPES.ADMIN,
    14: USER_TYPES.ADMIN,
    15: USER_TYPES.ADMIN,
    16: USER_TYPES.ADMIN,
    17: USER_TYPES.ADMIN,
    18: USER_TYPES.ADMIN,
    19: USER_TYPES.ADMIN,
    20: USER_TYPES.ADMIN,
    21: USER_TYPES.ADMIN,
    22: USER_TYPES.ADMIN,
    23: USER_TYPES.ADMIN,
    24: USER_TYPES.ADMIN,
    25: USER_TYPES.ADMIN,
    26: USER_TYPES.ADMIN,
    27: USER_TYPES.ADMIN,
    28: USER_TYPES.ADMIN
  };
  return groupIdsByUserType[groupId];
};

export function isTpTAdmin(groupId) {
  return groupId && getUserType(groupId) === USER_TYPES.ADMIN;
}

export const VIDEO_STATUSES = {
  PROCESS_NEW: 0,
  PROCESS_S3: 1,
  PROCESS_VIDEO_PROVIDER: 2,
  PROCESS_DONE: 3,
  PROCESS_VIDEO_PROVIDER_FAIL: 4,
  PROCESS_S3_FAIL: 5,
  PROCESS_S3_DONE: 6,
  PROCESS_PENDING_MAIN_VIDEO: 7,
  LOST_VIDEO_PROVIDER: 8,
  DISABLED: 9
};

export function isVideoProcessed(video) {
  return video && video.status === VIDEO_STATUSES.PROCESS_DONE;
}

export function isVideoDisabledByUser(video) {
  return video && video.status === VIDEO_STATUSES.DISABLED;
}

export const ANONYMOUS_COOKIE_ID = 'ajs_anonymous_id';

/**
 * License Types
 */

export const LICENSE_TYPE_NON_TRANSFERABLE = 'NON_TRANSFERABLE_LICENSE';
export const LICENSE_TYPE_TRANSFERABLE = 'TRANSFERABLE_LICENSE';

// Mapping of License Types to their corresponding price object keys on the API side
export const LICENSE_TYPE_API_FIELDS = {
  [LICENSE_TYPE_NON_TRANSFERABLE]: 'nonTransferableLicense',
  [LICENSE_TYPE_TRANSFERABLE]: 'transferableLicense'
};

export const BUNDLE_CHILDREN_PER_PAGE = 5;

// TODO: to be consolidated with design token work
export const ANIMATION_SPEED_SLOW = 900;
export const ANIMATION_SPEED_FAST = 500;
export const ANIMATION_EASING_OUT = 'cubic-bezier(0.4, 0, 0.2, 1)';
export const BREAKPOINTS = {
  xs: 320,
  sm: 580,
  md: 768,
  lg: 980,
  xl: 1200
};

// Max characters for standard description on tooltip
export const MAX_CHAR_STANDARD_DESCRIPTION = 300;

export const STANDARD_COLLAPSE_COUNT = 4;

// Digital facet URLs
export const DIGITAL_FACET_URL_GOOGLE_DRIVE = '/Usage/Google-Drive';
export const DIGITAL_FACET_URL_DIGITAL_TAGGED = '/Usage/Digital-Tagged';
export const DIGITAL_FACET_URL_ALL_DIGITAL = '/Usage/Digital';

export const HEAP_EVENTS = Object.freeze({
  VIEW__TPTDA_ENABLED_RECOMMENDATIONS_CAROUSEL: 'View - TpTDA Enabled Recommendations Carousel',
  VIEW__EASEL_CREATED_PROTO_SEARCH_CAROUSEL: 'View - Explore easel free resources'
});
export const HEAP_CATEGORIES = Object.freeze({
  HOMEPAGE: 'Homepage'
});

export const HAS_VIEWED_EASEL_PROMOS_COOKIE_KEY = 'hasViewedEaselPromo';
export const HAS_VIEWED_EASEL_PROMOS_COOKIE_EXPIRATION = '2021-12-31';

export const DISABLE_EASAL_MODAL_COOKIE_KEY = 'disable-tpt-easel-marketing-banner-carousel';
export const DISABLE_EASAL_MODAL_COOKIE_EXPIRATION = '2021-12-31';

export const TPT_EASEL_BUYER_LANDING_URL = CONFIG.endpoints.apis.easelByTpt;
export const TPT_EASEL_HOME_URL = CONFIG.endpoints.apis.easelFrontend;
export const EASEL_ACTIVITIES_URL = `${CONFIG.endpoints.apis.easelFrontend}/activities`;
export const EASEL_RESOURCES_URL = `${CONFIG.endpoints.apis.easelFrontend}/resources`;
export const EASEL_LISTINGS_ASSESSMENTS_URL = `${CONFIG.endpoints.apis.easelFrontend}/listings/assessments`;
export const EASEL_ASSESSMENTS_URL = `${CONFIG.endpoints.apis.easelFrontend}/assessments`;
export const EASEL_VIDEO_DEMO = 'https://www.youtube.com/watch?v=zMWOF3qHWsg';

export const HARDCODED_FREE_EASEL_RESOURCES = [
  '2570940',
  '3686688',
  '6682179',
  '3062300',
  '6276737',
  '4597695',
  '5580217',
  '6260747',
  '5656877',
  '2603330',
  '5336008'
];

export const LOW_SEARCH_RESULTS_THRESHOLD = 10;

export const EASEL_ACTIVITY_TYPENAME = 'DigitalActivityResourceAsset';
export const EASEL_ASSESSMENT_TYPENAME = 'EaselAssessmentResourceAsset';

export const MAX_PRODUCT_PRICE = 10000;
