import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { graphql, withApollo } from 'react-apollo';
import { compose } from 'redux';
import Anchor from 'components/Anchor';
import TrackedComponent from 'components/TrackedComponent/TrackedComponent';
import { generateMySchoolPropsFromQuery } from 'domains/Header/HeaderContainer';
import mySchoolInfoWithPendingInvitationQuery from 'tpt_modules/members/queries/MySchoolInfoWithPendingInvitation.graphql';
import { isFeatureFlagEnabled } from 'lib/features';
import { OrganizationInvitationType } from './proptypes';

function HeaderSchoolsLinkContainer({
  isOrgMember,
  pendingOrganizationInvitation,
  client,
  itemClassName
}) {
  const shouldShowMySchool = isOrgMember;
  let mySchoolLink = '/My-School';
  let trackingAction = 'Clicked My School';
  if (pendingOrganizationInvitation) {
    const uuid = get(pendingOrganizationInvitation, 'organization.uuid');
    const invitationHash = get(pendingOrganizationInvitation, 'invitationHash');
    mySchoolLink = `/School/Teacher-Intro/v2/${uuid}?invitationHash=${invitationHash}`;
    trackingAction = 'Clicked My School For Onboarding';
  }

  const schoolLink = isFeatureFlagEnabled(client, 'schools_access_header_links') && (
    // Tracked through GTM.
    <li className={itemClassName}>
      <Anchor color="gray" forceReload to="/TpTSchoolAccess/Teachers">
        TpT School Access
      </Anchor>
    </li>
  );

  return (
    <Fragment>
      {shouldShowMySchool || pendingOrganizationInvitation ? (
        <li className={itemClassName}>
          <TrackedComponent category="Schools" action={trackingAction}>
            <Anchor color="green" forceReload to={mySchoolLink}>
              <b>My School</b>
            </Anchor>
          </TrackedComponent>
        </li>
      ) : (
        schoolLink
      )}
    </Fragment>
  );
}

HeaderSchoolsLinkContainer.propTypes = {
  isOrgMember: PropTypes.bool.isRequired,
  pendingOrganizationInvitation: OrganizationInvitationType,
  client: PropTypes.object.isRequired,
  itemClassName: PropTypes.string
};

export default compose(
  graphql(mySchoolInfoWithPendingInvitationQuery, {
    options: { fetchPolicy: 'cache-only' },
    props: generateMySchoolPropsFromQuery
  }),
  withApollo
)(HeaderSchoolsLinkContainer);
