import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import emojiRegex from 'emoji-regex';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import TeachForJusticeSuggestionLayout from 'domains/Home/layouts/TeachForJusticeSuggestionLayout';
import analytics from 'lib/analytics';
import reportError from 'lib/reportError';
import { navigate } from 'util/utils/location';
import withLocation from 'decorators/withLocation';
import { DIGITAL_FACET_URL_ALL_DIGITAL } from 'config/constants';
import { callSuggestionsService } from './fetchSuggestions';
import SearchAutosuggest from './SearchAutosuggest';

export const isNoSuggestionFound = (suggestionsToDisplay) =>
  suggestionsToDisplay.every(({ category }) => category === undefined);

const hiddenSuggestionCategories = {
  DISTANCE_LEARNING_TIP: 'tip',
  DIGITAL_TIP: 'digitalTip',
  TEACH_FOR_JUSTICE: 'teachForJustice'
};

const allEmojiRgx = emojiRegex();
const notSupportedEmojiRgx = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
const teachForJusticeURL = 'https://www.teacherspayteachers.com/Teach-For-Justice-Resources';

class HeaderSearch extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    referer: PropTypes.string,
    featureVariants: featureVariantsPropTypes,
    isMobile: PropTypes.bool
  };

  static defaultProps = {
    featureVariants: {},
    isMobile: false
  };

  constructor(props) {
    super(props);

    this.canSearch = true; // onSuggestionSelected leverages this to disable handleKeyUp redirects

    this.suggestionsMemo = {}; // memo to store fetched suggestions
    // bootstrap this.debouncedFetchSuggestions.
    // this function fires every time the autosuggest needs to retrieve suggestions from the API.
    this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, 50);

    // HACK: this.defaultValue is used instead of this.state.value to populate the input
    const defaultValue = this.setDefaultValue(props.location.pathname, props.referer);

    this.state = {
      value: defaultValue, // value of the autosuggest input
      suggestions: [], // suggestions to be displayed when the input is focused
      defaultValue,
      key: 0 // Updating the key will force a remount of the input box
    };
  }

  componentDidMount() {
    if (!IS_TEST && !IS_KARMA) {
      this.debouncedFetchSuggestions({ value: this.state.value });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const newDefaultValue = this.setDefaultValue(nextProps.location.pathname, nextProps.referer);

      this.setState({
        value: newDefaultValue,
        defaultValue: newDefaultValue,
        key: this.state.key + 1 // Force remount of input box
      });
    }
  }

  setDefaultValue(pathname, referer) {
    let defaultValue = this.computeValueFromURL(pathname);

    if (!defaultValue) {
      defaultValue = this.computeValueFromReferer(pathname, referer);
    }

    return defaultValue;
  }

  getPlaceholder = (abTestVariant) => {
    if (abTestVariant === 'search_copy_1') {
      return 'Search for resources';
    }
    if (abTestVariant === 'search_copy_2') {
      return 'What are you looking for?';
    }
    return 'SEARCH';
  };

  computeValueFromReferer(pathname, referer) {
    if (
      !(
        referer &&
        referer.includes('/Search:') &&
        referer.includes('/Browse/') &&
        pathname.includes('/Product/')
      )
    ) {
      return '';
    }

    return decodeURIComponent(
      referer
        .split('/')
        .find((param) => param.includes('Search:'))
        .split(':')[1]
    );
  }

  computeValueFromURL(pathname) {
    // returns the search query located at the end of the current URL
    // TODO: move routing logic to `routes.jsx` when we have a react page for `/Browse/Search`
    const regex = /^\/Browse.*\/Search:(.+?)($|\/|\?)/i;

    const [, searchTermURL = ''] = pathname.match(regex) || [];

    const encodedSearchQuery = searchTermURL.replace(/[+]/g, '%20'); // well, this is weird.
    // For some reason, the PHP code relies on two different types of URL encoding.
    // Normally, it uses the standard, but when you sort a search, it likes to replace the
    // convention of spaces being '%20' for '+', because reasons, so now we need to adjust for that.

    return decodeURIComponent(encodedSearchQuery);
  }

  fetchSuggestions = async ({ value }) => {
    let suggestionsToDisplay;
    if (!this.suggestionsMemo[value]) {
      let response;
      try {
        response = await callSuggestionsService({
          query: value,
          includeSearches: 1,
          includeResourceTypes: value.length > 0 ? 1 : 0,
          includeSellers: value.length > 0 ? 1 : 0
        });
      } catch (e) {
        reportError(e, 'Error fetching suggestions');
        response = {};
      }
      const { list = [], sellers = [], resourcetypes = [] } = response;

      // format data; SearchAutosuggest depends on this data being in this format.
      // NOTE: if changing this data structure, change SearchAutosuggest accordingly.
      const formattedSuggestions = [
        {
          category: 'Suggestions',
          children: list.map((suggestion) => ({
            text: suggestion
          }))
        },
        {
          category: 'Sellers',
          children: Object.values(sellers).map((seller) => ({
            text: seller.text,
            thumbUrl: seller.thumb_url,
            url: seller.url
          }))
        },
        {
          category: 'Resources',
          children: Object.values(resourcetypes).map((resourcetype) => ({
            text: resourcetype.name,
            url: resourcetype.url
          }))
        }
      ].filter((category) => category.children.length); // filter out childless categories

      suggestionsToDisplay = this.addSuggestionEdgeCases(formattedSuggestions);

      this.suggestionsMemo[value] = suggestionsToDisplay;
    } else {
      // this value is memoized, so take the shortcut
      suggestionsToDisplay = this.suggestionsMemo[value];
    }

    this.setState({ suggestions: this.appendSuggestionsTip(suggestionsToDisplay) });
  };

  appendSuggestionsTip = (suggestions) => {
    const {
      featureVariants: {
        digital_search_suggestions_tip: digitalSuggestionsTip,
        digital_filter_location: digitalFilterLocation,
        digital_filter_facet: digitalFilterFacet,
        tfj_suggest: tfjSuggest
      }
    } = this.props;
    const { value } = this.state;

    if (tfjSuggest === 'on') {
      return this.addTeachForJusticeSuggest(suggestions);
    }

    if (
      digitalFilterFacet !== 'off' &&
      digitalFilterLocation === 'top' &&
      digitalSuggestionsTip === 'on'
    ) {
      if (this.skipDigitalResourcesTip()) {
        return suggestions;
      }
      return this.appendDigitalResourcesTip(suggestions);
    }
    if (value.length && this.skipDistanceLearningTip(value)) {
      return suggestions;
    }
    return this.appendDistanceLearningTip(suggestions, value);
  };

  // this function appends the `distance learning` tip as a first item to the suggestion list
  // it was added according to the covid-19 response project
  appendDistanceLearningTip(suggestions) {
    const { isMobile } = this.props;
    const { value } = this.state;

    const searchTermToAppend = 'distance learning';

    return [
      {
        hiddenCategory: hiddenSuggestionCategories.DISTANCE_LEARNING_TIP,
        children: [
          {
            displayNode: isMobile ? (
              <span>
                Add <b>{searchTermToAppend}</b> to your search
              </span>
            ) : (
              <span>
                Add <b>{searchTermToAppend}</b> to your search to find materials that support remote
                learning
              </span>
            ),
            text: value.length ? `${value} ${searchTermToAppend}` : searchTermToAppend
          }
        ]
      },
      ...suggestions
    ];
  }

  // this function appends the `digital resources` tip as a first item to the suggestion list
  appendDigitalResourcesTip(suggestions) {
    const { isMobile } = this.props;
    const { value } = this.state;

    const searchTermToAppend = 'digital resources';

    return [
      {
        hiddenCategory: hiddenSuggestionCategories.DIGITAL_TIP,
        children: [
          {
            displayNode: isMobile ? (
              <span>
                Add <b>{searchTermToAppend}</b> to your search
              </span>
            ) : (
              <span>
                Show me{' '}
                <b>
                  {searchTermToAppend}
                  {value.length ? ` for "${value}"` : ``}
                </b>
              </span>
            ),
            text: value,
            url: DIGITAL_FACET_URL_ALL_DIGITAL
          }
        ]
      },
      ...suggestions
    ];
  }

  addTeachForJusticeSuggest(suggestions) {
    const { value } = this.state;

    return [
      {
        hiddenCategory: hiddenSuggestionCategories.TEACH_FOR_JUSTICE,
        children: [
          {
            displayNode: <TeachForJusticeSuggestionLayout />,
            text: value,
            url: teachForJusticeURL
          }
        ]
      },
      ...suggestions
    ];
  }

  // returns true if search term contains some defined word
  // or if search term ends with some defined terms
  skipDistanceLearningTip(searchTerm) {
    const words = ['home', 'remote', 'distance'];
    const substringLength = 3;
    // expand words to ['rem', 'remo', 'remot', 'remote', ...]
    const expandedWords = words.reduce((acc, word) => {
      const slicesOfTheWord = [];
      for (let i = substringLength; i < word.length + 1; i++) {
        slicesOfTheWord.push(word.slice(0, i));
      }
      return [...slicesOfTheWord, ...acc];
    }, []);

    const someWordsIncluded = words.some((word) =>
      searchTerm.split(' ').some((termWord) => termWord === word)
    );
    const searchEndsOnSomeExpandedWord = expandedWords.some((expandedWord) => {
      const searchTermEnd = searchTerm.slice(-1 * expandedWord.length);
      return searchTermEnd === expandedWord;
    });
    return someWordsIncluded || searchEndsOnSomeExpandedWord;
  }

  // returns true if the digital filter is already applied
  skipDigitalResourcesTip() {
    const {
      location: { pathname }
    } = this.props;

    return pathname.includes(DIGITAL_FACET_URL_ALL_DIGITAL);
  }

  addSuggestionEdgeCases(formattedSuggestions) {
    // creates a new object based on the suggestions model that includes edge cases
    const suggestionsToDisplay = [...formattedSuggestions];

    if (this.state.value.length) {
      // if there is an input value, add a suggestion to see all results for that value
      suggestionsToDisplay.push({
        children: [
          {
            displayNode: (
              <span>
                See all results for <b>{this.state.value}</b>
              </span>
            ),
            text: this.state.value // value to be placed in the input when this is selected
          }
        ]
      });
    }

    if (this.state.value === '' && suggestionsToDisplay.length) {
      // if the input value is empty, relabel the suggestion category as "Popular Now"
      suggestionsToDisplay[0].category = 'Popular Now';
    }

    return suggestionsToDisplay;
  }

  clearSuggestions = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    // fires when autosuggest changes the input value by typing, selecting suggestions, pasting, etc
    this.setState({
      value: newValue.replace(allEmojiRgx, '').replace(notSupportedEmojiRgx, '')
    });
  };

  handleSuggestionSelected = (event, { suggestion, suggestionValue, sectionIndex }) => {
    // fires whenever a suggestion is 'click'ed or 'enter'ed
    if (!suggestion) {
      return;
    }
    // the rendered input does not update properly, so we update it directly with a ref
    this.inputRef.value = suggestionValue;

    // disable future search requests so `handleKeyUp` and `onSuggestionSelected` don't race
    this.canSearch = false;

    // get the category of the selected suggestion by looking it up in the state
    const { category, hiddenCategory } = this.state.suggestions[sectionIndex];
    const suggestionCategory = hiddenCategory || category;

    this.trackSelectedSuggestion(suggestionValue, suggestionCategory); // fire the analytics event

    if (suggestionCategory === hiddenSuggestionCategories.DIGITAL_TIP) {
      if (!suggestion.text) {
        navigate(`/Browse${suggestion.url}`);
      }
      this.search(suggestion.text, suggestion.url);
    } else if (suggestion.url) {
      navigate(suggestion.url);
    } else {
      this.search(suggestion.text);
    }
  };

  trackSelectedSuggestion(suggestionText, suggestionCategory) {
    // when a suggestion is selected, fire the appropriate tracking event
    const actionMap = {
      // map suggestionCategories to eventAct values
      'Popular Now': 'Popular Now Click',
      Sellers: 'Seller Click',
      Resources: 'Resource Type Click',
      Suggestions: 'Suggestion Click',
      Tip: 'Tip Click'
    };

    const eventOptions = {
      verticalScrollPositionCD: window.pageYOffset
    };

    const eventObj = {
      eventCat: 'Site Search',
      eventAct: actionMap[suggestionCategory] || 'See All Click',
      eventLbl: suggestionText,
      eventOptions
    };

    analytics.trackEvent(eventObj);
  }

  search(query, additionalFilterPath = '') {
    const {
      location: { pathname }
    } = this.props;
    const stickyFilterNames = new Set([
      'PreK-12-Subject-Area',
      'Grade-Level',
      'Core-Domain',
      'Usage',
      'Core-Standard',
      'Price-Range',
      'Format'
    ]);

    const typeOfResourceStickyNames = new Set(['Google-Apps', 'Internet-Activities', 'Webquests']);

    const tempPath = pathname + additionalFilterPath;

    if (query) {
      const searchType = '/Browse';

      const urlChunks = tempPath.split('/');
      const stickyFilters = urlChunks.reduce((chunks, filterName, index) => {
        const filterValue = urlChunks[index + 1];
        if (
          stickyFilterNames.has(filterName) ||
          (filterName === 'Type-of-Resource' && typeOfResourceStickyNames.has(filterValue))
        ) {
          return chunks.concat([filterName, filterValue]);
        }
        return chunks;
      }, []);

      const searchTerm =
        query === '' ? '' : `Search:${encodeURIComponent(query.replace('/', ' '))}`;

      const searchUrl = []
        .concat(searchType, stickyFilters, searchTerm)
        .filter((chunk) => chunk !== '')
        .join('/');

      try {
        localStorage.setItem('verticalScrollPositionCD', window.pageYOffset);
      } catch (e) {
        // If localStorage is not available, then we catch the error and the search term is not set.
      }

      navigate(searchUrl);
    }
  }

  handleButtonClick = () => {
    if (this.canSearch) {
      this.search(this.state.value);
    }
  };

  handleKeyUp = (event) => {
    if (event.key === 'Enter' && this.canSearch) {
      this.search(this.state.value);
    }
  };

  // TODO: clean up the IE11 hack when React16 comes out. Cleanup extends to SearchAutosuggest
  render() {
    const { key, value, defaultValue, suggestions } = this.state;
    const placeholder = 'Search';

    const inputProps = {
      value, // HACK: this value is NOT USED due to a bad interaction with IE (surprise)
      // It is necessary, though - deleting `value` will cause errors!
      defaultValue, // HACK: this sets the initial value of the input, instead
      onChange: this.handleChange,
      onKeyUp: this.handleKeyUp,
      key,
      placeholder
    };

    return (
      <SearchAutosuggest
        onSuggestionsFetchRequested={this.debouncedFetchSuggestions}
        onSuggestionsClearRequested={this.clearSuggestions}
        onSuggestionSelected={this.handleSuggestionSelected}
        handleButtonClick={this.handleButtonClick}
        suggestions={suggestions}
        inputProps={inputProps}
        // get the container a ref to the components input
        inputRefCallback={(element) => {
          this.inputRef = element;
        }}
      />
    );
  }
}

export { HeaderSearch as PureHeaderSearch };

export default compose(
  withLocation,
  connect(({ config: { isMobile } }) => ({ isMobile }))
)(HeaderSearch);
