import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { shouldShowSkinnyHeaderFooter } from 'domains/Header/util';
import { applicationFeatures } from 'config/features';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import featureVariantsInfo from 'decorators/featureVariants';
import routeState from 'decorators/routeState';
import withLocation from 'decorators/withLocation';
import Row from 'components/Grid/Row';
import Grid from 'components/Grid';
import Col from 'components/Grid/Col';
import Anchor from 'components/Anchor';
import Logo from 'components/Logo';
import SocialRow from 'components/Social/SocialRow';
import Box from 'components/Box';

import './FooterLayout.scss';

const tptSocialProfiles = {
  facebook: 'TeachersPayTeachers',
  pinterest: 'tptpins',
  instagram: 'teacherspayteachers',
  twitter: CONFIG.twitterId
};

@connect(({ config }) => ({ isMobile: config.isOgMobile }))
@withLocation
@routeState
// This class is written as stateful for ease of decoration + access via Enzyme:
/* eslint-disable react/prefer-stateless-function */
class FooterLayout extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    routeState: PropTypes.shape({
      shouldHideHeaderFooter: PropTypes.bool
    }),
    featureVariants: featureVariantsPropTypes
  };

  static defaultProps = {
    routeState: {},
    featureVariants: {}
  };

  render() {
    const {
      routeState: { shouldHideHeaderFooter },
      featureVariants,
      location
    } = this.props;
    const shouldRenderLinkColumns = !shouldShowSkinnyHeaderFooter(location);
    const shouldShowStudentPrivacyPolicy =
      featureVariants && featureVariants.student_privacy_policy === 'on';
    const shouldShowContactUs = !(
      featureVariants && featureVariants.hide_contact_us_in_footer === 'on'
    );

    if (shouldHideHeaderFooter) {
      return null;
    }
    return (
      <div className="FooterLayout">
        <Grid>
          <Row>
            <Col xs={6} className="FooterLayout__leftContainer">
              <Box marginBottom="sm">
                <Logo />
              </Box>
              <p className="FooterLayout__text">
                Teachers Pay Teachers is an online marketplace where teachers buy and sell original
                educational materials.
              </p>
              <Anchor opensNewTab to="/About-Us">
                Learn More
              </Anchor>
              <SocialRow social={tptSocialProfiles} />
            </Col>

            {shouldRenderLinkColumns && (
              <Col xs={6}>
                <Row>
                  <Col xs={4}>
                    <div>
                      <h4>About</h4>
                      <ul className="FooterLayout__list">
                        {/* eslint-disable max-len */}
                        <li>
                          {' '}
                          <Anchor color="inherit" forceReload to="/About-Us">
                            Who We Are
                          </Anchor>{' '}
                        </li>
                        <li>
                          {' '}
                          <Anchor color="inherit" forceReload to="/Careers">
                            We&apos;re Hiring
                          </Anchor>{' '}
                        </li>
                        <li>
                          {' '}
                          <Anchor color="inherit" forceReload to="/PressCoverage">
                            Press
                          </Anchor>{' '}
                        </li>
                        <li>
                          {' '}
                          <Anchor
                            color="inherit"
                            forceReload
                            to="http://blog.teacherspayteachers.com"
                          >
                            Blog
                          </Anchor>{' '}
                        </li>
                        {/* eslint-enable max-len */}
                      </ul>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div>
                      <h4>Support</h4>
                      <ul className="FooterLayout__list">
                        {/* eslint-disable max-len */}
                        <li>
                          {' '}
                          <Anchor color="inherit" forceReload to="/Help">
                            Help &amp; FAQ
                          </Anchor>{' '}
                        </li>
                        {shouldShowContactUs && (
                          <li>
                            {' '}
                            <Anchor color="inherit" forceReload to="/Contact">
                              Contact Us
                            </Anchor>{' '}
                          </li>
                        )}
                        <li>
                          {' '}
                          <Anchor color="inherit" forceReload to="/Terms-of-Service">
                            Terms of Service
                          </Anchor>{' '}
                        </li>
                        <li>
                          {' '}
                          <Anchor color="inherit" forceReload to="/Copyright-Policy">
                            Trademark &amp; Copyright
                          </Anchor>{' '}
                        </li>
                        <li>
                          {' '}
                          <Anchor color="inherit" forceReload to="/Privacy-Policy">
                            Privacy Policy
                          </Anchor>{' '}
                        </li>
                        {shouldShowStudentPrivacyPolicy && (
                          <li>
                            {' '}
                            <Anchor color="inherit" forceReload to="/Student-Privacy-Policy">
                              Student Privacy Policy
                            </Anchor>{' '}
                          </li>
                        )}
                        {this.props.isMobile && (
                          <li>
                            {' '}
                            <Anchor color="inherit" forceReload to="/pages/switch_version/mobile">
                              See Mobile Site
                            </Anchor>{' '}
                          </li>
                        )}
                        {/* eslint-enable max-len */}
                      </ul>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div>
                      <h4>Keep in Touch!</h4>
                      <p className="FooterLayout__newsletter">
                        Are you getting the free resources, updates, and special offers we send out
                        every week in our teacher newsletter?
                      </p>
                      <Anchor to="/My-Account/Email_Preferences" forceReload>
                        Sign Up
                      </Anchor>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Grid>
      </div>
    );
  }
}

export { FooterLayout as PureFooterLayout };

export default compose(featureVariantsInfo(applicationFeatures))(FooterLayout);
