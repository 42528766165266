import {
  TPT_DESELECT_ALL_RESOURCES,
  TPT_SELECT_ALL_RESOURCES,
  TPT_TOGGLE_RESOURCE
} from './actions';

function resourcesStateReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case TPT_DESELECT_ALL_RESOURCES:
      return payload.reduce((acc, r) => acc.filter(({ id }) => id !== r.id), state);

    case TPT_SELECT_ALL_RESOURCES:
      return payload.reduce((acc, r) => {
        const newacc = acc.filter(({ id }) => id !== r.id);
        return [...newacc, { id: r.id, rightsholderId: r.rightsholderId }];
      }, state);

    case TPT_TOGGLE_RESOURCE: {
      const cutState = state.filter(({ id }) => id !== payload.id);
      if (cutState.length < state.length) {
        return cutState;
      }
      return [...state, { id: payload.id, rightsholderId: payload.rightsholderId }];
    }

    default:
      return state;
  }
}

export default resourcesStateReducer;
