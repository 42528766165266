import React from 'react';
import PropTypes from 'prop-types';

// ButtonBase is a low-level UI component wrapper used to render clickable components
const ButtonBase = ({
  children,
  className,
  type,
  disabled,
  onClick,
  'data-testid': dataTestId
}) => (
  <button
    className={className}
    type={type}
    disabled={disabled}
    onClick={onClick}
    data-testid={dataTestId}
  >
    {children}
  </button>
);

ButtonBase.propTypes = {
  /** The content of the component */
  children: PropTypes.node,

  /** The class name used to style the button */
  className: PropTypes.string,

  /** Boolean indicating whether the button should render as disabled */
  disabled: PropTypes.bool,

  /** The type of the button */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),

  /** Callback function fired when component is clicked */
  onClick: PropTypes.func,
  'data-testid': PropTypes.string
};

ButtonBase.defaultProps = {
  type: 'button'
};

export default ButtonBase;
