import React from 'react';
import Url from 'url';
import emojiRegex from 'emoji-regex';
import FormattedNumber from 'react-intl/src/components/number';
import { parseMoney } from 'util/utils/index';

export const CONTRIBUTION_BASE_URL = 'ClassFund';
export const TPTCLASSFUND_BASE_URL = 'TpTClassFund';

export const MIN_CONTRIBUTION_AMOUNT = 3;
export const MAX_CONTRIBUTION_AMOUNT = 1000;
// Minimum characters count, which fire form input tracking action
export const CHAR_COUNT_TO_TRACK = 5;

export const CONTRIBUTION_FORM = 'contribution_form';

export const CONTRIBUTION_TOS_URL =
  'https://ecdn.teacherspayteachers.com/static/tptclassfundtermsandconditions.pdf';

export const CLASSFUND_URL = `/${TPTCLASSFUND_BASE_URL}`;

export const DASHBOARD_ED = `/ClassFund`;

export const TPT_CLASSFUND_TERMS_AND_CONDITIONS_URL =
  'https://ecdn.teacherspayteachers.com/static/tptclassfundtermsandconditions.pdf';

export const PREFILLED_CAMPAIGN_URL = {
  BACK_TO_SCHOOL: '/ClassFund/template/back-to-school-prep',
  BACK_TO_SCHOOL_SALE: '/ClassFund/template/back-to-school-sale'
};

export const UTM_CAMPAIGN = 'ClassFundPage';

export const FORM_ID = 'ContributionPageForm';

export const pageStatuses = {
  ACTIVE: 'active',
  CLOSED: 'closed',
  DELETED: 'deleted',
  DRAFT: 'draft',
  NONE: 'none'
};

export const formTabs = {
  BASIC: 'basic',
  STORY: 'story',
  OPTIONAL: 'optional'
};

// those two const is default messages which will be used once CMS is not available
export const FB_OWNER_SHARE_QUOTE =
  'Help my students succeed! TpT is my go-to source for high-quality, teacher-created classroom resources. A contribution to my TpT ClassFund allows me to get what I need to teach at my best. Thank you!';

export const TW_OWNER_SHARE_QUOTE =
  'Help my students succeed! TpT is my go-to source for high-quality, teacher-created classroom resources. A contribution to my TpT ClassFund allows me to get what I need to teach at my best. Thank you!';

export const ALLOWED_DOMAINS = [
  'www.teacherspayteachers.com',
  'teacherspayteachers.com',
  'www.tptclassfund.com',
  'tptclassfund.com'
];

export const isDeletedFriendlyPage = (isLoading, status) => {
  if (!isLoading && status === pageStatuses.DELETED) {
    return true;
  }
  return false;
};

export const isPageNotFound = (isLoading, status, canEdit) => {
  // If a contribution page is a draft that is not editable (non owner's page)
  if (!isLoading && status === pageStatuses.DRAFT && !canEdit) {
    return true;
  }
  return false;
};

export const isOwnersDraft = (isLoading, status, canEdit) => {
  // If a contribution page is an active draft and editable (owner's page) redirect to create form
  if (!isLoading && status === pageStatuses.DRAFT && canEdit) {
    return true;
  }
  return false;
};

export const validateNoEmojis = (text) =>
  emojiRegex().test(text) ? 'Emojis are not supported in this field.' : null;

export const validateAmountMin = (amount) =>
  parseFloat(amount) < MIN_CONTRIBUTION_AMOUNT
    ? `Please enter at least $${MIN_CONTRIBUTION_AMOUNT}.`
    : null;

export const validateAmountMax = (amount) =>
  parseFloat(amount) > MAX_CONTRIBUTION_AMOUNT
    ? `You can gift a maximum of $${MAX_CONTRIBUTION_AMOUNT}.`
    : null;

export const validateGoalMin = (minimumGoal = 5) => (goal) =>
  parseFloat(goal) < minimumGoal ? `Please enter at least $${minimumGoal}.` : null;

export const validateGoalMax = (maximumGoal = 2000) => (goal) =>
  parseFloat(goal) > maximumGoal ? `Please set a goal of no more than $${maximumGoal}.` : null;

export const validateWholeGoal = (goal) => {
  // eslint-disable-next-line eqeqeq
  if (parseInt(goal, 10) == goal) {
    return null;
  }
  return 'Please enter a whole dollar amount.';
};

export const validateAmount05 = (amount) =>
  Math.round(parseFloat(amount) * 100) % 5 ? `Please enter an amount in $0.05 increments.` : null;

export const normalizePrice = (value, previousValue) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^0-9.]+/g, '');
  const dotPos = onlyNums.indexOf('.') || onlyNums.indexOf(',');
  const noDots = dotPos !== -1 ? onlyNums.substring(0, dotPos + 3) : onlyNums;
  return noDots.length > 8 ? previousValue : noDots;
};

export const getNumericPrice = (value) => {
  if (!value) return 0;
  const normalized = normalizePrice(value);
  return parseFloat(normalized);
};

export const shouldShowContributionHeader = ({ pathname }) =>
  pathname.indexOf(`/${CONTRIBUTION_BASE_URL}`) === 0 &&
  !(
    pathname.endsWith('/Contributions') ||
    pathname.endsWith('/Dashboard') ||
    pathname.startsWith(`/${CONTRIBUTION_BASE_URL}/Create`) ||
    pathname.endsWith('/Edit') ||
    pathname.endsWith('/Share')
  );

export const shouldShowContributionHeaderCampaignEditor = ({ pathname }) =>
  pathname.indexOf(`/${CONTRIBUTION_BASE_URL}`) === 0 &&
  (pathname.startsWith(`/${CONTRIBUTION_BASE_URL}/Create`) || pathname.endsWith('/Edit'));

export const shouldShowClassFundFeedInHeader = ({ pathname }) =>
  pathname.indexOf(`/${CONTRIBUTION_BASE_URL}`) === 0 &&
  (pathname.endsWith('/Dashboard') || pathname.endsWith('/Share'));

export const isClassfundCheckoutPage = (pathname) => {
  const checkoutRegex = new RegExp(`/${CONTRIBUTION_BASE_URL}/(.*)/Contribute`, 'i');
  return checkoutRegex.test(pathname);
};

export const addUtmTags = (url, { source, campaign, medium = 'referral' }) => {
  const { query, search: _search, path: _path, href, _href, ...urlObject } = Url.parse(
    url.trim(),
    true
  );
  return Url.format({
    ...urlObject,
    query: {
      ...query,
      utm_source: source,
      utm_medium: medium,
      utm_campaign: campaign
    }
  });
};

export const addUtmCampaign = (url, campaign) => {
  const { query, search: _search, path: _path, href, _href, ...urlObject } = Url.parse(
    url.trim(),
    true
  );

  return Url.format({
    ...urlObject,
    query: {
      ...query,
      utm_campaign: campaign
    }
  });
};

export const visibilityOptions = {
  PUBLIC: 'public',
  TEACHERS_ONLY: 'teacher-only',
  PRIVATE: 'private'
};

export const isCreateMutationNeeded = (isNew, contributionPage) => isNew && !contributionPage.id;

export const isClassFundPageDraft = (contributionPages = []) =>
  !!(contributionPages && contributionPages.length) &&
  contributionPages.some(({ isDraft, active }) => isDraft && active);

export const amountFormat = (amount) => {
  const hasFractionDigits = amount % 1 !== 0;
  return (
    <FormattedNumber
      value={amount}
      currency="USD"
      style="currency"
      minimumFractionDigits={hasFractionDigits ? 2 : 0}
    />
  );
};

// this function should replace patterns %<key>% with object values
export const setStringVariables = (string, variablesObject) =>
  Object.keys(variablesObject).reduce(
    (acc, key) => acc.replace(new RegExp(`%${key}%`, 'g'), variablesObject[key]),
    string
  );

// action related functions
export const actionDialogProps = (action) => ({
  header: `Are you sure you want to ${
    action === pageStatuses.CLOSED ? 'close' : 'delete'
  } this campaign?`,
  acceptButtonLabel: action === pageStatuses.CLOSED ? 'Close' : 'Delete',
  acceptButtonSkin: action === pageStatuses.CLOSED ? 'primary' : 'red',
  variant: action === pageStatuses.CLOSED ? 'primary' : 'destructive'
});

export const actionCaptchaOK = (action, captcha) => {
  const closeRx = new RegExp('^close$', 'i');
  const deleteRx = new RegExp('^delete$', 'i');
  return action === pageStatuses.CLOSED ? closeRx.test(captcha) : deleteRx.test(captcha);
};

export const calculateCampaignGoalProgressPercent = (currentAmount, goalAmount) => {
  const goalAmountNumber = goalAmount ? parseMoney(goalAmount) : goalAmount;

  if (goalAmountNumber === 0.0) {
    return 0;
  }

  const currentAmountNumber = currentAmount ? parseMoney(currentAmount) : currentAmount;
  const ratio = currentAmountNumber / goalAmountNumber;
  const progress = ratio > 1 ? 100 : Math.floor(ratio * 100);

  return progress;
};

export const filterActiveTemplates = ({
  fields: { headline, description, thankYouNote, goalAmount }
}) =>
  headline.length >= 10 &&
  headline.length <= 90 &&
  goalAmount >= 5 &&
  goalAmount <= 2000 &&
  description.length >= 150 &&
  thankYouNote.length >= 5;

export const parseCampaignUpdateId = (hash) => {
  if (!hash || !hash.length) {
    return false;
  }

  if (hash.indexOf('#update-') !== 0) {
    return false;
  }

  const [, campaignId] = hash.split('-');

  return campaignId || false;
};
