import React from 'react';
import PropTypes from 'prop-types';
import './Badge.scss';

const Badge = ({ label }) => <span className="Badge">{label}</span>;

Badge.propTypes = {
  label: PropTypes.string.isRequired
};

export default Badge;
