// TODO-RM: Update CONSTANTS within the codebase

export const CCSS_JURISDICTION_ID = '3054';

export const NGSS_JURISDICTION_ID = '3055';

export const TEKS_JURISDICTION_ID = '3326';

export const VA_SOL_JURISDICTION_ID = '5785';

export const DIRECT_CHILDREN_DEPTH = 1;

export const JURISDICTIONS = {
  [CCSS_JURISDICTION_ID]: {
    id: '3054',
    name: 'Common Core State Standards',
    notation: 'CCSS',
    state: 'National',
    caption:
      "Buyers can opt-in to translating CCSS into their state-specific standards, when possible. They'll be able to see both the standards that you've tagged and their state-specific equivalents."
  },
  [NGSS_JURISDICTION_ID]: {
    id: '3055',
    name: 'Next Generation Science Standards',
    notation: 'NGSS',
    state: 'National',
    caption: null
  },
  [TEKS_JURISDICTION_ID]: {
    id: '3326',
    name: 'Texas Essential Knowledge and Skills',
    notation: 'TEKS',
    state: 'Texas',
    caption: `TEKS will only be displayed for Buyers in Texas.`
  },
  [VA_SOL_JURISDICTION_ID]: {
    id: '5785',
    name: 'Virginia Standards of Learning',
    notation: 'VA SOL',
    state: 'Virginia',
    caption: `VA SOL will only be displayed for Buyers in Virginia.`
  }
};

export const CCSS = JURISDICTIONS[CCSS_JURISDICTION_ID].notation;
export const TEKS = JURISDICTIONS[TEKS_JURISDICTION_ID].notation;
export const VA_SOL = JURISDICTIONS[VA_SOL_JURISDICTION_ID].notation;
export const TEXAS = 'Texas';
export const VIRGINIA = 'Virginia';
export const NEBRASKA = 'Nebraska';
export const OKLAHOMA = 'Oklahoma';
export const TEXAS_SHORT = 'TX';
export const VIRGINIA_SHORT = 'VA';
export const TEXAS_TAXONOMY_ID = '326';
export const VIRGINIA_TAXONOMY_ID = '329';
export const NON_US_TEACHER_TAXONOMY_ID = '335';
export const NON_US_TEACHER = 'Non USA teacher';
export const USA_COUNTRY_ID = '223';

export const CROSSWALK_RESULTS_INITIAL_COUNT = 5;
export const CROSSWALK_RESULTS_ADDITIONAL_COUNT = 20;
