import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { navigate } from 'util/utils/location';
import { parseMoney } from 'util/utils';
import { ITEM_TYPE_SCHOOL_FUNDS } from 'config/constants';
import getCartSummaryQuery from 'tpt_modules/cart/queries/GetCartHeaderSummary.graphql';
import HeaderCart from '.';

/**
 * Given a Graph API data response, formats the cart data in the response
 * to structure expected in HeaderCart component.
 * @param {object} - Graph API response data
 * @return {object}
 */
function formatCartDataFromGraph({ metadata, paginatedCartProducts }) {
  const { results: items } = paginatedCartProducts;

  function resetSchoolFundsProductName(name, type) {
    if (type === ITEM_TYPE_SCHOOL_FUNDS) {
      return 'TpT for Schools curriculum funds';
    }
    return name;
  }

  return {
    items: (items || []).map((i) => ({
      title: resetSchoolFundsProductName(i.product.name, i.product.itemType),
      url: i.product.url,
      price: parseMoney(i.totalFinalPrice) || 0,
      type: i.product.itemType
    })),
    total: parseMoney(metadata.total) || 0,
    licenseCount: metadata.productsTotalCount || 0
  };
}

/**
 * Direct users to their carts when clicking directly on the cart 'link'
 * This is a necessary hack because Firefox doesn't handle anchors in buttons :/
 * @return { undefined }
 */
function handleButtonClick() {
  // use a click handler to redirect instead of an anchor
  // R: firefox doesn't respect anchors that are nested inside of buttons
  // (and IE doesn't respect buttons that are nested in anchors)
  // TODO: replace with browserHistory.push when only moving from React pages to React pages
  navigate('/Cart');
}

// Graph API
const graphCartDataPropTypes = PropTypes.shape({
  paginatedCartProducts: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        totalFinalPrice: PropTypes.string,
        quantity: PropTypes.number,
        product: PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
          itemType: PropTypes.string
        })
      })
    )
  }),
  metadata: PropTypes.shape({
    total: PropTypes.string,
    productsTotalCount: PropTypes.number
  })
});

export class PureHeaderCartContainer extends Component {
  static propTypes = {
    cartSummary: graphCartDataPropTypes,
    /** Whether to adjust spacing as necessary to align in new responsive grid */
    isResponsive: PropTypes.bool,
    isShortened: PropTypes.bool
  };

  static defaultProps = {
    cartSummary: null,
    isResponsive: false,
    isShortened: false
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      cart: { items: [], total: 0, licenseCount: 0 }
    };
  }

  componentWillReceiveProps({ cartSummary }) {
    if (cartSummary && cartSummary !== this.props.cartSummary) {
      this.setState({ cart: formatCartDataFromGraph(cartSummary) });
    }
  }

  render() {
    const { isResponsive, isShortened } = this.props;
    const { cart } = this.state;

    return (
      <HeaderCart
        cart={cart}
        handleButtonClick={handleButtonClick}
        isResponsive={isResponsive}
        isShortened={isShortened}
      />
    );
  }
}

export default compose(
  graphql(getCartSummaryQuery, {
    options: () => ({
      variables: {
        limit: 5
      }
    }),
    props: ({ data }) => ({
      cartSummary: data ? data.cart : null
    }),
    skip: !IS_BROWSER
  })
)(PureHeaderCartContainer);
