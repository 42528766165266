import PropTypes from 'prop-types';
import React, { cloneElement, Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { applicationFeatures } from 'config/features';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import withUserData from 'decorators/withUserData';

import featureVariantsDecorator from 'decorators/featureVariants';

import favicon from './favicon.ico';
import mobileFavicon from './mobile-favicon.png';

@connect(({ config }) => ({ config }))
@featureVariantsDecorator(applicationFeatures)
@withUserData({
  props: ({ userData: { member } }) => ({
    userId: member ? member.id : undefined
  })
})
class ApplicationEntry extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    config: PropTypes.shape({
      isMobile: PropTypes.bool,
      isTablet: PropTypes.bool
    }).isRequired,
    error: PropTypes.any,
    featureVariants: featureVariantsPropTypes,
    html: PropTypes.object,
    location: PropTypes.object,
    userId: PropTypes.string
  };

  static childContextTypes = {
    defaultHeadTags: PropTypes.object,
    location: PropTypes.shape({
      pathhname: PropTypes.string
    })
  };

  static defaultProps = {
    userId: undefined
  };

  constructor(props, context) {
    super(props, context);

    this.defaultHeadTags = {
      style: [
        {
          cssText: 'body { margin: 0; padding: 0; }'
        }
      ],
      meta: [
        {
          charset: 'utf-8'
        },
        {
          'http-equiv': 'Content-Type',
          content: 'text/html; charset=utf-8'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        }
      ],
      link: [
        {
          rel: 'shortcut icon',
          href: favicon
        },
        {
          rel: 'icon',
          href: favicon
        },
        {
          rel: 'apple-touch-icon-precomposed',
          href: mobileFavicon
        }
      ]
    };
  }

  getChildContext() {
    return {
      // We have to pass down the default head tags so when RemotePage assigns
      // new tags to to Helmet, it does not clear the current tags and causes FOUC
      defaultHeadTags: this.defaultHeadTags,
      location: this.props.location
    };
  }

  render() {
    const { children, ...otherProps } = this.props;

    return (
      <div>
        <Helmet {...this.defaultHeadTags} />
        {cloneElement(children, otherProps)}
      </div>
    );
  }
}

export default ApplicationEntry;
