import React from 'react';
// Where to find Schools-related flash messages in SessionStorage.
export const SCHOOLS_FLASH_MESSAGE_KEY = 'SchoolsAlert';

// Export constants to be used by message producers to set keys in SessionStorage.
// Example message producer: LeaveMySchoolButton
export const TEACHER_LEAVES_SCHOOL = 'TEACHER_LEAVES_SCHOOL';
export const INVITE_REVOKED = 'INVITE_REVOKED';

export const TeacherLeavesSchool = () => (
  <span>
    <b>Sad to see you go!</b>{' '}
    {"If you'd like to join your school again, please let your administrator know."}
  </span>
);

export const InviteRevoked = () => (
  <span>
    <b>This invite link is no longer active.</b>{' '}
    {"If you'd like to join your school, please reach out to your administrator."}
  </span>
);

// Lookup table for the message consumer to find the text to display to the user.
export const SchoolsFlashMessages = {
  [TEACHER_LEAVES_SCHOOL]: <TeacherLeavesSchool />,
  [INVITE_REVOKED]: <InviteRevoked />
};
