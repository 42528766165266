import PropTypes from 'prop-types';
import React from 'react';
import { withApollo } from 'react-apollo';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import EaselHeaderLayout from '../EaselHeader/EaselHeaderLayout';
import HeaderCartContainer from '../HeaderCart/HeaderCartContainer';
import SellerDropdown from './SellerDropdown';

import './SellerHeaderLayout.scss';

const SellerHeaderLayout = ({
  firstName,
  name,
  imageUrl,
  countUnreadMessages,
  storeUrl,
  isPremiumSellerOrAdmin,
  featureVariants
}) => {
  const isEaselEducationExperiment = ['header_adoption', 'all_changes'].includes(
    featureVariants.easel_education
  );

  return (
    <div className="SellerHeaderLayout">
      {isEaselEducationExperiment && (
        <div className="SellerHeaderLayout__easelContainer">
          <EaselHeaderLayout isLoggedIn showNew />
        </div>
      )}
      <div className="SellerHeaderLayout__dropdownContainer">
        <SellerDropdown
          firstName={firstName}
          name={name}
          imageUrl={imageUrl}
          countUnreadMessages={countUnreadMessages}
          storeUrl={storeUrl}
          isPremiumSellerOrAdmin={isPremiumSellerOrAdmin}
          isShortened={isEaselEducationExperiment}
        />
      </div>
      <div className="SellerHeaderLayout__cartContainer">
        <HeaderCartContainer isShortened={isEaselEducationExperiment} />
      </div>
    </div>
  );
};

SellerHeaderLayout.propTypes = {
  name: PropTypes.string,
  firstName: PropTypes.string,
  countUnreadMessages: PropTypes.number,
  storeUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  isPremiumSellerOrAdmin: PropTypes.bool,
  featureVariants: featureVariantsPropTypes
};

SellerHeaderLayout.defaultProps = {
  featureVariants: {}
};

export default withApollo(SellerHeaderLayout);
