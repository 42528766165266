import React from 'react';
import PropTypes from 'prop-types';
import { getUnixTime, parseISO } from 'date-fns';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import SocialFeedContributionsQuery from './SocialFeedContributions.graphql';
/*
Example of using:
...
import ClassFundFeed from 'domains/Contribution/components/ClassFundFeed';
import NarrowClassFundFeedLayout from 'domains/Contribution/components/ClassFundFeed/NarrowClassFundFeedLayout';
...

const SomeLayout = () => (
  <>
    ...
    <ClassFundFeed className="ContributionFeedWrapper" component={NarrowClassFundFeedLayout} />
    ...
  <>
);
*/

const minimumContributionsToShow = 4;

const toUnixTime = (created) => {
  getUnixTime(parseISO(created));
};

const ClassFundFeed = ({
  component: ClassFundFeedLayout,
  contributions,
  className,
  variant,
  emptyRender
}) => {
  if (!emptyRender && (!contributions || contributions.length < minimumContributionsToShow)) {
    return null;
  }

  const sortedContributions = [...contributions];
  sortedContributions.sort((a, b) => toUnixTime(b.created) - toUnixTime(a.created));

  return (
    <ClassFundFeedLayout
      className={className}
      contributions={sortedContributions}
      variant={variant}
    />
  );
};

ClassFundFeed.propTypes = {
  component: PropTypes.func.isRequired,
  contributions: PropTypes.arrayOf(
    PropTypes.shape({
      contributorName: PropTypes.string,
      message: PropTypes.string,
      created: PropTypes.string
    })
  ).isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['timestamps', 'no_timestamps']),
  emptyRender: PropTypes.bool
};

ClassFundFeed.defaultProps = {
  variant: 'timestamps',
  emptyRender: false
};

export default graphql(SocialFeedContributionsQuery, {
  options: () => ({ variables: { countLimit: 20, ageLimitHours: 48 } }),
  props: ({ data }) => ({
    contributions: get(data, 'contribution.socialFeedContributions', [])
  })
})(ClassFundFeed);
