import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DrawerAsync from './DrawerAsync';
import DrawerHeader from './DrawerHeader';

class ToggleableDrawer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      drawerOpen: false
    };
  }

  /**
   * Handle case where the drawer component is
   * controlled via props.
   * @param {object} props
   * @param {object} state
   * @return {object} new state derived from props
   */
  static getDerivedStateFromProps({ isOpen }, state) {
    if (typeof isOpen !== 'undefined' && isOpen !== state.drawerOpen) {
      return {
        drawerOpen: isOpen
      };
    }
    return null;
  }

  onToggleClick = () => {
    const { onToggle } = this.props;
    const newState = !this.state.drawerOpen;
    this.setState({
      drawerOpen: newState
    });
    onToggle(newState);
  };

  render() {
    const { drawerOpen } = this.state;
    const { children, toggleNode, withHeader } = this.props;
    return (
      <div>
        <span // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          onClick={this.onToggleClick}
        >
          {toggleNode}
        </span>
        <DrawerAsync open={drawerOpen} onClose={this.onToggleClick} {...this.props}>
          {withHeader && <DrawerHeader />}
          {children}
        </DrawerAsync>
      </div>
    );
  }
}

ToggleableDrawer.propTypes = {
  /**
   * Optionally, the drawer's state can be controlled via
   * prop. If the prop is not set to a boolean, the drawer
   * manages its own state.
   */
  isOpen: PropTypes.bool,

  /**
   * When drawer closed/open state is controlled via a prop,
   * this callback can be used to subscribe to updates on state
   * changes. It receives a boolean indicating whether the drawer
   * is open or closed.
   */
  onToggle: PropTypes.func,
  withHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /* Node to be clicked on to show/hide Drawer */
  toggleNode: PropTypes.node.isRequired,
  anchor: PropTypes.oneOf(['left', 'right'])
};

ToggleableDrawer.defaultProps = {
  isOpen: undefined,
  onToggle: () => {},
  withHeader: true,
  anchor: 'left'
};

export default ToggleableDrawer;
