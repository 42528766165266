import { getAdvertisedPriceNumeric } from 'util/utils';

export const TPT_CLEAR_ALL_ERRORS = '@@tpt/CLEAR_ALL_ERRORS';
export const TPT_UPDATE_SERVER_STATE = '@@tpt/UPDATE_SERVER_STATE';
export const TPT_UPDATE_ROUTE_STATE = '@@tpt/UPDATE_ROUTE_STATE';
export const TPT_ADD_GLOBAL_ERROR = '@@tpt/ADD_GLOBAL_ERROR';
export const TPT_ADD_GLOBAL_ERROR_REQUEST_TRACE_ID = '@@tpt/TPT_ADD_GLOBAL_ERROR_REQUEST_TRACE_ID';
export const TPT_ENABLE_GLOBAL_ERROR_REQUEST_TRACE_IDS =
  '@@tpt/TPT_ENABLE_GLOBAL_ERROR_REQUEST_TRACE_IDS';
export const TPT_CLEAR_GLOBAL_ERRORS = '@@tpt/CLEAR_GLOBAL_ERRORS';
export const TPT_UPDATE_SELECTED_RESOURCES_PRICE = '@@tpt/TPT_UPDATE_SELECTED_RESOURCES_PRICE';

export const TPT_UPDATE_ADVERTISED_PRICE = '@@tpt/UPDATE_ADVERTISED_PRICE';
export const TPT_LOADING_STATE = '@@tpt/LOADING_STATE';
export const TPT_FORWARDING_STATE = '@@tpt/FORWARDING_STATE';
export const TPT_SET_BRAINTREE_INSTANCE = '@@tpt/TPT_SET_BRAINTREE_INSTANCE';
export const TPT_ADD_CHECKOUT_ERROR = '@@tpt/TPT_ADD_CHECKOUT_ERROR';
export const TPT_CLEAR_CHECKOUT_ERRORS = '@@tpt/TPT_CLEAR_CHECKOUT_ERROR';
export const TPT_SET_COOKIE = '@@tpt/TPT_SET_COOKIE';
export const TPT_SET_ISOMORPHIC_DATE = '@@tpt/TPT_SET_ISOMORPHIC_DATE';

export function addGlobalError(errorMessage, errorNameSpace) {
  return {
    type: TPT_ADD_GLOBAL_ERROR,
    payload: errorMessage,
    namespace: errorNameSpace
  };
}

export function clearGlobalErrors() {
  return {
    type: TPT_CLEAR_GLOBAL_ERRORS
  };
}

export function enableGlobalErrorRequestTraceIds() {
  return {
    type: TPT_ENABLE_GLOBAL_ERROR_REQUEST_TRACE_IDS
  };
}

export function addGlobalErrorRequestTraceId(traceId) {
  return {
    type: TPT_ADD_GLOBAL_ERROR_REQUEST_TRACE_ID,
    payload: traceId
  };
}

export function updateSelectedResourcesPrice(totalPrice) {
  return {
    type: TPT_UPDATE_SELECTED_RESOURCES_PRICE,
    payload: totalPrice
  };
}

export function updateAdvertisedPrice({ price, discountprice, saleprice }) {
  return {
    type: TPT_UPDATE_ADVERTISED_PRICE,
    payload: getAdvertisedPriceNumeric({ price, discountprice, saleprice })
  };
}

/**
 * Set a global loading state for particular items (e.g., a specific
 * component that is about to be updated by a mutation.)
 * @param {string} loadingItem Name of the loading item.
 * @param {boolean} loadingState True when listeners should be considered loading.
 * @return {object}
 */
export function setLoadingState(loadingItem, loadingState) {
  return {
    type: TPT_LOADING_STATE,
    payload: {
      loadingItem,
      loadingState
    }
  };
}

export function setBraintreeInstance(braintreeInstance, braintreeDropinStatus) {
  return {
    type: TPT_SET_BRAINTREE_INSTANCE,
    payload: {
      braintreeInstance,
      braintreeDropinStatus
    }
  };
}

export function addCheckoutError(errorMessage) {
  return {
    type: TPT_ADD_CHECKOUT_ERROR,
    payload: errorMessage
  };
}

export function clearCheckoutErrors() {
  return {
    type: TPT_CLEAR_CHECKOUT_ERRORS
  };
}

export function setCookie(name, value, attributes = {}) {
  return {
    type: TPT_SET_COOKIE,
    payload: { name, value, attributes }
  };
}
