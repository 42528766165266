import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const AlignItemsModificatorType = PropTypes.oneOf(['start', 'center', 'end']);
const JustifyContentModificatorType = PropTypes.oneOf([
  'start',
  'center',
  'end',
  'around',
  'between'
]);
const FlexWrapType = PropTypes.oneOf([
  'nowrap',
  'wrap',
  'wrap-reverse',
  'sm-nowrap',
  'sm-wrap',
  'sm-wrap-reverse',
  'md-nowrap',
  'md-wrap',
  'md-wrap-reverse',
  'lg-nowrap',
  'lg-wrap',
  'lg-wrap-reverse',
  'xl-nowrap',
  'xl-wrap',
  'xl-wrap-reverse'
]);

const Row = (props) => {
  const { alignItems, justifyContent, className, flexWrap } = props;
  const classes = [];

  // add align-items class
  if (typeof alignItems === 'string') {
    classes.push(`align-items-${alignItems}`);
  }

  // add justify-content class
  if (typeof justifyContent === 'string') {
    classes.push(`justify-content-${justifyContent}`);
  }

  // add flex wrap class
  if (typeof flexWrap === 'string') {
    classes.push(`flex-${flexWrap}`);
  }

  return <div className={classnames('row', className, classes)}>{props.children}</div>;
};

Row.defaultProps = {
  alignItems: undefined,
  justifyContent: undefined,
  flexWrap: undefined
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alignItems: AlignItemsModificatorType,
  justifyContent: JustifyContentModificatorType,
  flexWrap: FlexWrapType
};

export default Row;
