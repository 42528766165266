import React from 'react';
import PropTypes from 'prop-types';
import NavigationHeaderPill from './NavigationHeaderPill';

const NavigationSubcategory = ({ category }) => (
  <div className="Subcategory">
    <div className="SubcategoryHeading">{category.label}</div>
    <div className="SubcategoryContent">
      {category.subcategories.map((subcategory) => (
        <NavigationHeaderPill
          key={`${subcategory}+${subcategory.label}`}
          label={subcategory.label}
          url={subcategory.url}
        />
      ))}
    </div>
  </div>
);

NavigationSubcategory.propTypes = {
  category: PropTypes.object
};

export default NavigationSubcategory;
