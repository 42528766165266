import PropTypes from 'prop-types';
import React, { createElement, Component } from 'react';
import { ReactTrackingContext } from '../TrackingContext/TrackingContext';
import analytics from '../../lib/analytics';

// Used to wrap DOM elements and fire GA events when they're interacted with.
// For example:
//   <TrackedComponent>
//     <Link onClick={ onClick }>Click Me!</Link>
//   </TrackedComponent>
// will fire a GA event similar to: 'Link click "Click Me!"'
// Naming conventions for GA are as follows:
// category - where the event is fired from, for example 'Fired From Product Page'.
// group by user interactions. See getDefaultCategoryName() for more info.
// IMPORTANT NOTE: if your category is by route, eg Fired From Product Page',
// please use the default categories in getDefaultCategoryName(),and add new ones as needed.
// action - the action the user peformed, for example 'Add To Cart'
// label - typically the receiver of the action, this optional field is helpeful
// for distinguishing between if the same category and action can happen in multiple
// places or slightly different ways. For example, on the product page there are 2
// price boxes so you could use the label to distinguish between them, similarly in
// videos it's helpful to distnguish if the video type the action is performed on
// is a 'preview' or 'video'.

// If you need an imperative event track instead, see `lib/analytics.trackEvent`.
// To debug events, add `?debug_analytics=true` to your URL.

// If you need to trigger analytics events in a child component that doesn't know
// what the props should be (e.g., category) because it is used in different contexts,
// use TrackingContext. Example in TrackingContext component!
class TrackedComponentWithContext extends Component {
  static propTypes = {
    contextTag: PropTypes.string,
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.element.isRequired,
    event: PropTypes.string, // onClick, onChange, onSubmit, etc.
    // NOTE: if you're using componentDidMount as the trigger,
    // you may also want to set this as a non-interaction event via:
    // <TrackedComponent options={ {nonInteraction: true} } />
    // This would preserve an accurate bounce rate for the page.
    // See https://support.google.com/analytics/answer/1033068#NonInteractionEvents.
    lifecycleMethod: PropTypes.oneOf(['componentDidMount']),
    value: PropTypes.number,
    customDimensions: PropTypes.object,
    options: PropTypes.object
  };

  static defaultProps = {
    event: 'onClick',
    lifecycleMethod: null
  };

  componentDidMount() {
    const { lifecycleMethod } = this.props;
    if (lifecycleMethod === 'componentDidMount') {
      this.eventHandler({});
    }
  }

  eventHandler = (e) => {
    const { type = '', target = {} } = e;
    const { textContent = '' } = target;
    const {
      contextTag,
      category,
      action = type,
      children: {
        props: { label: originalLabel }
      },
      label = originalLabel || textContent,
      options,
      value,
      customDimensions
    } = this.props;

    // Do not track if `category` is not supplied
    if (!category) return;

    if (contextTag && (!category || !action)) {
      // eslint-disable-next-line no-console
      console.warn(
        `Component triggered track for "${contextTag}" tag, but category/action not provided. Did you mean to use TrackingContext?`
      );
      return;
    }

    if (window.google_tag_manager && window.dataLayer) {
      customDimensions && analytics.pushProperties(customDimensions);

      analytics.trackEvent({
        eventCat: category,
        eventAct: action,
        eventLbl: label,
        eventVal: value,
        eventOptions: options
      });
    }
  };

  render() {
    const { lifecycleMethod, event, children } = this.props;

    if (lifecycleMethod === 'componentDidMount') {
      return children;
    }

    const handler = {
      [event]: this.eventHandler
    };

    return createElement('span', { children, ...handler });
  }
}

export default function TrackedComponent(props) {
  const { contextTag } = props;
  return (
    <ReactTrackingContext.Consumer>
      {(trackingContextProps) => (
        <TrackedComponentWithContext
          {...(trackingContextProps && contextTag && trackingContextProps[contextTag])}
          {...props}
        />
      )}
    </ReactTrackingContext.Consumer>
  );
}

TrackedComponent.propTypes = {
  contextTag: PropTypes.string,
  event: PropTypes.string
};

TrackedComponent.defaultProps = {
  event: 'onClick'
};
