import React from 'react';
import PropTypes from 'prop-types';
import { getNavCategories } from 'domains/Header/components/NavigationHeader/NavigationHeaderDropdownMenu';
import { getNavigationMenuData } from 'domains/Header/components/NavigationHeader';
import { getMainCategories } from 'components/CategoryMenu';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import { getEaselRedirectLink } from 'domains/Header/HeaderDigitalActivity';
import NestedMenu from './Drawer/NestedMenu';

let dividerIds = 0;
const makeDividerItem = () => ({ isDivider: true, label: `divider-${dividerIds++}` });

const DEFAULT_LINKS = [
  {
    label: 'Log In',
    url: '/Login',
    color: 'black'
  },
  {
    label: 'Join Us',
    url: '/Signup/Buyer',
    color: 'black'
  }
];

const LOGGED_IN_LINKS = [
  {
    label: 'My Wish List',
    url: '/Wish-List',
    color: 'black'
  },
  {
    label: 'My Purchases',
    url: '/My-Purchases',
    color: 'black'
  }
];

export const getUserAccountLinks = (isLoggedIn) => (isLoggedIn ? LOGGED_IN_LINKS : DEFAULT_LINKS);

const ResponsiveCategoryMenu = ({
  isLoggedIn,
  isMobile,
  isSeller,
  isPremiumSeller,
  featureVariants
}) => {
  const items = getMainCategories();

  if (featureVariants?.show_global_category_nav_bar_v2 === 'show_navbar') {
    // sets resource type category
    items[3].subcategories = [
      ...getNavigationMenuData(false, isMobile),
      ...getNavCategories(false).resourceType.subcategories
    ];

    // moves special education up one level
    items[1].subcategories = [...getNavCategories(false).subject.subcategories];
  }

  // add title
  items.unshift({
    label: 'All Categories',
    isTitle: true
  });
  // add divider
  items.push(makeDividerItem());

  // add user account links
  items.push(...getUserAccountLinks(isLoggedIn));

  items.push({
    label: 'Easel by TpT',
    url: getEaselRedirectLink(isPremiumSeller),
    color: 'black',
    isNew: true
  });

  items.push({
    label: 'TpT School Access',
    url: '/TpTSchoolAccess/Teachers',
    color: 'black'
  });

  items.push({
    label: 'TpT ClassFund',
    url: '/ClassFund',
    color: 'black'
  });

  // add dashboard link if user is seller
  if (isSeller) {
    items.push({
      label: 'Dashboard',
      url: '/Dashboard',
      color: 'black'
    });
  }

  // add log out link if user is logged in
  if (isLoggedIn) {
    items.push(makeDividerItem(), {
      label: 'Log Out',
      url: '/Logout',
      color: 'green'
    });
  }
  // pass in classname for use in GTM tracking
  return <NestedMenu items={items} className="ResponsiveCategoryMenu" />;
};

ResponsiveCategoryMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  isSeller: PropTypes.bool.isRequired,
  isPremiumSeller: PropTypes.bool.isRequired,
  featureVariants: featureVariantsPropTypes
};

ResponsiveCategoryMenu.defaultProps = {
  isMobile: false,
  featureVariants: {}
};

export default ResponsiveCategoryMenu;
