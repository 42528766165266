import React from 'react';
import PropTypes from 'prop-types';
import Anchor from 'components/Anchor';
import TrackedComponent from 'components/TrackedComponent/TrackedComponent';
import { CONTRIBUTION_BASE_URL } from 'domains/Contribution/helpers';
import HeaderSchoolsLinkContainer from './HeaderSchoolsLinkContainer';
import HeaderDigitalActivity from './HeaderDigitalActivity';

const HeaderNavList = ({
  className,
  itemClassName,
  isLoggedIn,
  isSeller,
  isPremiumSeller,
  shouldShowDigitalActivities
}) => (
  <ul className={className}>
    <li className={itemClassName}>
      <Anchor color="gray" forceReload to="/About-Us">
        About Us
      </Anchor>
    </li>
    <li className={itemClassName}>
      <Anchor color="gray" forceReload to="/Gift-Card">
        Gift Cards
      </Anchor>
    </li>
    <li className={itemClassName}>
      <Anchor color="gray" forceReload to="/Help">
        Help
      </Anchor>
    </li>
    {shouldShowDigitalActivities && (
      <HeaderDigitalActivity
        itemClassName={itemClassName}
        isLoggedIn={isLoggedIn}
        isSeller={isSeller}
        isPremiumSeller={isPremiumSeller}
      />
    )}
    <HeaderSchoolsLinkContainer itemClassName={itemClassName} />
    <li className={itemClassName}>
      <TrackedComponent category="Header" action="Clicked TpT ClassFund">
        <Anchor color="gray" forceReload to={`/${CONTRIBUTION_BASE_URL}`}>
          TpT ClassFund
        </Anchor>
      </TrackedComponent>
    </li>
  </ul>
);

HeaderNavList.defaultProps = {
  className: 'HeaderLayout__linkList',
  itemClassName: 'HeaderLayout__link',
  shouldShowDigitalActivities: false,
  isSeller: false,
  isPremiumSeller: false
};

HeaderNavList.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isSeller: PropTypes.bool,
  isPremiumSeller: PropTypes.bool,
  shouldShowDigitalActivities: PropTypes.bool,
  className: PropTypes.string,
  itemClassName: PropTypes.string
};

export default HeaderNavList;
