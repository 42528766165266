/**
 * Decorator for attaching extra route-specific Redux state.
 *
 * If you would just like to access the current location or URL query arguments,
 * use the `@withLocation` decorator instead.
 */
import invariant from 'invariant';
import { isPlainObject } from 'lodash';
import { connect } from 'react-redux';
import analytics from 'lib/analytics';
import { TPT_UPDATE_ROUTE_STATE } from 'lib/actions';

/**
 * Fires generic GA events to indicate routeState changes
 * i.e. Date Range on /dashboard changed to [2015, 2016]
 *
 * @param { !object } stateChange
 * @return { undefined }
 */
function analyticsTrack(stateChange) {
  const later = window.requestIdleCallback || window.setTimeout;

  later.call(window, () => {
    Object.keys(stateChange).forEach((eventCat) => {
      analytics.trackEvent({
        eventCat,
        eventAct: 'changed',
        eventLbl: JSON.stringify(stateChange[eventCat])
      });
    });
  });
}

/**
 * Checks if route state is valid. Valid route state can be a
 * plain flat object or a treelike structure that includes
 * nested objects and arrays; either way leaf values can only
 * be strings, numbers, or booleans.
 * @param {object} state
 * @return {boolean}
 */
export function isValidRouteState(state) {
  return Object.values(state).every((v) => {
    if (isPlainObject(v)) {
      if (!isValidRouteState(v)) {
        return false;
      }
    } else if (Array.isArray(v)) {
      if (v.some((o) => !isValidRouteState(o))) {
        return false;
      }
      // eslint-disable-next-line no-bitwise
    } else if (!~['string', 'number', 'boolean'].indexOf(typeof v)) {
      return false;
    }
    return true;
  });
}

export default function routeState(WrappedComponent) {
  return connect(
    (state, { location }) => ({
      routeState: state.routeState[location.pathname.toLowerCase()]
    }),
    (dispatch, { location }) => ({
      setRouteState: (partialState, pathname) => {
        invariant(
          isValidRouteState(partialState),
          /* eslint-disable prefer-template */
          'setRouteState should only take plain objects, numbers, and strings: ' +
            JSON.stringify(partialState)
        );

        if (IS_BROWSER) {
          analyticsTrack(partialState);
        }

        return dispatch({
          type: TPT_UPDATE_ROUTE_STATE,
          payload: {
            pathname: pathname || location.pathname.toLowerCase(),
            partialState
          }
        });
      }
    })
  )(WrappedComponent);
}
