export const TPT_EASEL_DETAILS = {
  general:
    'These resources include or can be converted to interactive activities that students can complete from any device.',
  sellerCreated:
    'This resource includes a ready-to-use interactive activity students can complete on any device.',
  sellerCreatedActivities:
    'These resources include interactive activities that students can complete from any device.',
  sellerEnabled:
    'Create an interactive version of this PDF and assign it to students to complete from any device.',
  assessmentsIncluded:
    'This resource includes a self-grading quiz students can complete on any device.',
  sellerEnabledBlurb:
    'Create an interactive version of this PDF students can complete on any device.',
  bundleSellerCreated:
    'Some resources in this bundle include ready-to-use interactive activities that students can complete on any device.',
  bundleSellerEnabled:
    'Some resources in this bundle can be made into interactive versions and assigned to students to complete from any device.',
  bundleSellerEnabledBlurb:
    'Some resources in this bundle can be made into interactive versions that students can complete on any device.',
  bundleAssessmentsIncluded:
    'Some resources in this bundle include self-grading quizzes that students can complete on any device.'
};

export const TPT_EASEL_DETAILS_COPY_EXPERIMENT = {
  sellerEnabled:
    'Add answer boxes, highlights, movable pieces, and more. Then, assign it to students to complete on any device.'
};

// https://www.teacherspayteachers.com/Product/TpT-Digital-Activities-An-Interactive-Tutorial-5903492
export const TPT_DIGITAL_ACTIVITY_SAMPLE_PRODUCT_ID = 5903492;

export const TPT_EASEL_BADGE_COPY = {
  sellerEnabled: 'Activities',
  sellerCreated: 'Activity',
  sellerCreatedActivities: 'Activities',
  byTpT: 'By TpT',
  general: 'Compatible',
  sellerCreatedIncluded: 'Activity Included'
};

export const TPT_EASEL_BADGE_COPY_EXPERIMENT = {
  sellerEnabled: 'Create a Digital Activity from this PDF',
  sellerCreated: 'Includes a Digital Activity'
};

export const TPT_EASEL_TOOLTIP_COPY = {
  sellerEnabled: 'Activities',
  sellerCreated: 'Activity',
  sellerCreatedActivities: 'Activities Included',
  byTpT: 'By TpT',
  general: '',
  sellerCreatedIncluded: 'Activity Included'
};
