import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FlexBox from 'components/FlexBox';
import TpTEaselBadge from 'domains/TpTEasel/components/TpTEaselBadge';
import { TPT_EASEL_BADGE_COPY } from '../constants';
import { getTextSize } from '../helpers';
import CompatibleWithText from '../CompatibleWithText';

import './DigitalActivityBadge.scss';

export const getBadgeCopy = ({ isSellerPublishedDigital, variant, isBundle = false }) => {
  const activity = 'Easel Activity';
  const activities = 'Easel Activities';

  if (variant === 'long') {
    if (isSellerPublishedDigital) {
      return `Includes ${isBundle ? activities : activity}`;
    }
    return `Compatible with ${activities}`;
  }

  if (variant === 'plural') {
    return 'Easel Activities';
  }
  // 'short' and unrecognized variants
  return 'Easel Activity';
};

const DigitalActivityBadge = ({
  className,
  isSellerPublishedDigital,
  showNew,
  size,
  addCompatibleWithText,
  easelBadgeTextVariant,
  useEaselIcon,
  ellipsis,
  layoutVariant
}) => {
  const textSize = getTextSize(size);
  const showCompatibleWith = addCompatibleWithText && !isSellerPublishedDigital;
  const shouldWrap = useEaselIcon && showCompatibleWith && layoutVariant === 'column';

  return (
    <div className={classnames('DigitalActivityBadge', `DigitalActivityBadge--${size}`, className)}>
      <FlexBox
        alignItems={!useEaselIcon && showCompatibleWith ? 'center' : undefined}
        className="DigitalActivityBadge__flexWrapper"
        wrap
        flexDirection={shouldWrap ? 'column' : 'row'}
      >
        {showCompatibleWith && (
          <span className="DigitalActivityBadge__compatibleWith">
            <CompatibleWithText size={textSize} className={className} />
          </span>
        )}
        <div
          className={classnames('DigitalActivityBadge__section', {
            'DigitalActivityBadge__section--ellipsis': ellipsis
          })}
        >
          <TpTEaselBadge
            text={TPT_EASEL_BADGE_COPY[easelBadgeTextVariant]}
            showIcon={useEaselIcon}
            textVariant={textSize}
            showNew={showNew}
          />
        </div>
      </FlexBox>
    </div>
  );
};

DigitalActivityBadge.propTypes = {
  className: PropTypes.string,
  isSellerPublishedDigital: PropTypes.bool,
  showNew: PropTypes.bool,
  size: PropTypes.oneOf(['micro', 'small', 'medium', 'large']),
  addCompatibleWithText: PropTypes.bool,
  easelBadgeTextVariant: PropTypes.string,
  useEaselIcon: PropTypes.bool,
  ellipsis: PropTypes.bool,
  layoutVariant: PropTypes.oneOf(['column', 'row'])
};

DigitalActivityBadge.defaultProps = {
  className: '',
  isSellerPublishedDigital: false,
  showNew: false,
  size: 'medium',
  addCompatibleWithText: false,
  easelBadgeTextVariant: null,
  useEaselIcon: false,
  ellipsis: false,
  layoutVariant: 'column'
};

export default DigitalActivityBadge;
