import { capitalize } from 'lodash';
import logger from 'lib/logger';

/**
 * In Google Analytics the naming convention is to group events
 * categorically by where they are fired from. This can be a page or a component.
 * In general at TpT the page use case currently makes the most sense, as this is
 * how most user interactions are grouped. The only time you would want to name the
 * category by component instead is when you want to track a group of events within
 * a component that is shared across routes, header bar interactions would be a good example.
 * This function provides reasonable default category names for the most common categories
 * on the TpT site that are grouped by route.
 * @param {!string} pathname
 * @return {string} the default category based on the location
 * the event is fired from
 */
export function getDefaultCategoryName(pathname) {
  const lowerPathname = pathname.toLowerCase();
  if (lowerPathname === '/') {
    return 'Fired From Home Page';
  } else if (lowerPathname.startsWith('/product')) {
    return 'Fired From Product Page';
  } else if (lowerPathname.startsWith('/browse')) {
    return 'Fired From Search Page';
  } else if (lowerPathname.startsWith('/login')) {
    return 'Fired From Login Page';
  } else if (lowerPathname.startsWith('/store')) {
    return 'Fired From Store Page';
  } else if (lowerPathname.startsWith('/cart')) {
    if (lowerPathname.startsWith('/cart/checkout')) {
      return 'Fired From Checkout Flow';
    }
    return 'Fired From Cart Page';
  } else if (lowerPathname.startsWith('/wish-list')) {
    return 'Fired From Wish List Page';
  }
  logger.warn('Event fired without category name or reasonable default.');
  return '';
}

/**
 * Google Analytics is Case Sensitive so we must normalize all strings
 * before sending them. The convention we use is to capitalize the first
 * letter of each word, and lowercase everything else. For more information
 * ask Samira or Mari.
 * This function must handle the empty case as Category and Label are optional
 * values for GA. For consistency any empty value should be sent to GA as null,
 * including empty strings.
 * @param {string} originalString string of unknown casing
 * @return {string} with the first letter of each word only capitalized
 */
export function normalizeString(originalString) {
  if (!originalString) {
    return null;
  }
  return originalString
    .trim()
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ');
}
