class WebStorageBackingStore {
  constructor() {
    this.storage = window.sessionStorage;
  }

  getItem(key) {
    return this.storage.getItem(key);
  }

  setItem(key, value) {
    this.storage.setItem(key, value);
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}

class ObjectStorageBackingStore {
  constructor() {
    this.storage = {};
  }

  getItem(key) {
    return this.storage[key];
  }

  setItem(key, value) {
    this.storage[key] = value;
  }

  removeItem(key) {
    delete this.storage[key];
  }

  clear() {
    this.storage = {};
  }
}

class SessionStorage {
  constructor() {
    const hasSessionStorage = typeof sessionStorage !== 'undefined';

    if (hasSessionStorage) {
      this.backingStore = new WebStorageBackingStore();
    } else {
      this.backingStore = new ObjectStorageBackingStore();
    }
  }

  getItem(key) {
    return this.backingStore.getItem(key);
  }

  setItem(key, value) {
    this.backingStore.setItem(key, value);
  }

  removeItem(key) {
    this.backingStore.removeItem(key);
  }

  clear() {
    this.backingStore.clear();
  }
}

export default SessionStorage;
