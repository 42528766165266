/* eslint-env browser */

import React from 'react';
import ReactDOM, { hydrate } from 'react-dom';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';
import Loadable from 'react-loadable';
import { isTouch, downloadJson } from 'util/utils';
import createTree from 'lib/createTree';
import createApolloClient from 'lib/createApolloClient';
import createStore from '../shared/lib/createStore';
import createHistory from '../shared/lib/history';
import computeReactRouterProps from '../shared/lib/computeReactRouterProps';
import routes from '../shared/lib/legacyRoutes';

import '../shared/styles/app.scss';
import '../shared/styles/app.unscoped.scss';
import '../shared/styles/legacy/fontawesome.unscoped.scss';

async function init(initialState, mountNode) {
  /* eslint-disable no-param-reassign */
  mountNode || (mountNode = document.querySelector('[data-tpt-mount]'));
  typeof initialState === 'string' && (initialState = JSON.parse(initialState));
  initialState.config || (initialState.config = {});
  initialState.config.isTouch = isTouch();
  const { apolloState } = initialState;
  delete initialState.apolloState;
  /* eslint-enable no-param-reassign */

  const req = {
    headers: {
      cookie: window.document.cookie
    }
  };
  const history = createHistory();
  const apolloClient = createApolloClient({
    url: '',
    req,
    initialState: apolloState
  });
  const store = createStore(initialState, {}, [routerMiddleware(history)]);

  __TPT__._define('dumpStore', (type) => {
    const content = JSON.stringify(store.getState());
    type === 'file' && downloadJson(content);
    return content;
  });

  const renderProps = await computeReactRouterProps({
    routes,
    history: syncHistoryWithStore(history, store)
  });
  await Loadable.preloadReady();

  const tree = createTree(store, renderProps, apolloClient);
  hydrate(tree, mountNode);
}

// TODO: why are we exposing react? if we must, we can do so via webpack
window.React = React;
window.ReactDOM = ReactDOM;

__TPT__._define('init', init);
