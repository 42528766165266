import PropTypes from 'prop-types';
import React from 'react';
import createPlugin from 'bugsnag-react';

const bugsnagClient = global.Bugsnag;

/**
 * Minimal error boundary that will be used in any cases where
 * Bugsnag is not initialized on the client
 */
class MinimalErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };
  componentDidCatch() {
    // React already logs the error stack itself
  }
  render() {
    return this.props.children;
  }
}

const ErrorBoundary =
  bugsnagClient && bugsnagClient.use
    ? bugsnagClient.use(createPlugin(React))
    : MinimalErrorBoundary;

export default ErrorBoundary;
