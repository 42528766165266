import PropTypes from 'prop-types';
import { reduce } from 'lodash';

import logger from 'lib/logger';

import allFeatures from './features';
import { LICENSE_TYPE_NON_TRANSFERABLE, LICENSE_TYPE_TRANSFERABLE } from './constants';

/**
 * Commonly-used PropType definitions
 */

/**
 *
 * A more liberal way to declare that a property should not be used,
 * while not failing prop type validation immediately.
 *
 * Example:
 * ```
 * Foo.propTypes = {
 *   deprecatedString: deprecated(PropTypes.string),
 * };
 * ```
 *
 * Pulled from https://github.com/facebook/prop-types/issues/156#issuecomment-466180282
 *
 * @param {PropType} validator Original property type
 * @return {PropType} PropType, but will warn about deprecated usage
 */
export function deprecated(validator) {
  return function cb(props, propName, ...rest) {
    if (props[propName] != null) {
      logger.warn(`${propName} is deprecated`);
    }
    return validator(props, propName, ...rest);
  };
}

/**
 * Cart resources
 */
export const cartPropTypes = PropTypes.shape({});

// Partial definition - fill in new fields as needed
export const cartProductPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  totalPrice: PropTypes.string.isRequired,
  totalFinalPrice: PropTypes.string.isRequired,
  totalDiscountPrice: PropTypes.string.isRequired,
  product: PropTypes.shape({
    author: PropTypes.object
  }).isRequired
});

export const gradePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  formLabel: PropTypes.string.isRequired,
  level: PropTypes.number,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

export const videoPropTypes = PropTypes.shape({
  id: PropTypes.string,
  duration: PropTypes.string,
  thumbnail: PropTypes.shape({
    location: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
  }),
  status: PropTypes.number,
  url: PropTypes.string,
  iframeUrl: PropTypes.string
});

export const supportingDocumentPropTypes = PropTypes.shape({
  url: PropTypes.string,
  previewUrl: PropTypes.string
});

/**
 * prop type for result of passing item type string returned by
 * graph through itemTypeFn
 */
export const itemTypePropType = PropTypes.shape({
  isBundle: PropTypes.bool,
  isDigitalProduct: PropTypes.bool,
  isGiftCertificate: PropTypes.bool,
  isHardGood: PropTypes.bool,
  isOnlineResource: PropTypes.bool,
  isUnknown: PropTypes.bool,
  isUsedGood: PropTypes.bool,
  isVideo: PropTypes.bool,
  type: PropTypes.string
});

export const uploadFormTypePropType = PropTypes.shape({
  isNewProduct: PropTypes.bool,
  isSimilarProduct: PropTypes.bool,
  isEdit: PropTypes.bool,
  isMigrateToBundle: PropTypes.bool,
  isMigrateToVideo: PropTypes.bool,
  itemId: PropTypes.string
});

export const thumbnailShape = PropTypes.shape({
  large: PropTypes.string,
  original: PropTypes.string,
  medium: PropTypes.string,
  small: PropTypes.string,
  home: PropTypes.string
});

export const facetShape = PropTypes.shape({
  category: PropTypes.string,
  count: PropTypes.number,
  id: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string
  })
});

export const bundleBonusPropTypes = PropTypes.shape({
  title: PropTypes.string,
  url: PropTypes.string,
  previewUrl: PropTypes.string
});

export const licensePropType = PropTypes.oneOf([
  LICENSE_TYPE_NON_TRANSFERABLE,
  LICENSE_TYPE_TRANSFERABLE
]);

// Prices for a specific license type
export const licensePricePropTypes = PropTypes.shape({
  price: PropTypes.string.isRequired,
  discountPrice: PropTypes.string.isRequired,
  salePrice: PropTypes.string.isRequired,
  additionalLicensePrice: PropTypes.string
});

// Price object describing all price information for a product
// TODO: add isRequired to properties when this is in place on API side and all queries updated
export const productPricePropTypes = PropTypes.shape({
  nonTransferableLicense: licensePricePropTypes,
  transferableLicense: licensePricePropTypes,
  isFree: PropTypes.bool
});

export const customCategoryPropType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.number
});

export const customCategoryWithResourceCountPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  resourceCount: PropTypes.number
});

export const productResultPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(thumbnailShape),
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  plainTextDescription: PropTypes.string,
  supportingDocument: PropTypes.shape({
    previewUrl: PropTypes.string,
    url: PropTypes.string
  }),
  filePreview: PropTypes.shape({
    filesize: PropTypes.number,
    format: PropTypes.string
  }),
  url: PropTypes.string,
  prices: productPricePropTypes,
  price: PropTypes.string,
  discountprice: PropTypes.string,
  itemType: PropTypes.string,
  author: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.shape({
      location: PropTypes.string
    })
  }),
  subjects: PropTypes.arrayOf(facetShape),
  intentAttributes: PropTypes.shape({
    learningGoal: PropTypes.string
  }),
  types: PropTypes.arrayOf(facetShape),
  usGrades: PropTypes.arrayOf(facetShape),
  commonCoreStandards: PropTypes.arrayOf(facetShape),
  isFree: PropTypes.bool,
  isWishlisted: PropTypes.bool,
  type: PropTypes.number,
  isDeleted: PropTypes.bool,
  bundle: PropTypes.shape({
    bonus: bundleBonusPropTypes
  }),
  resourceProperties: PropTypes.shape({
    isAvailableForDigital: PropTypes.bool,
    isSellerPublishedDigital: PropTypes.bool,
    isAvailableOnSchoolAccess: PropTypes.bool
  }),
  categories: PropTypes.arrayOf(customCategoryPropType)
});

export const filePropTypes = PropTypes.shape({});

export const authorPropTypes = PropTypes.shape({});

export const ratingPropTypes = PropTypes.shape({
  question: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired
});

export const commentPropTypes = PropTypes.shape({
  answerToUrl: PropTypes.string,
  author: authorPropTypes,
  comment: PropTypes.string,
  count: PropTypes.number,
  created: PropTypes.string,
  forItem: PropTypes.bool,
  helpfulness: PropTypes.number,
  id: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnails: PropTypes.arrayOf(PropTypes.string)
  }),
  notify: PropTypes.bool,
  ratings: PropTypes.arrayOf(ratingPropTypes),
  userId: PropTypes.number
});

/**
 * User resources
 */
export const sellerPropTypes = PropTypes.shape({
  created: PropTypes.string,
  first_name: PropTypes.string,
  group_id: PropTypes.number,
  id: PropTypes.number,
  image_url: PropTypes.string,
  last_name: PropTypes.string,
  name: PropTypes.string,
  premium: PropTypes.bool,
  premium_renewal_date: PropTypes.string
});

export const userAuthorPropTypes = PropTypes.shape({
  id: PropTypes.number
});

export const userAddressPropTypes = PropTypes.shape({
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  allowShowCity: PropTypes.bool
});

export const userProfilePropTypes = PropTypes.shape({
  displayName: PropTypes.string,
  fullName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  grades: PropTypes.arrayOf(PropTypes.string)
});

export const storePropTypes = PropTypes.shape({
  totalItems: PropTypes.number,
  averageRating: PropTypes.string,
  votes: PropTypes.number,
  icon: PropTypes.shape({
    location: PropTypes.string
  }),
  url: PropTypes.string
});

// Used on the My-Account/Sales-Tax page to show nexus regions list
export const sellerNexusPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  hasNexus: PropTypes.bool.isRequired,
  regionCode: PropTypes.string.isRequired,
  regionName: PropTypes.string.isRequired
});

// Globally-used Graph API user (and seller) data intended to be requested on all pages
export const memberDataPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  userProfile: userProfilePropTypes.isRequired,
  store: storePropTypes,
  premiumExpirationDate: PropTypes.string,
  isTestUser: PropTypes.bool.isRequired,
  unreadMessagesCount: PropTypes.number.isRequired
});

export const userOrganizationsPropTypes = PropTypes.shape({
  member: PropTypes.shape({
    organizations: PropTypes.shape({
      organizations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          address: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          orgAdmin: PropTypes.shape({
            isAdmin: PropTypes.bool.isRequired
          }),
          requests: PropTypes.arrayOf(
            PropTypes.shape({
              requester: PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                email: PropTypes.string
              }),
              requestId: PropTypes.string
            })
          ),
          admins: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string
            })
          )
        })
      )
    })
  }),
  loading: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string
  })
});

export const organizationUserPropTypes = PropTypes.shape({
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  created: PropTypes.string.isRequired
});

export const organizationInvitationPropTypes = PropTypes.shape({
  recipientEmail: PropTypes.string.isRequired,
  invitationHash: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired
});

export const organizationJoinRequestPropTypes = PropTypes.shape({
  id: PropTypes.string,
  requesterFirstName: PropTypes.string,
  requesterLastName: PropTypes.string,
  requesterSchoolEmail: PropTypes.string
});

/**
 * The `input` prop that's passed to components
 * that are used in redux-form's <Field> component
 *
 * (redux-form has some obnoxiously conflated nomenclature,
 * like 'form' being a form id and 'input' being an object :/)
 */
export const reduxFormInputPropTypes = PropTypes.shape({
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.any
});

/**
 * The `meta` prop that's passed to components
 * that are used in redux-form's <Field> component
 */
export const reduxFormMetaPropTypes = PropTypes.shape({
  active: PropTypes.bool,
  asyncValidating: PropTypes.bool,
  dirty: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool
});

/**
 * This is used in SchoolRequestsLayout. It holds a product, requester, and
 * time created.
 */
export const schoolRequestPropTypes = PropTypes.shape({
  product: productResultPropTypes.isRequired,
  requester: PropTypes.shape({
    id: PropTypes.userId,
    name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  message: PropTypes.string,
  requestState: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  requestedAt: PropTypes.string.isRequired
});

/**
 * This is used in the School Library. It holds a product, number of licenses bought,
 * number of licenses used, purchase item ID, and a list of the emails of all license
 * recipients.
 */
export const libraryPurchasePropTypes = PropTypes.shape({
  product: productResultPropTypes,
  licenseUsed: PropTypes.number,
  licenseRecipients: PropTypes.arrayOf(PropTypes.string),
  licensesBought: PropTypes.number,
  purchaseItemId: PropTypes.number
});

/**
 * This is used in the School Library to populate the License Recipients filter. It holds
 * the following fields about the recipient: email, userId, fullName, inOrg, licenseCount
 * (the number of school licenses they are currently in posession of).
 */
export const licenseRecipientPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  userId: PropTypes.userId,
  fullName: PropTypes.string,
  inOrg: PropTypes.bool.isRequired,
  licenseCount: PropTypes.number.isRequired
});

/**
 * This is used in the School Library to represent the currently selected filter.
 */
export const organizationPurchasesFilterPropTypes = PropTypes.shape({
  selectedLicenseRecipients: PropTypes.arrayOf(licenseRecipientPropTypes),
  showOnlyAvailableLicenses: PropTypes.bool
});

export const organizationPurchasesFilterDefaultProps = {
  selectedLicenseRecipients: [],
  showOnlyAvailableLicenses: false
};

/**
 * FAQ/HelpPage PropTypes
 */
export const faqArticleType = PropTypes.shape({
  id: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  categoryUrl: PropTypes.string
});

export const faqCategoryType = PropTypes.shape({
  id: PropTypes.number,
  parent_id: PropTypes.number,
  url: PropTypes.string,
  name: PropTypes.string,
  articles: PropTypes.arrayOf(faqArticleType)
});

export const faqParamsType = PropTypes.shape({
  parentCategory: faqCategoryType,
  currentCategory: faqCategoryType,
  currentArticle: faqArticleType,
  searchQuery: PropTypes.string,
  page: PropTypes.number,
  offset: PropTypes.number
});

export const faqHomeType = PropTypes.shape({
  helpful: PropTypes.arrayOf(faqArticleType),
  popular: PropTypes.arrayOf(faqArticleType)
});

export const faqSearchType = PropTypes.shape({
  totalCount: PropTypes.number,
  query: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    originalString: PropTypes.string
  }),
  results: PropTypes.arrayOf(faqArticleType)
});

export const schoolPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  stateAbbr: PropTypes.string,
  zip: PropTypes.string
});

/**
 * Wrap a given dictionary of proptype defitions in a generic 'gql' shape
 * (This should only be used with entries fetched via `@graphql`!)
 *
 * @param { !object } schema
 * @return { function }
 */
function wrapGraphqlSchema(schema) {
  return PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string
    }),
    fetchMore: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    networkStatus: PropTypes.number.isRequired,
    refetch: PropTypes.func.isRequired,
    startPolling: PropTypes.func.isRequired,
    stopPolling: PropTypes.func.isRequired,
    subscribeToMore: PropTypes.func.isRequired,
    updateQuery: PropTypes.func.isRequired,
    ...schema
  });
}

export const productAndSlugGraphqlPropTypes = wrapGraphqlSchema({
  products: PropTypes.arrayOf(productResultPropTypes),
  resolveSlug: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }),
  variables: PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.string.isRequired),
    slug: PropTypes.string
  })
});

export const productWishListGraphqlPropTypes = wrapGraphqlSchema({
  products: PropTypes.arrayOf(productResultPropTypes)
});

export const featureVariantsPropTypes = PropTypes.shape(
  reduce(
    allFeatures,
    (acc, key) => ({
      ...acc,
      [key]: PropTypes.string
    }),
    {}
  )
);

/**
 * GraphQL query PropType for featureVariants
 * (Distinct from `featureVariantsPropTypes`, which represents a simple
 * object built from the data returned by this query)
 */
export const featureVariantsGraphqlPropTypes = wrapGraphqlSchema({
  featureVariants: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.required,
      variant: PropTypes.string.required
    })
  ),
  variables: PropTypes.shape({
    names: PropTypes.arrayOf(PropTypes.string)
  })
});

export const bundleChildrenPropTypes = PropTypes.arrayOf(productResultPropTypes);

export const bundleRevisionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    created: PropTypes.string,
    products: PropTypes.arrayOf(productResultPropTypes)
  })
);

/**
 * Form type to distinguish between /My-Product/New/Bundle vs
 * /itemsBundle/migrate/$id vs /itemsBundle/edit/$id
 */
export const bundleFormTypePropTypes = PropTypes.shape({
  isNewProduct: PropTypes.bool,
  isEdit: PropTypes.bool,
  isMigrate: PropTypes.bool
});

export const multiSelectOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
);

export const singleSelectOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    displayValue: PropTypes.string.isRequired
  })
);

const onlineResource = {
  name: PropTypes.string,
  fileType: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string)
};
onlineResource.children = PropTypes.arrayOf(PropTypes.shape(onlineResource));

export const onlineResourcePropTypes = PropTypes.shape(onlineResource);

export const userLocationPropTypes = PropTypes.shape({
  city: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  stateAbbreviation: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string
});
