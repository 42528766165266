import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import Anchor from 'components/Anchor';
import EaselLogoBlack from 'shared/domains/TpTEasel/components/TpTEaselLogo/icons/easel-by-tpt-black.svg';
import { TPT_EASEL_BUYER_LANDING_URL, TPT_EASEL_HOME_URL } from 'shared/config/constants';
import EaselHeaderMenu from './EaselHeaderMenu';

import './EaselHeaderDropdown.scss';

const EaselHeaderDropdown = ({ isLoggedIn }) => {
  return (
    <Dropdown
      openOnHover
      className="EaselHeaderBespoke"
      label={
        <div className="EaselHeaderBespoke__label">
          <Anchor to={isLoggedIn ? TPT_EASEL_HOME_URL : TPT_EASEL_BUYER_LANDING_URL} openNewTab>
            <img className="EaselHeaderBespoke__easelLogo" src={EaselLogoBlack} alt="" />
          </Anchor>
        </div>
      }
      labelBy="Easel"
    >
      <EaselHeaderMenu isLoggedIn={isLoggedIn} />
    </Dropdown>
  );
};

EaselHeaderDropdown.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

export default EaselHeaderDropdown;
