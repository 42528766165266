import { isClassfundCheckoutPage } from 'domains/Contribution/helpers';
/**
 * Regex covering which page locations should show skinny header/footer
 */
const CART_PATH_REGEX = /^\/(next-)?cart/i;
const EVALUATION_PATH_REGEX = /^\/evaluation/i;

const isCartPage = (pathname) =>
  CART_PATH_REGEX.test(pathname) && !pathname.toLowerCase().includes('/done');

export const isEvaluationPage = (pathname) => EVALUATION_PATH_REGEX.test(pathname);
/**
 * Determines whether to show skinny version of header/footer on current page
 * @param { !object } location - current page location
 * @return { boolean }
 */
export function shouldShowSkinnyHeaderFooter(location) {
  return (
    isCartPage(location.pathname) ||
    isEvaluationPage(location.pathname) ||
    isClassfundCheckoutPage(location.pathname)
  );
}
