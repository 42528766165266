import PropTypes from 'prop-types';
import React from 'react';
import Grid from 'components/Grid';
import Row from 'components/Grid/Row';
import Col from 'components/Grid/Col';
import Logo from 'components/Logo';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import GlobalErrorsContainer from 'domains/Header/GlobalErrorsContainer/GlobalErrorsContainer';
import ClassFundInterruptSocialModalContainer from './ClassFundInterruptSocialModal/ClassFundInterruptSocialModalContainer';
import LoggedOutHeaderLayout from './LoggedOutHeader/LoggedOutHeaderLayout';
import './SkinnyHeaderLayout.scss';

export default function SkinnyHeaderLayout({
  isHidden,
  showLogIn,
  adminToolboxComponent,
  featureVariants,
  pathname
}) {
  if (isHidden) {
    return null;
  }
  return (
    <div className="SkinnyHeaderLayout">
      <ClassFundInterruptSocialModalContainer
        variant={featureVariants.classfund_interrupt_modal_v2}
        pathname={pathname}
        isLoggedIn={!showLogIn}
      />
      <Grid>
        <Row>
          <Col xs={9}>
            <Row>
              <Col xs={6}>
                <Logo />
              </Col>
            </Row>
          </Col>
          <Col xs={3} className="SkinnyHeaderLayout__LoggedOutHeaderLayoutContainer">
            {showLogIn && (
              <LoggedOutHeaderLayout showCart={false} featureVariants={featureVariants} />
            )}
          </Col>
        </Row>
      </Grid>
      <GlobalErrorsContainer responsive />
      {adminToolboxComponent}
    </div>
  );
}

SkinnyHeaderLayout.defaultProps = {
  isHidden: false,
  showLogIn: false,
  adminToolboxComponent: null,
  featureVariants: {}
};

SkinnyHeaderLayout.propTypes = {
  isHidden: PropTypes.bool,
  showLogIn: PropTypes.bool,
  adminToolboxComponent: PropTypes.node,
  pathname: PropTypes.string.isRequired,
  featureVariants: featureVariantsPropTypes
};
