/* eslint-env browser */

import Cookie from 'cookie';

/**
 * Compute fetch options for apollo-client to use when making API
 * requests from the *client*.
 * @param {object} options Input options for client fetch options.
 * @param {object=} options.req Request meta-data including headers.
 * @return {object} Options to pass to `fetch`.
 */
export default function computeClientFetchOptions({ req = {} } = {}) {
  const options = {
    credentials: 'include', // include cookies
    redirect: 'manual', // dont follow redirects
    headers: {
      accept: 'application/json',
      /**
       * Must be included in case XHR ends up in node so it knows to proxy
       * immediately
       */
      'x-requested-with': 'XMLHttpRequest'
    }
  };

  const { headers = {} } = req;
  const { cookie = '' } = headers;
  const { csrfToken } = Cookie.parse(cookie);

  if (csrfToken) {
    /**
     * This is neccessary for successful CSRF validation. We compare the CSRF
     * value in the cookie to the one in the X-CSRF header
     */
    options.headers['x-csrf-token'] = csrfToken;
  }

  return options;
}
