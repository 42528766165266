import React from 'react';
import './NortonVerify.scss';

export default function NortonVerify() {
  return (
    <div className="NortonVerify">
      <iframe src="/norton-verify.html" title="Norton Secured Seal" />
    </div>
  );
}
