import React from 'react';
import PropTypes from 'prop-types';
import easelSVGLogo from 'domains/Home/layouts/EaselMarketingBannerCarouselLayout/easel-by-tpt-logo.svg';

// TODO: See WEB-3465
const EaselByTpTLogo = ({ className }) => (
  <img src={easelSVGLogo} alt="Easel by TpT" height={34} className={className} />
);

EaselByTpTLogo.propTypes = {
  className: PropTypes.string
};
export default EaselByTpTLogo;
