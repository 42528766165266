/* eslint-env browser */
import { EventEmitter } from 'events';
import Cookies from 'js-cookie';
import { keyBy } from 'lodash';
import queryString from 'query-string';
import { ANONYMOUS_COOKIE_ID, getUserType, ITEM_TYPE_ONLINE_RESOURCE } from 'config/constants';
import { normalizeString, getDefaultCategoryName } from 'lib/analyticsHelpers';
import logger from 'lib/logger';
import { parseMoney } from 'util/utils';
import ExtensionsVariantsQuery from 'tpt_modules/features/queries/ExtensionsVariants.graphql';
import { isSellerTaggedDigital } from 'domains/ProductPage/helpers/digitalHelper';
import { sendLegacyGAEventAsHeapCustomEvent } from './heapAnalytics';

/**
 * Our serialized Money type has leading "$".
 * Strip any non-numeric/non-period characters.
 * '$10.00' (our Money) => '10.00' (GA "currency")
 * @param {string} moneyString
 * @return {string}
 */
function moneyToGaCurrency(moneyString) {
  return moneyString.replace(/[^\d.]/g, '');
}

/**
 * This matches the makeTextAlphaNumerical in PHP.
 * We replace all nbsp; entities, multiple spaces, line breaks and tabs with one space.
 * We also strip any characters that are not alphanumerical.
 * @param {string} text
 * @return {string}
 */
function normalizeTextForGA(text) {
  return text
    .replace(/&nbsp;/g, ' ')
    .replace(/[^a-zA-Z0-9-_ ]+/g, '')
    .replace(/\s{2,}/g, ' ');
}

// To debug events, add `?debug_analytics=true` to your URL.

// maps an array of fields into an object of `field: null` pairs

const createNullObject = (fields) => fields.reduce((agg, field) => ({ ...agg, [field]: null }), {});

const googleAnalyticsTracker = () => {
  if (!(CONFIG.gtmId && window.dataLayer)) {
    // return a no-op if dataLayer isn't ready for interaction
    return () => ({});
  }
  const previousFields = [
    'searchTermCD',
    'gradeSearchFilterCD',
    'searchResultsPageDepthCD',
    'resourceTypeSearchFilterCD',
    'subjectSearchFilterCD',
    'priceSearchFilterCD',
    'itemIdCD',
    'itemSellerCD',
    'itemRatingsCD',
    'itemPriceCD',
    'searchResultsItemDepthCD',
    'itemGradesCD',
    'itemSubjectsCD',
    'itemIdCD',
    'searchAmendmentCD',
    'newSearchFlagCD',
    'searchResultsPromoItemsCD',
    'userIDCD',
    'clientIDCD',
    'refCD'
  ];
  // clean out the fields on initialization of tracker,
  // safeguard to flush data layter from GTM
  const fieldsToReset = [
    'event',
    'eventType',
    'eventCat',
    'eventAct',
    'eventLbl',
    'eventTimestampCD',
    'refCD',
    'arefCD'
  ];
  fieldsToReset.push(...previousFields);
  window.dataLayer.push(createNullObject(fieldsToReset));

  return ({ eventType, eventOptions = {}, ...eventInfo }, shouldFlushDataLayer = false) => {
    if (shouldFlushDataLayer) {
      window.dataLayer.push(createNullObject(fieldsToReset));
    }
    // eventOptions has custom dimensions and eventInfo has event category, action and label
    const dataLayerObj = {
      event: eventType,
      eventTimestampCD: new Date().getTime(),
      ...eventOptions,
      ...eventInfo
    };
    window.dataLayer.push(dataLayerObj);
  };
};

function debugEvent(eventObject, optimizelyName) {
  if (
    window &&
    window.location &&
    window.location.search &&
    window.location.search.includes('debug_analytics')
  ) {
    /* eslint-disable no-console */
    console.groupCollapsed(
      `Analytics event | Type: ${eventObject.eventType} | Category: ${eventObject.eventCat} | Action: ${eventObject.eventAct} | Label: ${eventObject.eventLbl} | Value: ${eventObject.eventVal}`
    );
    console.dir(eventObject);
    console.log(`Optimizely event API Name: ${optimizelyName}`);
    console.groupEnd();
    /* eslint-enable no-console */
  }
}

class Analytics extends EventEmitter {
  // mapping of search page params to GA site search params
  gaSearchParamsMap = {
    Search: 'q',
    'PreK-12-Subject-Area': 'subjectarea',
    'Grade-Level': 'grade',
    'Price-Range': 'price',
    'Type-of-Resource': 'resourcetype'
  };

  constructor() {
    super();
    this.gtmAnalytics = googleAnalyticsTracker();
    this.history = [];
    this.tracker = CONFIG.analytics;
  }

  track(eventType, shouldFlushDataLayer, ...properties) {
    this.history.push({ eventType, properties });
    this.gtmAnalytics(eventType, shouldFlushDataLayer, ...properties);
    this.emit('track', eventType, properties);
  }

  /* eslint-disable max-len */
  /**
   * Track a Google Analytics event and an Optimizely Web event.
   * @param { object } eventOpts
   *   @config { !string } eventCat - required, Usually the object that was interacted with ('Video')
   *   @config { !string } eventAct - required, The type of interaction ('play')
   *   @config { string= } eventLbl - optional, Useful for categorizing events ('Fall Campaign')
   *   @config { number= } eventVal - optional
   *   @config { object= } eventOptions - optional, Additional options
   * @param { boolean } shouldFlushDataLayer
   * @return { undefined }
   */
  /* eslint-enable max-len */
  trackEvent(eventData, shouldFlushDataLayer = false) {
    const { eventCat, eventAct, eventLbl, eventVal, eventOptions } = eventData;
    sendLegacyGAEventAsHeapCustomEvent(eventData);
    const eventObject = {
      eventType: 'eventTracker',
      eventCat: normalizeString(eventCat || getDefaultCategoryName(window.location.pathname)),
      eventAct: normalizeString(eventAct),
      eventLbl: normalizeString(eventLbl),
      eventVal,
      eventOptions
    };
    this.track(eventObject, shouldFlushDataLayer);
    const optimizelyName = this.getOptimizelyEventName(eventCat, eventAct, eventLbl);
    this.sendEventToOptimizely(optimizelyName);
    debugEvent(eventObject, optimizelyName);
  }

  trackThumbnailHoverEvent(eventAction) {
    if (global.heap) {
      global.heap.track(eventAction, {
        category: 'Item Actions',
        label: 'Product Page'
      });
    }
  }

  constructGaProducts(items, orderId) {
    if (!items) {
      return [];
    }

    return items.map(({ product, itemId, price }) => {
      const { name, author, subjects, resourceProperties, itemType, types } = product || {};

      const category = (subjects.length > 0 && subjects[0].name) || '';

      // TODO: This is currently set to match what exists in the legacy PHP checkout but can be updated in the future once checking with data and product
      return {
        id: orderId,
        name: normalizeTextForGA(name),
        sku: itemId,
        item_seller: (author && author.id) || '',
        quantity: '1',
        category: normalizeTextForGA(category),
        price: moneyToGaCurrency(price),
        isEnabledForDigital: resourceProperties?.isAvailableForDigital || false,
        isSellerPublishedDigital: resourceProperties?.isSellerPublishedDigital || false,
        isOnlineResource: itemType === ITEM_TYPE_ONLINE_RESOURCE,
        isSellerTaggedDigital: types ? isSellerTaggedDigital(types) : false
      };
    });
  }

  /**
   * @param {Order} - TPT Order object (see format in CartCheckoutWithBillingAddress.graphql)
   * @param {function} [callback] - optional callback that *might*
   *  be fired when GA tracking completes (call must succeed, user must not
   *  have tracking blocked for it to be called)
   * @return {void}
   */
  trackEcommerce({ id, totalAmount, orderItems, taxAmount }, callback = () => {}) {
    const gaProducts = this.constructGaProducts(orderItems, id);

    window.dataLayer.push({
      event: 'ecommerceEventTracker',
      eventCat: 'Ecommerce',
      eventAct: 'Purchase',
      eventLbl: 'Completed',
      eventVal: 0,
      nonInteraction: 0,
      itemIdCD: JSON.stringify(gaProducts),

      // TODO: validate whether "fee" field is reasonable approximation of what we want here
      // tptCommissionCD: moneyToGaCurrency(fee),

      ecommerce: {
        purchase: {
          actionField: {
            id: parseInt(id, 10),
            revenue: moneyToGaCurrency(totalAmount),
            shipping: '0.00',
            tax: moneyToGaCurrency(taxAmount)
          },
          products: gaProducts
        }
      },
      // eslint-disable-next-line object-shorthand
      eventCallback: function () {
        window.dataLayer.push({ ecommerce: undefined });
        callback();
      }
    });

    // Send event to Heap as well
    if (global.heap) {
      // First send the overall order to Heap, see best practices
      // https://docs.heap.io/docs/tracking-purchases-in-heap
      global.heap.track('Purchase Completed', {
        category: 'Ecommerce',
        label: 'Completed',
        orderId: parseInt(id, 10),
        revenue: moneyToGaCurrency(totalAmount),
        tax: moneyToGaCurrency(taxAmount)
      });

      gaProducts.forEach((item) => {
        // Now send one event per item
        global.heap.track('Item Purchase Completed', item);
      });
    }
  }

  getOptimizelyEventName(eventCat, eventAct, eventLbl) {
    // Total length must be below 64 characters.
    // Only letters, numbers, hyphens, underscores, spaces, periods.
    function sanitizeForOptimizely(part = '') {
      return String(part)
        .replace(/[^\w\-_\s.]/g, '')
        .substring(0, 20);
    }
    const eventCatSanitized = sanitizeForOptimizely(eventCat);
    const eventActSanitized = sanitizeForOptimizely(eventAct);
    const eventLblSanitized = sanitizeForOptimizely(eventLbl);
    return `${eventCatSanitized}_${eventActSanitized}_${eventLblSanitized}`;
  }

  sendEventToOptimizely(eventName) {
    window.optimizely = window.optimizely || [];
    window.optimizely.push({
      type: 'event',
      eventName
    });
  }

  /**
   * Retrieve the anonymous ID from cookies. Anonymous IDs are stored with
   * a long cookie lifetime in order to persistently track a particular browser
   * over time.
   *
   * @return { !string }
   */
  getAnonId() {
    return Cookies.get(ANONYMOUS_COOKIE_ID) || null;
  }

  /**
   * Used to push custom dimensions to the dataLayer which will be
   * sent to GA with the next pageview event
   *
   * @param { array } properties
   * @return { undefined }
   */
  pushProperties(properties) {
    window.dataLayer.push(properties);
  }

  /**
   * Used to set user-specific data before tracking
   *
   * @param { boolean } isLoggedIn
   * @param { object } user
   * @param { number } user.id
   * @param { number } user.group_id
   * @return { void }
   */
  pushUserMetaData(isLoggedIn, user) {
    const properties = {
      loggedInStatusCD: isLoggedIn.toString(),
      userID: user.id,
      userGroupCD: getUserType(user.group_id),
      anonymousIDCD: this.getAnonId()
    };
    window.dataLayer.push(properties);
  }

  trackPageView() {
    const pageAttributes = {};

    /* eslint-disable no-useless-escape */
    const arefRE = /^aref=([a-z0-9\/\-_]+)/;
    const refRE = /^ref=([a-z0-9\/\-_]+)/;
    /* eslint-enable no-useless-escape */
    let searchParams = false;
    let refMatches = false;
    let arefMatches = false;
    if (window.location.search && window.location.search.length > 2) {
      searchParams = window.location.search.slice(1).split('&');
      /* eslint-disable no-return-assign */
      searchParams.forEach((value) => (refMatches = refMatches || value.match(refRE)));
      searchParams.forEach((value) => (arefMatches = arefMatches || value.match(arefRE)));
      /* eslint-enable no-return-assign */
      pageAttributes.refCD = refMatches ? refMatches[1].split('/') : '';
      pageAttributes.arefCD = arefMatches ? arefMatches[1].split('/') : '';
    }

    // add page path params
    // widget links have a `widgetref` param that is set to the widget's parent page.
    // page views coming from widgets should have referrer set to the widget's parent page
    pageAttributes.referrer =
      document.referrer.indexOf('widgetref=') !== -1
        ? document.referrer.split('widgetref=')[1]
        : document.referrer;
    const gaSearchParams = document.URL.includes('/Browse/') ? this.getGaSearchParams() : '';
    pageAttributes.pageUrl = `${document.URL}${gaSearchParams}`;
    pageAttributes.pagePath = `${document.location.pathname}${gaSearchParams}`;

    this.track({
      eventType: 'pageviewTracker',
      eventOptions: pageAttributes,
      abTestsCD: this.formatAbTests(this.getAnalyticsAbTests())
    });

    if (global.heap) {
      // This serves as an alternate to the page view event for analyzing experiment funnels.
      // Unlike the Page View event, this event is guaranteed to have the attached experiment variants.
      global.heap.track('Page View Experiments', this.getAnalyticsAbTests());

      // Adds heap id for Easel marketing
      const easelQuery = window.location.search;
      const parsed = queryString.parse(easelQuery);
      if (parsed && parsed.h_anon_id) {
        global.heap.addEventProperties({
          easel_marketing_id: parsed.h_anon_id
        });
      }
    }

    // reset AB tests once pageview is fired
    window.abTests = {};
  }

  getGaSearchParams = () => {
    /*
      Add GA site search params for search pages only
      Example should add params 'q=amazing%20race&subjectarea=Math&grade=Fourth' to
      '/Browse/Search:amazing%20race/Grade-Level/Fourth/PreK-12-Subject-Area/Math'
    */
    const searchParams = [];
    Object.entries(this.gaSearchParamsMap).forEach(([key, value]) => {
      if (document.URL.includes(key)) {
        // eslint-disable-next-line no-useless-escape
        const regexStr = `/${key}(\/|:)(.+?)($|\\/|\\?)`;
        const regex = new RegExp(regexStr, 'i');
        const [, , keyValue = ''] = document.URL.match(regex) || [];
        // remove '\' from search keywords
        const cleanKeyValue = encodeURI(decodeURI(keyValue).replace('\\', ''));
        searchParams.push(`${value}=${cleanKeyValue}`);
      }
    });
    return `?${searchParams.join('&')}`;
  };

  /**
   * Adds AB tests to window.abTests which is formatted
   * and sent to Heap with the next page view event.
   * alwaysOnAbTests is a param temporarily added to hardcode
   * feature variants info in Heap without calling the API
   * this is useful for experience that we KNOW are on
   * based purely on the fact we're in the React code base.
   *
   * NOTE: This should not be called inside a `useEffect` hook.
   * The page view is tracked in `componentDidMount` from the
   * `ReactEntry` component and `componentDidMount` runs _before_
   * regular effects. If you'd like to register components in a
   * functional component, use `useLayoutEffect` or convert to a
   * class component and use `componentDidMount`.
   *
   * @param {array} props
   * @param {boolean=} alwaysOnAbTests
   * @return { undefined }
   */
  registerAbTests(props, alwaysOnAbTests) {
    window.abTests = window.abTests || {};

    if (props.featureVariants) {
      Object.assign(window.abTests, props.featureVariants);
    }

    if (props.client) {
      try {
        const { extensionsVariants } = props.client.readQuery({ query: ExtensionsVariantsQuery });
        window.abTestsExtensions = keyBy(extensionsVariants, 'test_name');
      } catch (e) {
        logger.warn('Failed to read extensionsVariants:', { err: e });
      }
    }

    if (alwaysOnAbTests) {
      window.abTests[alwaysOnAbTests] = 'on';
    }
  }

  /**
   * Returns window.abTests object.
   * Note that this function is used in legacy php pages in googlytics_trac.js.
   *
   * @return { !object }
   */
  getAnalyticsAbTests() {
    return window.abTests || {};
  }

  /**
   * Report GMV to analytics services.
   * Call only once after a purchase is made.
   * @param {string} amount Amount of money spent, e.g., "$12.55".
   * @return {undefined}
   */
  trackGMV(amount) {
    const valueInCents = Math.floor(parseMoney(amount) * 100);
    window.optimizely = window.optimizely || [];
    window.optimizely.push({
      type: 'event',
      eventName: 'trackRevenue',
      tags: {
        revenue: valueInCents
      }
    });
  }

  /**
   * Returns ab tests in test1,variant1,test2,variant2 format.
   * Note that this function is used in legacy php pages in googlytics_trac.js.
   *
   * @param {array} props
   * @return {!array}
   */
  formatAbTests(props = {}) {
    return Object.entries(props)
      .map(([name, variant]) => `${name},${variant}`)
      .join(';');
  }

  saveSearchClick(itemInfo) {
    const {
      itemPosition,
      product: {
        id: productId,
        ratings,
        subjects,
        usGrades,
        price,
        author: { id: sellerId }
      },
      search,
      page,
      facets = {}
    } = itemInfo;

    const {
      subjects: subjectsFacets,
      resourceTypes: types,
      priceRanges: prices,
      grades: gradesFacets
    } = facets;

    const pluckName = (arr) => (arr || []).map((ele) => ele.name).join(',');

    const itemSubjects = pluckName(subjects);
    const itemGrades = pluckName(usGrades);
    const subjectsFilters = pluckName(subjectsFacets);
    const gradesFilters = pluckName(gradesFacets);
    const typeFilters = pluckName(types);
    const priceFilters = pluckName(prices);

    const itemFacets = {
      subjects: subjectsFilters,
      grades: gradesFilters,
      price: priceFilters,
      type: typeFilters
    };
    const d = new Date();
    const clickData = {
      item_id: productId,
      time: d.getTime(),
      search,
      position: itemPosition,
      page,
      item_seller: sellerId,
      item_votes: ratings,
      item_price: price,
      item_grades: itemGrades,
      item_subjects: itemSubjects,
      filters: itemFacets
    };

    Cookies.set('SCITEM', clickData, {
      domain: CONFIG.cookieDomain,
      expires: 100
    });
  }

  activateOptimize() {
    window.dataLayer && window.dataLayer.push({ event: 'optimize.activate' });
  }

  /**
   * Clears heap global search token attribute
   * @return {void}
   */
  clearSearchTokenAttribute = () => {
    if (global.heap) {
      global.heap.removeEventProperty('searchToken');
    }
  };

  /**
   * Adds heap global search token attribute
   * @param {string} searchToken - algolia search token hash
   * @return {void}
   */
  addSearchTokenAttribute = (searchToken) => {
    if (global.heap && searchToken) {
      global.heap.addEventProperties({ searchToken });
    }
  };
}

const analyticsStub = {
  getAnonId: () => '0',
  registerAbTests: () => null,
  trackEvent: () => null,
  pushProperties: () => null,
  activateOptimize: () => null,
  clearSearchTokenAttribute: () => null,
  addSearchTokenAttribute: () => null
};

// checking for existence of window since it is not available
// during testing (watch mode) when the module is just required
export default IS_BROWSER && typeof window !== 'undefined' ? new Analytics() : analyticsStub;
