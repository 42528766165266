import React from 'react';
import PropTypes from 'prop-types';
import { featureVariantsPropTypes } from 'config/prop-definitions';
import ToggleableDrawer from 'domains/Responsive/Drawer/ToggleableDrawer';
import Logo from 'components/Logo';
import Button from 'components/LegacyButton';
import Row from 'components/ResponsiveGrid/ResponsiveRow';
import Col from 'components/ResponsiveGrid/ResponsiveCol';
import HeaderCartContainer from 'domains/Header/HeaderCart/HeaderCartContainer';
import HeaderNavList from 'domains/Header/HeaderNavList';
import ResponsiveCategoryMenu from './ResponsiveCategoryMenu';

const PrimaryHeaderLayout = ({
  isLoggedIn,
  isMobile,
  isSeller,
  isPremiumSeller,
  featureVariants
}) => {
  const shouldShowEasel =
    featureVariants.digital_activities_nav_bar === 'on' &&
    !['header_adoption', 'all_changes'].includes(featureVariants.easel_education);

  return (
    <Row className="PrimaryHeaderLayout" alignItems="center">
      <Col auto className="d-lg-none">
        <ToggleableDrawer
          withHeader
          toggleNode={<Button skin="primary outline" label="Menu" size="sm" />}
          anchor="left"
        >
          <ResponsiveCategoryMenu
            isLoggedIn={isLoggedIn}
            isSeller={isSeller}
            isPremiumSeller={isPremiumSeller}
            isMobile={isMobile}
            featureVariants={featureVariants}
          />
        </ToggleableDrawer>
      </Col>
      <Col>
        <div className="PrimaryHeaderLayout__Logo">
          <Logo />
        </div>
      </Col>
      <Col lg={8} className="d-none d-lg-block">
        <HeaderNavList
          shouldShowSchools={!isLoggedIn}
          shouldShowDigitalActivities={shouldShowEasel}
          isLoggedIn={isLoggedIn}
          isSeller={isSeller}
          isPremiumSeller={isPremiumSeller}
        />
      </Col>
      <Col auto className="d-lg-none">
        <HeaderCartContainer isResponsive />
      </Col>
    </Row>
  );
};

PrimaryHeaderLayout.defaultProps = {
  isLoggedIn: false,
  isMobile: false,
  isSeller: false,
  isPremiumSeller: false,
  featureVariants: {}
};

PrimaryHeaderLayout.propTypes = {
  isLoggedIn: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSeller: PropTypes.bool,
  isPremiumSeller: PropTypes.bool,
  featureVariants: featureVariantsPropTypes
};

export default PrimaryHeaderLayout;
