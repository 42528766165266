// Component spec: https://docs.google.com/document/d/13MzZT3IDHa4ELZNqpTNjh4TUKIetCeEVIWHJF2w0cNA/edit

import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/Logo';

const DrawerHeader = ({ children }) => (
  <div className="Drawer__header">
    <Logo isSymbol />
    {children}
  </div>
);

DrawerHeader.propTypes = {
  children: PropTypes.node
};

export default DrawerHeader;
