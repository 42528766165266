import logger from 'lib/logger';

/**
 * https://github.com/sindresorhus/query-string/blob/master/index.js#L138-L150
 * This module was added because query-string's implementation of decoding parameters
 * throws when decodeURIComponent receives an improperly formatted query string.
 * I am monkeypatching decodeURIComponent code to handle the throwing and return
 * the query parameter as is when it fails.
 * @param { !string } key
 * @param { * } value
 * @param { !object } accumulator
 * @return { undefined }
 */
function formatter(key, value, accumulator) {
  if (accumulator[key] === undefined) {
    // eslint-disable-next-line no-param-reassign
    accumulator[key] = value;
    return;
  }

  // eslint-disable-next-line no-param-reassign
  accumulator[key] = [].concat(accumulator[key], value);
}

// only difference between original is this function
function tryDecode(encodedStr) {
  try {
    return decodeURIComponent(encodedStr);
  } catch (e) {
    logger.debug(`the following string could not be decoded: ${encodedStr}`);
    return encodedStr;
  }
}

export function parseQueryString(str) {
  const ret = Object.create(null);
  str.split('&').forEach((param) => {
    const parts = param.replace(/\+/g, ' ').split('=');
    // Firefox (pre 40) decodes `%3D` to `=`
    // https://github.com/sindresorhus/query-string/pull/37
    const key = parts.shift();
    let val = parts.length > 0 ? parts.join('=') : undefined;

    // missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    val = val === undefined ? null : tryDecode(val);

    formatter(tryDecode(key), val, ret);
  });

  return ret;
}
