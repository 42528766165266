import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { pickHTMLProps } from 'pick-react-known-prop';

import './ResponsiveCol.scss';

const AlignmentModificatorType = PropTypes.oneOf(['start', 'center', 'end']);
const MediaQueryModiferTypes = PropTypes.oneOfType([PropTypes.number, PropTypes.bool]);

const Col = (props) => {
  const { alignSelf, auto, col, className, collapsed, containerRef, ...otherProps } = props;

  // filter out media query modifiers
  const colKeys = Object.keys(otherProps).filter((key) => key.match(/^(xs|sm|md|lg|xl)$/));

  // add media query modifiers
  const classes = [].concat(
    colKeys.map((key) => {
      if (typeof props[key] === 'number') {
        return `col-${key}-${props[key]}`;
      } else if (props[key] === true) {
        return `col-${key}`;
      }
      return '';
    })
  );

  // add auto class
  if (auto) {
    classes.push('col-auto');
  }

  // default to `col` class if no other classes are present
  // or if explicitly desired
  if (classes.length < 1 || col) {
    classes.push('col');
  }
  // add align-self class
  if (typeof alignSelf === 'string') {
    classes.push(`align-self-${alignSelf}`);
  }

  const collapsedClasses = ['Col--collapsed'];

  return (
    <div
      {...pickHTMLProps(otherProps)}
      // FIXME: needed because pick-react-known-prop does not include props added in React 15
      {...{ onTransitionEnd: otherProps.onTransitionEnd }}
      className={classnames(collapsed ? collapsedClasses : classes, className)}
      ref={containerRef}
    >
      {props.children}
    </div>
  );
};

Col.defaultProps = {
  alignSelf: undefined,
  className: undefined
};

Col.propTypes = {
  /* eslint-disable react/no-unused-prop-types, react/require-default-props */
  xs: MediaQueryModiferTypes,
  sm: MediaQueryModiferTypes,
  md: MediaQueryModiferTypes,
  lg: MediaQueryModiferTypes,

  /** Callback to receive DOM ref for outer container */
  containerRef: PropTypes.func,

  /* Set to true for col to take after width of child i.e. .col-auto */
  auto: PropTypes.bool,

  /* Set to true to explicitly add `col` class */
  col: PropTypes.bool,

  /* eslint-enable react/no-unused-prop-types, react/require-default-props */

  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  alignSelf: AlignmentModificatorType,
  collapsed: PropTypes.bool
};

export default Col;
