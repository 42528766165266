import React from 'react';
import PropTypes from 'prop-types';
import { featureVariantsPropTypes, memberDataPropTypes } from 'config/prop-definitions';
import withUserData from 'decorators/withUserData';
import BuyerHeaderLayout from './BuyerHeaderLayout';
import ResponsiveBuyerHeaderLayout from './ResponsiveBuyerHeaderLayout';

const BuyerHeaderContainer = ({ isResponsive, featureVariants, userData: { member } = {} }) => {
  const memberData = member || {};

  // It is possible for the buyer header to display when the user is not actually logged in,
  // just looks logged in. In such a case we show "Tpt Buyer" as default username.
  const userProfile = memberData.userProfile || {
    firstName: 'TpT',
    displayName: 'TpT Buyer'
  };
  const unreadMessagesCount = memberData.unreadMessagesCount || 0;

  if (isResponsive) {
    return (
      <ResponsiveBuyerHeaderLayout
        firstName={userProfile.firstName}
        name={userProfile.displayName}
        countUnreadMessages={unreadMessagesCount}
      />
    );
  }
  return (
    <BuyerHeaderLayout
      firstName={userProfile.firstName}
      name={userProfile.displayName}
      countUnreadMessages={unreadMessagesCount}
      featureVariants={featureVariants}
    />
  );
};

BuyerHeaderContainer.propTypes = {
  isResponsive: PropTypes.bool,
  userData: PropTypes.shape({ member: memberDataPropTypes }).isRequired,
  featureVariants: featureVariantsPropTypes
};

BuyerHeaderContainer.defaultProps = {
  isResponsive: false,
  featureVariants: {}
};

const BuyerHeaderContainerWithUserData = withUserData({ skip: false })(BuyerHeaderContainer);
export default BuyerHeaderContainerWithUserData;
