import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Box from 'components/Box';
import {
  flexProps,
  alignItemsProps,
  justifyContentProps,
  alignContentProps,
  alignSelfProps,
  flexDirectionProps
} from './propTypes';
import './FlexBox.scss';

const ROOT_CLASSNAME = 'FlexBox';

const FlexBox = (props) => {
  const {
    alignItems,
    alignContent,
    alignSelf,
    children,
    className,
    flex,
    flexDirection,
    justifyContent,
    wrap,
    ...other
  } = props;
  const classes = classnames(
    {
      [`${ROOT_CLASSNAME}--alignItems-${alignItems}`]: alignItems,
      [`${ROOT_CLASSNAME}--alignContent-${alignContent}`]: alignContent,
      [`${ROOT_CLASSNAME}--alignSelf-${alignSelf}`]: alignSelf,
      [`${ROOT_CLASSNAME}--justifyContent-${justifyContent}`]: justifyContent,
      [`${ROOT_CLASSNAME}--flexDirection-${flexDirection}`]: flexDirection,
      [`${ROOT_CLASSNAME}--flexWrap`]: wrap !== false,
      [`${ROOT_CLASSNAME}--flex-${flex}`]: flex
    },
    className
  );

  return (
    <Box display="flex" className={classes} {...other}>
      {children}
    </Box>
  );
};

FlexBox.defaultProps = {
  wrap: false
};

FlexBox.propTypes = {
  /** Defines the default behaviour for how flex items are laid out along the
   * cross-axis on the current line. Think of it as the justify-content version
   * for the cross-axis (perpendicular to the main-axis).
   */
  alignItems: PropTypes.oneOf(alignItemsProps),

  /** Aligns a flex container's lines within when there is extra space in the
   * cross-axis, similar to how justify-content aligns individual items
   * within the main-axis.
   */
  alignContent: PropTypes.oneOf(alignContentProps),

  /** Overrides a flex item's align-items value. It aligns the item on the cross axis. */
  alignSelf: PropTypes.oneOf(alignSelfProps),

  className: PropTypes.string,

  children: PropTypes.node,

  /** Sets how a flex item will grow or shrink to fit the space available in its flex container. */
  flex: PropTypes.oneOf(flexProps),

  /** Specifies the direction in which flex items are laid out. */
  flexDirection: PropTypes.oneOf(flexDirectionProps),

  /** Aligns flex items along the main axis of the current line of the flex container. */
  justifyContent: PropTypes.oneOf(justifyContentProps),

  /** Sets whether flex items are forced onto one line or can wrap onto multiple lines. */
  wrap: PropTypes.bool
};

export default FlexBox;
