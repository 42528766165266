import { DIGITAL_RESOURCE_TYPES } from 'config/constants';

const EASEL_REDIRECT_PATH = '/easel';
const EASEL_IMPORT_PATH = '/import?sourceIdentifier=';

export const isSellerTaggedDigital = (resourceTypes) =>
  resourceTypes.some((resourceType) => DIGITAL_RESOURCE_TYPES.includes(resourceType.name));

export const getImportToDigitalToolUrl = ({ id, isLoggedIn, ref = '' }) => {
  const refParam = ref ? `&ref=${ref}` : '';
  if (isLoggedIn) {
    const host = CONFIG.endpoints.apis.easelFrontend;

    return `${host}${EASEL_IMPORT_PATH}${id}${refParam}`;
  }
  return `/Login?f=${EASEL_REDIRECT_PATH}?path=${EASEL_IMPORT_PATH}${id}${refParam}`;
};

export const getImportToEaselAssessmentUrl = ({ resourceId, assessmentId }) => {
  const host = CONFIG.endpoints.apis.easelFrontend;
  return `${host}/import?contentType=Assessment&contentId=${assessmentId}&resourceId=${resourceId}`;
};
