import React from 'react';
import PropTypes from 'prop-types';
import Anchor from 'components/Anchor';

import './NavigationHeaderPill.scss';

const NavigationHeaderPill = ({ label, url, className }) => (
  <Anchor
    className={className ? `NavHeaderPill ${className}` : `NavHeaderPill`}
    color="inherit"
    forceReload
    decorateOnHover={false}
    to={url}
  >
    {label}
  </Anchor>
);

NavigationHeaderPill.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default NavigationHeaderPill;
