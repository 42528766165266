import React from 'react';
import Router from 'react-router/lib/Router';
import RouterContext from 'react-router/lib/RouterContext';
import IntlProvider from 'react-intl/src/components/provider';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import localeMessages from 'util/localeMessages';

export default function createTree(store, renderProps, apolloClient) {
  const { locale = 'en-US' } = store.getState().config;

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={localeMessages[locale]}>
          {IS_BROWSER ? <Router {...renderProps} /> : <RouterContext {...renderProps} />}
        </IntlProvider>
      </Provider>
    </ApolloProvider>
  );
}
