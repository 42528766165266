import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon';
import Dropdown from 'components/Dropdown';

import './SubMenuPrefab.scss';

const SubMenuPrefab = ({ className, children, label, ...rest }) => (
  <Dropdown
    {...rest}
    label={
      <div className="SubMenuPrefabLabelLayout" data-testid="SubMenuPrefabLabelLayout">
        <span className="label">{label}</span>
        <span className="SubMenuPrefab__icon">
          <Icon name="angle-right" />
        </span>
      </div>
    }
    className={classnames('SubMenuPrefab', className)}
    openOnHover
  >
    {children}
  </Dropdown>
);

SubMenuPrefab.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  // TODO: Make `label` type consistent!
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default SubMenuPrefab;
