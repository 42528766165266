import PropTypes from 'prop-types';
import React from 'react';
import Anchor from 'components/Anchor';
import { featureVariantsPropTypes } from 'shared/config/prop-definitions';
import HeaderCartContainer from '../HeaderCart/HeaderCartContainer';
import EaselHeaderLayout from '../EaselHeader/EaselHeaderLayout';

import './LoggedOutHeaderLayout.scss';

const LoggedOutHeaderLayout = ({ showCart = true, isResponsive, featureVariants = {} }) => {
  const isEaselEducationExperiment = ['header_adoption', 'all_changes'].includes(
    featureVariants.easel_education
  );

  if (isResponsive) {
    return (
      <ul className="Menu">
        <li className="MenuItem">
          <Anchor color="gray" forceReload to="/Signup">
            Join Us
          </Anchor>
        </li>

        <li className="MenuItem">
          <Anchor color="gray" forceReload to="/Login">
            Log In
          </Anchor>
        </li>
      </ul>
    );
  }
  return (
    <div className="LoggedOutHeaderLayout">
      {isEaselEducationExperiment && (
        <div className="LoggedOutHeaderLayout__easelContainer">
          <EaselHeaderLayout isLoggedIn={false} showNew />
        </div>
      )}
      <div className="LoggedOutHeaderLayout__linkContainer">
        <span className="LoggedOutHeaderLayout__link">
          <Anchor to="/Login" forceReload color="black">
            Log In
          </Anchor>
        </span>
        <span className="LoggedOutHeaderLayout__link">
          <Anchor to="/Signup" forceReload color="black">
            Join Us
          </Anchor>
        </span>
      </div>
      {showCart && (
        <div className="LoggedOutHeaderLayout__cartContainer">
          <HeaderCartContainer isShortened={isEaselEducationExperiment} />
        </div>
      )}
    </div>
  );
};

LoggedOutHeaderLayout.propTypes = {
  showCart: PropTypes.bool,
  isResponsive: PropTypes.bool,
  featureVariants: featureVariantsPropTypes
};

export default LoggedOutHeaderLayout;
