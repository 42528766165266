import PropTypes from 'prop-types';
import React from 'react';
import { featureVariantsPropTypes, memberDataPropTypes } from 'config/prop-definitions';
import withUserData from 'decorators/withUserData';
import UserDetailsRow from 'domains/Responsive/UserDetailsRow';
import { isPremiumSeller, isTpTAdmin } from 'config/constants';
import SellerDropdownMenu from './SellerDropdown/SellerDropdownMenu';
import SellerHeaderLayout from './SellerHeaderLayout';

const SellerHeaderContainer = ({ isResponsive, userData: { member } = {}, featureVariants }) => {
  const userData = member || {};
  const userProfile = userData.userProfile || {};
  const store = userData.store || {};
  const storeUrl = store.url || '#';
  const storeIcon = store.icon || {};
  const countUnreadMessages = userData.unreadMessagesCount || 0;
  const isPremiumSellerOrAdmin = isPremiumSeller(userData.groupId) || isTpTAdmin(userData.groupId);

  if (isResponsive) {
    return (
      <div>
        <UserDetailsRow
          loggedIn
          url={storeUrl}
          imageUrl={storeIcon.location}
          name={userProfile.firstName || userProfile.displayName}
        />

        <SellerDropdownMenu
          colProps={{ sm: 12, md: 4, lg: 4 }}
          countUnreadMessages={countUnreadMessages}
          storeUrl={storeUrl}
          isPremiumSellerOrAdmin={isPremiumSellerOrAdmin}
        />
      </div>
    );
  }
  return (
    <SellerHeaderLayout
      firstName={userProfile.firstName}
      name={userProfile.displayName}
      imageUrl={storeIcon.location}
      countUnreadMessages={countUnreadMessages}
      storeUrl={storeUrl}
      isPremiumSellerOrAdmin={isPremiumSellerOrAdmin}
      featureVariants={featureVariants}
    />
  );
};

SellerHeaderContainer.defaultProps = {
  isResponsive: false,
  featureVariants: {}
};

SellerHeaderContainer.propTypes = {
  isResponsive: PropTypes.bool,
  userData: PropTypes.shape({ member: memberDataPropTypes }).isRequired,
  featureVariants: featureVariantsPropTypes
};

export default withUserData({ skip: false })(SellerHeaderContainer);
