import React, { Component } from 'react';
import PropTypes from 'prop-types';
import analytics from 'lib/analytics';
import AsyncSchoolsAccessSellerEnrollmentModalLayout from './AsyncSchoolsAccessSellerEnrollmentModalLayout';

export const ACCESS_MODAL_CLOSED_BY_USER_KEY = 'userClosedAccessModal';

class SchoolsAccessSellerEnrollmentModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTimeModalOpened: false
    };
  }

  componentDidMount = () => {
    const sellerHasSeenModalBefore = this.userClosedSellerEnrollmentModal();
    const savedLocalStorageItem = this.saveShowedModalToLocalStorage();

    if (!sellerHasSeenModalBefore && savedLocalStorageItem) {
      // Render relies on clientside-only localStorage
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        isFirstTimeModalOpened: true
      });
    }
  };

  saveShowedModalToLocalStorage = () => {
    try {
      localStorage.setItem(ACCESS_MODAL_CLOSED_BY_USER_KEY, true);
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        'Failed to set localStorage for Schools Access Seller Enrollment interrupt modal',
        e
      );
    }
    return false;
  };

  closeModal = () => {
    this.setState({ isFirstTimeModalOpened: false });

    analytics.trackEvent({
      eventCat: 'Schools Access Seller Enrollment Modal',
      eventAct: 'TpTSA Seller Closed Modal',
      eventLbl: ''
    });
  };

  userClosedSellerEnrollmentModal = () =>
    localStorage.getItem(ACCESS_MODAL_CLOSED_BY_USER_KEY) !== null;

  render() {
    const { hasOptedOutOfSchools2020Catalog } = this.props;
    const { isFirstTimeModalOpened } = this.state;
    if (!isFirstTimeModalOpened) return null;

    if (hasOptedOutOfSchools2020Catalog) {
      analytics.trackEvent({
        eventCat: 'Schools Access Seller Enrollment Modal',
        eventAct: 'TpTSA Seller Opted Out',
        eventLbl: ''
      });
    } else {
      analytics.trackEvent({
        eventCat: 'Schools Access Seller Enrollment Modal',
        eventAct: 'TpTSA Seller Opted In',
        eventLbl: ''
      });
    }

    return (
      <AsyncSchoolsAccessSellerEnrollmentModalLayout
        hasOptedOutOfSchools2020Catalog={hasOptedOutOfSchools2020Catalog}
        isModalOpen
        onClose={this.closeModal}
      />
    );
  }
}

SchoolsAccessSellerEnrollmentModalContainer.propTypes = {
  hasOptedOutOfSchools2020Catalog: PropTypes.bool
};

export default SchoolsAccessSellerEnrollmentModalContainer;
