import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { ITEM_TYPE_SCHOOL_FUNDS } from 'config/constants';
import FormattedNumber from 'react-intl/src/components/number';
import Icon from 'components/Icon';
import Anchor from 'components/Anchor';
import LinkButton from 'components/LinkButton';
import Menu from 'components/Menu';
import MenuItem from 'components/MenuItem';
import Dropdown from 'components/Dropdown';
import Text from 'components/Text';

import './HeaderCart.scss';

const numberProps = { currency: 'USD', style: 'currency' };

const CartLabel = ({ licenseCount, className, to, hideLabel }) => (
  <div className={classnames('HeaderCartBespoke__label', className)}>
    <Anchor
      forceReload
      to={to}
      color="black"
      decorateOnHover={false}
      className="HeaderCartBespoke__cartLink"
      aria-label="Cart"
    >
      <span className="HeaderCartBespoke__badge">
        <Icon className="HeaderCartBespoke__badgeIcon" name="shopping-cart" />
        {licenseCount > 0 && (
          // TODO: consolidate with `Badge` component
          <span className="HeaderCartBespoke__badgeCount">
            <span className="HeaderCartBespoke__badgeCountText">{licenseCount}</span>
          </span>
        )}
      </span>
      {!hideLabel && (
        <span className="HeaderCartBespoke__labelText">
          <Text component="span">Cart</Text>
        </span>
      )}
    </Anchor>
  </div>
);

CartLabel.defaultProps = {
  licenseCount: 0,
  to: '',
  hideLabel: false
};

CartLabel.propTypes = {
  /** Item count to show next to cart icon */
  licenseCount: PropTypes.number,
  className: PropTypes.string,
  to: PropTypes.string,
  hideLabel: PropTypes.bool
};

const CartItem = ({ item }) =>
  item.type === ITEM_TYPE_SCHOOL_FUNDS ? (
    <MenuItem>
      <div className="HeaderCartBespoke__itemName">{item.title}</div>
      <div className="HeaderCartBespoke__itemPrice">
        <FormattedNumber value={item.price} {...numberProps} />
      </div>
    </MenuItem>
  ) : (
    <Anchor forceReload to={item.url}>
      <MenuItem>
        <div className="HeaderCartBespoke__itemName">{item.title}</div>
        <div className="HeaderCartBespoke__itemPrice">
          <FormattedNumber value={item.price} {...numberProps} />
        </div>
      </MenuItem>
    </Anchor>
  );

CartItem.propTypes = {
  item: PropTypes.shape({
    price: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

const CartItems = ({ items }) => (
  <Menu className="HeaderCartBespoke__items">
    {items.length > 0 ? (
      items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <CartItem item={item} key={i} />
      ))
    ) : (
      <span>Cart is empty</span>
    )}
  </Menu>
);

CartItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    })
  ).isRequired
};

const HeaderCartBespoke = ({ cart, handleButtonClick, isResponsive, isShortened }) =>
  isResponsive ? (
    <CartLabel
      to="/Cart"
      licenseCount={cart.licenseCount}
      className="HeaderCartBespoke__label--responsive"
      hideLabel={isShortened}
    />
  ) : (
    <Dropdown
      className="HeaderCartBespoke"
      handleButtonClick={handleButtonClick}
      isResponsive={isResponsive}
      label={<CartLabel to="/Cart" licenseCount={cart.licenseCount} hideLabel={isShortened} />}
      labelBy="Cart"
      hideLabel={isShortened}
      openOnHover
    >
      <div className="HeaderCartBespoke__dropdown">
        <CartItems items={cart.items} />
        <div className="HeaderCartBespoke__total">
          Total:
          <div className="HeaderCartBespoke__totalPrice">
            <FormattedNumber value={cart.total} {...numberProps} />
          </div>
        </div>
        <div className="HeaderCartBespoke__footer">
          <Anchor
            forceReload
            className="HeaderCartBespoke__viewWishList underlined"
            to="/Wish-List"
          >
            View Wish List
          </Anchor>
          <Icon className="HeaderCartBespoke__viewWishListIcon" name="angle-right" />
          <LinkButton
            className="HeaderCartBespoke__viewCart green cta"
            forceReload
            label="View Cart"
            to="/Cart"
          />
        </div>
      </div>
    </Dropdown>
  );

HeaderCartBespoke.defaultProps = {
  isResponsive: false,
  isShortened: false
};

HeaderCartBespoke.propTypes = {
  cart: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
      })
    ),
    licenseCount: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired,

  /** Whether to adjust spacing as necessary to align in new responsive grid */
  isResponsive: PropTypes.bool,
  isShortened: PropTypes.bool
};

export default HeaderCartBespoke;
